"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAssignAppBasePrefix = void 0;
const commons_1 = require("@gerber/commons");
const use_app_config_1 = require("../config/use-app-config");
const useAssignAppBasePrefix = () => {
    const { basePrefix } = (0, use_app_config_1.useAppConfig)();
    return (0, commons_1.concatUrlParts2C)(basePrefix);
};
exports.useAssignAppBasePrefix = useAssignAppBasePrefix;
