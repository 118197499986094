"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingOfferToolbarItemTooltip = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_tooltip_1 = require("react-tooltip");
const commons_front_1 = require("@gerber/commons-front");
const icons_1 = require("@gerber/icons");
const booking_offer_toolbar_item_tooltip_styled_1 = require("./booking-offer-toolbar-item-tooltip.styled");
const BookingOfferToolbarItemTooltip = ({ description, }) => {
    const tooltipId = (0, react_1.useId)();
    const [key, refreshKey] = (0, commons_front_1.useRefreshableKey)();
    const outsideRef = (0, commons_front_1.useOutsideClickRef)(refreshKey);
    return ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(booking_offer_toolbar_item_tooltip_styled_1.BookingOfferToolbarItemTooltipHolder, { ref: outsideRef, "data-tooltip-id": tooltipId, children: (0, jsx_runtime_1.jsx)(icons_1.InfoIcon, {}) }), (0, jsx_runtime_1.jsx)(react_tooltip_1.Tooltip, { id: tooltipId, content: description })] }, key));
};
exports.BookingOfferToolbarItemTooltip = BookingOfferToolbarItemTooltip;
