"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingOfferToolbarPackageBadge = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_1 = require("@gerber/icons");
const modals_1 = require("client/modules/booking/booking-wizard/modals");
const ui_1 = require("client/ui");
const BookingOfferToolbarPackageBadge = ({ offer, }) => {
    const pkgGroupDetailsModal = (0, modals_1.useBookingPackageGroupDetailsModal)();
    return ((0, jsx_runtime_1.jsxs)(ui_1.BadgeWithIcon, { "$hoverable": true, "$kind": "sunYellow", role: "button", onClick: () => void pkgGroupDetailsModal.show({
            packageGroup: offer.package.group,
        }), children: [offer.package.group.name, (0, jsx_runtime_1.jsx)(icons_1.ChevronDownIcon, { strokeWidth: 2, style: { height: '0.45rem' } })] }));
};
exports.BookingOfferToolbarPackageBadge = BookingOfferToolbarPackageBadge;
