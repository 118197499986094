"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalNormalize = void 0;
const styled_components_1 = require("styled-components");
const helpers_1 = require("../helpers");
exports.GlobalNormalize = (0, styled_components_1.createGlobalStyle) `
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    ${({ theme: { lineHeight, color, font } }) => (0, styled_components_1.css) `
      font-family: ${font.name};
      font-size: ${font.size.base};
      line-height: ${lineHeight.base};

      ${(0, helpers_1.genPaletteColorCSS)(color.parts.backdrop)}
    `}
  }
`;
