"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingMonthSelector = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const function_1 = require("fp-ts/function");
const fp_1 = require("date-fns/fp");
const forms_1 = require("@under-control/forms");
const icons_1 = require("@gerber/icons");
const helpers_1 = require("./helpers");
const booking_month_selector_styled_1 = require("./booking-month-selector.styled");
const i18n_1 = require("client/i18n");
exports.BookingMonthSelector = (0, forms_1.controlled)(({ disabled, control: { value, setValue } }) => {
    const { lang } = (0, i18n_1.useI18n)();
    const month = (0, helpers_1.getApproxFocusedMonthName)(lang)(value);
    const onAddMonth = (amount) => () => {
        setValue({
            value: (0, function_1.pipe)(month.date, (0, fp_1.addMonths)(amount), fp_1.startOfMonth),
        });
    };
    return ((0, jsx_runtime_1.jsxs)(booking_month_selector_styled_1.BookingMonthSelectorStyled, { children: [(0, jsx_runtime_1.jsx)(booking_month_selector_styled_1.BookingMonthSelectorArrowButton, { disabled: disabled, onClick: onAddMonth(-1), children: (0, jsx_runtime_1.jsx)(icons_1.LongArrowRightThinIcon, { direction: "left" }) }), (0, jsx_runtime_1.jsx)(booking_month_selector_styled_1.BookingMonthSelectorTitle, { children: `${month.name} ${month.date.getFullYear()}` }), (0, jsx_runtime_1.jsx)(booking_month_selector_styled_1.BookingMonthSelectorArrowButton, { disabled: disabled, onClick: onAddMonth(1), children: (0, jsx_runtime_1.jsx)(icons_1.LongArrowRightThinIcon, { direction: "right" }) })] }));
});
