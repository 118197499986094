"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingMobileStartLogo = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BookingMobileStartLogo = (0, styled_components_1.styled)(commons_front_1.Picture) `
  max-width: 60%;
  max-height: 30vh;
  grid-area: logo;
`;
