"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokBaseBlockContentV = exports.SdkStoryblokBaseBlockV = void 0;
const zod_1 = require("zod");
const sdk_storyblok_header_block_1 = require("./sdk-storyblok-header.block");
const sdk_storyblok_button_block_1 = require("./sdk-storyblok-button.block");
const sdk_storyblok_spacer_block_1 = require("./sdk-storyblok-spacer.block");
const sdk_storyblok_text_block_1 = require("./sdk-storyblok-text.block");
const sdk_storyblok_billboard_block_1 = require("./sdk-storyblok-billboard.block");
const sdk_storyblok_rich_text_block_1 = require("./sdk-storyblok-rich-text.block");
const sdk_storyblok_html_block_1 = require("./sdk-storyblok-html.block");
exports.SdkStoryblokBaseBlockV = zod_1.z.union([
    sdk_storyblok_header_block_1.SdkStoryblokHeaderBlockV,
    sdk_storyblok_button_block_1.SdkStoryblokButtonBlockV,
    sdk_storyblok_spacer_block_1.SdkStoryblokSpacerBlockV,
    sdk_storyblok_text_block_1.SdkStoryblokTextBlockV,
    sdk_storyblok_billboard_block_1.SdkStoryblokBillboardBlockV,
    sdk_storyblok_html_block_1.SdkStoryblokHTMLBlockV,
    sdk_storyblok_rich_text_block_1.SdkStoryblokRichTextBlockV,
]);
exports.SdkStoryblokBaseBlockContentV = zod_1.z
    .array(exports.SdkStoryblokBaseBlockV)
    .default([]);
