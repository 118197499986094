"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormTooltip = void 0;
const styled_components_1 = require("styled-components");
const components_1 = require("client/components");
exports.FormTooltip = (0, styled_components_1.styled)(components_1.Text) `
  display: block;

  ${({ theme }) => (0, styled_components_1.css) `
    margin-top: ${theme.spacing[1]};
    font-size: ${theme.font.size.xs};
  `}

  &:empty {
    display: none;
  }
`;
