"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flattenChildren = void 0;
const react_1 = require("react");
const flattenChildren = (children) => {
    const childrenArray = react_1.Children.toArray(children);
    return childrenArray.flatMap(child => {
        if (child.type === react_1.Fragment) {
            return (0, exports.flattenChildren)(child.props.children);
        }
        return [child];
    }, []);
};
exports.flattenChildren = flattenChildren;
