"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccordionToolbar = exports.AccordionInfoCardTitle = exports.AccordionInfoCardHeader = void 0;
const styled_components_1 = require("styled-components");
const info_card_1 = require("../info-card");
exports.AccordionInfoCardHeader = (0, styled_components_1.styled)(info_card_1.InfoCardHeader) `
  display: grid;
  grid-template: 'title toolbar' / 1fr max-content;
  align-items: center;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    gap: ${spacing[2]};
  `}
`;
exports.AccordionInfoCardTitle = styled_components_1.styled.div `
  display: flex;
  grid-area: title;

  &[role='button'] {
    cursor: pointer;
  }

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    flex-direction: row;
    align-items: center;
    gap: ${spacing[2]};
  `}
`;
exports.AccordionToolbar = styled_components_1.styled.div `
  display: flex;
  flex-direction: row;
  grid-area: toolbar;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    gap: ${spacing[2]};
  `}
`;
