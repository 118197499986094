"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbFooterLogo = void 0;
const styled_components_1 = require("styled-components");
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
exports.SbFooterLogo = (0, styled_components_1.styled)(react_1.SbPicture).attrs({
    optimize: {
        size: {
            w: 120,
            h: 0,
        },
    },
}) `
  max-width: 120px;
  margin: 0 auto;
`;
