"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppFonts = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const resources_1 = require("./resources");
const config_1 = require("client/config");
const AppFonts = ({ name = 'Roboto', pack = resources_1.FONTS_PACK, }) => {
    const config = (0, config_1.useAppConfig)();
    if (name in pack) {
        const Component = pack[name];
        return (0, jsx_runtime_1.jsx)(Component, { basePrefix: config.basePrefix });
    }
    return (0, jsx_runtime_1.jsx)(resources_1.LocalFont, { name: name });
};
exports.AppFonts = AppFonts;
