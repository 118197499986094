"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingMobileDetailsFooter = exports.BookingMobileDetailsHolder = exports.BookingMobileDetailsModalHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const ui_1 = require("client/ui");
exports.BookingMobileDetailsModalHolder = (0, styled_components_1.styled)(ui_1.BaseFullModal) `
  display: grid;
  grid-template-rows: 1fr min-content;
`;
exports.BookingMobileDetailsHolder = styled_components_1.styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    padding: ${spacing[5]} ${spacing[4]};
  `}
`;
exports.BookingMobileDetailsFooter = styled_components_1.styled.div `
  ${commons_front_1.styles.flexCenter}

  ${({ theme: { color, spacing } }) => (0, styled_components_1.css) `
    padding: ${spacing[3]} ${spacing[4]} ${spacing[3]};
    border-top: 1px solid ${color.parts.divider.light.main};
  `}
`;
