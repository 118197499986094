"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopSidebarTruncatedHeader = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const booking_desktop_sidebar_truncated_header_styled_1 = require("./booking-desktop-sidebar-truncated-header.styled");
const booking_desktop_sidebar_truncated_logo_styled_1 = require("./booking-desktop-sidebar-truncated-logo.styled");
const toolbar_1 = require("./toolbar");
const components_1 = require("client/components");
const context_1 = require("client/modules/booking/booking-wizard/context");
const BookingDesktopSidebarTruncatedHeader = () => {
    const config = (0, context_1.useBookingWizardConfigContext)();
    return ((0, jsx_runtime_1.jsxs)(booking_desktop_sidebar_truncated_header_styled_1.BookingDesktopSidebarTruncatedHeaderHolder, { children: [(0, jsx_runtime_1.jsx)(components_1.UniversalLink, { href: config.hotel.websiteUrl, target: "_blank", ...(0, commons_front_1.testid)('sidebar:website-logo'), children: (0, jsx_runtime_1.jsx)(booking_desktop_sidebar_truncated_logo_styled_1.BookingDesktopSidebarTruncatedLogo, { src: config.start.logo }) }), (0, jsx_runtime_1.jsx)(toolbar_1.BookingDesktopSidebarTruncatedToolbar, {})] }));
};
exports.BookingDesktopSidebarTruncatedHeader = BookingDesktopSidebarTruncatedHeader;
