"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGTMTrackBookingBasket = void 0;
const O = __importStar(require("fp-ts/Option"));
const function_1 = require("fp-ts/function");
const react_1 = require("react");
const commons_front_1 = require("@gerber/commons-front");
const hooks_1 = require("../hooks");
const form_1 = require("client/modules/booking/booking-wizard/form");
const useGTMTrackBookingBasket = ({ value, }) => {
    const gtm = (0, hooks_1.useGTM)();
    const completeRooms = (0, react_1.useMemo)(() => value.rooms.filter(form_1.areCriticalBookingRoomValuesFilled), [value.rooms]);
    const prevRoomsValue = (0, commons_front_1.useInstantPreviousPersistent)(completeRooms);
    const trackCartOperationItems = (event) => (items) => {
        items.forEach(item => {
            const { price, roomCategoryId, room } = item.offer.selected;
            gtm.trackGenericEvent({
                name: event,
                package_group_id: (0, form_1.extractWizardPackageGroupId)(value),
                ecommerce: {
                    currency: price.currency,
                    value: price.amount,
                    items: [
                        {
                            item_id: roomCategoryId,
                            item_name: room.category.name,
                            price: price.amount,
                            quantity: 1,
                        },
                    ],
                },
            });
        });
    };
    const trackDiffedRooms = (prev, current) => {
        (0, function_1.pipe)((0, form_1.diffRoomsByCategoryAndPrice)(prev)(current), trackCartOperationItems('add_to_cart'));
        (0, function_1.pipe)((0, form_1.diffRoomsByCategoryAndPrice)(current)(prev), trackCartOperationItems('remove_from_cart'));
    };
    (0, commons_front_1.useUpdateEffect)(() => {
        if (O.isSome(prevRoomsValue.prev)) {
            trackDiffedRooms(prevRoomsValue.prev.value, prevRoomsValue.value);
        }
    }, [prevRoomsValue]);
};
exports.useGTMTrackBookingBasket = useGTMTrackBookingBasket;
