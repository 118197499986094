"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoryblokJumbotronButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const commons_front_1 = require("@gerber/commons-front");
const icons_1 = require("@gerber/icons");
const parts_1 = require("./parts");
const ui_1 = require("client/ui");
const i18n_1 = require("client/i18n");
exports.StoryblokJumbotronButton = (0, react_1.memo)(() => {
    const { pack } = (0, i18n_1.useI18n)();
    const [visible, setVisible] = (0, react_1.useState)(false);
    const checkIfScrollVisible = () => {
        setVisible(!window.scrollY);
    };
    const onClick = () => {
        const header = document.querySelector('h1');
        if (!header) {
            return;
        }
        window.scrollTo({
            top: header.getBoundingClientRect().top + window.scrollY - 100,
            behavior: 'smooth',
        });
    };
    (0, commons_front_1.useWatchScreenSize)(checkIfScrollVisible);
    (0, commons_front_1.useWindowListener)({
        scroll: checkIfScrollVisible,
    });
    return (visible && ((0, jsx_runtime_1.jsxs)(parts_1.SbJumbotronScrollButtonHolder, { onClick: onClick, children: [pack.common.scroll, (0, jsx_runtime_1.jsx)(ui_1.ButtonSuffixIcon, { children: (0, jsx_runtime_1.jsx)(icons_1.LongArrowRightThinIcon, { direction: "bottom" }) })] })));
});
exports.StoryblokJumbotronButton.displayName = 'StoryblokJumbotronButton';
