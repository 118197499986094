"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingOffersGrid = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const forms_1 = require("@under-control/forms");
const booking_offer_card_1 = require("../../offer/booking-offer-card");
const booking_offers_grid_styled_1 = require("./booking-offers-grid.styled");
exports.BookingOffersGrid = (0, react_1.memo)((0, forms_1.controlled)(({ items, control: { bind } }) => ((0, jsx_runtime_1.jsx)(booking_offers_grid_styled_1.BookingOffersGridHolder, { children: items.map(item => ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(booking_offer_card_1.BookingOfferCard, { offer: item, ...bind.entire() }) }, item.room.category.id))) }))));
exports.BookingOffersGrid.displayName = 'BookingOffersGrid';
