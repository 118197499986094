"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentMethodsRow = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const item_1 = require("./item");
const components_1 = require("client/components");
const context_1 = require("client/modules/booking/booking-wizard/context");
exports.PaymentMethodsRow = (0, react_1.memo)(() => {
    const { payment } = (0, context_1.useBookingWizardConfigContext)();
    return ((0, jsx_runtime_1.jsx)(components_1.CleanList, { "$row": true, "$wrap": true, "$gap": 2, children: payment.methods.map(method => ((0, jsx_runtime_1.jsx)(item_1.PaymentMethodItem, { item: method }, method.name))) }));
});
exports.PaymentMethodsRow.displayName = 'PaymentMethodsRow';
