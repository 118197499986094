"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsMounted = void 0;
const react_1 = require("react");
const use_after_mount_1 = require("./use-after-mount");
const useIsMounted = () => {
    const [mounted, setMounted] = (0, react_1.useState)(false);
    (0, use_after_mount_1.useAfterMount)(() => {
        setMounted(true);
    });
    return mounted;
};
exports.useIsMounted = useIsMounted;
