"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollapseSidebarIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
/* eslint-disable max-len */
const CollapseSidebarIcon = ({ style, ...props }) => ((0, jsx_runtime_1.jsx)("svg", { width: "19", height: "19", viewBox: "0 0 19 19", fill: "none", xmlns: "http://www.w3.org/2000/svg", style: {
        transform: 'rotate(-90deg)',
        transition: 'transform 250ms ease-in-out',
        ...style,
    }, ...props, children: (0, jsx_runtime_1.jsx)("path", { d: "M16.3867 2.10859L12.3992 2.10859L12.3992 0.133594L16.3867 0.133594C16.9334 0.133594 17.401 0.328091 17.7904 0.717432C18.1797 1.10677 18.3742 1.57444 18.3742 2.12109L18.3742 16.1211C18.3742 16.6678 18.1797 17.1354 17.7904 17.5248C17.401 17.9141 16.9334 18.1086 16.3867 18.1086L2.38672 18.1086C1.84006 18.1086 1.3724 17.9141 0.983057 17.5248C0.593716 17.1354 0.39922 16.6678 0.39922 16.1211L0.399221 2.12109C0.399221 1.57443 0.593717 1.10677 0.983059 0.717431C1.3724 0.328089 1.84006 0.133593 2.38672 0.133593L6.37422 0.133593L6.37422 2.10859L2.38672 2.10859L2.37422 2.10859L2.37422 2.12109L2.37422 12.1211L2.37422 12.1336L2.38672 12.1336L16.3867 12.1336L16.3992 12.1336L16.3992 12.1211L16.3992 2.12109L16.3992 2.10859L16.3867 2.10859ZM10.3956 6.27993L11.9866 4.68893L13.3692 6.12094L9.38672 10.1034L5.40424 6.12094L6.78688 4.68893L8.37788 6.27993L8.39922 6.30127L8.39922 6.27109L8.39922 0.133594L10.3742 0.133594L10.3742 6.27109L10.3742 6.30127L10.3956 6.27993Z", fill: "currentColor", strokeWidth: "0.025" }) }));
exports.CollapseSidebarIcon = CollapseSidebarIcon;
