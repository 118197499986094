"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopToggleSidebarWrapper = exports.BookingDesktopSidebarWrapperHolder = exports.SIDEBAR_TOGGLE_BREAKPOINT = exports.SIDEBAR_EXTEND_BREAKPOINT = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const styles_1 = require("@gerber/commons-front/dist/styles");
exports.SIDEBAR_EXTEND_BREAKPOINT = (0, styles_1.mediaMinWidth)(1500);
exports.SIDEBAR_TOGGLE_BREAKPOINT = (0, styles_1.mediaMaxWidth)(1200);
exports.BookingDesktopSidebarWrapperHolder = styled_components_1.styled.div `
  display: grid;
  grid-template-areas: 'content sidebar';
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr 350px;

  ${exports.SIDEBAR_EXTEND_BREAKPOINT} {
    grid-template-columns: 1fr 400px;
  }

  ${exports.SIDEBAR_TOGGLE_BREAKPOINT} {
    grid-template-areas: 'content';
    grid-template-columns: 1fr;
  }
`;
exports.BookingDesktopToggleSidebarWrapper = styled_components_1.styled.div `
  grid-area: sidebar;
  transition: 250ms ${commons_front_1.styles.timingFunction.smooth.out} transform;

  ${exports.SIDEBAR_TOGGLE_BREAKPOINT} {
    position: fixed;
    height: 100%;
    right: 0;
    top: 0;
    width: 350px;
    transform: translateX(100%);

    ${({ $toggledOnTablet }) => $toggledOnTablet &&
    (0, styled_components_1.css) `
        transform: translateX(0);
        box-shadow: -10px 0px 16px 1px rgba(0, 0, 0, 0.11);
      `}
  }
`;
