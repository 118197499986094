"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ICONS_MAP = exports.IconNameV = void 0;
const zod_1 = require("zod");
const commons_front_1 = require("@gerber/commons-front");
const I = __importStar(require("./icons"));
exports.IconNameV = zod_1.z.enum([
    'accept-check',
    'airline',
    'arrow-360',
    'bed',
    'calendar',
    'check-circle',
    'check',
    'chevron-down',
    'collapse-sidebar',
    'copy',
    'edit',
    'ending-flag',
    'error',
    'hide-in-browser',
    'home',
    'info',
    'long-arrow-down-thin',
    'long-arrow-right-thin',
    'long-arrow-right',
    'notifications',
    'open-in-browser',
    'people',
    'phone',
    'plus',
    'share',
    'sidebar',
    'times',
    'warning',
]);
exports.ICONS_MAP = {
    'accept-check': I.AcceptCheckIcon,
    airline: I.AirlineIcon,
    'arrow-360': I.Arrow360Icon,
    bed: I.BedIcon,
    calendar: I.CalendarIcon,
    'check-circle': I.CheckCircleIcon,
    check: I.CheckIcon,
    'chevron-down': I.ChevronDownIcon,
    'collapse-sidebar': I.CollapseSidebarIcon,
    copy: I.CopyIcon,
    edit: I.EditIcon,
    'ending-flag': I.EndingFlagIcon,
    error: I.ErrorIcon,
    'hide-in-browser': I.HideInBrowserIcon,
    home: I.HomeIcon,
    info: I.InfoIcon,
    'long-arrow-right-thin': I.LongArrowRightThinIcon,
    'long-arrow-right': I.LongArrowRight,
    notifications: I.NotificationIcon,
    'open-in-browser': I.OpenInBrowserIcon,
    people: I.PeopleIcon,
    phone: I.PhoneIcon,
    plus: I.PlusIcon,
    share: I.ShareIcon,
    sidebar: I.SidebarIcon,
    times: I.TimesIcon,
    warning: I.WarningIcon,
    // predefined ones
    'long-arrow-down-thin': (0, commons_front_1.withProps)({
        direction: 'bottom',
    })(I.LongArrowRightThinIcon),
};
