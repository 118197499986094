"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SidebarSummaryWrapper = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.SidebarSummaryWrapper = styled_components_1.styled.div `
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.1);

  ${({ theme: { color, spacing } }) => (0, styled_components_1.css) `
    ${(0, commons_front_1.genPaletteColorCSS)(color.alias.black)}

    padding: ${spacing[4]} ${spacing[6]} ${spacing[5]};

    ${commons_front_1.styles.mediaSizeDown.sm} {
      margin-top: ${spacing[3]};
      padding: ${spacing[4]} ${spacing[4]} ${spacing[5]};
    }
  `}
`;
