"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkSbListRendererHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const dto_1 = require("../../../../../dto");
const config = {
    shouldForwardProp: (0, commons_front_1.shouldNotForwardProps)([
        'justify',
        'row',
    ]),
};
exports.SdkSbListRendererHolder = styled_components_1.styled.div.withConfig(config) `
  display: flex;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  text-align: left;

  > button {
    width: min-width;
  }

  ${({ justify = 'left' }) => (0, styled_components_1.css) `
    align-items: ${(0, dto_1.toFlexStoryblokJustify)(justify)};
  `}
`;
