"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkGerberApiController = void 0;
const function_1 = require("fp-ts/function");
const commons_1 = require("@gerber/commons");
class SdkGerberApiController {
    constructor(client) {
        this.client = client;
        this.fetchApiTE = ({ path, ...attrs }) => this.client.fetchApiTE({
            ...attrs,
            path: (0, commons_1.concatUrlParts)(this.defaultBasePath, path),
        });
        this.fetchApiAndValidateTE = (schema) => (attrs) => (0, function_1.pipe)(this.fetchApiTE(attrs), (0, commons_1.tryParseUsingZodSchemaTE)(schema));
    }
    get oneId() {
        return this.client.config.oneId;
    }
}
exports.SdkGerberApiController = SdkGerberApiController;
