"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getComputedTranslate = void 0;
const getComputedTranslate = (element) => {
    const { transform } = getComputedStyle(element);
    let mat = transform.match(/^matrix3d\((.+)\)$/);
    if (mat) {
        const split = mat[1].split(', ');
        return {
            x: parseFloat(split[13]),
            y: parseFloat(split[14]),
        };
    }
    mat = transform.match(/^matrix\((.+)\)$/);
    if (mat) {
        const split = mat[1].split(', ');
        return {
            x: parseFloat(split[4]),
            y: parseFloat(split[5]),
        };
    }
    return {
        x: 0,
        y: 0,
    };
};
exports.getComputedTranslate = getComputedTranslate;
