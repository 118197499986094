"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeSearchParams = void 0;
const safe_to_array_1 = require("../safe-to-array");
const decodeSearchParams = (url) => {
    const entries = [...new URLSearchParams(url.split('?')[1] || '').entries()];
    return entries.reduce((acc, [key, value]) => {
        if (key.endsWith('[]')) {
            const truncatedKey = key.slice(0, -2);
            const prevValue = truncatedKey in acc ? (0, safe_to_array_1.safeToArray)(acc[truncatedKey]) : [];
            acc[truncatedKey] = [...prevValue, value];
        }
        else {
            acc[key] = value;
        }
        return acc;
    }, {});
};
exports.decodeSearchParams = decodeSearchParams;
