"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDateScreenMobile = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const shared_1 = require("../shared");
const hooks_1 = require("../hooks");
const decorators_1 = require("client/modules/booking/booking-wizard/decorators");
const booking_calendar_1 = require("client/modules/booking/booking-calendar");
const parts_1 = require("client/modules/booking/booking-wizard/parts");
exports.BookingDateScreenMobile = (0, decorators_1.controlledBookingValue)(({ control }) => {
    const showRoomSwitch = (0, parts_1.useIsMobileRoomSwitchVisible)();
    const { handleSubmitEvent, bind, value, isFilled, loading, filters, firstMonth, preselectedRanges, } = (0, hooks_1.useBookingDateScreenForm)({
        control,
    });
    const sharedCalendarProps = {
        ...(value.packageGroup && {
            toolbar: (0, jsx_runtime_1.jsx)(shared_1.DateScreenPackageNotification, {}),
        }),
    };
    return ((0, jsx_runtime_1.jsx)(parts_1.BookingMobileScreenContent, { ...(0, commons_front_1.testid)('wizard:date'), spaced: false, footer: (0, jsx_runtime_1.jsx)(parts_1.BookingMobileFooter, { disabled: { next: !isFilled }, submitOnNext: true }), onSubmit: handleSubmitEvent, ...(showRoomSwitch && {
            toolbar: (0, jsx_runtime_1.jsx)(parts_1.BookingMobileRoomSwitch, {}),
        }), children: loading ? ((0, jsx_runtime_1.jsx)(booking_calendar_1.BookingCalendarSkeleton, { ...sharedCalendarProps })) : ((0, jsx_runtime_1.jsx)(booking_calendar_1.BookingCalendar, { ...bind.path('date'), roomOffer: value.offer.selected, filters: filters, firstMonth: firstMonth.value, preselectedRanges: preselectedRanges, ...sharedCalendarProps })) }));
});
