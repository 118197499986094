"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseModal = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const parts_1 = require("./parts");
const base_modal_styled_1 = require("./base-modal.styled");
const BaseModal = ({ out, size, minHeight, innerScroll, expandedMobile, children, onClose, ...props }) => {
    (0, commons_front_1.useLockWindowScroll)();
    (0, commons_front_1.useWindowListener)({
        click: e => {
            if (e.target.closest('a')) {
                onClose();
            }
        },
    });
    return ((0, jsx_runtime_1.jsx)(parts_1.BaseModalLayer, { out: out, onClose: onClose, ...props, children: (0, jsx_runtime_1.jsxs)(base_modal_styled_1.BaseModalHolder, { out: out, minHeight: minHeight, size: size, innerScroll: innerScroll, expandedMobile: expandedMobile, children: [(0, jsx_runtime_1.jsx)(parts_1.BaseModalCloseButton, { onClick: onClose }), children] }) }));
};
exports.BaseModal = BaseModal;
