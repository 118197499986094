"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingContactScreenMobile = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const commons_front_1 = require("@gerber/commons-front");
const form_1 = require("../form");
const form_2 = require("client/modules/booking/booking-wizard/form");
const i18n_1 = require("client/i18n");
const context_1 = require("client/modules/booking/booking-wizard/context");
const components_1 = require("client/components");
const ui_1 = require("client/ui");
const parts_1 = require("client/modules/booking/booking-wizard/parts");
exports.BookingContactScreenMobile = (0, react_1.memo)(({ onPay }) => {
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.contact;
    const globalForm = (0, context_1.useBookingGlobalForm)();
    const { bind, validator, submitState, value, handleSubmitEvent } = (0, form_1.useBookingContactScreenForm)({
        control: globalForm,
        onSubmit: onPay,
    });
    return ((0, jsx_runtime_1.jsxs)(parts_1.BookingMobileScreenContent, { ...(0, commons_front_1.testid)('wizard:contact'), footer: (0, jsx_runtime_1.jsx)(parts_1.BookingMobileFooter, { nextButton: (0, jsx_runtime_1.jsx)(ui_1.PayNowButton, { type: "submit", disabled: !(0, form_2.isTermsSectionFilled)(value) || submitState.loading }) }), onSubmit: handleSubmitEvent, children: [(0, jsx_runtime_1.jsx)(components_1.H1, { color: "primary", children: t.header }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 8 }), (0, jsx_runtime_1.jsx)(form_1.BookingContactForm, { ...bind.entire(), errors: validator.errors.all }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 8 })] }));
});
exports.BookingContactScreenMobile.displayName = 'BookingContactScreenMobile';
