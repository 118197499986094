"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.restoreDateFromSessionItem = void 0;
const date_fns_1 = require("date-fns");
const commons_1 = require("@gerber/commons");
const restoreDateFromSessionItem = (sessionItem) => {
    const beginDate = (0, commons_1.getZonedDate)(new Date(sessionItem.arrivalDay));
    return {
        range: {
            begin: beginDate,
            end: (0, date_fns_1.addDays)(beginDate, sessionItem.nights),
        },
        offer: {
            arrivalDay: beginDate,
            minimumPrice: sessionItem.price,
            nights: sessionItem.nights,
            date: (0, date_fns_1.addDays)(beginDate, sessionItem.nights),
        },
    };
};
exports.restoreDateFromSessionItem = restoreDateFromSessionItem;
