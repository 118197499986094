"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBookingWizardPackageGroupId = exports.extractWizardPackageGroupId = void 0;
const react_1 = require("react");
const context_1 = require("../../context");
const extractWizardPackageGroupId = (value) => value.rooms?.find(room => !!room.packageGroup)?.packageGroup?.id ?? null;
exports.extractWizardPackageGroupId = extractWizardPackageGroupId;
const useBookingWizardPackageGroupId = () => {
    const { value } = (0, context_1.useBookingGlobalForm)();
    return (0, react_1.useMemo)(() => (0, exports.extractWizardPackageGroupId)(value), [value]);
};
exports.useBookingWizardPackageGroupId = useBookingWizardPackageGroupId;
