"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkSessionItemV = exports.SdkSessionItemIdV = void 0;
const zod_1 = require("zod");
const dto_1 = require("../../../dto");
const sdk_session_item_guest_dto_1 = require("./sdk-session-item-guest.dto");
exports.SdkSessionItemIdV = zod_1.z.string();
exports.SdkSessionItemV = zod_1.z.intersection(zod_1.z.object({
    id: zod_1.z.string(),
    rateId: zod_1.z.number(),
    roomCategoryId: zod_1.z.number(),
    arrivalDay: zod_1.z.string(),
    variantId: zod_1.z.string().nullable().optional(),
    guests: zod_1.z.array(sdk_session_item_guest_dto_1.SdkSessionItemGuestV),
    comment: zod_1.z.string().default(''),
    nights: zod_1.z.number(),
    price: dto_1.SdkPriceV,
}), zod_1.z.union([
    zod_1.z.object({
        type: zod_1.z.literal('PACKAGE'),
        packageGroupId: zod_1.z.number(),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('ROOM'),
    }),
]));
