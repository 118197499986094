"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HiddenRequiredInput = exports.HiddenInputHolder = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const styled_components_1 = require("styled-components");
exports.HiddenInputHolder = styled_components_1.styled.input `
  opacity: 0;
  height: 1px;
  display: block;
`;
const HiddenRequiredInput = ({ required, ...props }) => ((0, jsx_runtime_1.jsx)(exports.HiddenInputHolder, { ...props, required: required, value: required ? '' : '1', onChange: () => { } }));
exports.HiddenRequiredInput = HiddenRequiredInput;
