"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalsContextProvider = exports.ModalsContext = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const use_ref_safe_callback_1 = require("../use-ref-safe-callback");
exports.ModalsContext = (0, react_1.createContext)(null);
const ModalsContextProvider = ({ children, }) => {
    const [modals, setModals] = (0, react_1.useState)({});
    const counterRef = (0, react_1.useRef)(0);
    const modalVisible = Object.keys(modals).length > 0;
    const hideModal = (0, use_ref_safe_callback_1.useRefSafeCallback)((uuid, result) => {
        modals[uuid]?.onClose?.(result);
        setModals(({ [uuid]: _, ...otherModals }) => otherModals);
    });
    const showModal = (0, use_ref_safe_callback_1.useRefSafeCallback)((modal) => {
        const nextCounter = counterRef.current + 1;
        counterRef.current = nextCounter;
        const uuid = `modal-${nextCounter}-${Date.now()}`;
        setModals(prevModals => ({
            ...prevModals,
            [uuid]: modal,
        }));
        return {
            uuid,
            unmount: () => {
                hideModal(uuid);
            },
        };
    });
    const updateModalProps = (0, use_ref_safe_callback_1.useRefSafeCallback)((uuid, props) => {
        setModals(prevModals => {
            const prevModal = prevModals[uuid];
            if (!prevModal) {
                return prevModals;
            }
            return {
                ...prevModals,
                [uuid]: {
                    ...prevModal,
                    showProps: {
                        ...prevModal?.showProps,
                        ...props,
                    },
                },
            };
        });
    });
    return ((0, jsx_runtime_1.jsxs)(exports.ModalsContext.Provider, { value: {
            modals,
            showModal,
            hideModal,
            updateModalProps,
        }, children: [typeof children === 'function' ? children({ modalVisible }) : children, modalVisible && ((0, jsx_runtime_1.jsx)("div", { id: "modals-root", children: Object.entries(modals).map(([uuid, modalConfig]) => (0, react_1.cloneElement)(modalConfig.renderModalContent({
                    showProps: modalConfig.showProps,
                    onClose: (result) => {
                        hideModal(uuid, result);
                    },
                }), {
                    key: uuid,
                })) }))] }));
};
exports.ModalsContextProvider = ModalsContextProvider;
