"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfoCard = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.InfoCard = styled_components_1.styled.div `
  display: flex;
  flex-direction: column;

  ${({ theme: { spacing, color }, $type, $gap = 4 }) => (0, styled_components_1.css) `
    gap: ${spacing[$gap]};
    padding: ${spacing[4]};
    border: 1px solid ${color.parts.infoCard[$type].border};

    ${(0, commons_front_1.genPaletteColorCSS)(color.parts.infoCard[$type])}
  `}
`;
