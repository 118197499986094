"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CancellationSummary = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const icons_1 = require("@gerber/icons");
const cancellation_summary_styled_1 = require("./cancellation-summary.styled");
const i18n_1 = require("client/i18n");
const context_1 = require("client/modules/booking/booking-wizard/context");
const components_1 = require("client/components");
const ui_1 = require("client/ui");
exports.CancellationSummary = (0, react_1.memo)(() => {
    const config = (0, context_1.useBookingWizardConfigContext)();
    const { pack } = (0, i18n_1.useI18n)();
    return ((0, jsx_runtime_1.jsxs)(cancellation_summary_styled_1.CancellationSummaryHolder, { icon: (0, jsx_runtime_1.jsx)(icons_1.InfoIcon, {}), size: "base", expanded: true, children: [(0, jsx_runtime_1.jsx)("span", { children: pack.booking.wizard.summary.cancellation }), (0, jsx_runtime_1.jsx)(components_1.Text, { size: "sm", children: (0, jsx_runtime_1.jsx)(components_1.UniversalLink, { href: config.hotel.cancellationUrl, target: "_blank", color: "inherit", children: (0, jsx_runtime_1.jsx)(ui_1.MoreInformationCleanButton, { short: true, as: "span" }) }) })] }));
});
exports.CancellationSummary.displayName = 'CancellationSummary';
