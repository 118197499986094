"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WizardContactSerializer = void 0;
const function_1 = require("fp-ts/function");
const O = __importStar(require("fp-ts/Option"));
const commons_1 = require("@gerber/commons");
const sdk_gerber_1 = require("@gerber/sdk-gerber");
exports.WizardContactSerializer = {
    ofCreateSessionUserDTO: (contact) => (0, function_1.pipe)((0, commons_1.dropBlankObjValues)({
        city: contact.address?.city,
        country: contact.address?.country?.id.toString(),
        email: contact.email,
        firstname: contact.firstName,
        lastname: contact.lastName,
        salutation: contact.gender,
        title: contact.title,
        street: contact.address?.street,
        zipcode: contact.address?.zipCode,
        phone: contact.phone,
    }), (0, commons_1.tryParseUsingZodSchema)(sdk_gerber_1.SdkSessionUserInputV, true), O.fromEither),
};
