"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.recreateBookingSession = void 0;
const function_1 = require("fp-ts/function");
const commons_1 = require("@gerber/commons");
const recreate_booking_session_rooms_1 = require("./recreate-booking-session-rooms");
const recreate_booking_session_contact_1 = require("./recreate-booking-session-contact");
const recreateBookingSession = (sdk) => (value) => (session) => {
    const deletedRoomsIds = (0, function_1.pipe)(session.items, (0, commons_1.pluck)('id'));
    return (0, function_1.pipe)(sdk.controllers.session.deleteItems({
        sessionId: session.sessionId,
        ids: deletedRoomsIds,
    }), (0, commons_1.tapTaskEitherTE)(() => (0, recreate_booking_session_rooms_1.recreateBookingSessionRooms)(sdk)(session.sessionId)(value.rooms)), (0, commons_1.tapTaskEitherTE)(() => (0, recreate_booking_session_contact_1.recreateBookingSessionContact)(sdk)(session.sessionId)(value.contact)));
};
exports.recreateBookingSession = recreateBookingSession;
