"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.innerJoinOffersWithConfig = exports.innerJoinOfferWithConfig = void 0;
const function_1 = require("fp-ts/function");
const O = __importStar(require("fp-ts/Option"));
const A = __importStar(require("fp-ts/Array"));
const commons_1 = require("@gerber/commons");
const inner_join_offer_category_with_config_1 = require("./inner-join-offer-category-with-config");
/**
 * Due to shallow API response we have to perform join of records manually.
 * It would be much better to join it on backend side in the future.
 */
const innerJoinOfferWithConfig = (config) => (offer) => {
    const { packages, rates } = config;
    const maybeRate = (0, commons_1.maybeFindItemById)(offer.rateId)(rates);
    const maybeExtendedRoomCategory = (0, inner_join_offer_category_with_config_1.innerJoinOfferCategoryWithConfig)(config)(offer.roomCategoryId);
    switch (offer.type) {
        case 'PACKAGE':
            return (0, function_1.pipe)(O.Do, O.bind('packageCategory', () => (0, commons_1.maybeFindItemById)(offer.packageCategoryId)(packages.categories)), O.bind('packageGroup', ({ packageCategory }) => (0, commons_1.maybeFindItemById)(offer.packageGroupId)(packageCategory.packageGroups)), O.bind('roomCategory', () => maybeExtendedRoomCategory), O.bind('rate', () => maybeRate), O.map(({ packageGroup, packageCategory, roomCategory, rate }) => ({
                ...offer,
                rate,
                package: {
                    group: packageGroup,
                    category: packageCategory,
                },
                room: {
                    category: roomCategory,
                },
            })));
        case 'LAST_MINUTE_PACKAGE':
        case 'ROOM':
            return (0, function_1.pipe)(O.Do, O.bind('roomCategory', () => maybeExtendedRoomCategory), O.bind('rate', () => maybeRate), O.map(({ roomCategory, rate }) => ({
                ...offer,
                rate,
                room: {
                    category: roomCategory,
                },
            })));
        default: {
            const unknownOfferType = offer;
            console.warn('Unknown offer type!', unknownOfferType);
            return O.none;
        }
    }
};
exports.innerJoinOfferWithConfig = innerJoinOfferWithConfig;
const innerJoinOffersWithConfig = (config) => (offers) => (0, function_1.pipe)(offers, A.filterMap((0, exports.innerJoinOfferWithConfig)(config)));
exports.innerJoinOffersWithConfig = innerJoinOffersWithConfig;
