"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokProvider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const sdk_storyblok_1 = require("../../../sdk-storyblok");
const sdk_storyblok_context_1 = require("./sdk-storyblok-context");
const SdkStoryblokProvider = ({ config, children, }) => {
    const value = (0, react_1.useMemo)(() => ({
        sdk: new sdk_storyblok_1.SdkStoryblok(config),
    }), [JSON.stringify(config)]);
    return ((0, jsx_runtime_1.jsx)(sdk_storyblok_context_1.SdkStoryblokContext.Provider, { value: value, children: children }));
};
exports.SdkStoryblokProvider = SdkStoryblokProvider;
