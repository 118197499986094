"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopSidebarTruncatedLogo = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BookingDesktopSidebarTruncatedLogo = (0, styled_components_1.styled)(commons_front_1.Picture) `
  grid-area: logo;
  width: auto;
  height: 48px;
  max-height: 100%;
  margin-right: auto;
`;
