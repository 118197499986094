"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDisabledCell = void 0;
const commons_1 = require("@gerber/commons");
const isDisabledCell = (cell) => ({ selection, offers, disabledRanges }) => {
    const begin = selection?.begin;
    if (!cell.minimumDayPrice && (!offers || !begin)) {
        return true;
    }
    if ((0, commons_1.getTotalDatesRangeDates)({
        begin: cell.date,
        end: (0, commons_1.getZonedDate)(new Date()),
    }) >= 1) {
        return true;
    }
    if (begin &&
        offers &&
        !(0, commons_1.compareDateOnly)(cell.date)(begin) &&
        !offers.some(offer => {
            if ((0, commons_1.isNil)(offer.date)) {
                return false;
            }
            return (0, commons_1.compareDateOnly)(offer.date)(cell.date);
        })) {
        return true;
    }
    if (begin) {
        const beforeSelection = (0, commons_1.getTotalDatesRangeDates)({
            begin: cell.date,
            end: begin,
        }) >= 1;
        if (beforeSelection) {
            return true;
        }
    }
    if (disabledRanges) {
        const disabled = (0, commons_1.findInDateRanges)(cell.date)(disabledRanges);
        if (disabled) {
            return true;
        }
    }
    return false;
};
exports.isDisabledCell = isDisabledCell;
