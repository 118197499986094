"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSessionRestoredBookingWizardFormValue = void 0;
const function_1 = require("fp-ts/function");
const NEA = __importStar(require("fp-ts/NonEmptyArray"));
const O = __importStar(require("fp-ts/Option"));
const TE = __importStar(require("fp-ts/TaskEither"));
const A = __importStar(require("fp-ts/Array"));
const RA = __importStar(require("fp-ts/ReadonlyArray"));
const AP = __importStar(require("fp-ts/Apply"));
const commons_1 = require("@gerber/commons");
const session_restore_1 = require("./session-restore");
const form_1 = require("client/modules/booking/booking-wizard/form");
/**
 * Restores items from SESSION
 */
const getSessionRestoredBookingWizardFormValue = ({ sdk, config }) => (session) => {
    const defaultValue = (0, form_1.createBlankGlobalFormValue)();
    const fetchRoomSessionItem = (sessionItem) => (0, function_1.pipe)(TE.Do, TE.bind('date', () => TE.of((0, session_restore_1.restoreDateFromSessionItem)(sessionItem))), TE.bind('who', () => TE.of((0, session_restore_1.restoreWhoFromSessionItem)(sessionItem))), TE.bind('packageGroup', () => (0, function_1.pipe)(sessionItem, (0, session_restore_1.restorePackageGroupFromSessionItem)(config), O.toNullable, TE.of)), TE.bind('offer', room => {
        if (!(0, commons_1.isNil)(sessionItem.variantId)) {
            return (0, session_restore_1.restoreVariantOfferFromSessionItem)({ config })(sessionItem);
        }
        const maybeFilters = (0, session_restore_1.getOfferFiltersFromSessionItem)(sessionItem);
        if (O.isNone(maybeFilters)) {
            return TE.of(O.none);
        }
        return (0, session_restore_1.restoreOfferFromSessionItem)({
            sdk,
            config,
            room,
        })(maybeFilters.value);
    }), TE.map(({ date, who, offer, packageGroup }) => {
        const base = (0, form_1.createBlankBookingRoom)();
        return {
            ...base,
            meta: {
                ...base.meta,
                sessionItem: {
                    id: sessionItem.id,
                },
            },
            ...(!(0, commons_1.isNil)(sessionItem.variantId) && {
                variant: {
                    id: sessionItem.variantId,
                },
            }),
            comment: sessionItem.comment ?? '',
            date,
            who,
            packageGroup,
            offer: {
                selected: O.toNullable(offer),
            },
        };
    }));
    const fetchRooms = (0, function_1.pipe)(session.items, A.map(fetchRoomSessionItem), TE.sequenceArray, TE.map((0, function_1.flow)(RA.toArray, NEA.fromArray, O.getOrElse(() => NEA.of((0, form_1.createBlankBookingRoom)())))));
    return (0, function_1.pipe)(AP.sequenceS(TE.ApplicativePar)({
        rooms: fetchRooms,
    }), TE.map(({ rooms }) => ({
        ...defaultValue,
        contact: (0, session_restore_1.restoreContactFromSessionItem)({ countries: config.countries })(session.user),
        rooms,
    })));
};
exports.getSessionRestoredBookingWizardFormValue = getSessionRestoredBookingWizardFormValue;
