"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateEffect = void 0;
const react_1 = require("react");
const useUpdateEffect = (effect, dependencies) => {
    const isInitialMountRef = (0, react_1.useRef)(true);
    const isInitialMount = isInitialMountRef.current;
    isInitialMountRef.current = false;
    (0, react_1.useEffect)(() => {
        if (!isInitialMount) {
            effect();
        }
    }, dependencies);
};
exports.useUpdateEffect = useUpdateEffect;
