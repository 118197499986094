"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWobblyGalleryNavigation = void 0;
const commons_1 = require("@gerber/commons");
const react_1 = require("react");
const commons_front_1 = require("@gerber/commons-front");
const utils_1 = require("../utils");
const useWobblyGalleryNavigation = (attrs) => {
    const forwarded = 'forwardNavigation' in attrs;
    const flattenSlides = forwarded
        ? attrs.forwardNavigation.flattenSlides
        : (0, utils_1.extractGalleryItemsFromChildren)(attrs.slides);
    const totalSlides = flattenSlides.length;
    const [context, setContext] = (0, react_1.useState)(() => {
        const next = (delta) => () => {
            setContext(prevContext => {
                const activeIndex = (0, commons_1.clamp)(0, totalSlides, prevContext.activeIndex + delta);
                return {
                    ...prevContext,
                    activeIndex,
                };
            });
        };
        const setIndex = (newIndex) => {
            setContext(prevContext => ({
                ...prevContext,
                activeIndex: newIndex,
            }));
        };
        return {
            activeIndex: attrs.initialActiveIndex ?? 0,
            totalSlides,
            setIndex,
            prev: next(-1),
            next: next(1),
        };
    });
    const prevActiveIndex = (0, commons_front_1.usePrevious)(context.activeIndex);
    (0, commons_front_1.useUpdateEffect)(() => {
        attrs.onIndexChanged?.(prevActiveIndex, context.activeIndex);
    }, [context.activeIndex]);
    if (forwarded) {
        return attrs.forwardNavigation;
    }
    return {
        context,
        flattenSlides,
    };
};
exports.useWobblyGalleryNavigation = useWobblyGalleryNavigation;
