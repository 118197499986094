"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAfterMount = void 0;
const react_1 = require("react");
const useAfterMount = (callback) => {
    const unmountRef = (0, react_1.useRef)();
    (0, react_1.useEffect)(() => {
        if (unmountRef.current) {
            return unmountRef.current;
        }
        const unmount = callback();
        unmountRef.current = () => {
            if (typeof unmount === 'function') {
                unmount();
            }
        };
    }, []);
};
exports.useAfterMount = useAfterMount;
