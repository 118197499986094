"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChevronDownIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const ChevronDownIcon = ({ style, direction = 'bottom', ...props }) => ((0, jsx_runtime_1.jsx)("svg", { width: "18", height: "10", viewBox: "0 0 18 10", fill: "none", xmlns: "http://www.w3.org/2000/svg", style: {
        ...style,
        transform: (0, commons_front_1.getDirectionTransform)(0)(direction),
    }, ...props, children: (0, jsx_runtime_1.jsx)("path", { d: "M1 0.5L9 8.5L17 0.5", stroke: "currentColor" }) }));
exports.ChevronDownIcon = ChevronDownIcon;
