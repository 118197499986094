"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopSidebarContent = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const shared_1 = require("../../../shared");
const placeholder_1 = require("../placeholder");
const booking_desktop_sidebar_content_styled_1 = require("./booking-desktop-sidebar-content.styled");
const context_1 = require("client/modules/booking/booking-wizard/context");
const BookingDesktopSidebarContent = ({ showSummary, showPlaceholder = true, }) => {
    const sidebar = (0, context_1.useSubscribeBookingWizardSidebarContent)();
    const content = sidebar.content ??
        (!showSummary && showPlaceholder && (0, jsx_runtime_1.jsx)(placeholder_1.SidebarPlaceholder, {}));
    return ((0, jsx_runtime_1.jsxs)(booking_desktop_sidebar_content_styled_1.BookingDesktopSidebarContentHolder, { children: [showSummary && (0, jsx_runtime_1.jsx)(shared_1.SummaryListContainer, {}), content] }));
};
exports.BookingDesktopSidebarContent = BookingDesktopSidebarContent;
