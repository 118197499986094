"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkKnownHotelIdV = void 0;
const zod_1 = require("zod");
exports.SdkKnownHotelIdV = zod_1.z.enum([
    'mooshaus',
    'lisl',
    'sporthotel',
    'alpenrosesportaktiv',
]);
