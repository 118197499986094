"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbGalleryC = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
const item_1 = require("./item");
const arrow_1 = require("./arrow");
const section_gallery_slider_1 = require("./section-gallery-slider");
const SbGalleryC = ({ item, }) => ((0, jsx_runtime_1.jsx)(section_gallery_slider_1.SectionSlider, { infinite: true, centerMode: true, variableWidth: true, dots: false, speed: 300, prevArrow: (0, jsx_runtime_1.jsx)(arrow_1.SectionGalleryArrow, { direction: "left" }), nextArrow: (0, jsx_runtime_1.jsx)(arrow_1.SectionGalleryArrow, { direction: "right" }), ...(0, react_1.sdkStoryblokEditableProps)(item), children: item.items.map(galleryItem => ((0, jsx_runtime_1.jsx)(item_1.SbGalleryItemC, { item: galleryItem }, galleryItem.uid))) }));
exports.SbGalleryC = SbGalleryC;
