"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sdkStoryblokEditableProps = void 0;
const react_1 = require("@storyblok/react");
const sdkStoryblokEditableProps = (info) => {
    if (!info?.editable) {
        return null;
    }
    return (0, react_1.storyblokEditable)({
        _editable: info.editable,
        _uid: info.uid,
        component: info.component,
    });
};
exports.sdkStoryblokEditableProps = sdkStoryblokEditableProps;
