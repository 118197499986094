"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppLangSelectInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const inputs_1 = require("@under-control/inputs");
const commons_1 = require("@gerber/commons");
const select_input_1 = require("../../../select-input");
const i18n_1 = require("client/i18n");
const TRANSLATED_LANGS = {
    de: {
        shortcut: 'DE',
        full: 'Deutsch',
    },
    en: {
        shortcut: 'EN',
        full: 'English',
    },
};
exports.AppLangSelectInput = (0, inputs_1.controlled)(({ control: { value, setValue }, shortcutOnly = true, ...props }) => {
    const { getSupportedLangs } = (0, i18n_1.useI18n)();
    const items = (0, react_1.useMemo)(() => getSupportedLangs().map(supportedLang => {
        const { shortcut, full } = TRANSLATED_LANGS[supportedLang];
        const content = shortcutOnly ? shortcut : `${full} (${shortcut})`;
        return {
            id: supportedLang,
            name: content,
        };
    }), [value, shortcutOnly]);
    return ((0, jsx_runtime_1.jsx)(select_input_1.SelectInputOrNative, { allowType: false, prependEmptyOption: false, items: items, value: (0, commons_1.findItemById)(value)(items) ?? null, onChange: item => {
            setValue({
                merge: false,
                value: (item?.id ?? 'en'),
            });
        }, ...props }));
});
