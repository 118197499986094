"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormGroupContent = void 0;
const styled_components_1 = require("styled-components");
exports.FormGroupContent = styled_components_1.styled.div `
  display: flex;
  grid-gap: ${({ theme, space }) => theme.spacing[space ?? 1]};

  ${({ row }) => (0, styled_components_1.css) `
    flex-direction: ${row ? 'row' : 'column'};
  `}
`;
