"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingPersonInfoRowTitle = exports.BookingPersonInfoRowHolder = void 0;
const styled_components_1 = require("styled-components");
exports.BookingPersonInfoRowHolder = styled_components_1.styled.div `
  display: grid;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    gap: ${spacing[3]};
  `}
`;
exports.BookingPersonInfoRowTitle = styled_components_1.styled.div `
  ${({ theme: { font } }) => (0, styled_components_1.css) `
    font-size: ${font.size.sm};
  `}
`;
