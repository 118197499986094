"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkGerberProvider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const sdk_gerber_1 = require("../../../sdk-gerber");
const sdk_gerber_context_1 = require("./sdk-gerber-context");
const SdkGerberProvider = ({ config, children, }) => {
    const value = (0, react_1.useMemo)(() => ({
        sdk: new sdk_gerber_1.SdkGerber({
            client: config,
        }),
    }), [JSON.stringify(config)]);
    return ((0, jsx_runtime_1.jsx)(sdk_gerber_context_1.SdkGerberContext.Provider, { value: value, children: children }));
};
exports.SdkGerberProvider = SdkGerberProvider;
