"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingMobileScreenContentHolder = void 0;
const styled_components_1 = require("styled-components");
exports.BookingMobileScreenContentHolder = styled_components_1.styled.div `
  grid-area: content;
  overflow-y: auto;
  position: relative;

  ${({ $spaced = true, $hasToolbar, theme: { spacing } }) => $spaced &&
    (0, styled_components_1.css) `
      padding: ${spacing[$hasToolbar ? 5 : 7]} ${spacing[5]} ${spacing[4]};
    `}
`;
