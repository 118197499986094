"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tween = void 0;
const clamp_1 = require("../clamp");
const lerp_1 = require("./lerp");
const tween = ({ durationMs, startPercentage = 0, endPercentage = 1 }) => async (fn) => new Promise(resolve => {
    const startDate = Date.now();
    let percentage = 0.0;
    const updateFrame = () => {
        percentage = (0, clamp_1.clamp)(0, 1, (0, lerp_1.lerp)(startPercentage, endPercentage, (Date.now() - startDate) / durationMs));
        const result = fn(percentage);
        if (percentage !== endPercentage && result !== false) {
            window.requestAnimationFrame(updateFrame);
        }
        else {
            resolve();
        }
    };
    window.requestAnimationFrame(updateFrame);
});
exports.tween = tween;
