"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbDescriptionImage = void 0;
const styled_components_1 = require("styled-components");
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
exports.SbDescriptionImage = (0, styled_components_1.styled)(react_1.SbPicture).attrs({
    optimize: {
        size: {
            w: 540,
            h: 0,
        },
    },
}) `
  grid-area: image;
  width: 100%;
`;
