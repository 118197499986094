"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectAmountListItem = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.SelectAmountListItem = styled_components_1.styled.li `
  ${({ theme: { color, spacing } }) => (0, styled_components_1.css) `
    display: flex;
    flex-direction: row;
    height: 48px;
    gap: ${spacing[1]};
    border: 1px solid ${color.alias.black.main};
  `}

  ${({ theme: { color }, $invalid }) => $invalid &&
    (0, styled_components_1.css) `
      background: ${color.parts.input.invalid.main};
    `}

  ${({ $active }) => !$active &&
    commons_front_1.styles.hoverCardTransformAnim({
        shadowOpacity: 0.18,
        margin: -2,
        shadow: '0px 4px 8px',
    })}}
`;
