"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingCellConnectorsInnerPrefixTitle = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BookingCellConnectorsInnerPrefixTitle = styled_components_1.styled.span `
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  align-items: center;
  flex-direction: row;

  ${({ $align }) => {
    if ($align === 'left') {
        return (0, styled_components_1.css) `
        transform: translateX(5px);
      `;
    }
    return (0, styled_components_1.css) `
      transform: translateX(-50%);
    `;
}}

  ${({ theme: { font } }) => (0, styled_components_1.css) `
    font-size: ${font.size.xs};
  `}

  ${commons_front_1.styles.mediaSizeDown.md} {
    display: none;
  }
`;
