"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkConfigRoomCategoryV = void 0;
const zod_1 = require("zod");
const commons_1 = require("@gerber/commons");
const dto_1 = require("../../../dto");
const sdk_config_room_description_dto_1 = require("./sdk-config-room-description.dto");
exports.SdkConfigRoomCategoryV = commons_1.IdNameV.extend({
    subtitle: zod_1.z.string(),
    sort: zod_1.z.number(),
    description: zod_1.z.string(),
    teaser: zod_1.z.string().optional(),
    maximumOccupancy: zod_1.z.number(),
    minimumOccupancy: zod_1.z.number(),
    mediums: zod_1.z.array(dto_1.SdkMediumV),
    size: zod_1.z.string(),
    features: zod_1.z.array(zod_1.z.number()),
    descriptions: zod_1.z.array(sdk_config_room_description_dto_1.SdkConfigRoomDescriptionV).default([]),
});
