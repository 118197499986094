"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkBookingWizardSidebarContext = exports.getEmptySidebarContextValue = void 0;
const react_1 = require("react");
const commons_1 = require("@gerber/commons");
const getEmptySidebarContextValue = () => (0, commons_1.createStoreSubscriber)({
    content: null,
});
exports.getEmptySidebarContextValue = getEmptySidebarContextValue;
exports.SdkBookingWizardSidebarContext = (0, react_1.createContext)((0, exports.getEmptySidebarContextValue)());
