"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAsyncDebounce = void 0;
const react_1 = require("react");
const commons_1 = require("@gerber/commons");
const use_ref_safe_callback_1 = require("./use-ref-safe-callback");
const useAsyncDebounce = (fn, config) => {
    const safeCallback = (0, use_ref_safe_callback_1.useRefSafeCallback)(fn);
    const debounceRef = (0, react_1.useRef)();
    if (!!config.disabled || !config.delay) {
        return async (...args) => Promise.resolve(safeCallback(...args));
    }
    if (!debounceRef.current) {
        debounceRef.current = (0, commons_1.asyncDebounce)(config, async (...args) => safeCallback(...args));
    }
    return debounceRef.current;
};
exports.useAsyncDebounce = useAsyncDebounce;
