"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbNavigationHomepageItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_1 = require("@gerber/icons");
const ui_1 = require("client/ui");
const parts_1 = require("client/modules/landing-page/parts");
const i18n_1 = require("client/i18n");
const SbNavigationHomepageItem = ({ link, truncated, }) => {
    const { pack } = (0, i18n_1.useI18n)();
    return ((0, jsx_runtime_1.jsx)(parts_1.SbLink, { link: link, target: "_blank", children: (0, jsx_runtime_1.jsx)(ui_1.Button, { as: "span", "$kind": "outline", "$square": truncated, children: truncated ? ((0, jsx_runtime_1.jsx)(icons_1.HomeIcon, { width: 20, height: 20 })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [pack.common.website, (0, jsx_runtime_1.jsx)(ui_1.ButtonSuffixIcon, { children: (0, jsx_runtime_1.jsx)(icons_1.LongArrowRightThinIcon, {}) })] })) }) }));
};
exports.SbNavigationHomepageItem = SbNavigationHomepageItem;
