"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GridSpan = void 0;
const styled_components_1 = require("styled-components");
const helpers_1 = require("../helpers");
exports.GridSpan = styled_components_1.styled.div.withConfig({
    shouldForwardProp: (0, helpers_1.shouldNotForwardProps)(['start', 'span']),
}) `
  ${({ start = 1, span }) => span &&
    (0, styled_components_1.css) `
      grid-column-start: ${start};
      grid-column-end: span ${span};
    `}
`;
