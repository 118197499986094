"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GOLD_APP_THEME = void 0;
const GOLD_ALIAS_COLORS = {
    black: {
        main: '#000000',
        contrastText: '#ffffff',
    },
    white: {
        main: '#ffffff',
        contrastText: '#000000',
    },
};
const GOLD_BASE_COLORS = {
    primary: {
        main: '#AD9D84',
        contrastText: '#FFFFFF',
    },
};
const GOLD_PARTS_COLORS = {
    summaryBox: GOLD_ALIAS_COLORS.black,
    checkbox: GOLD_ALIAS_COLORS.black,
    button: {
        primary: GOLD_BASE_COLORS.primary,
        outline: GOLD_ALIAS_COLORS.white,
    },
    input: {
        border: GOLD_ALIAS_COLORS.black.main,
        filled: GOLD_ALIAS_COLORS.black,
        focus: {
            border: GOLD_ALIAS_COLORS.black.main,
        },
    },
    infoCard: {
        light: {
            contrastText: GOLD_ALIAS_COLORS.black.main,
        },
    },
};
exports.GOLD_APP_THEME = {
    color: {
        alias: GOLD_ALIAS_COLORS,
        base: GOLD_BASE_COLORS,
        parts: GOLD_PARTS_COLORS,
    },
};
