"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokBlockV = exports.SdkStoryblokBlockEditableInfoV = void 0;
const function_1 = require("fp-ts/function");
const zod_1 = require("zod");
const helpers_1 = require("../helpers");
exports.SdkStoryblokBlockEditableInfoV = zod_1.z.object({
    _uid: zod_1.z.string(),
    _editable: zod_1.z.string().optional(),
    component: zod_1.z.string(),
});
const SdkStoryblokBlockV = (component, content) => (0, function_1.pipe)(exports.SdkStoryblokBlockEditableInfoV.omit({
    component: true,
})
    .extend({
    component: zod_1.z.literal(component),
})
    .extend(content), helpers_1.rawPreprocess, type => type.transform(helpers_1.normalizeStoryblokDTO));
exports.SdkStoryblokBlockV = SdkStoryblokBlockV;
