"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingOfferToolbar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const forms_1 = require("@under-control/forms");
const react_1 = require("react");
const commons_front_1 = require("@gerber/commons-front");
const booking_offer_toolbar_styled_1 = require("./booking-offer-toolbar.styled");
const booking_offer_toolbar_list_1 = require("./booking-offer-toolbar-list");
const use_has_toolbar_selected_value_1 = require("./use-has-toolbar-selected-value");
const components_1 = require("client/components");
const ui_1 = require("client/ui");
exports.BookingOfferToolbar = (0, forms_1.controlled)(({ offer, control: { bind, value, setValue }, onShowDetails, ...props }) => {
    const listRef = (0, react_1.useRef)();
    const hasSelectedValue = (0, use_has_toolbar_selected_value_1.useHasToolbarSelectedValue)({
        offers: offer.offers,
        value,
    });
    const expanded = (0, forms_1.useControl)({
        defaultValue: hasSelectedValue,
        onChange: newExpanded => {
            if (!newExpanded && value && hasSelectedValue) {
                setValue({
                    value: null,
                });
            }
        },
    });
    (0, commons_front_1.useUpdateEffect)(() => {
        // collapse if user selected value in other card
        if (expanded.value && !hasSelectedValue && value) {
            expanded.setValue({
                value: false,
            });
        }
        // show if user selected value in current cart without expand
        if (!expanded.value && value && hasSelectedValue) {
            expanded.setValue({
                value: true,
            });
        }
    }, [hasSelectedValue, value]);
    (0, commons_front_1.useUpdateEffect)(() => {
        const scrollableNode = listRef.current?.firstElementChild;
        if (!expanded.value || !scrollableNode) {
            return;
        }
        if ((0, commons_front_1.getScreenNameByWindowWidth)() === 'xs') {
            scrollableNode.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    }, [expanded.value]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(booking_offer_toolbar_styled_1.BookingOfferToolbarHolder, { children: [(0, jsx_runtime_1.jsx)(ui_1.DetailsButton, { onClick: onShowDetails }), (0, jsx_runtime_1.jsx)(ui_1.SelectButton, { ...expanded.bind.entire(), filled: true })] }), expanded.value && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Divider, { "$space": 5, "$type": "light", "$style": "dashed" }), (0, jsx_runtime_1.jsx)(booking_offer_toolbar_list_1.BookingOfferToolbarList, { ...props, ...bind.entire(), ref: listRef, offer: offer })] }))] }));
});
