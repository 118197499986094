"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectAmountItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const forms_1 = require("@under-control/forms");
const select_amount_item_button_styled_1 = require("./select-amount-item-button.styled");
const select_amount_list_item_styled_1 = require("./select-amount-list-item.styled");
exports.SelectAmountItem = (0, forms_1.controlled)(({ amount, invalid, control, renderMetaFieldFn, onToggle }) => {
    const { value } = control;
    const active = !!value;
    return ((0, jsx_runtime_1.jsxs)(select_amount_list_item_styled_1.SelectAmountListItem, { "$active": active, "$invalid": invalid, children: [(0, jsx_runtime_1.jsx)(select_amount_item_button_styled_1.SelectAmountItemButton, { type: "button", "$hoverable": false, "$kind": "outline", "$active": active, "$showOutline": !active, onClick: () => {
                    onToggle(!value);
                }, children: amount }), renderMetaFieldFn?.({
                active,
                control,
            })] }));
});
