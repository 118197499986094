"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useErrorTaskEitherNotification = void 0;
const function_1 = require("fp-ts/function");
const commons_1 = require("@gerber/commons");
const use_error_notification_1 = require("./use-error-notification");
const useErrorTaskEitherNotification = () => {
    const error = (0, use_error_notification_1.useErrorNotification)();
    return (task) => (0, function_1.pipe)(task, (0, commons_1.tapTaskEitherError)(() => {
        error.show();
    }));
};
exports.useErrorTaskEitherNotification = useErrorTaskEitherNotification;
