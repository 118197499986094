"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetailsButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const button_1 = require("../../button");
const i18n_1 = require("client/i18n");
const DetailsButton = (props) => {
    const { pack } = (0, i18n_1.useI18n)();
    return ((0, jsx_runtime_1.jsx)(button_1.Button, { title: pack.common.details, type: "button", "$kind": "outline", ...props, children: pack.common.details }));
};
exports.DetailsButton = DetailsButton;
