"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopSidebarTruncatedHeaderHolder = void 0;
const styled_components_1 = require("styled-components");
const booking_desktop_sidebar_wrapper_styled_1 = require("../../../sidebar-wrapper/booking-desktop-sidebar-wrapper.styled");
exports.BookingDesktopSidebarTruncatedHeaderHolder = styled_components_1.styled.div `
  display: grid;
  grid-template: 'logo' 'toolbar';
  place-content: flex-start;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    gap: ${spacing[4]};
  `}

  ${booking_desktop_sidebar_wrapper_styled_1.SIDEBAR_EXTEND_BREAKPOINT} {
    grid-template: 'logo toolbar' / min-content 1fr;
    place-content: initial;
  }
`;
