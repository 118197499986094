"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LongArrowRight = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
/* eslint-disable max-len */
const commons_front_1 = require("@gerber/commons-front");
const LongArrowRight = ({ style, direction = 'right', ...props }) => ((0, jsx_runtime_1.jsx)("svg", { width: "21", height: "16", viewBox: "0 0 21 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", style: {
        ...style,
        transform: (0, commons_front_1.getDirectionTransform)(90)(direction),
    }, ...props, children: (0, jsx_runtime_1.jsx)("path", { d: "M20.7071 8.70711C21.0976 8.31658 21.0976 7.68342 20.7071 7.29289L14.3431 0.928932C13.9526 0.538407 13.3195 0.538407 12.9289 0.928932C12.5384 1.31946 12.5384 1.95262 12.9289 2.34315L18.5858 8L12.9289 13.6569C12.5384 14.0474 12.5384 14.6805 12.9289 15.0711C13.3195 15.4616 13.9526 15.4616 14.3431 15.0711L20.7071 8.70711ZM20 7L8.74228e-08 7L-8.74228e-08 9L20 9L20 7Z", fill: "currentColor" }) }));
exports.LongArrowRight = LongArrowRight;
