"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WobblyGallery = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const styled_1 = require("../styled");
const hooks_1 = require("../hooks");
const context_1 = require("../context");
const WobblyGallery = ({ className, vertical, slideDuration = 500, header, footer, trackWrapperFn, ...props }) => {
    const { context, flattenSlides } = (0, context_1.useWobblyGalleryNavigation)('navigation' in props
        ? {
            forwardNavigation: props.navigation,
        }
        : {
            slides: props.children,
        });
    const { activeIndex, totalSlides } = context;
    const { draggableRef, animation } = (0, hooks_1.useWobblyDraggableAnim)({
        vertical,
        activeIndex,
        slideDuration,
    });
    if (!totalSlides) {
        return null;
    }
    let track = ((0, jsx_runtime_1.jsx)(styled_1.WobblyGalleryTrack, { ref: draggableRef, "$vertical": vertical, children: flattenSlides.map((Component, index) => {
            const distanceToActive = Math.abs(activeIndex - index);
            if (!(0, react_1.isValidElement)(Component)) {
                return null;
            }
            return (0, react_1.cloneElement)(Component, {
                attrs: {
                    index,
                    animation,
                    preload: distanceToActive <= 1,
                    active: index === activeIndex,
                    first: !index,
                    last: index === totalSlides - 1,
                },
            });
        }) }));
    if (trackWrapperFn) {
        track = trackWrapperFn(track);
    }
    return ((0, jsx_runtime_1.jsx)(context_1.WobblyGalleryNavigationContext.Provider, { value: context, children: (0, jsx_runtime_1.jsxs)(styled_1.WobblyGalleryWrapper, { className: className, children: [header, track, footer] }) }));
};
exports.WobblyGallery = WobblyGallery;
