"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokFooterSocialBlockV = void 0;
const zod_1 = require("zod");
const parts_1 = require("../../parts");
const sdk_storyblok_block_dto_1 = require("../../sdk-storyblok-block.dto");
exports.SdkStoryblokFooterSocialBlockV = (0, sdk_storyblok_block_dto_1.SdkStoryblokBlockV)('Footer_Social', {
    icon: parts_1.SdkStoryblokPictureV,
    title: zod_1.z.string(),
    link: parts_1.SdkStoryblokLinkV,
});
