"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMergeBookingSessionWithUrlParams = void 0;
const zod_1 = require("zod");
const function_1 = require("fp-ts/function");
const TE = __importStar(require("fp-ts/TaskEither"));
const O = __importStar(require("fp-ts/Option"));
const A = __importStar(require("fp-ts/Array"));
const commons_front_1 = require("@gerber/commons-front");
const react_1 = require("@gerber/sdk-gerber/dist/third-party/react");
const commons_1 = require("@gerber/commons");
const get_session_restored_booking_wizard_form_value_1 = require("./get-session-restored-booking-wizard-form-value");
const prefill_room_restore_1 = require("./prefill-url-restore/prefill-room.restore");
const package_restore_1 = require("./package-restore");
const hooks_1 = require("client/hooks");
const form_1 = require("client/modules/booking/booking-wizard/form");
const SearchRestoreChildrenV = zod_1.z
    .string()
    .array()
    .transform((0, function_1.pipe)(A.filterMap(commons_1.tryParseNumber)))
    .optional();
const SearchRestoreWizardValueParamsV = zod_1.z.object({
    packageGroupId: zod_1.z.coerce.number().optional(),
    offerVariantId: zod_1.z.string().optional(),
    skipWelcome: zod_1.z.coerce.boolean().optional(),
    adults: zod_1.z.coerce.number().optional(),
    children: SearchRestoreChildrenV,
    begin: zod_1.z.coerce.date().optional(),
    end: zod_1.z.coerce.date().optional(),
});
/**
 * This hook is responsible for second phase of session restore. It has to:
 *
 * 1. Check if OFFER VARIANT parameter is present in the url
 *  1.1 Create new session, add variant, generate form value if present
 *  1.2 Otherwise if no session provided create blank form value, without session create
 *  1.3 If session already present create value from session
 *
 * 2. Check if OFFER PACKAGE parameter is present in the url
 *  1.1 If present extend booking form value with package room
 *
 * 3. Check if prefilled URL are present - if so add new blank room or update previous incomplete
 * 4. Check if utm_source, utm_medium, utm_campaign is present - if so upsert session (or create)
 * 5. Disable welcome screen for mobile if user come from LP
 */
const useMergeBookingSessionWithUrlParams = () => {
    const { sdk } = (0, react_1.useGerberSdk)();
    const getUTMParams = (0, hooks_1.useGetUTMSearchParamsOrNilIO)();
    const getRestoreSearchParams = (0, commons_front_1.useGetSearchParamsAndDropIO)(SearchRestoreWizardValueParamsV, {
        packageGroupId: null,
        offerVariantId: null,
        skipWelcome: null,
        adults: null,
        children: null,
        begin: null,
        end: null,
    });
    const tryRestorePackageGroupFromUrl = (config) => (value) => (0, function_1.flow)((0, package_restore_1.restorePackageGroupRoom)(config), O.match(() => value, (packageRoom) => ({
        ...value,
        rooms: (0, package_restore_1.tryPrependPackageRoom)(packageRoom)(value.rooms),
    })));
    const tryAssignValuesFromUrlParams = ({ config, maybeSession, }) => (0, function_1.pipe)(TE.Do, TE.bind('droppedSearchParams', () => TE.fromEither(getRestoreSearchParams())), 
    // append variant to session and extend session if url param exists in url
    TE.chainW(({ droppedSearchParams, ...attrs }) => {
        if (droppedSearchParams.offerVariantId) {
            // try create session if not exists for variant and then append variant to it
            return (0, function_1.pipe)(TE.Do, TE.bind('sessionWithNewVariant', () => (0, function_1.pipe)(maybeSession, O.matchW(() => sdk.controllers.session.createSession(), TE.of), TE.chain(session => sdk.controllers.session.addVariantItem({
                sessionId: session.sessionId,
                variantId: droppedSearchParams.offerVariantId,
            })))), TE.bind('upsertedChainedValue', ({ sessionWithNewVariant }) => (0, get_session_restored_booking_wizard_form_value_1.getSessionRestoredBookingWizardFormValue)({
                config,
                sdk,
            })(sessionWithNewVariant)), TE.map(({ sessionWithNewVariant, upsertedChainedValue }) => ({
                ...attrs,
                maybeUpsertedSession: O.some(sessionWithNewVariant),
                chainedValue: upsertedChainedValue,
                droppedSearchParams,
            })));
        }
        return (0, function_1.pipe)(maybeSession, O.matchW(() => TE.of((0, form_1.createBlankGlobalFormValue)()), (0, get_session_restored_booking_wizard_form_value_1.getSessionRestoredBookingWizardFormValue)({ config, sdk })), TE.map(chainedValue => ({
            maybeUpsertedSession: maybeSession,
            droppedSearchParams,
            chainedValue,
        })));
    }), 
    // append package to form state if exists `packageGroupId` exists in url
    TE.chainW(({ chainedValue, droppedSearchParams, ...attrs }) => {
        if (droppedSearchParams.packageGroupId) {
            return TE.of({
                ...attrs,
                droppedSearchParams,
                chainedValue: tryRestorePackageGroupFromUrl(config)(chainedValue)(droppedSearchParams.packageGroupId),
            });
        }
        return TE.of({
            ...attrs,
            droppedSearchParams,
            chainedValue,
        });
    }), 
    // append new room or update last incomplete if provided adults or children provided
    TE.chainW(({ chainedValue, droppedSearchParams, ...attrs }) => {
        const { adults, children, begin, end } = droppedSearchParams;
        if (!adults) {
            return TE.of({
                ...attrs,
                droppedSearchParams,
                chainedValue,
            });
        }
        return (0, function_1.pipe)(chainedValue.rooms, (0, prefill_room_restore_1.restorePrefilledRoom)(sdk)({
            adults,
            children,
            begin,
            end,
        }), TE.map(newRooms => ({
            ...attrs,
            droppedSearchParams,
            chainedValue: {
                ...chainedValue,
                rooms: newRooms,
            },
        })));
    }), 
    // UTM campaign parameters must be restored
    TE.chainW(({ maybeUpsertedSession, droppedSearchParams, ...attrs }) => {
        const { utm_campaign: utmCampaign, utm_medium: utmMedium, utm_source: utmSource, } = getUTMParams();
        if (utmCampaign && utmMedium && utmSource) {
            return (0, function_1.pipe)(maybeUpsertedSession, O.matchW(() => sdk.controllers.session.createSession(), TE.of), (0, commons_1.tapTaskEitherTE)(session => sdk.controllers.session.updateCampaign({
                sessionId: session.sessionId,
                medium: utmMedium,
                name: utmCampaign,
                source: utmSource,
            })), TE.map(sessionWithCampaign => ({
                ...attrs,
                maybeUpsertedSession: O.some(sessionWithCampaign),
                droppedSearchParams,
            })));
        }
        return TE.of({
            ...attrs,
            maybeUpsertedSession,
            droppedSearchParams,
        });
    }), TE.chainW(({ chainedValue, droppedSearchParams, maybeUpsertedSession }) => {
        let newValue = chainedValue;
        if (!!droppedSearchParams.skipWelcome || newValue.rooms.length > 1) {
            newValue = {
                ...newValue,
                meta: {
                    ...newValue.meta,
                    hideMobileStartJumbotron: true,
                },
            };
        }
        return TE.of({
            formValue: newValue,
            maybeUpsertedSession,
        });
    }));
    return {
        tryAssignValuesFromUrlParams,
    };
};
exports.useMergeBookingSessionWithUrlParams = useMergeBookingSessionWithUrlParams;
