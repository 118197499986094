"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useResetSidebarContentIfActive = exports.useSetSidebarContentIfActive = void 0;
const react_1 = require("react");
const context_1 = require("../context");
const useSetSidebarContentIfActive = ({ active, render, deps = [], }) => {
    const store = (0, context_1.useBookingWizardSidebarContextOrThrow)();
    (0, react_1.useEffect)(() => {
        if (active) {
            store.notify({
                content: render(),
            });
        }
    }, [active, JSON.stringify(deps)]);
};
exports.useSetSidebarContentIfActive = useSetSidebarContentIfActive;
const useResetSidebarContentIfActive = (attrs) => {
    (0, exports.useSetSidebarContentIfActive)({
        ...attrs,
        render: () => null,
    });
};
exports.useResetSidebarContentIfActive = useResetSidebarContentIfActive;
