"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingCalendarCellFooterHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BookingCalendarCellFooterHolder = styled_components_1.styled.div `
  ${commons_front_1.styles.truncatedText}

  --space: 8px;

  position: absolute;
  left: var(--space);
  bottom: 0;
  pointer-events: none;
  text-align: center;

  ${({ $span }) => (0, styled_components_1.css) `
    width: calc(${$span * 100}% - var(--space) * 2);
    z-index: ${$span};
  `}

  ${({ theme: { color, spacing } }) => (0, styled_components_1.css) `
    ${(0, commons_front_1.genPaletteColorCSS)(color.parts.calendarCell.footer)}

    padding: 2px ${spacing[1]};
    font-size: 0.64rem;
  `}
`;
