"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHttpAction = exports.useHttpContext = exports.HttpContext = exports.createBlankHttpContext = void 0;
const react_1 = require("react");
const createBlankHttpContext = () => ({});
exports.createBlankHttpContext = createBlankHttpContext;
exports.HttpContext = (0, react_1.createContext)((0, exports.createBlankHttpContext)());
const useHttpContext = () => (0, react_1.useContext)(exports.HttpContext);
exports.useHttpContext = useHttpContext;
const useHttpAction = () => {
    const ctx = (0, exports.useHttpContext)();
    return {
        statusCode: (code) => {
            ctx.statusCode = code;
        },
        redirect: (redirect) => {
            ctx.redirect = redirect;
        },
    };
};
exports.useHttpAction = useHttpAction;
