"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.highlightPhrase = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const styled_components_1 = require("styled-components");
const HighlightedText = styled_components_1.styled.span `
  font-weight: 600;
  text-decoration: underline;
`;
const HighlightedPhrase = ({ highlight, text }) => {
    const pos = text.toLowerCase().indexOf(highlight.toLowerCase());
    if (pos === -1) {
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: text });
    }
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [text.substring(0, pos), (0, jsx_runtime_1.jsx)(HighlightedText, { children: text.substring(pos, pos + highlight.length) }), text.substring(pos + highlight.length)] }));
};
function highlightPhrase(phrase, items) {
    if (!phrase) {
        return items;
    }
    return items.map(item => {
        if (typeof item.name !== 'string') {
            return item;
        }
        const { name, ...rest } = item;
        return {
            ...rest,
            name: (0, jsx_runtime_1.jsx)(HighlightedPhrase, { highlight: phrase, text: name }),
            text: name,
        };
    });
}
exports.highlightPhrase = highlightPhrase;
