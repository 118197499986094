"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sdkStoryblokHydrate = void 0;
const react_1 = require("@storyblok/react");
const sdkStoryblokHydrate = (config) => {
    (0, react_1.storyblokInit)({
        accessToken: config.accessToken,
        use: [react_1.apiPlugin],
        bridge: config.version === 'draft',
        apiOptions: {
            region: config.region,
        },
    });
};
exports.sdkStoryblokHydrate = sdkStoryblokHydrate;
