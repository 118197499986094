"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingMobileStartFooter = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_1 = require("@gerber/icons");
const booking_mobile_start_footer_styled_1 = require("./booking-mobile-start-footer.styled");
const i18n_1 = require("client/i18n");
const components_1 = require("client/components");
const button_1 = require("client/ui/button");
const BookingMobileStartFooter = () => {
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.start;
    return ((0, jsx_runtime_1.jsx)(booking_mobile_start_footer_styled_1.BookingMobileStartFooterHolder, { children: (0, jsx_runtime_1.jsxs)(button_1.Button, { title: t.cta.bookNow, type: "button", "$kind": "primary", "$shadow": 4, as: components_1.UniversalLink, href: "#start", children: [t.cta.bookNow, (0, jsx_runtime_1.jsx)(button_1.ButtonSuffixIcon, { children: (0, jsx_runtime_1.jsx)(icons_1.LongArrowRightThinIcon, { direction: "bottom" }) })] }) }));
};
exports.BookingMobileStartFooter = BookingMobileStartFooter;
