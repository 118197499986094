"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBookingCalendarCellsList = void 0;
/* eslint-disable import/no-duplicates */
const react_1 = require("react");
const function_1 = require("fp-ts/function");
const date_fns_1 = require("date-fns");
const fp_1 = require("date-fns/fp");
const A = __importStar(require("fp-ts/Array"));
const commons_1 = require("@gerber/commons");
const use_booking_calendar_cells_month_loader_1 = require("./use-booking-calendar-cells-month-loader");
const constants_1 = require("client/modules/booking/booking-calendar/constants");
const useBookingCalendarCellsList = ({ filters = {
    adults: 0,
    children: [],
}, preloadWeeks = 3, navigationDate = (0, fp_1.startOfMonth)((0, commons_1.getZonedDate)(new Date())), skipLoading, cachedMonths, }) => {
    const visibleDays = (0, react_1.useMemo)(() => {
        const begin = (0, date_fns_1.startOfWeek)(navigationDate, { weekStartsOn: 1 });
        const end = (0, date_fns_1.addDays)(begin, constants_1.CALENDAR_MONTH_TOTAL_CELLS);
        return {
            total: constants_1.CALENDAR_MONTH_TOTAL_CELLS,
            begin,
            end,
        };
    }, [navigationDate]);
    // cell that is in viewport center, it is not center of month
    const pivotCalendarCell = (0, date_fns_1.addDays)(navigationDate, constants_1.CALENDAR_MONTH_TOTAL_CELLS / 2 -
        (0, commons_1.transformDayToSundayLast)(navigationDate.getDay()));
    const months = {
        prev: (0, use_booking_calendar_cells_month_loader_1.useBookingCalendarCellsMonthLoader)({
            skip: skipLoading,
            month: (0, function_1.pipe)(pivotCalendarCell, fp_1.startOfMonth, (0, fp_1.subMonths)(1)),
            filters,
            cachedMonths,
        }),
        current: (0, use_booking_calendar_cells_month_loader_1.useBookingCalendarCellsMonthLoader)({
            skip: skipLoading,
            month: (0, fp_1.startOfMonth)(pivotCalendarCell),
            filters,
            cachedMonths,
        }),
        next: (0, use_booking_calendar_cells_month_loader_1.useBookingCalendarCellsMonthLoader)({
            skip: skipLoading,
            month: (0, function_1.pipe)(pivotCalendarCell, fp_1.startOfMonth, (0, fp_1.addMonths)(1)),
            filters,
            cachedMonths,
        }),
    };
    const result = (0, react_1.useMemo)(() => {
        const allMonthsDays = [
            ...months.prev.days,
            ...months.current.days,
            ...months.next.days,
        ];
        const mapGeneratedDays = A.map((date) => {
            const cell = allMonthsDays.find(day => (0, commons_1.compareDateOnly)(day.date)(date));
            return (cell ?? {
                loading: true,
                date,
            });
        });
        const prepend = (0, function_1.pipe)(A.makeBy(constants_1.CALENDAR_WEEK_TOTAL_CELLS * preloadWeeks, offset => (0, date_fns_1.subDays)(visibleDays.begin, constants_1.CALENDAR_WEEK_TOTAL_CELLS * preloadWeeks - offset)), mapGeneratedDays);
        const append = (0, function_1.pipe)(A.makeBy(constants_1.CALENDAR_WEEK_TOTAL_CELLS * preloadWeeks, offset => (0, date_fns_1.addDays)(visibleDays.end, offset)), mapGeneratedDays);
        const visible = (0, function_1.pipe)(A.makeBy(visibleDays.total, offset => (0, date_fns_1.addDays)(visibleDays.begin, offset)), mapGeneratedDays);
        return {
            loading: allMonthsDays.some(item => item.loading),
            prepend,
            visible,
            append,
            preloadWeeks,
        };
    }, [preloadWeeks, months.prev, months.current, months.next]);
    return result;
};
exports.useBookingCalendarCellsList = useBookingCalendarCellsList;
