"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoomSortModeV = void 0;
const zod_1 = require("zod");
exports.RoomSortModeV = zod_1.z.enum([
    'recommended',
    'name-az',
    'rate:asc',
    'rate:desc',
]);
