"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectAmountItemButton = void 0;
const styled_components_1 = require("styled-components");
const button_1 = require("client/ui/button");
exports.SelectAmountItemButton = (0, styled_components_1.styled)(button_1.Button) `
  ${({ theme: { font, spacing, ...theme }, $active, $kind = 'outline' }) => {
    if ($kind === 'outline-transparent') {
        return null;
    }
    const color = theme.color.parts.button[$kind];
    return (0, styled_components_1.css) `
      height: 100%;
      border: 0;
      font-size: ${font.size.xl};
      padding: ${spacing[3]} ${spacing[6]};

      ${$active &&
        (0, styled_components_1.css) `
        background: ${color.contrastText};
        color: ${color.main};
        font-weight: 600;
      `}
    `;
}}
`;
