"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingPersonInfoRowForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const forms_1 = require("@under-control/forms");
const booking_person_info_row_form_styled_1 = require("./booking-person-info-row-form.styled");
const ui_1 = require("client/ui");
const i18n_1 = require("client/i18n");
exports.BookingPersonInfoRowForm = (0, forms_1.controlled)(({ errors, control: { bind } }) => {
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.contact.peopleInfo;
    const validation = (0, forms_1.useFormValidatorMessages)({ errors });
    return ((0, jsx_runtime_1.jsxs)(booking_person_info_row_form_styled_1.BookingPersonInfoRowFormGrid, { children: [(0, jsx_runtime_1.jsx)(ui_1.FormGroup, { required: true, space: 0, area: "first-name", label: t.firstName.label, error: (0, jsx_runtime_1.jsx)(ui_1.ValidationErrorsHint, { ...validation.extract('firstName') }), children: (0, jsx_runtime_1.jsx)(ui_1.Input, { ...bind.path('firstName', { input: val => val ?? '' }), required: true, name: "first-name", placeholder: t.firstName.placeholder }) }), (0, jsx_runtime_1.jsx)(ui_1.FormGroup, { required: true, space: 0, area: "last-name", label: t.lastName.label, error: (0, jsx_runtime_1.jsx)(ui_1.ValidationErrorsHint, { ...validation.extract('lastName') }), children: (0, jsx_runtime_1.jsx)(ui_1.Input, { ...bind.path('lastName', { input: val => val ?? '' }), required: true, name: "last-name", placeholder: t.lastName.placeholder }) }), (0, jsx_runtime_1.jsx)(ui_1.FormGroup, { required: true, space: 0, area: "birth-date", label: t.birthDate.label, error: (0, jsx_runtime_1.jsx)(ui_1.ValidationErrorsHint, { ...validation.extract('birthDate') }), children: (0, jsx_runtime_1.jsx)(ui_1.Input, { ...bind.path('birthDate', { input: val => val ?? '' }), required: true, type: "date", name: "birth-date", placeholder: t.birthDate.placeholder }) })] }));
});
