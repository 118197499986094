"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.springWobbly = void 0;
/**
 * @see {@link https://medium.com/@dtinth/spring-animation-in-css-2039de6e1a03}
 */
const springWobbly = (value, { k, c } = { k: 180, c: 12 }) => {
    if (k === 180 && c === 12) {
        return (-0.5 *
            Math.E ** (-6 * value) *
            (-2 * Math.E ** (6 * value) +
                Math.sin(12 * value) +
                2 * Math.cos(12 * value)));
    }
    if (k === 90 && c === 15.5) {
        return (-1.141643 * Math.E ** (-7.75 * value) * Math.sin(5.47152 * value) -
            Math.E ** (-7.75 * value) * Math.cos(5.47152 * value) +
            1);
    }
    if (k === 90 && c === 14.5) {
        return (-1.18491 * Math.E ** (-7.25 * value) * Math.sin(6.11862 * value) -
            Math.E ** (-7.25 * value) * Math.cos(6.11862 * value) +
            1);
    }
    throw new Error('Incorrect spring wobbly value!');
};
exports.springWobbly = springWobbly;
