"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbLayoutGrid = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const commons_front_2 = require("@gerber/commons-front");
const GRID_LAYOUTS = {
    1: (0, styled_components_1.css) `
    grid-template-columns: 1fr;
  `,
    '1:1': (0, styled_components_1.css) `
    grid-template-columns: repeat(2, 1fr);
  `,
    '1:1:1': (0, styled_components_1.css) `
    grid-template-columns: repeat(3, 1fr);
  `,
};
const GRID_SPACING = {
    xs: 15,
    sm: 28,
    md: 45,
    lg: 70,
};
const config = {
    shouldForwardProp: (0, commons_front_2.shouldNotForwardProps)([
        ...commons_front_1.styles.maxBreakpointFields('layout'),
        ...commons_front_1.styles.maxBreakpointFields('space'),
    ]),
};
exports.SbLayoutGrid = styled_components_1.styled.div.withConfig(config) `
  display: grid;

  ${(0, commons_front_1.maxBreakpointProp)('layout')(layout => layout && GRID_LAYOUTS[layout])}
  ${(0, commons_front_1.maxBreakpointProp)('space')(space => space &&
    (0, styled_components_1.css) `
        gap: ${GRID_SPACING[space]};
      `)}
`;
