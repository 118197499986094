"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Checkbox = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const forms_1 = require("@under-control/forms");
const type_1 = require("./type");
const styles_1 = require("./styles");
const CheckboxComponents = {
    circle: type_1.CheckCircleButton,
    square: type_1.CheckSquareButton,
};
exports.Checkbox = (0, forms_1.controlled)(({ control, required, disabled, children, name, className, inputProps, buttonProps, labelProps, alignTopButton, shape = 'circle', }) => {
    const value = control.getValue();
    const ButtonComponent = CheckboxComponents[shape];
    const onToggle = () => {
        control.setValue({
            value: !value,
        });
    };
    const onToggleEvent = e => {
        if (!!disabled || e.currentTarget.closest('a')) {
            return;
        }
        e.preventDefault();
        onToggle();
    };
    return ((0, jsx_runtime_1.jsxs)(styles_1.CheckboxHolder, { "$disabled": disabled, className: className, onClick: onToggleEvent, onKeyDown: e => {
            if (e.key === 'Enter') {
                onToggle();
                (0, forms_1.suppressEvent)(e);
            }
        }, children: [(0, jsx_runtime_1.jsx)(styles_1.CheckboxHiddenInput, { type: "checkbox", disabled: disabled, required: required, name: name, checked: !!value, autoComplete: "off", onChange: onToggleEvent, ...inputProps }, value ? '1' : '0'), (0, jsx_runtime_1.jsx)(ButtonComponent, { "$disabled": disabled, "$alignTop": alignTopButton, value: value, role: "checkbox", "aria-checked": value ? 'true' : 'false', ...buttonProps }), children && ((0, jsx_runtime_1.jsx)(styles_1.CheckboxTitleHolder, { "$disabled": disabled, ...labelProps, children: children }))] }));
});
