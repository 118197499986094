"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomSelectInputWrapper = void 0;
const styled_components_1 = require("styled-components");
exports.CustomSelectInputWrapper = styled_components_1.styled.div `
  display: inline-flex;
  position: relative;

  ${({ $compact }) => !$compact &&
    (0, styled_components_1.css) `
      min-width: 220px;
    `}
`;
