"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Divider = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.Divider = styled_components_1.styled.hr `
  display: block;
  width: 100%;
  border: 0;

  ${(0, commons_front_1.maxBreakpointProp$)('space')(space => (0, styled_components_1.css) `
      margin: ${({ theme }) => theme.spacing[space ?? 5]} 0;
    `)}

  ${({ theme: { color }, $type = 'dark', $style = 'solid' }) => (0, styled_components_1.css) `
    border-top: 1px ${$style} ${color.parts.divider[$type].main};
  `}

  ${({ $truncated }) => $truncated &&
    (0, styled_components_1.css) `
      max-width: 128px;
    `}

  ${({ $hideIfLast }) => $hideIfLast &&
    (0, styled_components_1.css) `
      &:last-child {
        display: none;
      }
    `}
`;
