"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InnerInputIconHolder = exports.InnerInputIconWrapperHolder = exports.ICON_BOX_WIDTH = void 0;
const commons_front_1 = require("@gerber/commons-front");
const styled_components_1 = require("styled-components");
const input_1 = require("../../input");
exports.ICON_BOX_WIDTH = 32;
exports.InnerInputIconWrapperHolder = styled_components_1.styled.span `
  position: relative;

  ${input_1.Input} {
    width: 100%;
    padding-right: ${exports.ICON_BOX_WIDTH}px;
  }
`;
exports.InnerInputIconHolder = styled_components_1.styled.span `
  ${commons_front_1.styles.flexCenter}

  position: absolute;
  top: 0;
  right: 0;
  width: ${exports.ICON_BOX_WIDTH}px;
  height: 100%;
  opacity: 0.2;
  font-size: 18px;

  ${({ clickable }) => clickable &&
    (0, styled_components_1.css) `
      cursor: pointer;
    `}
`;
