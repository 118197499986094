"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.minBreakpointFields$ = exports.maxBreakpointFields$ = exports.minBreakpointFields = exports.maxBreakpointFields = exports.minBreakpointProp = exports.maxBreakpointProp = exports.minBreakpointProp$ = exports.maxBreakpointProp$ = exports.breakpointOmitFields = void 0;
const styled_components_1 = require("styled-components");
const breakpoint_style_1 = require("./breakpoint.style");
const breakpointOmitFields = (sign, prefix) => (name) => [
    `${sign}${name}`,
    ...breakpoint_style_1.SCREEN_BREAKPOINTS_NAMES.map(screen => `${sign}${prefix}${screen}:${name}`),
];
exports.breakpointOmitFields = breakpointOmitFields;
const breakpointProp = (sign, prefix, mediaQueryCreator) => (name) => (reader) => {
    const fields = (0, exports.breakpointOmitFields)(sign, prefix)(name);
    const possibleProps = [
        [null, fields[0]],
        ...breakpoint_style_1.SCREEN_BREAKPOINTS_NAMES.map((screen, index) => [screen, fields[index + 1]]),
    ];
    return (props) => possibleProps.flatMap(([screen, propName]) => {
        if (!(propName in props) && screen) {
            return [];
        }
        const content = reader(props[propName], props);
        if (screen == null) {
            return [content];
        }
        return [
            (0, styled_components_1.css) `
            ${mediaQueryCreator(screen)} {
              ${content}
            }
          `,
        ];
    });
};
exports.maxBreakpointProp$ = breakpointProp('$', 'max-', breakpoint_style_1.mediaMaxScreenWidth);
exports.minBreakpointProp$ = breakpointProp('$', 'min-', breakpoint_style_1.mediaMinScreenWidth);
exports.maxBreakpointProp = breakpointProp('', 'max-', breakpoint_style_1.mediaMaxScreenWidth);
exports.minBreakpointProp = breakpointProp('', 'min-', breakpoint_style_1.mediaMinScreenWidth);
exports.maxBreakpointFields = (0, exports.breakpointOmitFields)('', 'max-');
exports.minBreakpointFields = (0, exports.breakpointOmitFields)('', 'min-');
exports.maxBreakpointFields$ = (0, exports.breakpointOmitFields)('$', 'max-');
exports.minBreakpointFields$ = (0, exports.breakpointOmitFields)('$', 'min-');
