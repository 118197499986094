"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseModalSubtitle = exports.BaseModalHeader = void 0;
const styled_components_1 = require("styled-components");
exports.BaseModalHeader = styled_components_1.styled.div `
  ${({ theme: { font } }) => (0, styled_components_1.css) `
    font-size: ${font.size['3xl']};
    font-weight: 700;
  `}
`;
exports.BaseModalSubtitle = styled_components_1.styled.div `
  ${({ theme: { font } }) => (0, styled_components_1.css) `
    font-size: ${font.size.base};
    font-weight: 600;
  `}
`;
