"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitialAnimatedChildren = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_2 = require("../../../helpers/react");
const initial_animated_element_1 = require("./initial-animated-element");
/**
 * Use it for components in which order of children never changes.
 * Look at `key`. If your component has custom keys - use `InitialAnimatedElement` standalone
 */
exports.InitialAnimatedChildren = (0, react_1.memo)(({ children, animClassName, childClassName, }) => {
    const childrenArray = (0, react_2.flattenChildren)(children);
    if (!animClassName) {
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: children });
    }
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: childrenArray.map((child, index) => {
            if (!child) {
                return null;
            }
            return ((0, jsx_runtime_1.jsx)(initial_animated_element_1.InitialAnimatedElement, { children: ({ anim }) => ((0, jsx_runtime_1.jsx)("div", { ...anim(animClassName, { className: childClassName }), children: child })) }, (0, react_2.tryGetReactComponentKey)(child) ?? index));
        }) }));
});
exports.InitialAnimatedChildren.displayName = 'InitialAnimatedChildren';
