"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CountrySelectInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const inputs_1 = require("@under-control/inputs");
const async_select_input_1 = require("client/ui/select-input/async-select-input");
const context_1 = require("client/modules/booking/booking-wizard/context");
exports.CountrySelectInput = (0, inputs_1.controlled)(({ control, ...props }) => {
    const { countries } = (0, context_1.useBookingWizardConfigContext)();
    const onFetchItems = async (phrase) => {
        if (!phrase?.trim()?.length) {
            return countries;
        }
        const normalizedPhrase = phrase.toLowerCase();
        return countries.filter(country => country.name.toLowerCase().includes(normalizedPhrase));
    };
    return ((0, jsx_runtime_1.jsx)(async_select_input_1.AsyncSelectInput, { ...props, ...control.bind.entire(), onFetchItems: onFetchItems }));
});
