"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopCalendarToolbar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const forms_1 = require("@under-control/forms");
const react_1 = require("react");
const shared_1 = require("../../shared");
const booking_desktop_calendar_toolbar_styled_1 = require("./booking-desktop-calendar-toolbar.styled");
exports.BookingDesktopCalendarToolbar = (0, react_1.memo)((0, forms_1.controlled)(({ control: { bind }, ...props }) => ((0, jsx_runtime_1.jsx)(booking_desktop_calendar_toolbar_styled_1.BookingDesktopCalendarToolbarHolder, { children: (0, jsx_runtime_1.jsx)(shared_1.BookingMonthSelector, { ...bind.path('date'), ...props }) }))), (prevProps, nextProps) => prevProps.disabled === nextProps.disabled &&
    'value' in prevProps &&
    'value' in nextProps &&
    prevProps.value.date?.toISOString() === nextProps.value.date?.toISOString());
exports.BookingDesktopCalendarToolbar.displayName = 'BookingDesktopCalendarToolbar';
