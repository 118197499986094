"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckboxHolder = void 0;
const styled_components_1 = require("styled-components");
exports.CheckboxHolder = styled_components_1.styled.label `
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  ${({ $disabled }) => $disabled
    ? (0, styled_components_1.css) `
          pointer-events: none;
        `
    : (0, styled_components_1.css) `
          cursor: pointer;
        `}}
`;
