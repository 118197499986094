"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbButtonC = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
const storyblok_link_1 = require("../../parts/storyblok-link");
const ui_1 = require("client/ui");
const SbButtonC = ({ item, }) => {
    const { text, kind, icon, iconLocation, square, link } = item;
    const content = ((0, jsx_runtime_1.jsxs)(ui_1.Button, { ...(0, react_1.sdkStoryblokEditableProps)(item), "$kind": kind, "$square": square, ...(link && { as: 'span' }), children: [iconLocation === 'left' && icon && ((0, jsx_runtime_1.jsx)(ui_1.ButtonPrefixIcon, { children: (0, jsx_runtime_1.jsx)(react_1.SbIcon, { icon: icon }) })), iconLocation === 'center' && icon ? ((0, jsx_runtime_1.jsx)(react_1.SbIcon, { icon: icon, width: 20, height: 20 })) : (text), iconLocation === 'right' && icon && ((0, jsx_runtime_1.jsx)(ui_1.ButtonSuffixIcon, { children: (0, jsx_runtime_1.jsx)(react_1.SbIcon, { icon: icon }) }))] }));
    if (link) {
        return (0, jsx_runtime_1.jsx)(storyblok_link_1.SbLink, { link: link, children: content });
    }
    return content;
};
exports.SbButtonC = SbButtonC;
