"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractThemeColorInv = exports.extractThemeColor = void 0;
const extractThemeColor = (color) => (theme) => {
    const { alias, base } = theme;
    if (!color) {
        return null;
    }
    if (typeof color === 'object' && 'main' in color) {
        return color;
    }
    if (color in alias) {
        return alias[color];
    }
    if (color in base) {
        return base[color];
    }
    return null;
};
exports.extractThemeColor = extractThemeColor;
const extractThemeColorInv = (theme) => (color) => (0, exports.extractThemeColor)(color)(theme);
exports.extractThemeColorInv = extractThemeColorInv;
