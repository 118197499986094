"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopWobblyGallery = exports.BookingDesktopGalleryContentWrapper = void 0;
const styled_components_1 = require("styled-components");
const wobbly_gallery_1 = require("@gerber/wobbly-gallery");
exports.BookingDesktopGalleryContentWrapper = styled_components_1.styled.div `
  display: grid;
  position: relative;
  width: 100%;
  height: 100vh;
  height: 100dvh;
`;
exports.BookingDesktopWobblyGallery = (0, styled_components_1.styled)(wobbly_gallery_1.WobblyGallery) ``;
