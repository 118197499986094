"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomSelectInputClear = exports.CustomSelectInputClearHolder = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const styled_components_1 = require("styled-components");
const icons_1 = require("@gerber/icons");
const commons_front_1 = require("@gerber/commons-front");
const input_1 = require("../../input");
exports.CustomSelectInputClearHolder = (0, styled_components_1.styled)(input_1.BasicClearInputButton) `
  ${commons_front_1.styles.flexCenter}

  position: absolute;
  top: 50%;
  right: ${({ text }) => (text ? 17 : 50)}px;
  transform: translateY(-50%);
`;
const CustomSelectInputClear = (props) => ((0, jsx_runtime_1.jsx)(exports.CustomSelectInputClearHolder, { type: "button", ...props, children: (0, jsx_runtime_1.jsx)(icons_1.TimesIcon, {}) }));
exports.CustomSelectInputClear = CustomSelectInputClear;
