"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TasksResolverProvider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const client_task_resolver_1 = require("../client/client-task-resolver");
const task_resolver_context_1 = require("./task-resolver-context");
const TasksResolverProvider = ({ children, resolver, }) => {
    const cachedResolver = (0, react_1.useMemo)(() => resolver ?? client_task_resolver_1.ClientTasksResolver.ofHydrationData(), [resolver]);
    return ((0, jsx_runtime_1.jsx)(task_resolver_context_1.TasksResolverContext.Provider, { value: cachedResolver, children: children }));
};
exports.TasksResolverProvider = TasksResolverProvider;
