"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkBookInputV = exports.SdkBookOutputV = exports.SdkBookRedirectPaymentMethodOutputV = exports.SdkBookJsPaymentMethodOutputV = void 0;
const zod_1 = require("zod");
const dto_1 = require("../../../dto");
exports.SdkBookJsPaymentMethodOutputV = zod_1.z.object({
    type: zod_1.z.literal('JS'),
    paymentUrl: zod_1.z.string(),
    parameters: zod_1.z.object({
        brands: zod_1.z.string(),
        callbackUrl: zod_1.z.string(),
    }),
});
exports.SdkBookRedirectPaymentMethodOutputV = zod_1.z.object({
    type: zod_1.z.literal('REDIRECT'),
    paymentUrl: zod_1.z.string(),
});
exports.SdkBookOutputV = zod_1.z.union([
    exports.SdkBookJsPaymentMethodOutputV,
    exports.SdkBookRedirectPaymentMethodOutputV,
]);
exports.SdkBookInputV = zod_1.z.object({
    total: dto_1.SdkPriceV,
    successUrl: zod_1.z.string(),
    errorUrl: zod_1.z.string(),
    cancelUrl: zod_1.z.string(),
});
