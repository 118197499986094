"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Portal = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_dom_1 = require("react-dom");
class Portal extends react_1.Component {
    constructor() {
        super(...arguments);
        this.node = null;
    }
    componentWillUnmount() {
        const { node } = this;
        if (node?.parentNode) {
            node.parentNode.removeChild(node);
        }
        this.node = null;
    }
    render() {
        const { node, children, containerSelector } = this.props;
        if (!node && !this.node) {
            this.node = document.createElement('div');
            (containerSelector?.() ?? document.body).appendChild(this.node);
        }
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, react_dom_1.createPortal)(children, node ?? this.node) });
    }
}
exports.Portal = Portal;
