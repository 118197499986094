"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingOffersTotalTitle = exports.BookingOffersTotalToolbarHolder = void 0;
const styled_components_1 = require("styled-components");
exports.BookingOffersTotalToolbarHolder = styled_components_1.styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
exports.BookingOffersTotalTitle = styled_components_1.styled.span `
  text-transform: uppercase;

  ${({ theme: { font } }) => (0, styled_components_1.css) `
    font-size: ${font.size.sm};
  `}
`;
