"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbHotelCardsListC = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const card_1 = require("../card");
const storyblok_hotel_cards_divider_1 = require("./storyblok-hotel-cards-divider");
const SbHotelCardsListC = ({ item, }) => {
    const { cards } = item;
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: cards.map((card, index) => ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(card_1.SbHotelCardC, { item: card }), index + 1 !== +cards.length && (0, jsx_runtime_1.jsx)(storyblok_hotel_cards_divider_1.SbHotelCardsDivider, {})] }, `${card.uid}-${card.oneId}-${card.title}`))) }));
};
exports.SbHotelCardsListC = SbHotelCardsListC;
