"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokTitledCtaBlockV = void 0;
const zod_1 = require("zod");
const components_1 = require("../components");
const sdk_storyblok_block_dto_1 = require("../../sdk-storyblok-block.dto");
const sdk_storyblok_rwd_attr_dto_1 = require("../../sdk-storyblok-rwd-attr.dto");
const data_source_1 = require("../../data-source");
const sdk_storyblok_hotel_cards_list_block_1 = require("../hotel/sdk-storyblok-hotel-cards-list.block");
const gallery_1 = require("./gallery");
const SdkStoryblokTitledCtaContentV = zod_1.z
    .union([
    components_1.SdkStoryblokBaseBlockV,
    gallery_1.SdkStoryblokGalleryBlockV,
    sdk_storyblok_hotel_cards_list_block_1.SdkStoryblokHotelCardsListBlockV,
])
    .array()
    .default([]);
exports.SdkStoryblokTitledCtaBlockV = zod_1.z.intersection((0, sdk_storyblok_block_dto_1.SdkStoryblokBlockV)('Section_Titled_CTA', {
    title: components_1.SdkStoryblokBaseBlockContentV,
    content: SdkStoryblokTitledCtaContentV,
    toolbar: components_1.SdkStoryblokBaseBlockContentV,
    spacing: data_source_1.SdkStoryblokSpacingV.default('md').catch('md'),
    justify: data_source_1.SdkStoryblokJustifyV.catch('left').default('left'),
}), (0, sdk_storyblok_rwd_attr_dto_1.SdkStoryblokRwdAttrSM)('max_width')(zod_1.z
    .string()
    .optional()
    .transform(str => (str?.length ? str : void 0))));
