"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkOneOfferV = exports.SdkOfferTypeV = void 0;
const zod_1 = require("zod");
const expect_type_1 = require("expect-type");
const dto_1 = require("../../../dto");
const SdkOneOfferBaseV = zod_1.z.object({
    price: dto_1.SdkPriceV,
    arrivalDay: zod_1.z.string(),
    adults: zod_1.z.number(),
    children: zod_1.z.array(zod_1.z.number()),
    nights: zod_1.z.number(),
    roomCategoryId: zod_1.z.number(),
    rateId: zod_1.z.number(),
    overlayMessages: zod_1.z.array(zod_1.z.string()),
});
const SdkOneRoomOfferV = SdkOneOfferBaseV.extend({
    type: zod_1.z.literal('ROOM'),
});
const SdkOnePackageOfferV = SdkOneOfferBaseV.extend({
    type: zod_1.z.literal('PACKAGE'),
    lastMinutePackageId: zod_1.z.number().optional(),
    packageGroupId: zod_1.z.number(),
    packageCategoryId: zod_1.z.number(),
});
const SdkOneLastMinutePackageOfferV = SdkOneOfferBaseV.extend({
    type: zod_1.z.literal('LAST_MINUTE_PACKAGE'),
    lastMinutePackageId: zod_1.z.number(),
});
exports.SdkOfferTypeV = zod_1.z.enum(['ROOM', 'PACKAGE', 'LAST_MINUTE_PACKAGE']);
exports.SdkOneOfferV = zod_1.z.discriminatedUnion('type', [
    SdkOneRoomOfferV,
    SdkOnePackageOfferV,
    SdkOneLastMinutePackageOfferV,
]);
(0, expect_type_1.expectTypeOf)().toEqualTypeOf();
