"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokBaseLayoutBlockV = void 0;
const zod_1 = require("zod");
const sdk_storyblok_grid_block_1 = require("./sdk-storyblok-grid.block");
const sdk_storyblok_section_block_1 = require("./sdk-storyblok-section.block");
const sdk_storyblok_container_block_1 = require("./sdk-storyblok-container.block");
exports.SdkStoryblokBaseLayoutBlockV = zod_1.z.union([
    sdk_storyblok_grid_block_1.SdkStoryblokGridBlockV,
    sdk_storyblok_section_block_1.SdkStoryblokSectionBlockV,
    sdk_storyblok_container_block_1.SdkStoryblokContainerBlockV,
]);
