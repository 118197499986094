"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopGallery = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const booking_wizard_progress_tracker_1 = require("../../../tracking/booking-wizard-progress-tracker");
const sidebar_wrapper_1 = require("../sidebar-wrapper");
const booking_desktop_gallery_navigation_provider_1 = require("./booking-desktop-gallery-navigation-provider");
const track_1 = require("./track");
const prefix_1 = require("./prefix");
const booking_desktop_gallery_styled_1 = require("./booking-desktop-gallery.styled");
const decorators_1 = require("client/modules/booking/booking-wizard/decorators");
exports.BookingDesktopGallery = (0, decorators_1.controlledBookingValue)(({ control: { bind }, onPay }) => ((0, jsx_runtime_1.jsx)(booking_desktop_gallery_navigation_provider_1.BookingDesktopGalleryNavigationProvider, { ...bind.entire(), onPay: onPay, children: navigation => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(booking_wizard_progress_tracker_1.BookingWizardProgressTracker, {}), (0, jsx_runtime_1.jsx)(sidebar_wrapper_1.BookingDesktopSidebarWrapper, { children: (0, jsx_runtime_1.jsxs)(booking_desktop_gallery_styled_1.BookingDesktopGalleryContentWrapper, { children: [(0, jsx_runtime_1.jsx)(prefix_1.BookingDesktopGalleryPrefix, {}), (0, jsx_runtime_1.jsx)(booking_desktop_gallery_styled_1.BookingDesktopWobblyGallery, { navigation: navigation, vertical: true }), (0, jsx_runtime_1.jsx)(track_1.BookingDesktopGalleryTrack, {})] }) })] })) })));
