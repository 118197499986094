"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SummaryInfoCard = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const commons_1 = require("@gerber/commons");
const summary_package_group_1 = require("./summary-package-group");
const summary_variant_offer_1 = require("./summary-variant-offer");
const summary_rows_1 = require("./summary-rows");
const form_1 = require("client/modules/booking/booking-wizard/form");
const shared_1 = require("client/modules/booking/shared");
const components_1 = require("client/components");
exports.SummaryInfoCard = (0, react_1.memo)(({ room }) => {
    const { date, offer, who, variant } = room;
    const isEditable = !(0, form_1.isReadonlyBookingRoom)(room);
    const hasWho = (0, form_1.isWhoBookingSectionFilled)(room);
    const hasDate = (0, form_1.isDateBookingSectionFilled)(room);
    const hasOffer = (0, form_1.isOfferBookingSectionFilled)(room);
    const showVariantDescription = !(0, commons_1.isNil)(variant?.id) && offer.selected;
    const packageGroup = (() => {
        if (room.packageGroup) {
            return room.packageGroup;
        }
        if (offer.selected?.type === 'PACKAGE') {
            return offer.selected.package.group;
        }
        return null;
    })();
    const topDescription = (() => {
        if (packageGroup) {
            return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(summary_package_group_1.PackageGroupSummary, { packageGroup: packageGroup, showCover: !hasDate, showTeaser: !hasOffer }), (0, jsx_runtime_1.jsx)(components_1.Divider, { "$space": 3, "$type": "light" })] }));
        }
        if (showVariantDescription) {
            return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(summary_variant_offer_1.VariantOfferSummary, { variantOffer: offer.selected, showCover: true, showTeaser: true }), (0, jsx_runtime_1.jsx)(components_1.Divider, { "$space": 3, "$type": "light" })] }));
        }
        return (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 5 });
    })();
    return ((0, jsx_runtime_1.jsxs)(shared_1.InfoCardContent, { children: [topDescription, hasWho && (0, jsx_runtime_1.jsx)(summary_rows_1.WhoSummary, { who: who, isEditable: isEditable }), hasDate && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Divider, { "$type": "light", "$space": 3 }), (0, jsx_runtime_1.jsx)(summary_rows_1.DateSummary, { date: date, isEditable: isEditable })] })), !showVariantDescription && hasOffer && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Divider, { "$type": "light", "$space": 3 }), (0, jsx_runtime_1.jsx)(summary_rows_1.OfferSummary, { offer: offer, isEditable: isEditable })] })), hasWho && (0, jsx_runtime_1.jsx)(components_1.Divider, { "$type": "light", "$space": 3 }), (0, jsx_runtime_1.jsx)(summary_rows_1.CancellationSummary, {}), (hasDate || hasOffer) && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Divider, { "$type": "light", "$space": 3 }), (0, jsx_runtime_1.jsx)(summary_rows_1.PriceSummary, { room: room })] }))] }));
});
exports.SummaryInfoCard.displayName = 'SummaryInfoCard';
