"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.restoreVariantOfferFromSessionItem = void 0;
const function_1 = require("fp-ts/function");
const TE = __importStar(require("fp-ts/TaskEither"));
const O = __importStar(require("fp-ts/Option"));
const shared_1 = require("client/modules/booking/booking-wizard/screens/03-room/shared");
const restoreVariantOfferFromSessionItem = ({ config }) => (sessionItem) => {
    const { guests } = sessionItem;
    const adults = guests.filter(item => item.type === 'ADULT').length;
    const children = guests
        .flatMap(item => (item.type === 'ADULT' ? [] : [item.childAge]))
        .filter(Boolean);
    const maybeExtendedCategory = (0, shared_1.innerJoinOfferCategoryWithConfig)(config)(sessionItem.roomCategoryId);
    return TE.of((0, function_1.pipe)(maybeExtendedCategory, O.map(category => ({
        type: 'ROOM',
        adults,
        children,
        arrivalDay: sessionItem.arrivalDay,
        nights: sessionItem.nights,
        overlayMessages: [],
        price: sessionItem.price,
        rateId: sessionItem.rateId,
        roomCategoryId: sessionItem.roomCategoryId,
        rate: {
            name: category.name,
            id: sessionItem.rateId,
        },
        room: {
            category,
        },
    }))));
};
exports.restoreVariantOfferFromSessionItem = restoreVariantOfferFromSessionItem;
