"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Spinner = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.Spinner = styled_components_1.styled.span `
  display: block;
  margin: 0 auto;
  border: 2px solid;
  place-self: center;
  border-color: currentColor currentColor transparent transparent;
  border-radius: 50%;

  ${commons_front_1.styles.animation.rotate.around}

  ${({ $width = 25 }) => (0, styled_components_1.css) `
    width: ${$width}px;
    height: ${$width}px;
  `}
`;
