"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnimatedModalWrapper = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const commons_1 = require("@gerber/commons");
function AnimatedModalWrapper({ closeAnimDuration, children, onClose, }) {
    const [hiding, setHiding] = (0, react_1.useState)();
    const performCloseAnim = async (result) => {
        setHiding(true);
        await (0, commons_1.timeout)(closeAnimDuration);
        setHiding(false);
        onClose(result);
    };
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: children({
            hiding: !!hiding,
            performCloseAnim,
        }) }));
}
exports.AnimatedModalWrapper = AnimatedModalWrapper;
