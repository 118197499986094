"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkOneOffersV = exports.SdkOneOffersInputV = void 0;
const zod_1 = require("zod");
const sdk_one_offer_dto_1 = require("./sdk-one-offer.dto");
exports.SdkOneOffersInputV = zod_1.z.object({
    arrivalDay: zod_1.z.date(),
    adults: zod_1.z.number(),
    children: zod_1.z.array(zod_1.z.number()),
    nights: zod_1.z.number(),
});
exports.SdkOneOffersV = zod_1.z.object({
    offers: zod_1.z.array(sdk_one_offer_dto_1.SdkOneOfferV),
});
