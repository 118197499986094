"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblok = void 0;
const controllers_1 = require("./controllers");
const sdk_storyblok_client_1 = require("./sdk-storyblok-client");
class SdkStoryblok {
    constructor(config) {
        this.client = new sdk_storyblok_client_1.SdkStoryblokClient(config);
        this.controllers = {
            pages: new controllers_1.SdkStoryblokPagesController(this.client),
        };
    }
}
exports.SdkStoryblok = SdkStoryblok;
