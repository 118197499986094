"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBookingMobileDetailsModal = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const booking_mobile_details_modal_1 = require("./booking-mobile-details.modal");
const useBookingMobileDetailsModal = () => (0, commons_front_1.useAnimatedModal)({
    renderModalContent: ({ hiding, onAnimatedClose }) => ((0, jsx_runtime_1.jsx)(booking_mobile_details_modal_1.BookingMobileDetailsModal, { out: hiding, onClose: () => {
            void onAnimatedClose();
        } })),
});
exports.useBookingMobileDetailsModal = useBookingMobileDetailsModal;
