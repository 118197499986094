"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbFooterGrid = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const layout_1 = require("../layout");
exports.SbFooterGrid = (0, styled_components_1.styled)(layout_1.SbLayoutContainer) `
  display: grid;
  grid-template: 'media address logo' / 1fr auto 1fr;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    gap: ${spacing[16]};
  `}

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    ${commons_front_1.styles.mediaSizeDown.md} {
      grid-template: 'address' 'media' 'logo';
      gap: ${spacing[8]};
    }
  `}
`;
