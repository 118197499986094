"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsMobile = void 0;
const use_match_screen_1 = require("./use-match-screen");
const useIsMobile = () => {
    const sm = (0, use_match_screen_1.useMatchScreen)('sm');
    const xs = (0, use_match_screen_1.useMatchScreen)('xs');
    return sm || xs;
};
exports.useIsMobile = useIsMobile;
