"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMatchScreenNameByUserAgent = void 0;
const use_user_agent_1 = require("./use-user-agent");
const useMatchScreenNameByUserAgent = () => {
    const { device: { type }, } = (0, use_user_agent_1.useUserAgent)();
    switch (type) {
        case 'console':
        case 'desktop':
        case 'smarttv':
            return 'xl';
        case 'tablet':
            return 'md';
        case 'wearable':
        case 'embedded':
        case 'mobile':
            return 'sm';
        default: {
            const errorType = type;
            throw new Error(`Screen not found: ${errorType}`);
        }
    }
};
exports.useMatchScreenNameByUserAgent = useMatchScreenNameByUserAgent;
