"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hoverCardTransformAnim = exports.hoveredTransform = exports.hoverCardStyle = void 0;
const styled_components_1 = require("styled-components");
exports.hoverCardStyle = (0, styled_components_1.css) `
  position: relative;
  top: 0px;
  cursor: pointer;
  transition: 0.2s ease-out;
  transition-property: top, opacity, box-shadow, filter;
`;
const hoveredTransform = ({ shadowOpacity = 0.1, shadow = '0px 4px 8px', margin = -3, }) => (0, styled_components_1.css) `
  top: ${margin}px;
  box-shadow: ${shadow} rgba(0, 0, 0, ${shadowOpacity});
`;
exports.hoveredTransform = hoveredTransform;
const hoverCardTransformAnim = (attrs = {}) => (0, styled_components_1.css) `
  ${exports.hoverCardStyle}
  &:hover {
    ${(0, exports.hoveredTransform)(attrs)}
  }
`;
exports.hoverCardTransformAnim = hoverCardTransformAnim;
