"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingCellConnectorHolder = exports.connectorSizeBoxStyle = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const connectorSizeBoxStyle = (hasTopSpace) => {
    const topSizePrefix = hasTopSpace ? 14 : 0;
    return (0, styled_components_1.css) `
    position: absolute;
    top: ${topSizePrefix + 14}px;
    height: 32px;

    ${commons_front_1.styles.mediaSizeUp.md} {
      top: ${topSizePrefix + 17}px;
      height: 40px;
    }
  `;
};
exports.connectorSizeBoxStyle = connectorSizeBoxStyle;
exports.BookingCellConnectorHolder = styled_components_1.styled.span `
  position: absolute;
  width: 50%;

  ${({ $hasTopSpace }) => (0, exports.connectorSizeBoxStyle)($hasTopSpace)}
  ${({ $color }) => (0, commons_front_1.genPaletteColorCSS)($color)}
  ${({ $direction }) => {
    switch ($direction) {
        case 'left':
            return (0, styled_components_1.css) `
          left: 0;
          z-index: 3;
        `;
        case 'right':
            return (0, styled_components_1.css) `
          right: 0;
        `;
        default: {
            const unknownDirection = $direction;
            console.warn('Unknown direction:', unknownDirection);
        }
    }
}}
`;
