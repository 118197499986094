"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Notification = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const commons_front_1 = require("@gerber/commons-front");
const icons_1 = require("@gerber/icons");
const notification_styled_1 = require("./notification.styled");
const notification_close_button_1 = require("./notification-close-button");
const Notification = ({ children, icon, hideTimeout = 2800, onHide, ...props }) => {
    const [$out, setOut] = (0, react_1.useState)(false);
    const onAnimatedHide = () => {
        setOut(true);
    };
    (0, commons_front_1.useTimeout)(onAnimatedHide, { time: hideTimeout });
    (0, commons_front_1.useTimeout)(onHide, { time: hideTimeout + 400 });
    return ((0, jsx_runtime_1.jsxs)(notification_styled_1.NotificationHolder, { ...props, "$out": $out, children: [icon ?? (0, jsx_runtime_1.jsx)(icons_1.NotificationIcon, {}), children, (0, jsx_runtime_1.jsx)(notification_close_button_1.NotificationCloseButton, { onClick: onAnimatedHide })] }));
};
exports.Notification = Notification;
