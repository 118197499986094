"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopSidebarSummaryFooter = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const sdk_gerber_1 = require("@gerber/sdk-gerber");
const commons_front_1 = require("@gerber/commons-front");
const more_than_one_room_alert_1 = require("../more-than-one-room-alert");
const booking_desktop_sidebar_summary_footer_styled_1 = require("./booking-desktop-sidebar-summary-footer.styled");
const price_1 = require("./price");
const share_1 = require("./share");
const i18n_1 = require("client/i18n");
const components_1 = require("client/components");
const shared_1 = require("client/modules/booking/booking-wizard/parts/shared");
exports.BookingDesktopSidebarSummaryFooter = (0, react_1.memo)(({ totalPrice, totalRooms }) => {
    const { pack } = (0, i18n_1.useI18n)();
    const isShareable = (0, shared_1.useIsShareableBookingWizardValue)();
    const [showContact, setShowContact] = (0, react_1.useState)(false);
    return ((0, jsx_runtime_1.jsxs)(booking_desktop_sidebar_summary_footer_styled_1.SidebarSummaryWrapper, { children: [totalRooms > 1 && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(more_than_one_room_alert_1.MoreThanOneRoomAlert, { totalRooms: totalRooms }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 4 })] })), (0, jsx_runtime_1.jsx)(price_1.BookingDesktopSidebarSummaryPrice, { totalPrice: totalPrice }), isShareable && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Divider, { "$space": 4, "$type": "light" }), (0, jsx_runtime_1.jsx)(share_1.BookingDesktopSidebarSummaryShare, {})] })), (0, jsx_runtime_1.jsx)(commons_front_1.CleanButton, { onClick: () => {
                    setShowContact(!showContact);
                }, children: (0, jsx_runtime_1.jsx)(components_1.Text, { decoration: "underline", children: pack.common.contact }) }), showContact && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Divider, { "$space": 4, "$type": "light" }), (0, jsx_runtime_1.jsx)(shared_1.HotelContactRow, {})] }))] }));
}, (prevProps, nextProps) => (0, sdk_gerber_1.isPriceEqual)(prevProps.totalPrice)(nextProps.totalPrice));
exports.BookingDesktopSidebarSummaryFooter.displayName =
    'BookingDesktopSidebarSummaryFooter';
