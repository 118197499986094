"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSessionStorageObject = exports.useStorageObject = void 0;
const react_1 = require("react");
const function_1 = require("fp-ts/function");
const O = __importStar(require("fp-ts/Option"));
const commons_1 = require("@gerber/commons");
const useStorageObject = (name, { getStorage, withCache = true }) => {
    const cache = (0, react_1.useRef)(O.none);
    if ((0, commons_1.isUnsafeObjKey)(name)) {
        throw new Error('Incorrect storage object key name!');
    }
    const get = () => {
        if (withCache && O.isSome(cache.current)) {
            return cache.current;
        }
        return (0, function_1.pipe)(getStorage()[name], data => (0, commons_1.tryParseJSON)(data));
    };
    const set = (value) => {
        getStorage()[name] = JSON.stringify(value);
        if (withCache) {
            cache.current = O.some(value);
        }
    };
    const remove = () => {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete getStorage()[name];
    };
    const getAndRemove = (0, function_1.flow)(get, (0, commons_1.tapOption)(remove));
    return { get, set, remove, getAndRemove };
};
exports.useStorageObject = useStorageObject;
const useSessionStorageObject = (name, attrs = {}) => (0, exports.useStorageObject)(name, {
    ...attrs,
    getStorage: () => window.sessionStorage,
});
exports.useSessionStorageObject = useSessionStorageObject;
