"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MediumsPreviewToolbarHolder = exports.MediumsPreviewNavButton = void 0;
const styled_components_1 = require("styled-components");
const ui_1 = require("client/ui");
exports.MediumsPreviewNavButton = (0, styled_components_1.styled)(ui_1.Button).attrs({
    $kind: 'outline',
    type: 'button',
}) `
  width: 64px;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    padding: ${spacing[1]} ${spacing[2]};
  `}
`;
exports.MediumsPreviewToolbarHolder = styled_components_1.styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
