"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokHeaderBlockV = exports.SdkStoryblokHeaderLevelV = void 0;
const zod_1 = require("zod");
const sdk_storyblok_block_dto_1 = require("../../sdk-storyblok-block.dto");
const data_source_1 = require("../../data-source");
exports.SdkStoryblokHeaderLevelV = zod_1.z.coerce.number();
exports.SdkStoryblokHeaderBlockV = (0, sdk_storyblok_block_dto_1.SdkStoryblokBlockV)('Component_Header', {
    title: zod_1.z.string().default(''),
    level: exports.SdkStoryblokHeaderLevelV.default(2),
    align: data_source_1.SdkStoryblokJustifyV.catch('left'),
    color: data_source_1.SdkStoryblokColorV.default('black').catch('black'),
});
