"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ControlledBookingGlobalFormProvider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const hooks_1 = require("../../form/hooks");
const controlled_booking_global_form_context_1 = require("./controlled-booking-global-form-context");
const ControlledBookingGlobalFormProvider = ({ children, defaultValue, }) => {
    const form = (0, hooks_1.useBookingWizardGlobalForm)({
        defaultValue,
    });
    return ((0, jsx_runtime_1.jsx)(controlled_booking_global_form_context_1.ControlledBookingGlobalFormContext.Provider, { value: form, children: children }));
};
exports.ControlledBookingGlobalFormProvider = ControlledBookingGlobalFormProvider;
