"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfoCardContentHolder = void 0;
const styled_components_1 = require("styled-components");
const shared_1 = require("client/modules/booking/shared");
exports.InfoCardContentHolder = (0, styled_components_1.styled)(shared_1.InfoCardContent) `
  display: flex;
  flex-direction: column;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    padding-top: ${spacing[2]};
  `}
`;
