"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckboxHiddenInput = void 0;
const styled_components_1 = require("styled-components");
exports.CheckboxHiddenInput = styled_components_1.styled.input `
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
`;
