"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSelectInputState = void 0;
const react_1 = require("react");
const commons_front_1 = require("@gerber/commons-front");
const to_select_item_1 = require("../helpers/to-select-item");
const use_selected_item_1 = require("./use-selected-item");
const useSelectInputState = ({ value, items, blurOnChangeValue = true, onBlur: onForwardBlur, }) => {
    const prevValue = (0, commons_front_1.usePrevious)(value);
    const [active, setActive] = (0, react_1.useState)(false);
    const selected = (0, use_selected_item_1.useSelectedItem)(value, items ?? []);
    const onFocus = () => {
        setActive(true);
    };
    const onBlur = () => {
        setActive(false);
        onForwardBlur?.();
    };
    const outsideRef = (0, commons_front_1.useOutsideClickRef)(onBlur, {
        inShadowDOM: true,
        disabled: !active,
    });
    (0, commons_front_1.useUpdateEffect)(() => {
        if (blurOnChangeValue) {
            onBlur();
        }
        else {
            // handle case when user changes phrase
            // and autocomplete is blurred (sometimes it happens on rerender)
            // force focus input again but only if changed value
            // is changed only when user types input, not selects input value
            const [prevItem, item] = [
                prevValue ? (0, to_select_item_1.toSelectItem)(false)(prevValue) : null,
                value && (0, to_select_item_1.toSelectItem)(false)(value),
            ];
            if (!!item &&
                prevItem?.name !== item.name &&
                item.id === null &&
                !item.name) {
                onFocus();
            }
        }
    }, [value]);
    return {
        outsideRef,
        active,
        selected,
        onBlur,
        onFocus,
        setActive,
    };
};
exports.useSelectInputState = useSelectInputState;
