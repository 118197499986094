"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingExitButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const hooks_1 = require("../../../hooks");
const booking_exit_button_styled_1 = require("./booking-exit-button.styled");
exports.BookingExitButton = (0, react_1.memo)((props) => {
    const onExit = (0, hooks_1.useExitBookingWizard)();
    return (0, jsx_runtime_1.jsx)(booking_exit_button_styled_1.BookingExitButtonHolder, { onClick: onExit, ...props });
});
exports.BookingExitButton.displayName = 'BookingExitButton';
