"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseModalBackdrop = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BaseModalBackdrop = styled_components_1.styled.div.withConfig({
    shouldForwardProp: (0, commons_front_1.shouldNotForwardProps)(['out']),
}) `
  ${commons_front_1.styles.layer.fixed}
  ${({ out }) => commons_front_1.styles.animation.fade[out ? 'out' : 'in']};

  background: rgba(255, 255, 255, 0.5);
  z-index: 999;
`;
