"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBookingWizardJsPaymentModal = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const booking_wizard_js_payment_modal_1 = require("./booking-wizard-js-payment.modal");
const useBookingWizardJsPaymentModal = () => (0, commons_front_1.useAnimatedModal)({
    renderModalContent: ({ showProps, hiding, onAnimatedClose }) => ((0, jsx_runtime_1.jsx)(booking_wizard_js_payment_modal_1.BookingWizardJsPaymentModal, { ...showProps, out: hiding, onClose: () => {
            void onAnimatedClose();
        } })),
});
exports.useBookingWizardJsPaymentModal = useBookingWizardJsPaymentModal;
