"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_APP_THEME = exports.DEFAULT_PARTS_COLORS = exports.DEFAULT_BASE_COLORS = exports.DEFAULT_ALIAS_COLORS = void 0;
const polished_1 = require("polished");
exports.DEFAULT_ALIAS_COLORS = {
    black: {
        main: '#000000',
        contrastText: '#ffffff',
    },
    white: {
        main: '#ffffff',
        contrastText: '#000000',
    },
    gray: {
        main: '#EAEAEA',
        contrastText: '#000000',
    },
    orange: {
        main: '#E95E1D',
        contrastText: '#ffffff',
    },
    solidGray: {
        main: '#CBCBCB',
        contrastText: '#000000',
    },
    lightGray: {
        main: '#F8F8F8',
        contrastText: '#000000',
    },
    red: {
        main: '#ff0000',
        contrastText: '#ffffff',
    },
    skyBlue: {
        main: '#80DDFF',
        contrastText: '#000000',
    },
    sunYellow: {
        main: '#fbdb04',
        contrastText: '#000000',
    },
};
exports.DEFAULT_BASE_COLORS = {
    primary: exports.DEFAULT_ALIAS_COLORS.black,
    darkMuted: {
        main: '#808080',
        contrastText: '#000000',
    },
    muted: {
        main: '#b7b7b7',
        contrastText: '#000000',
    },
    error: exports.DEFAULT_ALIAS_COLORS.red,
};
exports.DEFAULT_PARTS_COLORS = {
    backdrop: exports.DEFAULT_ALIAS_COLORS.white,
    button: {
        primary: exports.DEFAULT_BASE_COLORS.primary,
        outline: exports.DEFAULT_ALIAS_COLORS.white,
        inactive: {
            main: '#cbcbcb',
            contrastText: exports.DEFAULT_ALIAS_COLORS.white.main,
        },
    },
    checkbox: exports.DEFAULT_ALIAS_COLORS.black,
    tabs: {
        border: exports.DEFAULT_ALIAS_COLORS.black.main,
        active: {
            ...exports.DEFAULT_ALIAS_COLORS.white,
            border: exports.DEFAULT_ALIAS_COLORS.black.main,
        },
        inactive: {
            main: exports.DEFAULT_ALIAS_COLORS.gray.main,
            contrastText: '#5f5f5f',
            border: exports.DEFAULT_ALIAS_COLORS.solidGray.main,
        },
    },
    calendarCell: {
        footer: {
            main: '#dddddd',
            contrastText: '#302723',
        },
    },
    input: {
        border: exports.DEFAULT_ALIAS_COLORS.black.main,
        text: exports.DEFAULT_ALIAS_COLORS.black.main,
        placeholder: '#cbcbcb',
        background: exports.DEFAULT_ALIAS_COLORS.white.main,
        readOnly: {
            color: '#818181',
            background: '#f5f5f5',
            border: '#d7d7d7',
        },
        focus: {
            border: exports.DEFAULT_ALIAS_COLORS.black.main,
            background: exports.DEFAULT_ALIAS_COLORS.gray.main,
        },
        filled: exports.DEFAULT_ALIAS_COLORS.black,
        invalid: {
            main: (0, polished_1.transparentize)(0.8)(exports.DEFAULT_ALIAS_COLORS.red.main),
            contrastText: exports.DEFAULT_ALIAS_COLORS.black.main,
        },
    },
    divider: {
        dark: exports.DEFAULT_ALIAS_COLORS.black,
        light: {
            main: '#cbcbcb',
            contrastText: '#000000',
        },
    },
    footerNav: exports.DEFAULT_ALIAS_COLORS.gray,
    infoCard: {
        dark: {
            ...exports.DEFAULT_ALIAS_COLORS.solidGray,
            border: exports.DEFAULT_ALIAS_COLORS.solidGray.main,
        },
        light: {
            ...exports.DEFAULT_ALIAS_COLORS.gray,
            border: exports.DEFAULT_ALIAS_COLORS.black.main,
        },
        outline: {
            main: 'initial',
            contrastText: exports.DEFAULT_ALIAS_COLORS.black.main,
            border: exports.DEFAULT_ALIAS_COLORS.solidGray.main,
        },
    },
    sidebar: exports.DEFAULT_ALIAS_COLORS.lightGray,
    summaryBox: exports.DEFAULT_ALIAS_COLORS.black,
    packageGroup: {
        notification: exports.DEFAULT_ALIAS_COLORS.sunYellow,
    },
};
exports.DEFAULT_APP_THEME = {
    color: {
        alias: exports.DEFAULT_ALIAS_COLORS,
        base: exports.DEFAULT_BASE_COLORS,
        parts: exports.DEFAULT_PARTS_COLORS,
    },
    spacing: {
        0: '0px',
        1: '4px',
        2: '8px',
        3: '12px',
        4: '16px',
        5: '20px',
        6: '24px',
        7: '28px',
        8: '32px',
        9: '36px',
        10: '40px',
        11: '44px',
        12: '48px',
        13: '52px',
        14: '56px',
        15: '60px',
        16: '64px',
        17: '68px',
        18: '74px',
        19: '78px',
    },
    radius: {
        1: '4px',
        2: '8px',
        3: '12px',
        4: '16px',
        5: '20px',
        6: '24px',
        7: '28px',
        8: '32px',
        full: '100%',
    },
    font: {
        name: 'App-font',
        size: {
            xs: '0.75rem',
            'xs-sm': '0.825rem',
            sm: '0.875rem',
            base: '16px',
            lg: '1.125rem',
            'lg-xl': '1.22rem',
            xl: '1.35rem',
            '2xl': '1.5rem',
            '3xl': '1.875rem',
            '4xl': '2.25rem',
        },
    },
    lineHeight: {
        base: '1.5',
        3: '.75rem',
        4: '1rem',
        5: '1.25rem',
        6: '1.5rem',
        7: '1.75rem',
        8: '2rem',
        9: '2.25rem',
        10: '2.5rem',
        11: '2.7rem',
        12: '2.95rem',
    },
    shadows: {
        0: 'none',
        1: '0 0 6px rgba(0, 0, 0, 0.06)',
        2: '0 0 12px rgba(0, 0, 0, 0.16)',
        3: '0 1px 24px rgba(16, 0, 0, 0.16)',
        4: '0 -1px 24px rgba(16, 0, 0, 0.16)',
        5: '0 1px 32px rgba(0, 0, 0, 0.2)',
        6: '0 0 64px rgba(0, 0, 0, 0.2)',
    },
};
