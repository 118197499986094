"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingWizardNotificationCloseButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const icons_1 = require("@gerber/icons");
const i18n_1 = require("client/i18n");
const BookingWizardNotificationCloseButtonHolder = (0, styled_components_1.styled)(commons_front_1.CleanButton) `
  grid-area: close;
`;
const BookingWizardNotificationCloseButton = ({ onClick, }) => {
    const { pack } = (0, i18n_1.useI18n)();
    return ((0, jsx_runtime_1.jsx)(BookingWizardNotificationCloseButtonHolder, { type: "button", title: pack.common.close, "$focusBorder": false, onClick: onClick, children: (0, jsx_runtime_1.jsx)(icons_1.TimesIcon, {}) }));
};
exports.BookingWizardNotificationCloseButton = BookingWizardNotificationCloseButton;
