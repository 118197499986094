"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkSessionCreateItemInputV = exports.SdkSessionItemTypeV = void 0;
const zod_1 = require("zod");
const dto_1 = require("../../../dto");
exports.SdkSessionItemTypeV = zod_1.z.enum([
    'ROOM',
    'PACKAGE',
    'LAST_MINUTE_PACKAGE',
]);
exports.SdkSessionCreateItemInputV = zod_1.z.object({
    type: exports.SdkSessionItemTypeV,
    price: dto_1.SdkPriceV,
    roomCategoryId: zod_1.z.number(),
    rateId: zod_1.z.number(),
    variantId: zod_1.z.string().optional(),
    packageGroupId: zod_1.z.number().optional().nullable(),
    lastMinutePackageId: zod_1.z.number().nullable(),
    arrivalDay: zod_1.z.string(),
    nights: zod_1.z.number(),
    adults: zod_1.z.number(),
    children: zod_1.z.array(zod_1.z.number()),
    comment: zod_1.z.string().optional().default(''),
});
