"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPackageGroupBookingRoomValue = exports.BOOKING_STEPS = void 0;
const commons_1 = require("@gerber/commons");
exports.BOOKING_STEPS = [
    'who',
    'date',
    'offer',
    'ending',
    'contact',
];
const isPackageGroupBookingRoomValue = (value) => !(0, commons_1.isNil)(value.packageGroup);
exports.isPackageGroupBookingRoomValue = isPackageGroupBookingRoomValue;
