"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTextComponent = exports.text = exports.NOT_FORWARD_TEXT_PROPS = void 0;
const styled_components_1 = require("styled-components");
exports.NOT_FORWARD_TEXT_PROPS = [
    'display',
    'color',
    'size',
    'align',
    'expanded',
    'lineHeight',
    'weight',
    'decoration',
    'expanded',
];
const extractTextThemeColor = (theme, color) => {
    const aliasColor = theme.color.alias[color];
    if (aliasColor) {
        return aliasColor.main;
    }
    const baseColor = theme.color.base[color]?.main;
    if (baseColor) {
        return baseColor;
    }
    return color;
};
const text = () => (0, styled_components_1.css) `
  ${({ display }) => display &&
    (0, styled_components_1.css) `
      display: ${display};
    `}

  ${({ expanded }) => expanded &&
    (0, styled_components_1.css) `
      width: 100%;
    `}

  ${({ align }) => align &&
    (0, styled_components_1.css) `
      text-align: ${align};
    `}

  ${({ decoration }) => (0, styled_components_1.css) `
      text-decoration: ${decoration ?? 'none'};
    `}

  ${({ transform }) => transform &&
    (0, styled_components_1.css) `
      text-transform: ${transform};
    `}

  ${({ color, theme }) => {
    if (!color) {
        return null;
    }
    return (0, styled_components_1.css) `
      color: ${extractTextThemeColor(theme, color)};
    `;
}}

  ${({ size, theme }) => size &&
    (0, styled_components_1.css) `
      font-size: ${theme.font.size[size]};
    `}

  ${({ lineHeight, theme }) => lineHeight &&
    (0, styled_components_1.css) `
      line-height: ${theme.lineHeight[lineHeight]};
    `}

  ${({ weight }) => weight &&
    (0, styled_components_1.css) `
      font-weight: ${weight};
    `}
`;
exports.text = text;
const createTextComponent = (tag = 'span') => (0, styled_components_1.styled)(tag).withConfig({
    shouldForwardProp: prop => !exports.NOT_FORWARD_TEXT_PROPS.includes(prop),
})(exports.text);
exports.createTextComponent = createTextComponent;
