"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WobblyGalleryItemWrapper = void 0;
const styled_components_1 = require("styled-components");
exports.WobblyGalleryItemWrapper = styled_components_1.styled.div `
  position: relative;
  display: grid;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  overflow-y: auto;
  overflow-x: hidden;
  content-visibility: auto;
  contain-intrinsic-size: 0 100dvh;
`;
