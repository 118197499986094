"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.animation = exports.animKeyframe = exports.timingFunction = void 0;
const styled_components_1 = require("styled-components");
exports.timingFunction = {
    smooth: {
        in: 'cubic-bezier(0.16, 1, 0.3, 1)',
        out: 'cubic-bezier(0.16, 1, 0.3, 1)',
    },
};
exports.animKeyframe = {
    fadeIn: (0, styled_components_1.keyframes) `
    0%    { opacity: 0; }
    100%  { opacity: 1; }
  `,
    fadeOut: (0, styled_components_1.keyframes) `
    0%    { opacity: 1; }
    100%  { opacity: 0; }
  `,
    spaceFromDown: (0, styled_components_1.keyframes) `
    0% {
      transform: translateY(50px);
    }
    100% {
      transform: translateY(0px);
    }
  `,
    spaceFullFromDownIn: (0, styled_components_1.keyframes) `
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0px);
    }
  `,
    spaceFullFromDownOut: (0, styled_components_1.keyframes) `
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(100%);
    }
  `,
    spaceFromDownOut: (0, styled_components_1.keyframes) `
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(50px);
    }
  `,
    rotateAround: (0, styled_components_1.keyframes) `
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  `,
    fullFixedLayerShow: (0, styled_components_1.keyframes) `
    0% {
      opacity: 0;
      transform: scale3d(.9,.9,.9) translateY(10px);
    }

    100% {
      opacity: 1
    }
  `,
};
exports.animation = {
    rotate: {
        around: (0, styled_components_1.css) `
      animation: ${exports.animKeyframe.rotateAround} 1s linear infinite;
    `,
    },
    fade: {
        in: (0, styled_components_1.css) `
      animation: ${exports.animKeyframe.fadeIn} 300ms ease-in forwards;
    `,
        out: (0, styled_components_1.css) `
      animation: ${exports.animKeyframe.fadeOut} 300ms ease-out forwards;
    `,
    },
    modal: {
        in: (0, styled_components_1.css) `
      animation: ${exports.animKeyframe.fadeIn} 200ms ease-in forwards,
        ${exports.animKeyframe.spaceFromDown} 650ms ${exports.timingFunction.smooth.in} forwards;
    `,
        out: (0, styled_components_1.css) `
      animation: ${exports.animKeyframe.fadeOut} 150ms ease-out forwards,
        ${exports.animKeyframe.spaceFromDownOut} 300ms ${exports.timingFunction.smooth.out}
          forwards;
    `,
        layer: {
            in: (0, styled_components_1.css) `
        animation: ${exports.animKeyframe.fadeIn} 300ms ease-in forwards,
          ${exports.animKeyframe.spaceFullFromDownIn} 650ms ${exports.timingFunction.smooth.in}
            forwards;
      `,
            out: (0, styled_components_1.css) `
        animation: ${exports.animKeyframe.fadeOut} 150ms ease-out forwards,
          ${exports.animKeyframe.spaceFullFromDownOut} 650ms
            ${exports.timingFunction.smooth.out} forwards;
      `,
        },
    },
    space: {
        fromFullDown: {
            in: (0, styled_components_1.css) `
        animation: ${exports.animKeyframe.spaceFullFromDownIn} 200ms
          ${exports.timingFunction.smooth.in} forwards;
      `,
            out: (0, styled_components_1.css) `
        animation: ${exports.animKeyframe.spaceFullFromDownOut} 200ms
          ${exports.timingFunction.smooth.out} forwards;
      `,
        },
    },
};
