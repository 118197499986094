"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkSbComponentsListRenderer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const sdk_storyblok_component_renderer_1 = require("../sdk-storyblok-component-renderer");
const sdk_storyblok_list_renderer_holder_styled_1 = require("./sdk-storyblok-list-renderer-holder.styled");
const SdkSbComponentsListRenderer = ({ animProps, items, itemWrapperTag: WrapperTag, wrapped = false, ...props }) => {
    let content = items.map(item => {
        const itemContent = ((0, jsx_runtime_1.jsx)(sdk_storyblok_component_renderer_1.SdkStoryblokComponentRenderer, { item: item }, item.uid));
        if (WrapperTag) {
            return (0, jsx_runtime_1.jsx)(WrapperTag, { children: itemContent }, item.uid);
        }
        return itemContent;
    });
    if (animProps) {
        content = ((0, jsx_runtime_1.jsx)(commons_front_1.InitialAnimatedChildren, { ...animProps, children: content }));
    }
    if (!wrapped) {
        return content;
    }
    return ((0, jsx_runtime_1.jsx)(sdk_storyblok_list_renderer_holder_styled_1.SdkSbListRendererHolder, { ...props, children: content }));
};
exports.SdkSbComponentsListRenderer = SdkSbComponentsListRenderer;
