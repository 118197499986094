"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SectionGalleryArrowHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const ui_1 = require("client/ui");
const config = {
    shouldForwardProp: (0, commons_front_1.shouldNotForwardProps)([
        'direction',
    ]),
};
exports.SectionGalleryArrowHolder = (0, styled_components_1.styled)(ui_1.Button).withConfig(config) `
  position: absolute;
  z-index: 9;

  ${commons_front_1.styles.mediaMaxScreenWidth('md')} {
    bottom: -28px;

    ${({ direction }) => {
    if (direction === 'left') {
        return (0, styled_components_1.css) `
          right: calc(50% + 5px);
        `;
    }
    return (0, styled_components_1.css) `
        left: calc(50% + 5px);
      `;
}}
  }

  ${commons_front_1.styles.mediaMinScreenWidth('md')} {
    top: 170px;
    transform: translateX(-50%);

    ${({ direction }) => {
    if (direction === 'left') {
        return (0, styled_components_1.css) `
          left: calc(
            50% - var(--gallery-slider-width) / 2 -
              var(--gallery-slider-spacing)
          );
        `;
    }
    return (0, styled_components_1.css) `
        left: calc(
          50% + var(--gallery-slider-width) / 2 + var(--gallery-slider-spacing)
        );
      `;
}}
  }
`;
