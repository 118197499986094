"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chunkByProp = exports.chunkBy = void 0;
/* eslint-disable @typescript-eslint/prefer-reduce-type-parameter */
const function_1 = require("fp-ts/function");
const Record_1 = require("fp-ts-std/Record");
const group_by_1 = require("./group-by");
const chunkBy = (fn) => (0, function_1.flow)((0, group_by_1.groupBy)(fn), Record_1.values);
exports.chunkBy = chunkBy;
const chunkByProp = (key) => (0, exports.chunkBy)(item => item[key]);
exports.chunkByProp = chunkByProp;
