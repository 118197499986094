"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbHotelCard = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.SbHotelCard = styled_components_1.styled.article `
  display: grid;
  width: 100%;
  grid-template: 'cover content' / 40% 1fr;
  align-items: center;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    gap: ${spacing[12]};

    ${commons_front_1.styles.mediaSizeDown.md} {
      grid-template: 'cover' 'content';
      gap: ${spacing[7]};
    }
  `}
`;
