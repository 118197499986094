"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopGalleryTrackLine = exports.BookingDesktopGalleryTrackHolder = exports.HIDE_TRACK_BREAKPOINT = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const booking_desktop_sidebar_wrapper_styled_1 = require("../../sidebar-wrapper/booking-desktop-sidebar-wrapper.styled");
exports.HIDE_TRACK_BREAKPOINT = commons_front_1.styles.mediaMaxWidth(1320);
exports.BookingDesktopGalleryTrackHolder = styled_components_1.styled.div `
  display: flex;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-area: right;
  right: 450px;

  ${booking_desktop_sidebar_wrapper_styled_1.SIDEBAR_EXTEND_BREAKPOINT} {
    right: 530px;
  }

  ${exports.HIDE_TRACK_BREAKPOINT} {
    display: none;
  }
`;
exports.BookingDesktopGalleryTrackLine = styled_components_1.styled.div `
  width: 1px;
  height: 500px;
  max-height: 80vh;
  position: relative;

  ${({ theme: { color } }) => (0, styled_components_1.css) `
    color: ${color.alias.solidGray.main};
    background: ${color.alias.solidGray.main};
  `}
`;
