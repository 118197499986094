"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingMobileExitButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const hooks_1 = require("../../hooks");
const ui_1 = require("client/ui");
exports.BookingMobileExitButton = (0, react_1.memo)((props) => {
    const onExit = (0, hooks_1.useExitBookingWizard)();
    return (0, jsx_runtime_1.jsx)(ui_1.ExitButton, { "$kind": "outline", onClick: onExit, ...props });
});
exports.BookingMobileExitButton.displayName = 'BookingMobileExitButton';
