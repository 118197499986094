"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Picture = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const breakpoint_style_1 = require("../../styles/breakpoint.style");
const picture_styled_1 = require("./picture.styled");
exports.Picture = (0, react_1.memo)(({ src, alt, className }) => {
    if (!src) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(picture_styled_1.PictureHolder, { children: [src.sm && (0, jsx_runtime_1.jsx)("source", { srcSet: src.sm, media: (0, breakpoint_style_1.mediaMaxScreenWidth)('sm') }), src.md && (0, jsx_runtime_1.jsx)("source", { srcSet: src.md, media: (0, breakpoint_style_1.mediaMaxScreenWidth)('lg') }), (0, jsx_runtime_1.jsx)("img", { loading: "lazy", alt: alt ?? src.alt, src: src.original, className: className })] }));
});
exports.Picture.displayName = 'Picture';
