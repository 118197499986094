"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const forms_1 = require("@under-control/forms");
const commons_1 = require("@gerber/commons");
const commons_front_1 = require("@gerber/commons-front");
const input_1 = require("./input");
const styles_1 = require("./styles");
const guards_1 = require("./guards");
const helpers_1 = require("./helpers");
const hooks_1 = require("./hooks");
const i18n_1 = require("client/i18n");
exports.SelectInput = (0, forms_1.controlled)(({ style, control, inputContentPrefix, required, fillIfValueIsPresent = true, prependEmptyOption = true, showSelectedAsFirst = true, allowType = false, resetIncompleteValueOnBlur = false, truncateOptionsListWidth = true, mountToBody = false, showClearButton = false, showArrow = true, expanded, compact = true, borderless, loading, className, items: unmappedItems, inputProps, disabled: globalDisable, formatSelectedValue, inputName, onShowDropdown, onBlur: onForwardBlur, }) => {
    const { disabled = globalDisable } = inputProps ?? {};
    const i18n = (0, i18n_1.useI18n)();
    const listRef = (0, react_1.useRef)(null);
    const items = (0, react_1.useMemo)(() => (0, helpers_1.toSelectItems)(true)(unmappedItems), [unmappedItems]);
    const [position, setPosition] = (0, react_1.useState)();
    const { outsideRef, active, selected, setActive } = (0, hooks_1.useSelectInputState)({
        blurOnChangeValue: !allowType,
        value: control.getValue(),
        items,
        onBlur() {
            onForwardBlur?.();
            if (!resetIncompleteValueOnBlur) {
                return;
            }
            if (!(0, guards_1.isCompleteListItemValue)(control.getValue())) {
                control.setValue({
                    value: null,
                });
            }
        },
    });
    (0, react_1.useEffect)(() => {
        if (!active) {
            if (position) {
                setPosition(null);
            }
            return;
        }
        const rect = outsideRef.current?.getBoundingClientRect();
        const listRect = listRef.current?.getBoundingClientRect();
        if (!rect || !listRect) {
            return;
        }
        const width = Math.min(window.innerWidth * 0.8, truncateOptionsListWidth ? rect.width : listRect.width);
        const rightSpaceDelta = Math.min(0, window.innerWidth - 5 - (+rect.left + width));
        const maxHeight = (0, commons_1.clamp)(100, 300, window.innerHeight - (rect.bottom + window.scrollY) - 20);
        if (mountToBody) {
            setPosition({
                width,
                maxHeight,
                top: +rect.bottom + window.scrollY,
                left: +rect.left + rightSpaceDelta,
            });
        }
        else {
            setPosition({
                top: rect.height,
                left: rightSpaceDelta,
                width,
                maxHeight,
            });
        }
    }, [active, outsideRef.current, items, mountToBody]);
    (0, commons_front_1.useUpdateEffect)(() => {
        if (active) {
            onShowDropdown?.();
            outsideRef.current?.querySelector('input')?.focus();
        }
        else {
            onForwardBlur?.();
        }
    }, [active]);
    const onKeyDown = e => {
        switch (e.key) {
            case 'ArrowDown':
                setActive(true);
                break;
            case 'Tab':
                setActive(false);
                break;
            case 'Enter':
                (0, forms_1.suppressEvent)(e);
                break;
            default:
        }
    };
    const onSelectItem = (item) => {
        setActive(false);
        control.setValue({
            value: item,
        });
    };
    const onClickClear = e => {
        setActive(false);
        (0, forms_1.suppressEvent)(e);
        control.setValue({
            value: null,
        });
        onForwardBlur?.();
    };
    const onSetPhraseValue = (phrase) => {
        if (!active) {
            setActive(true);
        }
        control.setValue({
            value: (0, helpers_1.toSelectItem)(false)(phrase),
        });
    };
    const filled = !!selected?.name;
    let inputContent = null;
    if (allowType) {
        inputContent = ((0, jsx_runtime_1.jsx)(styles_1.CustomSelectPhraseInput, { name: inputName, required: required, placeholder: inputProps?.placeholder ?? i18n.pack.placeholders.chooseValue, disabled: disabled, "$allowType": allowType, autoComplete: "new-password", value: selected?.text ?? selected?.name ?? '', onChange: (event) => {
                onSetPhraseValue((0, forms_1.pickEventValue)(event));
            } }));
    }
    else {
        inputContent = ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(formatSelectedValue?.(selected) ?? selected?.name) || ((0, jsx_runtime_1.jsx)(styles_1.CustomSelectInputPlaceholder, { children: inputProps?.placeholder ?? i18n.pack.placeholders.chooseValue })), required && (0, jsx_runtime_1.jsx)("input", { type: "hidden", required: true, name: inputName })] }));
    }
    let dropdown = null;
    if (active) {
        dropdown = ((0, jsx_runtime_1.jsx)(input_1.SelectableList, { ref: listRef, style: position
                ? {
                    top: `${position.top}px`,
                    left: `${position.left}px`,
                    minWidth: `${position.width}px`,
                    maxHeight: `${position.maxHeight}px`,
                }
                : {
                    visibility: 'hidden',
                    top: 0,
                    left: 0,
                    maxHeight: 0,
                }, loading: loading, selected: selected, showSelectedAsFirst: showSelectedAsFirst, items: items, prependEmptyOption: prependEmptyOption, onSelectItem: onSelectItem }));
        if (mountToBody) {
            dropdown = (0, jsx_runtime_1.jsx)(commons_front_1.Portal, { children: dropdown });
        }
    }
    return ((0, jsx_runtime_1.jsxs)(styles_1.CustomSelectInputWrapper, { style: style, className: className, "$compact": compact, ref: outsideRef, children: [(0, jsx_runtime_1.jsxs)(styles_1.CustomSelectInputHolder, { ...inputProps, disabled: disabled, "$fillIfValuePresent": fillIfValueIsPresent, "$borderless": borderless, "$filled": filled, "$active": active, "$expanded": expanded, "$allowType": allowType, "$showArrow": showArrow, "$hasPrefix": !!inputContentPrefix, onFocus: () => {
                    setActive(true);
                }, onKeyDownCapture: onKeyDown, ...(allowType
                    ? {
                        onClick: () => {
                            setActive(true);
                        },
                    }
                    : {
                        onMouseDown: (event) => {
                            setActive(!active);
                            (0, forms_1.suppressEvent)(event);
                            if (!outsideRef.current?.contains(document.activeElement) &&
                                outsideRef.current !== document.activeElement) {
                                document.activeElement?.blur();
                            }
                        },
                    }), ...(!allowType && {
                    tabIndex: 0,
                }), ...(disabled && {
                    tabIndex: -1,
                }), children: [inputContentPrefix, inputContent, !disabled && filled && showClearButton && ((0, jsx_runtime_1.jsx)(styles_1.CustomSelectInputClear, { tabIndex: -1, title: i18n.pack.common.clear, onMouseDown: onClickClear }))] }), dropdown] }));
});
