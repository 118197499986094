"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalBlurWrapper = exports.modalBackdropBlurStyle = void 0;
const styled_components_1 = require("styled-components");
const layer_style_1 = require("../../../styles/layer.style");
const breakpoint_style_1 = require("../../../styles/breakpoint.style");
const modalBackdropBlurStyle = ({ $active, }) => (0, styled_components_1.css) `
  ${breakpoint_style_1.mediaSizeUp.xs} {
    transition: filter 150ms ease-in-out;

    ${$active &&
    (0, styled_components_1.css) `
      &:after {
        ${layer_style_1.layer.fixed}

        content: '';
        z-index: 1;
        backdrop-filter: blur(4px) grayscale(100%);
      }
    `}
  }
`;
exports.modalBackdropBlurStyle = modalBackdropBlurStyle;
exports.ModalBlurWrapper = styled_components_1.styled.div `
  min-height: 100%;

  ${exports.modalBackdropBlurStyle}
`;
