"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingRoute = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const booking_meta_1 = require("./booking.meta");
const providers_1 = require("client/providers");
const modules_1 = require("client/modules");
const hooks_1 = require("client/hooks");
const BookingRoute = () => {
    const [, params] = (0, hooks_1.useAppPrefixedRoute)('/book/:oneId');
    return ((0, jsx_runtime_1.jsxs)(providers_1.AppRouteProviders, { oneId: params?.oneId, children: [(0, jsx_runtime_1.jsx)(booking_meta_1.BookingRouteMeta, {}), (0, jsx_runtime_1.jsx)(modules_1.BookingWizardContainer, {})] }));
};
exports.BookingRoute = BookingRoute;
