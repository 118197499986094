"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingMobileDetailsModal = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const icons_1 = require("@gerber/icons");
const hooks_1 = require("../../desktop/sidebar/hooks");
const context_1 = require("../../../context");
const content_1 = require("../../desktop/sidebar/content");
const footer_1 = require("../../desktop/sidebar/footer");
const expanded_1 = require("../../desktop/sidebar/header/expanded");
const booking_mobile_details_styled_1 = require("./booking-mobile-details.styled");
const i18n_1 = require("client/i18n");
const components_1 = require("client/components");
const ui_1 = require("client/ui");
const BookingMobileDetailsModal = ({ onClose, ...props }) => {
    const navigation = (0, context_1.useBookingWizardNavigation)();
    const showSummary = (0, hooks_1.useShowBookingSummary)();
    const { pack } = (0, i18n_1.useI18n)();
    (0, commons_front_1.useUpdateEffect)(() => {
        onClose();
    }, [navigation.context.activeIndex]);
    return ((0, jsx_runtime_1.jsxs)(booking_mobile_details_styled_1.BookingMobileDetailsModalHolder, { ...props, children: [(0, jsx_runtime_1.jsxs)(booking_mobile_details_styled_1.BookingMobileDetailsHolder, { children: [(0, jsx_runtime_1.jsx)(expanded_1.BookingSidebarHeaderToolbar, {}), (0, jsx_runtime_1.jsx)(components_1.Divider, { "$type": "light" }), (0, jsx_runtime_1.jsx)(content_1.BookingDesktopSidebarContent, { showSummary: showSummary }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 2 }), (0, jsx_runtime_1.jsx)(footer_1.BookingDesktopSidebarFooter, { showSummaryPrice: showSummary, spaced: false })] }), (0, jsx_runtime_1.jsx)(booking_mobile_details_styled_1.BookingMobileDetailsFooter, { children: (0, jsx_runtime_1.jsxs)(ui_1.Button, { type: "button", "$kind": "outline", onClick: onClose, children: [pack.booking.wizard.footer.cta.hideDetails, (0, jsx_runtime_1.jsx)(ui_1.ButtonSuffixIcon, { children: (0, jsx_runtime_1.jsx)(icons_1.HideInBrowserIcon, {}) })] }) })] }));
};
exports.BookingMobileDetailsModal = BookingMobileDetailsModal;
