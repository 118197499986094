"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncMemoryCache = void 0;
/* eslint-disable @typescript-eslint/brace-style */
const Option_1 = require("fp-ts/Option");
const abstract_async_cache_1 = require("./abstract-async-cache");
class SyncMemoryCache {
    constructor(config) {
        this.vacuumInterval = null;
        this.cache = new Map();
        if (config.vacuumInterval) {
            this.vacuumInterval = setInterval(this.vacuum.bind(this), config.vacuumInterval);
        }
    }
    static ofObject(cache, config = { vacuumInterval: null }) {
        return new SyncMemoryCache(config).mset(cache);
    }
    vacuum() {
        const { cache } = this;
        for (const [key, entry] of cache.entries()) {
            if ((0, abstract_async_cache_1.isCacheEntryExpired)(entry)) {
                cache.delete(key);
            }
        }
    }
    destroy() {
        if (this.vacuumInterval !== null) {
            clearInterval(this.vacuumInterval);
            this.vacuumInterval = null;
        }
    }
    size() {
        return this.cache.size;
    }
    clear() {
        this.cache.clear();
    }
    del(key) {
        this.cache.delete(key);
    }
    get(key) {
        const { cache } = this;
        const entry = cache.get(key);
        if (!entry) {
            return Option_1.none;
        }
        if ((0, abstract_async_cache_1.isCacheEntryExpired)(entry)) {
            this.del(key);
            return Option_1.none;
        }
        return (0, Option_1.some)(entry.data);
    }
    mset(data) {
        for (const [key, value] of Object.entries(data)) {
            this.set(key, value, null);
        }
        return this;
    }
    set(key, data, expireSeconds = null) {
        this.cache.set(key, {
            expire: expireSeconds && Date.now() + expireSeconds * 1000,
            data,
        });
        return this;
    }
}
exports.SyncMemoryCache = SyncMemoryCache;
