"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetSearchParams = void 0;
const react_1 = require("react");
const commons_1 = require("@gerber/commons");
const use_after_mount_1 = require("./use-after-mount");
const useGetSearchParams = () => {
    const [searchParams, setSearchParams] = (0, react_1.useState)(null);
    (0, use_after_mount_1.useAfterMount)(() => {
        setSearchParams((0, commons_1.decodeSearchParams)(document.location.href));
    });
    return searchParams;
};
exports.useGetSearchParams = useGetSearchParams;
