"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkConfigPackageCategoryV = exports.SdkConfigPackageGroupV = void 0;
const zod_1 = require("zod");
const commons_1 = require("@gerber/commons");
const dto_1 = require("../../../dto");
const sdk_config_package_dto_1 = require("./sdk-config-package.dto");
exports.SdkConfigPackageGroupV = commons_1.IdNameV.extend({
    sort: zod_1.z.number(),
    description: zod_1.z.string(),
    teaser: zod_1.z.string(),
    includedServices: zod_1.z.string(),
    mediums: zod_1.z.array(dto_1.SdkMediumV),
    packages: zod_1.z.array(sdk_config_package_dto_1.SdkConfigPackageV),
});
exports.SdkConfigPackageCategoryV = commons_1.IdNameV.extend({
    sort: zod_1.z.number(),
    packageGroups: zod_1.z.array(exports.SdkConfigPackageGroupV),
});
