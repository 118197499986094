"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateSummary = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const function_1 = require("fp-ts/function");
const commons_1 = require("@gerber/commons");
const editable_1 = require("../../editable");
const summary_box_styled_1 = require("../../editable/summary-box.styled");
const context_1 = require("client/modules/booking/booking-wizard/context");
const i18n_1 = require("client/i18n");
exports.DateSummary = (0, react_1.memo)(({ date, isEditable }) => {
    const { pack } = (0, i18n_1.useI18n)();
    const navigation = (0, context_1.useBookingWizardNavigation)();
    const formatDate = (0, i18n_1.useFormatDate)();
    const range = date?.range;
    const onEditClick = () => {
        navigation.goTo('date');
    };
    if (!range) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(editable_1.SummaryEditableSection, { isEditable: isEditable && navigation.step !== 'date', onEditClick: onEditClick, children: [range.begin && ((0, jsx_runtime_1.jsx)(summary_box_styled_1.SummaryBox, { children: formatDate.shortcut(true)(range.begin) })), range.end && ((0, jsx_runtime_1.jsx)(summary_box_styled_1.SummaryBox, { children: formatDate.shortcut(true)(range.end) })), (0, commons_1.isFilledDateRange)(range) && ((0, jsx_runtime_1.jsx)(editable_1.SummaryCleanBox, { children: (0, function_1.pipe)((0, commons_1.getTotalDatesRangeDates)(range), pack.booking.wizard.summary.nights) }))] }));
});
exports.DateSummary.displayName = 'DateSummary';
