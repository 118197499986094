"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbTitledCtaSectionC = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
const storyblok_titled_cta_styled_1 = require("./storyblok-titled-cta.styled");
const components_1 = require("client/components");
const TITLED_SECTION_SPACING = {
    xs: 3,
    sm: 4,
    md: 5,
    lg: 8,
};
const SbTitledCtaSectionC = ({ item }) => {
    const space = TITLED_SECTION_SPACING[item.spacing];
    return ((0, jsx_runtime_1.jsxs)(storyblok_titled_cta_styled_1.StoryblokTitledCtaHolder, { ...(0, react_1.sdkStoryblokEditableProps)(item), maxWidth: item.maxWidth, "max-sm:maxWidth": item.smMaxWidth, children: [(0, jsx_runtime_1.jsx)(react_1.SdkSbComponentsListRenderer, { items: item.title, animProps: { animClassName: 'animate-fade-in-from-down' } }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": space }), (0, jsx_runtime_1.jsx)(react_1.SdkSbComponentsListRenderer, { items: item.content, justify: item.justify, wrapped: true }), item.toolbar.length > 0 && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": space }), (0, jsx_runtime_1.jsx)(react_1.SdkSbComponentsListRenderer, { items: item.toolbar, justify: item.justify, wrapped: true })] }))] }));
};
exports.SbTitledCtaSectionC = SbTitledCtaSectionC;
