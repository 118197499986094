"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingPaymentRouteProviders = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const booking_wizard_container_providers_1 = require("../../booking-wizard/booking-wizard-container-providers");
const hooks_1 = require("client/hooks");
const providers_1 = require("client/providers");
const BookingPaymentRouteProviders = ({ children, }) => {
    const [, params] = (0, hooks_1.useAppPrefixedRoute)('/payment/:oneId*');
    return ((0, jsx_runtime_1.jsx)(providers_1.AppRouteProviders, { oneId: params?.oneId?.split('/')[0], children: (0, jsx_runtime_1.jsx)(booking_wizard_container_providers_1.BookingWizardContainerProviders, { children: children }) }));
};
exports.BookingPaymentRouteProviders = BookingPaymentRouteProviders;
