"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkOneConfigV = void 0;
const zod_1 = require("zod");
const sdk_booking_countries_dto_1 = require("../../countries/dto/sdk-booking-countries.dto");
const sdk_sales_info_dto_1 = require("./sdk-sales-info.dto");
const sdk_page_customization_dto_1 = require("./sdk-page-customization.dto");
const sdk_config_package_group_dto_1 = require("./sdk-config-package-group.dto");
const sdk_config_room_category_dto_1 = require("./sdk-config-room-category.dto");
const sdk_config_rate_dto_1 = require("./sdk-config-rate.dto");
const sdk_config_room_category_feature_dto_1 = require("./sdk-config-room-category-feature.dto");
exports.SdkOneConfigV = zod_1.z
    .object({
    adultAge: zod_1.z.number(),
    defaultAdults: zod_1.z.number(),
    minChildren: zod_1.z.number().default(2),
    maxChildren: zod_1.z.number().default(4),
    pageCustomization: sdk_page_customization_dto_1.SdkPageCustomizationV,
    packageCategories: zod_1.z.array(sdk_config_package_group_dto_1.SdkConfigPackageCategoryV),
    roomCategories: zod_1.z.array(sdk_config_room_category_dto_1.SdkConfigRoomCategoryV),
    roomCategoryFeatures: zod_1.z.array(sdk_config_room_category_feature_dto_1.SdkConfigRoomCategoryFeatureV),
    salesInfos: zod_1.z.array(sdk_sales_info_dto_1.SdkPageSalesInfoV),
    rates: zod_1.z.array(sdk_config_rate_dto_1.SdkConfigRateV),
    countries: zod_1.z.array(sdk_booking_countries_dto_1.SdkBookingCountryV),
})
    .transform(obj => ({
    ...obj,
    maxAdults: obj.roomCategories.reduce((acc, item) => Math.max(acc, item.maximumOccupancy), 0),
}));
