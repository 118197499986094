"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingStartScreenMobile = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const parts_1 = require("../../../parts");
const hooks_1 = require("../hooks");
const shared_1 = require("../shared");
const booking_start_screen_mobile_styled_1 = require("./booking-start-screen-mobile.styled");
const jumbotron_1 = require("./jumbotron");
const decorators_1 = require("client/modules/booking/booking-wizard/decorators");
const form_1 = require("client/modules/booking/booking-wizard/form");
const context_1 = require("client/modules/booking/booking-wizard/context");
const i18n_1 = require("client/i18n");
const components_1 = require("client/components");
exports.BookingStartScreenMobile = (0, decorators_1.controlledBookingValue)(({ control }) => {
    const globalForm = (0, context_1.useBookingGlobalForm)();
    const showRoomSwitch = (0, parts_1.useIsMobileRoomSwitchVisible)();
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.start;
    const { bind, value, handleSubmitEvent, validator: { errors }, } = (0, hooks_1.useBookingStartScreenForm)({
        control,
    });
    return ((0, jsx_runtime_1.jsxs)(booking_start_screen_mobile_styled_1.BookingStartScreenMobileWrapper, { ...(0, commons_front_1.testid)('wizard:start'), children: [!globalForm.value.meta.hideMobileStartJumbotron && ((0, jsx_runtime_1.jsx)(jumbotron_1.BookingMobileStartJumbotron, {})), (0, jsx_runtime_1.jsxs)(parts_1.BookingMobileScreenContent, { id: "start", footer: (0, jsx_runtime_1.jsx)(parts_1.BookingMobileFooter, { submitOnNext: true, disabled: {
                        next: !(0, form_1.isWhoBookingSectionFilled)(value),
                    } }), onSubmit: handleSubmitEvent, ...(showRoomSwitch && {
                    toolbar: (0, jsx_runtime_1.jsx)(parts_1.BookingMobileRoomSwitch, {}),
                }), children: [(0, jsx_runtime_1.jsx)(components_1.H1, { color: "primary", children: t.header }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 4 }), value.packageGroup && (0, jsx_runtime_1.jsx)(shared_1.StartScreenPackageNotification, {}), (0, jsx_runtime_1.jsx)(shared_1.StartScreenFormContent, { ...bind.path('who'), ...errors.extract('who', { nested: true }) })] })] }));
});
