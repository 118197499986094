"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbLayoutSection = void 0;
const function_1 = require("fp-ts/function");
const styled_components_1 = require("styled-components");
const styles_1 = require("@gerber/commons-front/dist/styles");
const commons_front_1 = require("@gerber/commons-front");
const getSectionColor = (theme) => (color) => {
    switch (color) {
        case 'muted':
            return theme.color.alias.lightGray;
        case 'none':
            return null;
    }
};
const GRID_SPACING = {
    xs: 15,
    sm: 32,
    md: 45,
    lg: 70,
};
const config = {
    shouldForwardProp: (0, commons_front_1.shouldNotForwardProps)([
        ...(0, styles_1.maxBreakpointFields)('space'),
        'color',
        'noSpace',
    ]),
};
exports.SbLayoutSection = styled_components_1.styled.section.withConfig(config) `
  overflow-x: hidden;

  ${({ theme, color }) => (0, function_1.pipe)(color, getSectionColor(theme), commons_front_1.genPaletteColorCSS)}

  ${(0, commons_front_1.maxBreakpointProp)('space')((space, { noSpace }) => space &&
    (0, styled_components_1.css) `
        padding: ${noSpace?.top ? 0 : GRID_SPACING[space]}px 0
          ${noSpace?.bottom ? 0 : GRID_SPACING[space]}px 0;
      `)}
`;
