"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingRoomCommentRow = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const forms_1 = require("@under-control/forms");
const booking_room_comment_row_styled_1 = require("./booking-room-comment-row.styled");
const components_1 = require("client/components");
const i18n_1 = require("client/i18n");
exports.BookingRoomCommentRow = (0, forms_1.controlled)(({ control: { bind } }) => {
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.contact.additionalInfo;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Text, { weight: 600, display: "block", children: t.title }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 2 }), (0, jsx_runtime_1.jsx)(components_1.Text, { weight: 500, display: "block", children: t.subtitle }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 4 }), (0, jsx_runtime_1.jsx)(booking_room_comment_row_styled_1.BookingRoomCommentInput, { as: "textarea", rows: 3, placeholder: t.placeholder, "$expanded": true, ...bind.entire() })] }));
});
