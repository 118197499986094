"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbNavigationLogo = exports.SbNavigationTitleHolder = exports.SbNavigationBrandHolder = void 0;
const styled_components_1 = require("styled-components");
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
exports.SbNavigationBrandHolder = styled_components_1.styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    gap: ${spacing[5]};
  `}
`;
exports.SbNavigationTitleHolder = styled_components_1.styled.div `
  ${({ theme: { font } }) => (0, styled_components_1.css) `
    font-size: ${font.size.lg};
    font-weight: 600;
  `}
`;
exports.SbNavigationLogo = (0, styled_components_1.styled)(react_1.SbPicture) `
  height: 38px;
`;
