"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokButtonBlockV = void 0;
const zod_1 = require("zod");
const helpers_1 = require("../../../helpers");
const parts_1 = require("../../parts");
const sdk_storyblok_block_dto_1 = require("../../sdk-storyblok-block.dto");
const SdkStoryblokButtonIconLocationV = zod_1.z.enum(['left', 'center', 'right']);
const SdkStoryblokButtonKindV = zod_1.z.enum([
    'primary',
    'inactive',
    'outline',
    'outline-transparent',
]);
exports.SdkStoryblokButtonBlockV = (0, sdk_storyblok_block_dto_1.SdkStoryblokBlockV)('Component_Button', {
    text: zod_1.z.string().default('').optional(),
    kind: SdkStoryblokButtonKindV.catch('outline'),
    square: zod_1.z.boolean().default(false),
    link: parts_1.SdkStoryblokLinkV.optional().nullable(),
    icon: (0, helpers_1.optionalStoryblokAttrV)(parts_1.SdkStoryblokIconV),
    icon_location: (0, helpers_1.optionalStoryblokAttrV)(SdkStoryblokButtonIconLocationV, 'left'),
});
