"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokDescriptionImageBlockV = exports.SdkStoryblokDescriptionImageAlignV = void 0;
const zod_1 = require("zod");
const components_1 = require("../components");
const sdk_storyblok_block_dto_1 = require("../../sdk-storyblok-block.dto");
const sdk_storyblok_picture_dto_1 = require("../../parts/sdk-storyblok-picture.dto");
const sdk_storyblok_titled_cta_section_block_1 = require("./sdk-storyblok-titled-cta-section.block");
exports.SdkStoryblokDescriptionImageAlignV = zod_1.z.enum(['left', 'right']);
const SdkStoryblokDescriptionImageContentV = zod_1.z
    .union([components_1.SdkStoryblokBaseBlockV, sdk_storyblok_titled_cta_section_block_1.SdkStoryblokTitledCtaBlockV])
    .array()
    .default([]);
exports.SdkStoryblokDescriptionImageBlockV = (0, sdk_storyblok_block_dto_1.SdkStoryblokBlockV)('Section_Description_Image', {
    image: sdk_storyblok_picture_dto_1.SdkStoryblokPictureV,
    content: SdkStoryblokDescriptionImageContentV,
    align: exports.SdkStoryblokDescriptionImageAlignV.catch('left'),
});
