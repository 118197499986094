"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenderSelectInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const inputs_1 = require("@under-control/inputs");
const commons_1 = require("@gerber/commons");
const i18n_1 = require("client/i18n");
const helpers_1 = require("client/ui/select-input/helpers");
const select_input_1 = require("client/ui/select-input");
exports.GenderSelectInput = (0, inputs_1.controlled)(({ control: { value, setValue }, ...props }) => {
    const { pack } = (0, i18n_1.useI18n)();
    const items = (0, helpers_1.typeSafeEnumSelectItemsMap)(pack.gender);
    return ((0, jsx_runtime_1.jsx)(select_input_1.SelectInputOrNative, { items: items, value: (0, commons_1.findItemById)(value)(items) ?? null, onChange: item => {
            setValue({
                merge: false,
                value: item?.id,
            });
        }, ...props }));
});
