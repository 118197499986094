"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingWizardOverlay = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const layout_1 = require("client/layout");
exports.BookingWizardOverlay = (0, styled_components_1.styled)(layout_1.BaseAppHolder) `
  ${commons_front_1.styles.layer.fixed}

  height: 100vh;
  height: 100dvh;
  overflow: hidden;
`;
