"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokGalleryBlockItemV = void 0;
const zod_1 = require("zod");
const sdk_storyblok_block_dto_1 = require("../../../sdk-storyblok-block.dto");
const components_1 = require("../../components");
const parts_1 = require("../../../parts");
exports.SdkStoryblokGalleryBlockItemV = (0, sdk_storyblok_block_dto_1.SdkStoryblokBlockV)('Section_Gallery_Item', {
    title: zod_1.z.string(),
    image: parts_1.SdkStoryblokPictureV,
    description: parts_1.SdkStoryblokRichTextV,
    toolbar: components_1.SdkStoryblokBaseBlockContentV,
});
