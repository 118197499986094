"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopSidebarExpandedLogo = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BookingDesktopSidebarExpandedLogo = (0, styled_components_1.styled)(commons_front_1.Picture) `
  width: auto;
  height: auto;
  height: 100px;
  max-width: 100%;
  margin-right: auto;
  object-fit: contain;
`;
