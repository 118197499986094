"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInstantAfterMount = void 0;
const react_1 = require("react");
const useInstantAfterMount = (callback) => {
    const executedRef = (0, react_1.useRef)(false);
    if (!executedRef.current) {
        callback();
    }
    executedRef.current = true;
};
exports.useInstantAfterMount = useInstantAfterMount;
