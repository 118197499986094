"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbJumbotronImage = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
exports.SbJumbotronImage = (0, styled_components_1.styled)(react_1.SbPicture).attrs({
    optimize: {
        size: {
            w: 1200,
            h: 0,
        },
    },
}) `
  width: calc(100% + var(--container-x-space, 0) * 2);
  height: max(600px, 45vh);
  margin: 0 calc(-1 * var(--container-x-space, 0));
  object-fit: cover;

  ${commons_front_1.styles.mediaSizeDown.xs} {
    height: auto;
  }
`;
