"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useExpandedCookie = void 0;
const react_1 = require("react");
const commons_1 = require("@gerber/commons");
const commons_front_1 = require("@gerber/commons-front");
const useExpandedCookie = ({ expandCacheId, defaultExpanded, prefix, }) => {
    const expandCookie = (0, commons_front_1.useCookie)(`expanded-${prefix}-${expandCacheId}`);
    const [expanded, setExpanded] = (0, react_1.useState)(() => {
        const cookieResult = expandCookie.get();
        if (!(0, commons_1.isNil)(cookieResult)) {
            return cookieResult === '1';
        }
        return !!defaultExpanded;
    });
    const onToggle = () => {
        setExpanded(prevExpanded => {
            expandCookie.set(Number(!prevExpanded).toString(), {
                expires: new Date(Date.now() + commons_1.Time.toSeconds.years(10) * 1000),
            });
            return !prevExpanded;
        });
    };
    return {
        expanded,
        onToggle,
    };
};
exports.useExpandedCookie = useExpandedCookie;
