"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LandingPageContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const E = __importStar(require("fp-ts/Either"));
const commons_1 = require("@gerber/commons");
const client_1 = require("@gerber/ssr/dist/client");
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
const commons_front_1 = require("@gerber/commons-front");
const landing_page_story_1 = require("./landing-page-story");
const error_1 = require("client/routes/error");
const ui_1 = require("client/ui");
const i18n_1 = require("client/i18n");
const LandingPageContainer = ({ slug }) => {
    const { sdk } = (0, react_1.useStoryblokSdk)();
    const { lang } = (0, i18n_1.useI18n)();
    const http = (0, client_1.useHttpAction)();
    const result = (0, client_1.useHydratedPromise)(sdk.controllers.pages.getPage(slug, {
        resolve_relations: landing_page_story_1.RESOLVE_LP_RELATIONS,
        fallback_lang: 'de',
        language: lang,
    }), {
        uuid: 'landing-page',
        deps: [slug, lang],
        ...(!(0, commons_1.isDevBuild)() &&
            sdk.client.isPublished() && {
            cache: {
                policy: 'client-and-server',
                expireInSeconds: 200,
            },
        }),
    });
    (0, commons_front_1.useScrollRestoreOnMount)({
        skip: !result.value || !E.isRight(result.value),
    });
    if (result.loading || !result?.value) {
        return (0, jsx_runtime_1.jsx)(ui_1.SpinnerContainer, { "$height": "100vh" });
    }
    if (E.isLeft(result.value)) {
        http.statusCode(404);
        return (0, jsx_runtime_1.jsx)(error_1.NotFoundLayer, {});
    }
    return (0, jsx_runtime_1.jsx)(landing_page_story_1.LandingPageStory, { story: result.value.right.story });
};
exports.LandingPageContainer = LandingPageContainer;
