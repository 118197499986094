"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsercentricsHeadScript = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
/* eslint-disable max-len */
const react_helmet_1 = require("react-helmet");
const i18n_1 = require("./../../../i18n");
const config_1 = require("client/config");
const UsercentricsHeadScript = () => {
    const { usercentrics, storyblok } = (0, config_1.useAppConfig)();
    const urlLang = (0, i18n_1.useI18nUrlLang)();
    if (storyblok.version === 'draft' || !usercentrics) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(react_helmet_1.Helmet, { children: (0, jsx_runtime_1.jsx)("script", { id: "usercentrics-cmp", src: "https://web.cmp.usercentrics.eu/ui/loader.js", "data-settings-id": usercentrics.id, "data-language": urlLang ?? 'en', async: true }) }));
};
exports.UsercentricsHeadScript = UsercentricsHeadScript;
