"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWatchScreenSizeFlag = void 0;
const react_1 = require("react");
const use_watch_screen_size_1 = require("./use-watch-screen-size");
const useWatchScreenSizeFlag = (reader) => {
    const [satisfy, setSatisfy] = (0, react_1.useState)(null);
    (0, use_watch_screen_size_1.useWatchScreenSize)(() => {
        setSatisfy(reader(window.innerWidth, window.innerHeight));
    });
    return [satisfy === null, !!satisfy];
};
exports.useWatchScreenSizeFlag = useWatchScreenSizeFlag;
