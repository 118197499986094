"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseModalLayer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const base_modal_backdrop_styled_1 = require("./base-modal-backdrop.styled");
const base_modal_wrapper_styled_1 = require("./base-modal-wrapper.styled");
const BaseModalLayer = ({ children, out, onClose, ...props }) => {
    // prevent mouse down that starts in modal and ends outside
    let startClickElement = null;
    return ((0, jsx_runtime_1.jsxs)("div", { ...props, children: [(0, jsx_runtime_1.jsx)(base_modal_backdrop_styled_1.BaseModalBackdrop, { out: out }), (0, jsx_runtime_1.jsx)(base_modal_wrapper_styled_1.BaseModalWrapper, { out: out, onMouseDown: e => {
                    startClickElement = e.target;
                }, onClick: e => {
                    if (e.target === e.currentTarget && startClickElement === e.target) {
                        onClose();
                    }
                }, children: children })] }));
};
exports.BaseModalLayer = BaseModalLayer;
