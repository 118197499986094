"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkBookingRoomDayV = exports.SdkBookingRoomDayInputV = void 0;
const zod_1 = require("zod");
const sdk_booking_room_day_offer_dto_1 = require("./sdk-booking-room-day-offer.dto");
exports.SdkBookingRoomDayInputV = zod_1.z.object({
    arrivalDay: zod_1.z.date(),
    adults: zod_1.z.number(),
    children: zod_1.z.array(zod_1.z.number()),
});
exports.SdkBookingRoomDayV = zod_1.z.object({
    offers: zod_1.z.array(sdk_booking_room_day_offer_dto_1.SdkBookingRoomDayOfferV),
});
