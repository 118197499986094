"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackageGroupCover = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const package_group_cover_styled_1 = require("./package-group-cover.styled");
const i18n_1 = require("client/i18n");
const PackageGroupCover = ({ mediums }) => {
    const { pack } = (0, i18n_1.useI18n)();
    return ((0, jsx_runtime_1.jsxs)(package_group_cover_styled_1.PackageGroupCoverWrapper, { children: [(0, jsx_runtime_1.jsx)(package_group_cover_styled_1.PackageGroupCoverBadge, { children: pack.common.packageGroup }), (0, jsx_runtime_1.jsx)(package_group_cover_styled_1.PackageGroupCoverPicture, { medium: mediums[0], type: "MEDIUM" })] }));
};
exports.PackageGroupCover = PackageGroupCover;
