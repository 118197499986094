"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopFooterRight = exports.BookingDesktopFooterLeft = exports.BookingDesktopFooterHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BookingDesktopFooterHolder = styled_components_1.styled.div `
  display: grid;
  grid-template: 'left content right' / ${commons_front_1.CONTAINER_WIDE_SPACING}px 1fr ${commons_front_1.CONTAINER_WIDE_SPACING}px;
`;
exports.BookingDesktopFooterLeft = styled_components_1.styled.div `
  display: flex;
  flex-direction: column;
  grid-area: left;
  align-items: flex-start;
`;
exports.BookingDesktopFooterRight = styled_components_1.styled.div `
  display: flex;
  flex-direction: column;
  grid-area: right;
  align-items: flex-end;
`;
