"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useScrollRestoreOnMount = void 0;
const O = __importStar(require("fp-ts/Option"));
const use_storage_object_1 = require("./use-storage-object");
const use_isomorphic_effect_1 = require("./use-isomorphic-effect");
const useScrollRestoreOnMount = ({ skip } = {}) => {
    const scrollStorage = (0, use_storage_object_1.useSessionStorageObject)('cached-route-scroll');
    (0, use_isomorphic_effect_1.useIsomorphicLayoutEffect)(() => {
        if (skip) {
            return;
        }
        const maybeScroll = scrollStorage.getAndRemove();
        const currentPath = location.pathname;
        if (O.isSome(maybeScroll) && maybeScroll.value.pathname === currentPath) {
            window.scrollTo(0, maybeScroll.value.y);
        }
        return () => {
            scrollStorage.set({
                y: window.scrollY,
                pathname: currentPath,
            });
        };
    }, [skip]);
};
exports.useScrollRestoreOnMount = useScrollRestoreOnMount;
