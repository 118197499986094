"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokPagesController = void 0;
const function_1 = require("fp-ts/function");
const commons_1 = require("@gerber/commons");
const abstract_1 = require("../abstract");
const dto_1 = require("../dto");
class SdkStoryblokPagesController extends abstract_1.SdkStoryblokApiController {
    constructor() {
        super(...arguments);
        this.getPage = (slug, params) => (0, function_1.pipe)(slug, (0, commons_1.concatUrlParts2C)('/', 'cdn', 'stories'), parsedSlug => this.client.getTE(parsedSlug, params));
        this.getPageParsed = (0, function_1.flow)(this.getPage, (0, commons_1.tryParseUsingZodSchemaTE)(dto_1.SdkStoryblokPageStoryV));
    }
}
exports.SdkStoryblokPagesController = SdkStoryblokPagesController;
