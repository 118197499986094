"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RenderOnScreen = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const styles_1 = require("../../../styles");
const use_match_screen_1 = require("../use-match-screen");
const RenderOnScreen = ({ default: defaultScreen, ...screens }) => {
    const screenName = (0, use_match_screen_1.useMatchScreenName)();
    const matchedScreenName = (0, react_1.useMemo)(() => {
        const currentScreenSizeIndex = (0, styles_1.getScreenSizeIndex)(screenName);
        const sortedScreens = Object.entries(screens).sort((a, b) => (0, styles_1.getScreenSizeIndex)(a[0]) -
            (0, styles_1.getScreenSizeIndex)(b[0]));
        for (const [name] of sortedScreens) {
            if ((0, styles_1.getScreenSizeIndex)(name) >= currentScreenSizeIndex) {
                return name;
            }
        }
        return null;
    }, [screenName]);
    const screen = screens[matchedScreenName] ?? defaultScreen;
    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: typeof screen === 'function' ? screen() : screen });
};
exports.RenderOnScreen = RenderOnScreen;
