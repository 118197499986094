"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HotelContactRowHolder = void 0;
const styled_components_1 = require("styled-components");
exports.HotelContactRowHolder = styled_components_1.styled.div `
  display: flex;
  flex-direction: column;
  text-align: right;
  width: 100%;
`;
