"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewportAnimationManager = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const use_is_in_viewport_1 = require("../../../hooks/use-is-in-viewport");
const react_2 = require("../../../helpers/react");
const animation_manager_1 = require("./animation-manager");
exports.ViewportAnimationManager = (0, react_1.forwardRef)(({ tag: Tag = 'div', children, ...props }, ref) => {
    const [enabledAnim, setEnabledAnim] = (0, react_1.useState)(false);
    const { elementRef, isIntersecting } = (0, use_is_in_viewport_1.useIsInViewport)({
        threshold: [0.2],
    });
    (0, react_1.useEffect)(() => {
        if (isIntersecting) {
            setEnabledAnim(isIntersecting);
        }
    }, [isIntersecting]);
    return ((0, jsx_runtime_1.jsx)(Tag, { ref: (0, react_2.mergeRefs)(elementRef, ref), ...props, children: (0, jsx_runtime_1.jsx)(animation_manager_1.AnimationManager, { enabled: enabledAnim, children: children }) }));
});
exports.ViewportAnimationManager.displayName = 'ViewportAnimationManager';
