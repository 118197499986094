"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopSidebarScrollableContent = exports.BookingDesktopSidebarContentSpacer = exports.BookingDesktopSidebarHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BookingDesktopSidebarHolder = styled_components_1.styled.div `
  display: grid;
  position: relative;
  height: 100%;
  max-height: 100vh;
  max-height: 100dvh;
  grid-template-rows: 1fr min-content;
  overflow-y: auto;
  overflow-x: hidden;

  ${({ theme: { color } }) => (0, styled_components_1.css) `
    ${(0, commons_front_1.genPaletteColorCSS)(color.parts.sidebar)}
  `}
`;
exports.BookingDesktopSidebarContentSpacer = styled_components_1.styled.div `
  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    padding: ${spacing[6]};
  `}
`;
exports.BookingDesktopSidebarScrollableContent = (0, styled_components_1.styled)(exports.BookingDesktopSidebarContentSpacer) `
  display: flex;
  flex-direction: column;

  ${commons_front_1.styles.mediaMinHeight(800)} {
    overflow-y: auto;
  }

  ${({ $scrollable = true }) => !$scrollable &&
    (0, styled_components_1.css) `
      overflow-y: initial;
    `}
`;
