"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomSelectInputOptions = exports.CustomSelectInputOptionsWrapper = void 0;
const styled_components_1 = require("styled-components");
const components_1 = require("client/components");
exports.CustomSelectInputOptionsWrapper = styled_components_1.styled.div `
  position: absolute;
  z-index: 9999;
  padding-bottom: ${({ theme }) => theme.spacing[3]};
`;
exports.CustomSelectInputOptions = (0, styled_components_1.styled)(components_1.CleanList) `
  max-height: inherit;
  overflow-y: auto;

  &:empty {
    display: none;
  }

  ${({ theme: { spacing, color, shadows, font } }) => (0, styled_components_1.css) `
    margin: ${spacing[2]} 0 ${spacing[3]};
    border: 1px solid ${color.parts.input.border};
    box-shadow: ${shadows[2]};
    background: ${color.alias.white.main};
    font-size: ${font.size.sm};
  `}
`;
