"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.H3 = exports.H2 = exports.H1 = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const theme_1 = require("client/theme");
const baseHeaderConfig = {
    shouldForwardProp: (0, commons_front_1.shouldNotForwardProps)([
        'weight',
        'align',
        'color',
    ]),
};
const baseHeaderStyle = ({ weight, color, align }) => (0, styled_components_1.css) `
  font-weight: ${weight ?? 600};
  text-wrap: balance;

  ${color &&
    (0, styled_components_1.css) `
    color: ${({ theme }) => (0, theme_1.extractThemeColor)(color)(theme.color)?.main};
  `}

  ${align &&
    (0, styled_components_1.css) `
    text-align: ${align};
  `}
`;
exports.H1 = styled_components_1.styled.h1.withConfig(baseHeaderConfig) `
  ${baseHeaderStyle}
  ${({ theme: { font, lineHeight } }) => (0, styled_components_1.css) `
    font-size: ${font.size['3xl']};
    line-height: ${lineHeight[10]};
  `}
`;
exports.H2 = styled_components_1.styled.h2.withConfig(baseHeaderConfig) `
  ${baseHeaderStyle}
  ${({ theme: { font, lineHeight } }) => (0, styled_components_1.css) `
    font-size: ${font.size.xl};
    line-height: ${lineHeight[9]};
  `}
`;
exports.H3 = styled_components_1.styled.h2.withConfig(baseHeaderConfig) `
  ${baseHeaderStyle}
  ${({ theme: { font, lineHeight } }) => (0, styled_components_1.css) `
    font-size: ${font.size.lg};
    line-height: ${lineHeight[8]};
  `}
`;
