"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbHotelCoverLogo = exports.SbHotelCover = exports.SbHotelCardCoverHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
const components_1 = require("client/components");
exports.SbHotelCardCoverHolder = (0, styled_components_1.styled)(components_1.UniversalLink) `
  position: relative;
  grid-area: cover;
`;
exports.SbHotelCover = (0, styled_components_1.styled)(react_1.SbPicture).attrs({
    optimize: {
        size: {
            w: 500,
            h: 0,
        },
    },
}) `
  width: 100%;
  aspect-ratio: 1.7;
  object-fit: cover;
`;
exports.SbHotelCoverLogo = (0, styled_components_1.styled)(react_1.SbPicture).attrs({
    optimize: {
        size: {
            w: 180,
            h: 0,
        },
    },
}) `
  ${commons_front_1.styles.absoluteCenter}

  width: 30%;
`;
