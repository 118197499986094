"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.anonymizeSdkSession = void 0;
const anonymizeSdkSessionItem = (item) => ({
    ...item,
    guests: (item.guests ?? []).map(guest => ({
        ...guest,
        firstname: 'XXX',
        lastname: 'XXX',
    })),
});
const anonymizeSdkSession = (session) => ({
    ...session,
    items: session.items.map(anonymizeSdkSessionItem),
    ...(session.user && {
        user: {
            phone: '*** *** ***',
            firstname: '***',
            lastname: '***',
            email: '***',
            street: '***',
            zipcode: '***',
            city: '***',
        },
    }),
});
exports.anonymizeSdkSession = anonymizeSdkSession;
