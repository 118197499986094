"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWindowListener = void 0;
const use_element_listener_1 = require("./use-element-listener");
/**
 * Mounts listeners to window, functions are cache safe.
 *
 * @example
 *  useWindowListener({
 *    resize() { ... },
 *  });
 */
const useWindowListener = (hash, attrs) => {
    (0, use_element_listener_1.useElementListener)(hash, {
        ...attrs,
        selectorFn: () => window,
    });
};
exports.useWindowListener = useWindowListener;
