"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInDateRange = void 0;
const date_fns_1 = require("date-fns");
const isInDateRange = ({ begin, end }) => (value) => {
    const startValue = (0, date_fns_1.startOfDay)(value);
    const endValue = (0, date_fns_1.endOfDay)(value);
    return ((!begin || startValue >= (0, date_fns_1.startOfDay)(begin)) &&
        (!end || endValue <= (0, date_fns_1.endOfDay)(end)));
};
exports.isInDateRange = isInDateRange;
