"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.genPaletteColorCSS = void 0;
const styled_components_1 = require("styled-components");
const genPaletteColorCSS = (color) => color &&
    (0, styled_components_1.css) `
    color: ${color.contrastText};
    background: ${color.main};
  `;
exports.genPaletteColorCSS = genPaletteColorCSS;
