"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingWizardNotification = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const booking_wizard_notification_styled_1 = require("./booking-wizard-notification.styled");
const booking_wizard_notification_close_button_1 = require("./booking-wizard-notification-close-button");
const hooks_1 = require("client/hooks");
const BookingWizardNotification = ({ expandCacheId, color, ...attrs }) => {
    const theme = (0, styled_components_1.useTheme)();
    const expandCookie = (0, hooks_1.useExpandedCookie)({
        prefix: 'wizard-notification',
        defaultExpanded: true,
        expandCacheId,
    });
    if (!expandCookie.expanded) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(booking_wizard_notification_styled_1.BookingWizardNotificationHolder, { "$color": color ?? theme.color.parts.packageGroup.notification, children: [(0, jsx_runtime_1.jsxs)(commons_front_1.GridArea, { "$name": "content", children: ['children' in attrs && attrs.children, 'html' in attrs && ((0, jsx_runtime_1.jsx)("span", { dangerouslySetInnerHTML: { __html: attrs.html } }))] }), (0, jsx_runtime_1.jsx)(booking_wizard_notification_close_button_1.BookingWizardNotificationCloseButton, { onClick: expandCookie.onToggle })] }));
};
exports.BookingWizardNotification = BookingWizardNotification;
