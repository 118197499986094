"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchApiTE = void 0;
const commons_1 = require("@gerber/commons");
const exceptions_1 = require("../exceptions");
const fetchApiTE = ({ url, query, options, blob, }) => {
    const task = async () => {
        if (query) {
            url = (0, commons_1.withSearchParams)(query)(url);
        }
        if ((0, commons_1.isDevBuild)()) {
            console.info('API Call:', url);
        }
        const headers = {
            ...(!(options?.body instanceof FormData) && {
                'Content-Type': 'application/json',
                ...(!blob && {
                    Accept: 'application/json',
                }),
            }),
            ...options?.headers,
        };
        const result = await fetch(url, {
            method: 'GET',
            ...options,
            headers,
        });
        if (blob) {
            return result.blob();
        }
        return result.json();
    };
    return exceptions_1.SdkException.tryTask('sdk-request-failed')(task);
};
exports.fetchApiTE = fetchApiTE;
