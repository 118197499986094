"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingWizardSidebarProvider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const booking_wizard_sidebar_context_1 = require("./booking-wizard-sidebar-context");
const BookingWizardSidebarProvider = ({ children, }) => {
    const value = (0, react_1.useMemo)(booking_wizard_sidebar_context_1.getEmptySidebarContextValue, []);
    return ((0, jsx_runtime_1.jsx)(booking_wizard_sidebar_context_1.SdkBookingWizardSidebarContext.Provider, { value: value, children: children }));
};
exports.BookingWizardSidebarProvider = BookingWizardSidebarProvider;
