"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingCellConnectorsBottomTitle = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BookingCellConnectorsBottomTitle = styled_components_1.styled.span `
  position: absolute;
  display: block;
  bottom: 3px;
  width: 100%;
  transform: translateX(0.5ch);
  text-align: center;
  font-size: 0.65rem;

  ${commons_front_1.styles.mediaSizeUp.md} {
    display: none;
  }
`;
