"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.areCriticalBookingRoomValuesFilled = void 0;
const date_1 = require("./date");
const offer_1 = require("./offer");
const who_1 = require("./who");
const areCriticalBookingRoomValuesFilled = (value) => (0, who_1.isWhoBookingSectionFilled)(value) &&
    (0, offer_1.isOfferBookingSectionFilled)(value) &&
    (0, date_1.isDateBookingSectionFilled)(value);
exports.areCriticalBookingRoomValuesFilled = areCriticalBookingRoomValuesFilled;
