"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbRichText = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const storyblok_rich_text_react_renderer_1 = require("storyblok-rich-text-react-renderer");
const storyblok_rich_text_styled_1 = require("./storyblok-rich-text.styled");
const SbRichText = ({ text, ...props }) => {
    const content = (0, storyblok_rich_text_react_renderer_1.render)(text);
    return (0, jsx_runtime_1.jsx)(storyblok_rich_text_styled_1.SbRichTextHolder, { ...props, children: content });
};
exports.SbRichText = SbRichText;
