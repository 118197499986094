"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingStartScreen = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const use_set_sidebar_content_if_active_1 = require("../use-set-sidebar-content-if-active");
const controlled_booking_screen_1 = require("../controlled-booking-screen");
const mobile_1 = require("./mobile");
const desktop_1 = require("./desktop");
exports.BookingStartScreen = (0, controlled_booking_screen_1.controlledBookingWizardScreen)(({ control, active }) => {
    const { bind } = control;
    const isMobile = (0, commons_front_1.useIsMobile)();
    (0, use_set_sidebar_content_if_active_1.useResetSidebarContentIfActive)({ active });
    if (isMobile) {
        return (0, jsx_runtime_1.jsx)(mobile_1.BookingStartScreenMobile, { ...bind.entire() });
    }
    return (0, jsx_runtime_1.jsx)(desktop_1.BookingStartScreenDesktop, { ...bind.entire() });
});
