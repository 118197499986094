"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.APP_THEMES = void 0;
const default_theme_1 = require("./default.theme");
const orange_theme_1 = require("./orange.theme");
const pink_theme_1 = require("./pink.theme");
const light_pink_theme_1 = require("./light-pink.theme");
const cyan_theme_1 = require("./cyan.theme");
const gold_theme_1 = require("./gold.theme");
exports.APP_THEMES = {
    default: default_theme_1.DEFAULT_APP_THEME,
    orange: orange_theme_1.ORANGE_APP_THEME,
    pink: pink_theme_1.PINK_APP_THEME,
    'light-pink': light_pink_theme_1.LIGHT_PINK_APP_THEME,
    cyan: cyan_theme_1.CYAN_APP_THEME,
    gold: gold_theme_1.GOLD_APP_THEME,
};
