"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingRoomScreenMobile = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const wobbly_gallery_1 = require("@gerber/wobbly-gallery");
const forms_1 = require("@under-control/forms");
const commons_front_1 = require("@gerber/commons-front");
const shared_1 = require("../shared");
const i18n_1 = require("client/i18n");
const decorators_1 = require("client/modules/booking/booking-wizard/decorators");
const form_1 = require("client/modules/booking/booking-wizard/form");
const components_1 = require("client/components");
const parts_1 = require("client/modules/booking/booking-wizard/parts");
exports.BookingRoomScreenMobile = (0, decorators_1.controlledBookingValue)(({ control, active }) => {
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.room;
    const galleryItem = (0, wobbly_gallery_1.useWobblyGalleryItemContext)();
    const showRoomSwitch = (0, parts_1.useIsMobileRoomSwitchVisible)();
    const { handleSubmitEvent, value, bind } = (0, form_1.useBookingWizardRoomScreenForm)({
        control,
    });
    const sortControl = (0, forms_1.useControlStrict)({
        defaultValue: 'recommended',
    });
    return ((0, jsx_runtime_1.jsxs)(parts_1.BookingMobileScreenContent, { ...(0, commons_front_1.testid)('wizard:room'), footer: (0, jsx_runtime_1.jsx)(parts_1.BookingMobileFooter, { disabled: {
                next: !(0, form_1.isOfferBookingSectionFilled)(value),
            }, submitOnNext: true }), onSubmit: handleSubmitEvent, ...(showRoomSwitch && {
            toolbar: (0, jsx_runtime_1.jsx)(parts_1.BookingMobileRoomSwitch, {}),
        }), children: [(0, jsx_runtime_1.jsx)(components_1.H1, { color: "primary", children: t.header }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 4 }), (0, jsx_runtime_1.jsx)(shared_1.RoomSortInput, { ...sortControl.bind.entire() }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 7 }), (0, jsx_runtime_1.jsx)(shared_1.BookingOffersContainer, { ...bind.entire(), sort: sortControl.value, offscreen: !active, forceLoading: !galleryItem.active || galleryItem.animation })] }));
});
