"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SummaryCleanBox = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.SummaryCleanBox = styled_components_1.styled.div `
  ${commons_front_1.styles.flexCenter}

  font-weight: 600;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    padding: ${spacing[1]} ${spacing[4]};
  `}
`;
