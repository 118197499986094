"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkValidationErrorsV = exports.SdkValidationErrorV = void 0;
const zod_1 = require("zod");
exports.SdkValidationErrorV = zod_1.z.object({
    error: zod_1.z.string(),
    detail: zod_1.z.string().optional(),
    field: zod_1.z.string(),
});
exports.SdkValidationErrorsV = zod_1.z.object({
    errors: zod_1.z.array(exports.SdkValidationErrorV),
});
