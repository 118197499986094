"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfoCardDetails = void 0;
const styled_components_1 = require("styled-components");
const ui_1 = require("client/ui");
exports.InfoCardDetails = (0, styled_components_1.styled)(ui_1.MoreInformationCleanButton) `
  font-weight: 700;

  ${({ theme: { font } }) => (0, styled_components_1.css) `
    font-size: ${font.size.sm};
  `}
`;
