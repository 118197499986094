"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHotKeys = void 0;
const react_1 = require("react");
const forms_1 = require("@under-control/forms");
const use_window_listener_1 = require("./use-window-listener");
const useHotKeys = (hotKeys, attrs) => {
    const hotKeysRef = (0, react_1.useRef)();
    hotKeysRef.current = hotKeys;
    (0, use_window_listener_1.useWindowListener)({
        keydown: (e) => {
            if (attrs?.disabled) {
                return;
            }
            const handler = hotKeysRef.current?.[e.key];
            if (!handler) {
                return;
            }
            const result = handler(e);
            if (result !== false) {
                (0, forms_1.suppressEvent)(e);
            }
        },
    }, {
        options: {
            capture: true,
        },
    });
};
exports.useHotKeys = useHotKeys;
