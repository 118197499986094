"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingCalendarTableHolder = exports.BookingCalendarTableRow = void 0;
const styled_components_1 = require("styled-components");
exports.BookingCalendarTableRow = styled_components_1.styled.div `
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px 0px;
  padding: 1px 0;

  ${({ theme: { color } }) => (0, styled_components_1.css) `
    background: ${color.alias.black.main};
  `}
`;
exports.BookingCalendarTableHolder = (0, styled_components_1.styled)(exports.BookingCalendarTableRow) `
  grid-template-rows: 1fr;
`;
