"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationsListHolder = void 0;
const styled_components_1 = require("styled-components");
const components_1 = require("client/components");
exports.NotificationsListHolder = (0, styled_components_1.styled)(components_1.CleanList).attrs({
    $row: false,
    $gap: 3,
}) `
  position: fixed;
  top: 0;
  z-index: 10001;
`;
