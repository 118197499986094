"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInViewport = void 0;
const isInViewport = (el) => {
    if (!el) {
        return false;
    }
    const bounds = el.getBoundingClientRect();
    return bounds.top >= 0 && bounds.bottom <= window.innerHeight;
};
exports.isInViewport = isInViewport;
