"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useI18nUrlLang = void 0;
const react_1 = require("react");
const i18n_decode_header_1 = require("../i18n-decode-header");
const hooks_1 = require("client/hooks");
const useI18nUrlLang = () => {
    const [, params] = (0, hooks_1.useAppPrefixedRoute)('*');
    return (0, react_1.useMemo)(() => (0, i18n_decode_header_1.decodeI18nOrDefault)(params?.lang?.split('/')?.[0]), [params?.lang]);
};
exports.useI18nUrlLang = useI18nUrlLang;
