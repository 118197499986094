"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reactFormat = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const reactFormat = (str, templateArgs) => {
    const tokens = str.split(/(%{[?.\w]*})/g);
    return tokens.map(token => {
        const match = token.match(/%{([?.\w]*)}/);
        if (!match?.length) {
            return token;
        }
        const [, key] = match;
        return (0, jsx_runtime_1.jsx)(react_1.Fragment, { children: templateArgs[key] }, key);
    });
};
exports.reactFormat = reactFormat;
