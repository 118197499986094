"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingCalendarCell = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_1 = require("@gerber/commons");
const booking_calendar_cell_title_1 = require("./title/booking-calendar-cell-title");
const booking_calendar_cell_styled_1 = require("./booking-calendar-cell.styled");
const connectors_1 = require("./connectors");
const subtitle_1 = require("./subtitle");
const toolbar_1 = require("./toolbar");
const tooltip_1 = require("./tooltip");
const i18n_1 = require("client/i18n");
const BookingCalendarCell = ({ children, info, connectors, disabled, selected, tooltip, showDayShortcut = true, onClick, onClear, }) => {
    const { lang } = (0, i18n_1.useI18n)();
    const isClearCell = connectors?.first && !!connectors.right;
    return ((0, jsx_runtime_1.jsxs)(booking_calendar_cell_styled_1.BookingCalendarCellHolder, { "$disabled": disabled, role: "button", title: (0, commons_1.extractDate)(info.date), onClick: onClick, children: [showDayShortcut && ((0, jsx_runtime_1.jsx)(subtitle_1.BookingCalendarCellSubtitle, { children: (0, commons_1.getDayShortcut)(lang)(info.date).toUpperCase() })), (0, jsx_runtime_1.jsx)(booking_calendar_cell_title_1.BookingCalendarCellTitle, { selected: selected, shrink: !!connectors?.left && !!connectors.right, hasTopSpace: showDayShortcut, ...(isClearCell && {
                    toolbar: (0, jsx_runtime_1.jsx)(toolbar_1.BookingCalendarCellClear, { onClick: onClear }),
                }), children: info.date.getDate() }), connectors && ((0, jsx_runtime_1.jsx)(connectors_1.BookingCalendarCellConnectors, { date: info.date, connectors: connectors, hasTopSpace: showDayShortcut })), !disabled && tooltip && (0, jsx_runtime_1.jsx)(tooltip_1.BookingCalendarCellTooltip, { content: tooltip }), children] }));
};
exports.BookingCalendarCell = BookingCalendarCell;
