"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbHotelDiscoverOffersCTA = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const forms_1 = require("@under-control/forms");
const icons_1 = require("@gerber/icons");
const ui_1 = require("client/ui");
const i18n_1 = require("client/i18n");
exports.SbHotelDiscoverOffersCTA = (0, forms_1.controlled)(({ control: { value, setValue }, ...props }) => {
    const { pack } = (0, i18n_1.useI18n)();
    return ((0, jsx_runtime_1.jsxs)(ui_1.Button, { "$kind": "primary", type: "button", onClick: () => {
            setValue({
                value: !value,
            });
        }, ...props, children: [pack.landingPage.hotel.discoverOffers, (0, jsx_runtime_1.jsx)(ui_1.ButtonSuffixIcon, { children: (0, jsx_runtime_1.jsx)(icons_1.LongArrowRightThinIcon, { direction: value ? 'top' : 'bottom' }) })] }));
});
