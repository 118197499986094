"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingCalendarSliderPostPreloaded = exports.BookingCalendarSliderPrePreloaded = exports.BookingCalendarSliderTrack = exports.BookingCalendarSliderTrackWrapper = exports.BookingCalendarSliderWrapper = void 0;
const styled_components_1 = require("styled-components");
const booking_calendar_table_styled_1 = require("../booking-calendar-table.styled");
exports.BookingCalendarSliderWrapper = styled_components_1.styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  overflow: hidden;
`;
exports.BookingCalendarSliderTrackWrapper = styled_components_1.styled.div `
  position: relative;
  overflow: hidden;
`;
exports.BookingCalendarSliderTrack = styled_components_1.styled.div `
  width: 100%;
`;
const BookingCalendarSliderPreloaded = (0, styled_components_1.styled)(booking_calendar_table_styled_1.BookingCalendarTableRow) `
  position: absolute;
  left: 0;
  width: 100%;
`;
exports.BookingCalendarSliderPrePreloaded = (0, styled_components_1.styled)(BookingCalendarSliderPreloaded) `
  bottom: 100%;
  padding: 1px 0 0;
`;
exports.BookingCalendarSliderPostPreloaded = (0, styled_components_1.styled)(BookingCalendarSliderPreloaded) `
  top: 100%;
  padding: 0 0 1px;
`;
