"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tabs = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const forms_1 = require("@under-control/forms");
const react_1 = require("react");
const commons_1 = require("@gerber/commons");
const toolbar_1 = require("./toolbar");
const styles_1 = require("./styles");
exports.Tabs = (0, react_1.memo)((0, forms_1.controlled)(({ control: { value, setValue }, tabs, className, contentProps, ...tabsProps }) => {
    const tab = (0, commons_1.findItemById)(value)(tabs);
    if (!tab) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)("div", { className: className, children: [(0, jsx_runtime_1.jsx)(toolbar_1.ToolbarHolder, { children: tabs.map(({ id, name, icon, badge, disabled }) => ((0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)(toolbar_1.ToolbarItem, { type: "button", icon: icon, badge: badge, disabled: disabled, active: id === value, onClick: () => {
                            setValue({
                                value: id,
                            });
                        }, ...tabsProps, children: name }) }, id))) }), !!tab.children && ((0, jsx_runtime_1.jsx)(styles_1.TabsContent, { ...contentProps, children: tab.children?.() }))] }));
}));
exports.Tabs.displayName = 'Tabs';
