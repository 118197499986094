"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingOffersTotalToolbar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const commons_1 = require("@gerber/commons");
const booking_offers_total_toolbar_styled_1 = require("./booking-offers-total-toolbar.styled");
const i18n_1 = require("client/i18n");
exports.BookingOffersTotalToolbar = (0, react_1.memo)(({ total }) => {
    const { pack } = (0, i18n_1.useI18n)();
    return ((0, jsx_runtime_1.jsx)(booking_offers_total_toolbar_styled_1.BookingOffersTotalToolbarHolder, { children: (0, jsx_runtime_1.jsx)(booking_offers_total_toolbar_styled_1.BookingOffersTotalTitle, { children: (0, commons_1.format)(pack.common.results, { total }) }) }));
});
exports.BookingOffersTotalToolbar.displayName = 'BookingOffersTotalToolbar';
