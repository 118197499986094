"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MediumsPreview = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const wobbly_gallery_1 = require("@gerber/wobbly-gallery");
const mediums_preview_toolbar_1 = require("./mediums-preview-toolbar");
const mediums_preview_styled_1 = require("./mediums-preview.styled");
const components_1 = require("client/components");
exports.MediumsPreview = (0, react_1.memo)(({ mediums, preview360Url }) => {
    const navigation = (0, wobbly_gallery_1.useWobblyGalleryNavigation)({
        initialActiveIndex: Math.floor(mediums.length / 2),
        slides: mediums.map(medium => ((0, jsx_runtime_1.jsx)(wobbly_gallery_1.WobblyGalleryItem, { children: () => ((0, jsx_runtime_1.jsx)(mediums_preview_styled_1.MediumsPreviewPictureWrapper, { children: (0, jsx_runtime_1.jsx)(mediums_preview_styled_1.MediumsPreviewPicture, { medium: medium, type: "MEDIUM" }) })) }, medium.variants[0].url))),
    });
    return ((0, jsx_runtime_1.jsxs)(mediums_preview_styled_1.MediumsPreviewGalleryWrapper, { children: [(0, jsx_runtime_1.jsx)(wobbly_gallery_1.WobblyGallery, { navigation: navigation }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 5 }), (0, jsx_runtime_1.jsx)(mediums_preview_toolbar_1.MediumsPreviewToolbar, { navigation: navigation, preview360Url: preview360Url })] }));
});
exports.MediumsPreview.displayName = 'MediumsPreview';
