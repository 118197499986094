"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWobblyDraggableAnim = void 0;
const react_1 = require("react");
const commons_1 = require("@gerber/commons");
const commons_front_1 = require("@gerber/commons-front");
const useWobblyDraggableAnim = ({ vertical, activeIndex = 0, fallbackToTransition = true, slideDuration, }) => {
    const draggableRef = (0, react_1.useRef)(null);
    const [animation, setAnimation] = (0, react_1.useState)(false);
    const getApproxSlideSize = () => draggableRef.current?.children[0]?.getBoundingClientRect()[vertical ? 'height' : 'width'];
    /**
     * Don't use async function here! `updateScroll` is used in `useInstantUpdateEffect`
     * so it MUCH faster to batch `setAnimation(true)` state here
     */
    const updateScroll = 
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    (anim, force) => (newActiveIndex) => {
        const slideSize = getApproxSlideSize();
        const node = draggableRef.current;
        if (!slideSize || !node) {
            return;
        }
        const axis = vertical ? 'y' : 'x';
        const isAccelerationEnabled = !window.matchMedia('(pointer: coarse)').matches;
        const currentScroll = -(0, commons_front_1.getComputedTranslate)(node)[axis];
        const newScroll = newActiveIndex * slideSize;
        if (currentScroll === newScroll && !force) {
            return;
        }
        const setNodeScrollStyle = (translate) => {
            node.style.transform = `translate${axis.toLocaleLowerCase()}(${-translate}px)`;
            return true;
        };
        node.style.visibility = 'visible';
        setAnimation(anim);
        return Promise.resolve().then(async () => {
            if (anim) {
                if (isAccelerationEnabled || !fallbackToTransition) {
                    await (0, commons_1.tween)({ durationMs: slideDuration })(percentage => {
                        const translate = (0, commons_1.lerp)(currentScroll, newScroll, Math.abs(1 - percentage) <= 0.05
                            ? 1
                            : (0, commons_1.springWobbly)(percentage, { k: 90, c: 14.5 }));
                        return setNodeScrollStyle(translate);
                    });
                }
                else {
                    node.style.transition = `transform ${slideDuration}ms ${commons_front_1.styles.timingFunction.smooth.in}`;
                    setNodeScrollStyle(newScroll);
                    await (0, commons_1.timeout)(slideDuration);
                }
                setAnimation(false);
            }
            else {
                setNodeScrollStyle(newScroll);
            }
            return newActiveIndex;
        });
    };
    (0, commons_front_1.useInstantUpdateEffect)(() => {
        void updateScroll(true)(activeIndex);
    }, [activeIndex]);
    (0, commons_front_1.useWatchScreenSize)(() => {
        void updateScroll(false, true)(activeIndex);
    });
    return {
        animation,
        updateScroll,
        draggableRef,
    };
};
exports.useWobblyDraggableAnim = useWobblyDraggableAnim;
