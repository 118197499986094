"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingEndingScreenDesktop = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const forms_1 = require("@under-control/forms");
const commons_front_1 = require("@gerber/commons-front");
const form_1 = require("../../../form");
const shared_1 = require("../shared");
const parts_1 = require("client/modules/booking/booking-wizard/parts");
exports.BookingEndingScreenDesktop = (0, forms_1.controlled)(({ control: { value } }) => {
    const isDisabledPrev = (0, form_1.isReadonlyBookingRoom)(value);
    return ((0, jsx_runtime_1.jsx)(parts_1.BookingDesktopScreenContent, { ...(0, commons_front_1.testid)('wizard:ending'), children: (0, jsx_runtime_1.jsx)(shared_1.BookingEndingContent, { hideBack: isDisabledPrev }) }));
});
