"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRefSafeCallback = void 0;
const react_1 = require("react");
const useRefSafeCallback = (fn) => {
    const callbackRef = (0, react_1.useRef)();
    callbackRef.current = fn;
    return (0, react_1.useCallback)((...args) => callbackRef.current(...args), []);
};
exports.useRefSafeCallback = useRefSafeCallback;
