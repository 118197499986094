"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingCalendar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
/* eslint-disable react-hooks/rules-of-hooks */
const react_1 = require("react");
const forms_1 = require("@under-control/forms");
const commons_front_1 = require("@gerber/commons-front");
const mobile_1 = require("./mobile");
const desktop_1 = require("./desktop");
exports.BookingCalendar = (0, react_1.memo)((0, forms_1.controlled)(({ control: { bind }, ...props }) => {
    const isMobile = (0, commons_front_1.useIsMobile)();
    if (isMobile) {
        return (0, jsx_runtime_1.jsx)(mobile_1.BookingMobileCalendar, { ...bind.entire(), ...props });
    }
    return (0, jsx_runtime_1.jsx)(desktop_1.BookingDesktopCalendar, { ...bind.entire(), ...props });
}));
exports.BookingCalendar.displayName = 'BookingCalendar';
