"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccordionInfoCard = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const commons_front_1 = require("@gerber/commons-front");
const icons_1 = require("@gerber/icons");
const info_card_1 = require("../info-card");
const accordion_info_card_styled_1 = require("./accordion-info-card.styled");
const components_1 = require("client/components");
const i18n_1 = require("client/i18n");
const AccordionInfoCard = ({ defaultExpanded = true, hideExpand, headerProps, header, toolbar, children, renderChildrenIfCollapsed, $type = 'outline', onToggled, ...props }) => {
    const { pack } = (0, i18n_1.useI18n)();
    const [expanded, setExpanded] = (0, react_1.useState)(defaultExpanded);
    const onToggle = () => {
        setExpanded(prevExpanded => !prevExpanded);
        onToggled?.(!expanded);
    };
    return ((0, jsx_runtime_1.jsxs)(info_card_1.InfoCard, { ...props, "$type": $type, children: [(0, jsx_runtime_1.jsxs)(accordion_info_card_styled_1.AccordionInfoCardHeader, { bold: expanded, ...headerProps, children: [(0, jsx_runtime_1.jsx)(accordion_info_card_styled_1.AccordionInfoCardTitle, { ...(!hideExpand && {
                            role: 'button',
                            onClick: onToggle,
                        }), children: header }), (0, jsx_runtime_1.jsxs)(accordion_info_card_styled_1.AccordionToolbar, { children: [!hideExpand && ((0, jsx_runtime_1.jsxs)(commons_front_1.CleanButton, { type: "button", onClick: onToggle, children: [!expanded && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Text, { size: "sm", children: pack.common.more }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$x": 2 })] })), (0, jsx_runtime_1.jsx)(icons_1.LongArrowRightThinIcon, { direction: expanded ? 'top' : 'bottom' })] })), toolbar] })] }), (expanded || renderChildrenIfCollapsed) && children(expanded)] }));
};
exports.AccordionInfoCard = AccordionInfoCard;
