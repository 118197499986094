"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultUrlProtocol = void 0;
const has_url_protocol_1 = require("./has-url-protocol");
const defaultUrlProtocol = (protocol) => (url) => {
    let mappedUrl = url;
    if (!(0, has_url_protocol_1.hasUrlProtocol)(mappedUrl)) {
        mappedUrl = `${protocol}://${url}`;
    }
    return mappedUrl;
};
exports.defaultUrlProtocol = defaultUrlProtocol;
