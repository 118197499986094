"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopScreenContentHolder = void 0;
const styled_components_1 = require("styled-components");
const exit_button_1 = require("../exit-button");
const track_1 = require("../gallery/track");
exports.BookingDesktopScreenContentHolder = styled_components_1.styled.div `
  display: grid;
  align-items: center;
  width: 100%;
  margin: 0 auto;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    padding: ${spacing[7]} 160px;

    ${exit_button_1.HIDE_EXIT_BUTTON_BREAKPOINT} {
      padding-left: ${spacing[9]};
    }

    ${track_1.HIDE_TRACK_BREAKPOINT} {
      padding-right: ${spacing[9]};
    }
  `}
`;
