"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHideStartJumbotronOnModification = void 0;
const commons_front_1 = require("@gerber/commons-front");
const helpers_1 = require("../../helpers");
const types_1 = require("../../types");
/**
 * After passing first screen there is no need to show welcome screen anymore.
 *
 * `hideMobileStartJumbotron` can be also set on bootstrap phase of form.
 *  It happens when user enters wizard from landing-page.
 */
const useHideStartJumbotronOnModification = ({ value, setValue, }) => {
    (0, commons_front_1.useInstantEffect)(() => {
        if (value.meta.hideMobileStartJumbotron) {
            return;
        }
        if (!value.rooms.filter(types_1.isWhoBookingSectionFilled).length &&
            !value.meta.focusedRoomIndex) {
            return;
        }
        setValue({
            value: (0, helpers_1.assignMetaGlobalFormValue)({
                hideMobileStartJumbotron: true,
            })(value),
        });
    }, [value]);
};
exports.useHideStartJumbotronOnModification = useHideStartJumbotronOnModification;
