"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MediumsPreviewPicture = exports.MediumsPreviewPictureWrapper = exports.MediumsPreviewGalleryWrapper = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const shared_1 = require("client/modules/booking/shared");
exports.MediumsPreviewGalleryWrapper = styled_components_1.styled.div `
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  aspect-ratio: 1.4;
`;
exports.MediumsPreviewPictureWrapper = styled_components_1.styled.div `
  ${commons_front_1.styles.layer.absolute}
`;
exports.MediumsPreviewPicture = (0, styled_components_1.styled)(shared_1.MediumPicture) `
  ${commons_front_1.styles.layer.absolute}

  object-fit: cover;
`;
