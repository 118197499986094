"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbMobileNavigationC = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
const brand_1 = require("../brand");
const toolbar_1 = require("../toolbar");
const storyblok_mobile_navigation_styled_1 = require("./storyblok-mobile-navigation.styled");
const SbMobileNavigationC = ({ item, }) => ((0, jsx_runtime_1.jsxs)(storyblok_mobile_navigation_styled_1.SbMobileNavigationHolder, { ...(0, react_1.sdkStoryblokEditableProps)(item), children: [(0, jsx_runtime_1.jsx)(brand_1.SbNavigationBrand, { logo: item.logo }), (0, jsx_runtime_1.jsx)(toolbar_1.SbNavigationToolbar, { item: item, truncated: true })] }));
exports.SbMobileNavigationC = SbMobileNavigationC;
