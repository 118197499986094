"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbRichTextHolder = void 0;
const styled_components_1 = require("styled-components");
exports.SbRichTextHolder = styled_components_1.styled.div `
  & a {
    color: inherit;
  }

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    & p:not(:last-child) {
      margin-bottom: ${spacing[5]};
    }
  `}
`;
