"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOutsideClickRef = void 0;
const react_1 = require("react");
const use_window_listener_1 = require("./use-window-listener");
const useOutsideClickRef = (callback, attrs = {}) => {
    const nodeRef = (0, react_1.useRef)(null);
    (0, use_window_listener_1.useWindowListener)({
        click: e => {
            const { current: node } = nodeRef;
            if (!node || attrs.disabled) {
                return;
            }
            if (!node.contains(e.target)) {
                callback(e);
            }
        },
    });
    return nodeRef;
};
exports.useOutsideClickRef = useOutsideClickRef;
