"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopSidebarHeader = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const expanded_1 = require("./expanded");
const truncated_1 = require("./truncated");
exports.BookingDesktopSidebarHeader = (0, react_1.memo)(({ truncated }) => {
    if (truncated) {
        return (0, jsx_runtime_1.jsx)(truncated_1.BookingDesktopSidebarTruncatedHeader, {});
    }
    return (0, jsx_runtime_1.jsx)(expanded_1.BookingDesktopSidebarExpandedHeader, {});
});
exports.BookingDesktopSidebarHeader.displayName = 'BookingDesktopSidebarHeader';
