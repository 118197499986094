"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopCalendar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const forms_1 = require("@under-control/forms");
const date_fns_1 = require("date-fns");
const shared_1 = require("../shared");
const toolbar_1 = require("./toolbar");
const components_1 = require("client/components");
exports.BookingDesktopCalendar = (0, forms_1.controlled)(({ control: { bind }, firstMonth, toolbar, ...props }) => {
    const navigation = (0, forms_1.useControlStrict)({
        defaultValue: {
            date: (0, date_fns_1.startOfMonth)(firstMonth.days[0].date),
        },
    });
    return ((0, jsx_runtime_1.jsx)(shared_1.BookingCalendarTable, { ...bind.entire(), ...props, cachedMonths: [firstMonth], navigation: navigation, header: ({ loading }) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(toolbar_1.BookingDesktopCalendarToolbar, { disabled: loading, ...navigation.bind.entire() }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": toolbar ? 7 : 4 }), toolbar] })) }));
});
