"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabeledIcon = exports.LabeledIconText = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const styled_components_1 = require("styled-components");
const text_1 = require("./text");
const LabeledIconHolder = (0, styled_components_1.styled)(text_1.Text) `
  display: ${({ display }) => display ?? 'inline-flex'};
  flex-direction: row;
  align-items: center;
  gap: ${({ theme, $spacing = 2 }) => theme.spacing[$spacing]};
  text-decoration: inherit;

  svg {
    flex-shrink: 0;
    width: auto;
    height: 1.4em;
    line-height: 1.4em;
  }
`;
exports.LabeledIconText = styled_components_1.styled.span `
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;
const LabeledIcon = ({ children, icon, ...props }) => ((0, jsx_runtime_1.jsxs)(LabeledIconHolder, { ...props, children: [icon, children && (0, jsx_runtime_1.jsx)(exports.LabeledIconText, { children: children })] }));
exports.LabeledIcon = LabeledIcon;
