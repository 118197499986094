"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingCalendarCellTooltipHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BookingCalendarCellTooltipHolder = styled_components_1.styled.div `
  ${commons_front_1.styles.flexCenter}

  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 2;

  width: 18px;
  height: 18px;

  ${({ theme: { color } }) => (0, styled_components_1.css) `
    color: ${color.base.muted.main};
  `}

  ${commons_front_1.styles.mediaMaxScreenWidth('sm')} {
    display: none;
  }
`;
