"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExceptionWithCode = void 0;
class ExceptionWithCode extends Error {
    constructor(code, message, meta) {
        super(message);
        this.code = code;
        this.meta = meta;
    }
    asJSON() {
        const { code, message } = this;
        return {
            code,
            message,
        };
    }
}
exports.ExceptionWithCode = ExceptionWithCode;
