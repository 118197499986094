"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceSummary = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const sdk_gerber_1 = require("@gerber/sdk-gerber");
const price_summary_styled_1 = require("./price-summary.styled");
const i18n_1 = require("client/i18n");
const form_1 = require("client/modules/booking/booking-wizard/form");
const PriceSummary = ({ room, hideTaxInfo }) => {
    const price = (0, form_1.getBookingRoomPrice)(room);
    const { pack } = (0, i18n_1.useI18n)();
    if (!price) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(price_summary_styled_1.PriceSummaryHolder, { children: [(0, jsx_runtime_1.jsx)(price_summary_styled_1.PriceLabel, { children: pack.booking.wizard.price[price.approx ? 'from' : 'exact'] }), (0, jsx_runtime_1.jsxs)(price_summary_styled_1.PriceTitleHolder, { children: [(0, jsx_runtime_1.jsx)(price_summary_styled_1.PriceTitle, { children: (0, sdk_gerber_1.formatPrice)(price) }), !hideTaxInfo && ((0, jsx_runtime_1.jsx)(price_summary_styled_1.PriceSubtitle, { children: pack.common.includeTax }))] })] }));
};
exports.PriceSummary = PriceSummary;
