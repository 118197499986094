"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SectionHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.SectionHolder = styled_components_1.styled.section.withConfig({
    shouldForwardProp: (0, commons_front_1.shouldNotForwardProps)([
        'topSpace',
        'bottomSpace',
    ]),
}) `
  ${({ theme: { spacing }, topSpace = 11, bottomSpace = 11 }) => (0, styled_components_1.css) `
    margin: ${spacing[topSpace]} 0 ${spacing[bottomSpace]};
  `}
`;
