"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopScreenHeaderHolder = void 0;
const styled_components_1 = require("styled-components");
const booking_desktop_sidebar_wrapper_styled_1 = require("../../sidebar-wrapper/booking-desktop-sidebar-wrapper.styled");
exports.BookingDesktopScreenHeaderHolder = styled_components_1.styled.div `
  display: grid;
  grid-template: 'content toolbar' / 1fr min-content;

  ${booking_desktop_sidebar_wrapper_styled_1.SIDEBAR_TOGGLE_BREAKPOINT} {
    margin-right: 70px;
  }
`;
