"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.smoothStepInterpolation = void 0;
const lerp_1 = require("./lerp");
const smoothStep = (value, leftEdge = 0, rightEdge = 1) => {
    const x = Math.max(0, Math.min(1, (value - leftEdge) / (rightEdge - leftEdge)));
    return x * x * (3 - 2 * x);
};
const smoothStepInterpolation = (min, max, value) => (0, lerp_1.lerp)(min, max, smoothStep(value));
exports.smoothStepInterpolation = smoothStepInterpolation;
