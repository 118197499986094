"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbDesktopNavigationContainer = exports.SbDesktopNavigationHolder = void 0;
const styled_components_1 = require("styled-components");
const polished_1 = require("polished");
const container_1 = require("../../layout/container");
exports.SbDesktopNavigationHolder = styled_components_1.styled.nav `
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);

  ${({ theme: { spacing, color } }) => (0, styled_components_1.css) `
    padding: ${spacing[3]} ${spacing[7]};
    backdrop-filter: blur(6px);
    background: ${(0, polished_1.transparentize)(0.18)(color.alias.lightGray.main)};
  `}
`;
exports.SbDesktopNavigationContainer = (0, styled_components_1.styled)(container_1.SbLayoutContainer).attrs({
    wide: true,
}) `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
`;
