"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingOffersHeader = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const commons_1 = require("@gerber/commons");
const booking_offers_header_styled_1 = require("./booking-offers-header.styled");
const i18n_1 = require("client/i18n");
exports.BookingOffersHeader = (0, react_1.memo)(({ duration, total }) => {
    const { pack } = (0, i18n_1.useI18n)();
    if (!duration) {
        return null;
    }
    const { range } = duration;
    if (!(0, commons_1.isFilledDateRange)(range)) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(booking_offers_header_styled_1.BookingOffersHeaderHolder, { children: [(0, jsx_runtime_1.jsxs)(booking_offers_header_styled_1.BookingOffersHeaderTitle, { children: [(0, commons_1.extractShortDate)(range.begin, '.'), ' - ', (0, commons_1.extractShortDate)(range.end, '.')] }), total > 0 && (0, jsx_runtime_1.jsx)("span", { children: (0, commons_1.format)(pack.common.results, { total }) })] }));
});
exports.BookingOffersHeader.displayName = 'BookingOffersHeader';
