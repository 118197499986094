"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBackgroundInterpolation = void 0;
const react_1 = require("react");
const commons_1 = require("@gerber/commons");
const commons_front_1 = require("@gerber/commons-front");
const useBackgroundInterpolation = () => {
    const refs = {
        source: (0, react_1.useRef)(null),
        interpolation: (0, react_1.useRef)(null),
    };
    const updateScroll = () => {
        const { source, interpolation } = refs;
        if (!source.current || !interpolation.current) {
            return;
        }
        const sourceRect = source.current.getBoundingClientRect();
        const sourcePos = {
            top: sourceRect.top + window.scrollY,
            left: sourceRect.left + window.scrollX,
            width: sourceRect.width,
            height: sourceRect.height,
        };
        const min = window.innerHeight / 2 - sourceRect.height / 2;
        const dist = window.innerHeight / 3;
        const percentage = (0, commons_1.clamp)(0, dist, window.scrollY - min) / dist;
        const i = commons_1.smoothStepInterpolation;
        Object.assign(interpolation.current.style, {
            display: percentage === 1 ? 'none' : 'block',
            top: `${i(0, sourcePos.top, percentage) - window.scrollY}px`,
            left: `${i(0, sourcePos.left, percentage)}px`,
            width: `${i(window.innerWidth, sourcePos.width, percentage)}px`,
            height: `${i(window.innerHeight, sourcePos.height, percentage)}px`,
        });
    };
    (0, commons_front_1.useAfterMount)(updateScroll);
    (0, commons_front_1.useWatchScreenSize)(updateScroll);
    (0, commons_front_1.useWindowListener)({
        scroll: updateScroll,
    }, {
        options: {
            passive: false,
            capture: true,
        },
    });
    return {
        refs,
    };
};
exports.useBackgroundInterpolation = useBackgroundInterpolation;
