"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopGalleryTrack = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const booking_desktop_gallery_track_item_1 = require("./booking-desktop-gallery-track-item");
const booking_desktop_gallery_track_styled_1 = require("./booking-desktop-gallery-track.styled");
const context_1 = require("client/modules/booking/booking-wizard/context");
exports.BookingDesktopGalleryTrack = (0, react_1.memo)(() => {
    const { slides, context: { activeIndex, setIndex }, } = (0, context_1.useBookingWizardNavigation)();
    return ((0, jsx_runtime_1.jsx)(booking_desktop_gallery_track_styled_1.BookingDesktopGalleryTrackHolder, { children: (0, jsx_runtime_1.jsx)(booking_desktop_gallery_track_styled_1.BookingDesktopGalleryTrackLine, { children: slides.map((slide, index) => ((0, jsx_runtime_1.jsx)(booking_desktop_gallery_track_item_1.BookingDesktopGalleryTrackItem, { icon: slide.icon, disabled: slide.isDisabled?.() ?? activeIndex < index, "$active": activeIndex === index, style: {
                    top: `${index ? ((index / (slides.length - 1)) * 100).toFixed(2) : 0}%`,
                }, onClick: () => {
                    setIndex(index);
                } }, slide.key))) }) }));
});
exports.BookingDesktopGalleryTrack.displayName = 'BookingDesktopGalleryTrack';
