"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteErrorDescription = exports.RouteErrorTitle = exports.RouteErrorIconHolder = exports.RouteErrorLayerHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const route_error_page_background_icon_1 = require("./route-error-page-background.icon");
const components_1 = require("client/components");
exports.RouteErrorLayerHolder = styled_components_1.styled.div `
  ${commons_front_1.styles.layer.fixed}
  ${commons_front_1.styles.flexCenter}

  flex-direction: column;
`;
exports.RouteErrorIconHolder = (0, styled_components_1.styled)(route_error_page_background_icon_1.RouteErrorPageBackgroundIcon) `
  width: auto;
  max-width: min(350px, 80%);

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    margin-bottom: ${spacing[5]};
  `}
`;
exports.RouteErrorTitle = (0, styled_components_1.styled)(components_1.H1) `
  font-weight: 300;

  ${({ theme: { font } }) => (0, styled_components_1.css) `
    font-size: ${font.size['4xl']};
  `}
`;
exports.RouteErrorDescription = styled_components_1.styled.div `
  ${({ theme: { font, spacing } }) => (0, styled_components_1.css) `
    margin-top: ${spacing[3]};
    font-size: ${font.size.base};
    opacity: 0.5;
  `}
`;
