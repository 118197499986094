"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbDescriptionImageSectionC = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
const parts_1 = require("./parts");
const storyblok_description_image_styled_1 = require("./storyblok-description-image.styled");
const SbDescriptionImageSectionC = ({ item }) => {
    const { anim } = (0, commons_front_1.useInitialAnimatedElement)();
    return ((0, jsx_runtime_1.jsxs)(storyblok_description_image_styled_1.SbDescriptionImageHolder, { ...(0, react_1.sdkStoryblokEditableProps)(item), align: item.align, children: [(0, jsx_runtime_1.jsx)(parts_1.SbDescriptionImage, { picture: item.image, loading: "lazy", ...anim('animate-fade-in-from-right') }), (0, jsx_runtime_1.jsx)(parts_1.SbDescriptionContent, { items: item.content, ...anim('animate-fade-in-from-down') })] }));
};
exports.SbDescriptionImageSectionC = SbDescriptionImageSectionC;
