"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.concatUrlParts2C = exports.concatUrlParts = exports.concatUrlPartsA = void 0;
const concat_urls_1 = require("./concat-urls");
const concatUrlPartsA = (parts) => parts.reduce((acc, part) => (0, concat_urls_1.concatUrls)(acc, part), '');
exports.concatUrlPartsA = concatUrlPartsA;
const concatUrlParts = (...parts) => (0, exports.concatUrlPartsA)(parts);
exports.concatUrlParts = concatUrlParts;
const concatUrlParts2C = (...prefix) => (b) => (0, exports.concatUrlParts)(...prefix, b);
exports.concatUrlParts2C = concatUrlParts2C;
