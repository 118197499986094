"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBookingCalendarFirstAvailableMonth = void 0;
const function_1 = require("fp-ts/function");
const commons_1 = require("@gerber/commons");
const react_1 = require("@gerber/sdk-gerber/dist/third-party/react");
const client_1 = require("@gerber/ssr/dist/client");
const useBookingCalendarFirstAvailableMonth = ({ skip, ...filters }) => {
    const { sdk } = (0, react_1.useGerberSdk)();
    return (0, client_1.useHydratedPromise)((0, function_1.pipe)(sdk.controllers.oneCalendar.getMonth({
        ...filters,
        arrivalMonth: null,
    }), commons_1.tryOrThrowTE), {
        uuid: 'available-month',
        skip,
    });
};
exports.useBookingCalendarFirstAvailableMonth = useBookingCalendarFirstAvailableMonth;
