"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jsonToFormData = exports.patchPayload = exports.formDataPayload = exports.deletePayload = exports.putPayload = exports.postPayload = exports.getPayload = void 0;
const getPayload = () => ({
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
    },
});
exports.getPayload = getPayload;
const postPayload = (data) => ({
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data ?? {}),
});
exports.postPayload = postPayload;
const putPayload = (body) => ({
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(body ?? {}),
});
exports.putPayload = putPayload;
const deletePayload = (body) => ({
    method: 'DELETE',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(body ?? {}),
});
exports.deletePayload = deletePayload;
const formDataPayload = (body) => ({
    method: 'POST',
    body,
});
exports.formDataPayload = formDataPayload;
const patchPayload = (body) => ({
    method: 'PATCH',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
});
exports.patchPayload = patchPayload;
const jsonToFormData = ({ files, ...json }) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(json)) {
        formData.append(key, value);
    }
    if (files) {
        Object.values(files).forEach(file => {
            formData.append(file.name, file);
        });
    }
    return formData;
};
exports.jsonToFormData = jsonToFormData;
