"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WobblyGalleryTrack = void 0;
const styled_components_1 = require("styled-components");
exports.WobblyGalleryTrack = styled_components_1.styled.div `
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  visibility: hidden;

  ${({ $vertical }) => (0, styled_components_1.css) `
    flex-direction: ${$vertical ? 'column' : 'row'};
  `}
`;
