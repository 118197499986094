"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingOfferToolbarItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_tooltip_1 = require("react-tooltip");
const sdk_gerber_1 = require("@gerber/sdk-gerber");
const commons_front_1 = require("@gerber/commons-front");
const booking_offer_toolbar_package_badge_1 = require("./booking-offer-toolbar-package-badge");
const tooltip_1 = require("./tooltip");
const booking_offer_toolbar_item_styled_1 = require("./booking-offer-toolbar-item.styled");
const ui_1 = require("client/ui");
const components_1 = require("client/components");
exports.BookingOfferToolbarItem = (0, react_1.memo)(({ withDescription, offer, readOnly, ...props }) => {
    const { rate, overlayMessages } = offer;
    const tooltipId = (0, react_1.useId)();
    const isMobile = (0, commons_front_1.useIsMobile)();
    const showHoverableTooltip = !withDescription && !isMobile && rate.description;
    return ((0, jsx_runtime_1.jsxs)(booking_offer_toolbar_item_styled_1.BookingOfferToolbarItemWrapper, { ...(showHoverableTooltip && {
            'data-tooltip-id': tooltipId,
        }), children: [(0, jsx_runtime_1.jsxs)(booking_offer_toolbar_item_styled_1.BookingOfferToolbarItemHolder, { children: [(0, jsx_runtime_1.jsxs)(booking_offer_toolbar_item_styled_1.BookingOfferToolbarItemTitle, { children: [offer.type === 'PACKAGE' && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(booking_offer_toolbar_package_badge_1.BookingOfferToolbarPackageBadge, { offer: offer }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 2 })] })), (0, jsx_runtime_1.jsxs)(booking_offer_toolbar_item_styled_1.BookingOfferToolbarItemInnerTitle, { children: [rate.name, !showHoverableTooltip && rate.description && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["\u00A0", (0, jsx_runtime_1.jsx)(tooltip_1.BookingOfferToolbarItemTooltip, { description: rate.description })] }))] })] }), (0, jsx_runtime_1.jsx)(booking_offer_toolbar_item_styled_1.BookingOfferToolbarItemPrice, { children: (0, sdk_gerber_1.formatPrice)(offer.price) }), (0, jsx_runtime_1.jsx)(booking_offer_toolbar_item_styled_1.BookingOfferToolbarItemCta, { children: (0, jsx_runtime_1.jsx)(ui_1.SelectButton, { ...props, disabled: readOnly, "$size": "sm" }) })] }), withDescription && overlayMessages.length > 0 && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 2 }), (0, jsx_runtime_1.jsx)(components_1.Text, { size: "sm", color: "darkMuted", as: "div", children: (0, jsx_runtime_1.jsx)(components_1.UnorderedList, { as: "ul", children: overlayMessages.map(message => ((0, jsx_runtime_1.jsx)("li", { children: message }, message))) }) })] })), showHoverableTooltip && ((0, jsx_runtime_1.jsx)(react_tooltip_1.Tooltip, { id: tooltipId, content: rate.description }))] }));
});
exports.BookingOfferToolbarItem.displayName = 'BookingOfferToolbarItem';
