"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.genStoryblokImageOptimizedUrl = void 0;
const function_1 = require("fp-ts/function");
const commons_1 = require("@gerber/commons");
const genStoryblokImageOptimizedUrl = ({ size, filters }) => (url) => {
    if (url.endsWith('.svg')) {
        return url;
    }
    return (0, function_1.pipe)([
        url,
        'm/',
        size && `${size.w}x${size.h}`,
        filters &&
            filters.quality > 0 &&
            `filters:quality(${filters.quality})`,
    ], commons_1.rejectFalsyItems, commons_1.concatUrlPartsA);
};
exports.genStoryblokImageOptimizedUrl = genStoryblokImageOptimizedUrl;
