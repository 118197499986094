"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PictureV = void 0;
const zod_1 = require("zod");
exports.PictureV = zod_1.z.object({
    sm: zod_1.z.string().optional(),
    md: zod_1.z.string().optional(),
    original: zod_1.z.string(),
    alt: zod_1.z.string().optional(),
});
