"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupByFlatProp = exports.groupByFlat = void 0;
const groupByFlat = (fn) => (array) => array.reduce((acc, item) => {
    acc[fn(item)] = item;
    return acc;
}, {});
exports.groupByFlat = groupByFlat;
const groupByFlatProp = (key) => (0, exports.groupByFlat)(item => item[key]);
exports.groupByFlatProp = groupByFlatProp;
