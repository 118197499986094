"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assignMetaGlobalFormValue = void 0;
const assignMetaGlobalFormValue = (meta) => (value) => ({
    ...value,
    meta: {
        ...value.meta,
        ...meta,
    },
});
exports.assignMetaGlobalFormValue = assignMetaGlobalFormValue;
