"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StartScreenPackageNotification = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const shared_1 = require("../../../../parts/shared");
const i18n_1 = require("client/i18n");
const StartScreenPackageNotification = () => {
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.start;
    return ((0, jsx_runtime_1.jsx)(shared_1.BookingWizardNotification, { expandCacheId: "start-screen-package", html: t.notification.package }));
};
exports.StartScreenPackageNotification = StartScreenPackageNotification;
