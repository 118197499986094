"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormLabel = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const theme_1 = require("client/theme");
exports.FormLabel = styled_components_1.styled.label.withConfig({
    shouldForwardProp: (0, commons_front_1.shouldNotForwardProps)([
        'size',
        'color',
        'bold',
        'row',
        'required',
    ]),
}) `
  display: flex;
  position: relative;
  margin-bottom: 4px;
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  white-space: nowrap;

  ${({ theme, size, color, required }) => (0, styled_components_1.css) `
    left: ${theme.spacing[2]};
    font-size: ${theme.font.size[size ?? 'xs']};

    ${color &&
    (0, styled_components_1.css) `
      color: ${(0, theme_1.extractThemeColor)(color)(theme.color)?.main};
    `}

    ${required &&
    (0, styled_components_1.css) `
      &::after {
        content: '*';

        margin-left: 3px;
      }
    `}
  `}

  ${({ row }) => row &&
    (0, styled_components_1.css) `
      display: inline-flex;
      flex-direction: row;
    `}
`;
