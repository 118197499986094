"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopFooterShadowBackdrop = exports.BookingDesktopFooterShadowContainer = exports.BookingDesktopFooterShadowHolder = void 0;
const commons_front_1 = require("@gerber/commons-front");
const styled_components_1 = require("styled-components");
const booking_desktop_footer_styled_1 = require("../booking-desktop-footer.styled");
exports.BookingDesktopFooterShadowHolder = styled_components_1.styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  pointer-events: none;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    padding: ${spacing[2]} 0 ${spacing[4]};
  `}

  button {
    pointer-events: initial;
  }
`;
exports.BookingDesktopFooterShadowContainer = (0, styled_components_1.styled)(commons_front_1.Container) `
  position: relative;
  z-index: 2;

  ${({ wide, theme: { spacing } }) => wide &&
    (0, styled_components_1.css) `
      ${booking_desktop_footer_styled_1.BookingDesktopFooterLeft} {
        padding-right: ${spacing[8]};
        align-items: flex-end;
      }

      ${booking_desktop_footer_styled_1.BookingDesktopFooterRight} {
        padding-left: ${spacing[8]};
        align-items: flex-start;
      }
    `}
`;
exports.BookingDesktopFooterShadowBackdrop = styled_components_1.styled.div `
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 18%,
    rgba(0, 0, 0, 0) 100%
  );
`;
