"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToolbarHolder = void 0;
const styled_components_1 = require("styled-components");
const components_1 = require("client/components");
exports.ToolbarHolder = (0, styled_components_1.styled)(components_1.CleanList).attrs({
    $row: true,
    $gap: 0,
}) `
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  ${({ theme: { color } }) => (0, styled_components_1.css) `
    color: ${color.alias.black.main};
    border-bottom: 1px solid ${color.parts.tabs.border};
  `}
`;
