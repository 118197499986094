"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.recreateBookingSessionRooms = void 0;
const TE = __importStar(require("fp-ts/TaskEither"));
const A = __importStar(require("fp-ts/Array"));
const NEA = __importStar(require("fp-ts/NonEmptyArray"));
const O = __importStar(require("fp-ts/Option"));
const R = __importStar(require("fp-ts/Record"));
const Record_1 = require("fp-ts-std/Record");
const function_1 = require("fp-ts/function");
const commons_1 = require("@gerber/commons");
const booking_wizard_session_exception_1 = require("../../booking-wizard-session.exception");
const serializers_1 = require("../serializers");
const recreate_booking_session_room_guests_info_1 = require("./recreate-booking-session-room-guests-info");
const recreateBookingSessionRooms = (sdk) => (sessionId) => (rooms) => {
    const cannotGetLastItem = NEA.of(new booking_wizard_session_exception_1.BookingWizardSessionException('sync-failed', 'Item has been added to session but there is no information about that in response!'));
    const updateGuestsInfo = (0, recreate_booking_session_room_guests_info_1.recreateBookingSessionRoomGuestsInfo)(sdk)(sessionId);
    const recreateVariants = (0, function_1.pipe)(rooms, A.filter(room => !!room.variant), (0, commons_1.groupBy)(item => item.variant.id), R.mapWithIndex((variantId, dtos) => (0, function_1.pipe)(sdk.controllers.session.addVariantItem({
        variantId,
        sessionId,
    }), TE.chainW(result => (0, function_1.pipe)(result.items, A.takeRight(dtos.length), A.mapWithIndex((index, sessionItem) => updateGuestsInfo(dtos[index])(sessionItem)), TE.sequenceSeqArray)))), Record_1.values, TE.sequenceSeqArray, commons_1.toVoidTE);
    const recreateNormalRooms = (0, function_1.pipe)(rooms, A.filter(room => !room.variant), A.map(room => (0, function_1.pipe)(O.Do, O.bind('room', () => O.some(room)), O.bind('dto', () => serializers_1.WizardRoomSerializer.ofCreateSessionItemDTO(room)))), A.compact, A.map(({ dto, room }) => (0, function_1.pipe)(sdk.controllers.session.addItem({
        ...dto,
        sessionId,
    }), TE.chainW(result => (0, function_1.pipe)(A.last(result.items), TE.fromOption(() => cannotGetLastItem), TE.chainW(updateGuestsInfo(room)))))), TE.sequenceSeqArray, commons_1.toVoidTE);
    return (0, function_1.pipe)(TE.sequenceSeqArray([recreateNormalRooms, recreateVariants]), commons_1.toVoidTE);
};
exports.recreateBookingSessionRooms = recreateBookingSessionRooms;
