"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBookingOfferDetailsModal = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const booking_offer_details_modal_1 = require("./booking-offer-details.modal");
const useBookingOfferDetailsModal = () => (0, commons_front_1.useAnimatedModal)({
    renderModalContent: ({ showProps, hiding, onAnimatedClose }) => ((0, jsx_runtime_1.jsx)(booking_offer_details_modal_1.BookingOfferDetailsModal, { ...showProps, out: hiding, onAfterChoose: value => {
            void onAnimatedClose({
                value,
            });
        }, onClose: () => {
            void onAnimatedClose();
        } })),
});
exports.useBookingOfferDetailsModal = useBookingOfferDetailsModal;
