"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientOnly = exports.clientOnly = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const hooks_1 = require("../hooks");
const clientOnly = ({ fallback = null } = {}) => (Component) => {
    const ClientOnly = (props) => {
        const isMounted = (0, hooks_1.useIsMounted)();
        if (!isMounted) {
            return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: fallback });
        }
        return (0, jsx_runtime_1.jsx)(Component, { ...props });
    };
    return ClientOnly;
};
exports.clientOnly = clientOnly;
const ClientOnly = ({ loading, children, fallback, }) => {
    const isMounted = (0, hooks_1.useIsMounted)();
    if (!isMounted || loading) {
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: fallback ?? null });
    }
    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: typeof children === 'function' ? children() : children });
};
exports.ClientOnly = ClientOnly;
