"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractGalleryItemsFromChildren = void 0;
const react_1 = require("react");
const function_1 = require("fp-ts/function");
const A = __importStar(require("fp-ts/Array"));
const commons_front_1 = require("@gerber/commons-front");
const wobbly_gallery_item_1 = require("../components/wobbly-gallery-item");
const extractGalleryItemsFromChildren = (children) => (0, function_1.pipe)((0, commons_front_1.flattenChildren)(children), A.chain(item => (0, react_1.isValidElement)(item) && (0, wobbly_gallery_item_1.areGalleryItemProps)(item.props)
    ? [item]
    : []));
exports.extractGalleryItemsFromChildren = extractGalleryItemsFromChildren;
