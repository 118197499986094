"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMatchScreenByWindowWidth = exports.getScreenNameByWindowWidth = void 0;
const react_1 = require("react");
const breakpoint_style_1 = require("../../styles/breakpoint.style");
const use_watch_screen_size_1 = require("../use-watch-screen-size");
/**
 * It is based on tailwind.config.js. Order of breakpoints matters.
 * Place the biggest breakpoints at the top.
 */
const getScreenNameByWindowWidth = () => {
    if (window.innerWidth <= window.innerHeight ||
        Math.min(window.innerWidth, window.innerHeight) <= 480) {
        return 'xs';
    }
    for (const [screenName, width] of Object.entries(breakpoint_style_1.SCREEN_BREAKPOINTS)) {
        if (window.innerWidth >= width) {
            return screenName;
        }
    }
    return 'sm';
};
exports.getScreenNameByWindowWidth = getScreenNameByWindowWidth;
function useMatchScreenByWindowWidth(initialScreen) {
    const [screenName, setScreenName] = (0, react_1.useState)(initialScreen);
    (0, use_watch_screen_size_1.useWatchScreenSize)(() => {
        setScreenName((0, exports.getScreenNameByWindowWidth)());
    });
    return screenName;
}
exports.useMatchScreenByWindowWidth = useMatchScreenByWindowWidth;
