"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInstantPrevious = void 0;
const react_1 = require("react");
/**
 * Returns "value" state from previous render
 *
 * @example
 *  const prevValue = usePrevious(value);
 */
const useInstantPrevious = (value) => {
    const ref = (0, react_1.useRef)(null);
    const prev = ref.current;
    ref.current = value;
    return prev;
};
exports.useInstantPrevious = useInstantPrevious;
