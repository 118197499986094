"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokHotelCardBlockV = void 0;
const zod_1 = require("zod");
const sdk_storyblok_block_dto_1 = require("../../sdk-storyblok-block.dto");
const parts_1 = require("../../parts");
const sdk_storyblok_hotel_feature_block_1 = require("./sdk-storyblok-hotel-feature.block");
exports.SdkStoryblokHotelCardBlockV = (0, sdk_storyblok_block_dto_1.SdkStoryblokBlockV)('Hotel_Card', {
    one_id: zod_1.z.string(),
    title: zod_1.z.string().default(''),
    request_now_link: parts_1.SdkStoryblokLinkV.optional().catch(undefined),
    description: parts_1.SdkStoryblokRichTextV,
    features: sdk_storyblok_hotel_feature_block_1.SdkStoryblokHotelFeatureV.array(),
    logo: parts_1.SdkStoryblokPictureV,
    cover: parts_1.SdkStoryblokPictureV,
});
