"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBookingSmoothSelectionOffersRange = void 0;
const react_1 = require("react");
const date_fns_1 = require("date-fns");
const commons_1 = require("@gerber/commons");
/**
 * API does not return price for days that are between ranges that are longer than 1 day.
 * Example:
 *  - 01.01.2023 - 344$
 *  - 10.01.2023 - 1024$
 *
 * The problem is that we want to display price for 5th day.
 * This function performs lerp interpolation between these two days so the output looks like:
 *
 * - 01.01.2023 - 344$
 * - 02.01.2023 - ..
 * - 03.01.2023 - ..
 *   ....
 * - 10.01.2023 - 1024$
 */
const useBookingSmoothSelectionOffersRange = (selectionOffers) => (0, react_1.useMemo)(() => {
    if (!selectionOffers) {
        return [];
    }
    let prevDayNights = 0;
    let prevDayPrice = 0;
    return selectionOffers.reduce((acc, day) => {
        if (!day.nights || !day.date || !day.minimumPrice) {
            acc.push(day);
            return acc;
        }
        const delta = day.nights - prevDayNights - 1;
        if (delta >= 1) {
            for (let offset = 1; offset <= delta; ++offset) {
                const percentage = offset / (delta + 1);
                const amount = Math.ceil((0, commons_1.lerp)(prevDayPrice, day.minimumPrice.amount, percentage));
                acc.push({
                    nights: prevDayNights + offset,
                    arrivalDay: day.arrivalDay,
                    date: (0, date_fns_1.subDays)(day.date, delta - offset + 1),
                    minimumPrice: {
                        ...day.minimumPrice,
                        amount,
                    },
                });
            }
        }
        prevDayNights = day.nights;
        prevDayPrice = day.minimumPrice.amount;
        acc.push(day);
        return acc;
    }, []);
}, [selectionOffers]);
exports.useBookingSmoothSelectionOffersRange = useBookingSmoothSelectionOffersRange;
