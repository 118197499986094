"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingOfferDetailsModalContent = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const base_modal_content_1 = require("client/ui/modal/parts/base-modal-content");
exports.BookingOfferDetailsModalContent = (0, styled_components_1.styled)(base_modal_content_1.BaseModalContent) `
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content 1fr min-content;
  grid-template-areas:
    'description preview'
    'description offers'
    'description toolbar';

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    gap: ${spacing[12]};
  `}

  ${commons_front_1.styles.mediaSizeDown.sm} {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas:
      'preview'
      'description'
      'offers'
      'toolbar';

    ${({ theme: { spacing } }) => (0, styled_components_1.css) `
      gap: ${spacing[7]};
    `}
  }
`;
