"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopSidebarExpandedHeaderHolder = void 0;
const styled_components_1 = require("styled-components");
exports.BookingDesktopSidebarExpandedHeaderHolder = styled_components_1.styled.div `
  display: grid;
  grid-template-columns: 60% 1fr;
  grid-template-areas:
    'logo toolbar'
    'title title'
    'footer footer';

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    gap: ${spacing[6]} ${spacing[10]};
  `}
`;
