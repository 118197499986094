"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShareCleanSessionButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_1 = require("@gerber/icons");
const hooks_1 = require("./hooks");
const ui_1 = require("client/ui");
const i18n_1 = require("client/i18n");
const ShareCleanSessionButton = ({ icon, ...props }) => {
    const { pack } = (0, i18n_1.useI18n)();
    const copySessionUrl = (0, hooks_1.useCopyCleanSessionUrl)();
    return ((0, jsx_runtime_1.jsx)(ui_1.Button, { title: pack.booking.wizard.share.shareCopy, type: "button", "$kind": "outline", onClick: () => void copySessionUrl(), ...props, children: icon ?? (0, jsx_runtime_1.jsx)(icons_1.CopyIcon, {}) }));
};
exports.ShareCleanSessionButton = ShareCleanSessionButton;
