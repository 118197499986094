"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSubscribeBookingWizardSessionContent = void 0;
const react_1 = require("react");
const use_booking_wizard_session_context_or_throw_1 = require("./use-booking-wizard-session-context-or-throw");
const useSubscribeBookingWizardSessionContent = () => {
    const store = (0, use_booking_wizard_session_context_or_throw_1.useBookingWizardSessionContextOrThrow)();
    return (0, react_1.useSyncExternalStore)(store.subscribe, store.getSnapshot, store.getSnapshot);
};
exports.useSubscribeBookingWizardSessionContent = useSubscribeBookingWizardSessionContent;
