"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbJumbotronScrollButtonHolder = void 0;
const styled_components_1 = require("styled-components");
const ui_1 = require("client/ui");
exports.SbJumbotronScrollButtonHolder = (0, styled_components_1.styled)(ui_1.Button) `
  position: fixed;
  top: initial !important;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  font-weight: 600;
  z-index: 9999;
`;
