"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonPrefixIcon = exports.ButtonSuffixIcon = exports.ButtonIcon = void 0;
const styled_components_1 = require("styled-components");
exports.ButtonIcon = styled_components_1.styled.span `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 1em;

  ${({ $scaled = true }) => $scaled &&
    (0, styled_components_1.css) `
      svg {
        width: 16px;
        height: auto;
      }
    `}
`;
exports.ButtonSuffixIcon = (0, styled_components_1.styled)(exports.ButtonIcon) `
  ${({ $space, theme: { spacing } }) => (0, styled_components_1.css) `
    margin-left: ${spacing[$space ?? 3]};
  `}
`;
exports.ButtonPrefixIcon = (0, styled_components_1.styled)(exports.ButtonIcon) `
  ${({ $space, theme: { spacing } }) => (0, styled_components_1.css) `
    margin-right: ${spacing[$space ?? 3]};
  `}
`;
