"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbLayoutSectionC = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
const container_1 = require("../container");
const storyblok_section_styled_1 = require("./storyblok-section.styled");
const SbLayoutSectionC = ({ item, }) => {
    const isMobile = (0, commons_front_1.useIsMobile)();
    return ((0, jsx_runtime_1.jsx)(storyblok_section_styled_1.SbLayoutSection, { ...(0, react_1.sdkStoryblokEditableProps)(item), id: item.id, color: item.color, space: item.space, "max-sm:space": item.smSpace, noSpace: {
            top: item.noTopSpace,
            bottom: item.noBottomSpace,
        }, children: (0, jsx_runtime_1.jsx)(commons_front_1.ViewportAnimationManager, { tag: container_1.SbLayoutContainer, children: (0, jsx_runtime_1.jsx)(react_1.SdkSbComponentsListRenderer, { items: item.content, ...(!isMobile &&
                    item.animated && {
                    animProps: { animClassName: 'animate-fade-in-from-down' },
                }) }) }) }));
};
exports.SbLayoutSectionC = SbLayoutSectionC;
