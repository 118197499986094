"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingWizardGallery = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const context_1 = require("../context");
const desktop_1 = require("./desktop");
const mobile_1 = require("./mobile");
const BookingWizardGallery = () => {
    const isDesktop = (0, commons_front_1.useIsDesktop)();
    const { bind, value, getValue, submit, submitState } = (0, context_1.useBookingGlobalForm)();
    const bindMethods = bind.path(`rooms[${value.meta.focusedRoomIndex}]`);
    const { execAfterAutosave } = (0, context_1.useAutosaveBookingWizardSession)({
        getValue,
        disabled: submitState.loading,
    });
    const onAutosaveSafeSubmit = async () => execAfterAutosave(async () => {
        return submit();
    });
    if (isDesktop) {
        return ((0, jsx_runtime_1.jsx)(desktop_1.BookingDesktopGallery, { ...bindMethods, onPay: onAutosaveSafeSubmit }));
    }
    return (0, jsx_runtime_1.jsx)(mobile_1.BookingMobileGallery, { ...bindMethods, onPay: onAutosaveSafeSubmit });
};
exports.BookingWizardGallery = BookingWizardGallery;
