"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePredefinedFormValidators = exports.isRequiredFieldFilled = void 0;
const commons_1 = require("@gerber/commons");
const validate_1 = require("@under-control/validate");
const i18n_1 = require("client/i18n");
const guards_1 = require("client/ui/select-input/guards");
const isRequiredFieldFilled = (value) => value !== '' && !(0, commons_1.isNil)(value);
exports.isRequiredFieldFilled = isRequiredFieldFilled;
const usePredefinedFormValidators = () => {
    const { validation } = (0, i18n_1.useI18n)().pack;
    const titledErrorPathByPred = (errorTitle) => (path, predFn) => ({
        path,
        fn: attrs => {
            if (predFn(attrs)) {
                return (0, validate_1.error)(errorTitle, null, path);
            }
        },
    });
    const incorrectPathByPred = titledErrorPathByPred(validation.incorrect);
    const requiredPathByPred = titledErrorPathByPred(validation.required);
    const required = (path, refineFn) => requiredPathByPred(path, attrs => !(0, exports.isRequiredFieldFilled)(attrs.value) || !!refineFn?.(attrs));
    const requiredListItem = (path, refineFn) => requiredPathByPred(path, attrs => !(0, guards_1.isCompleteListItemValue)(attrs.value) || !!refineFn?.(attrs));
    return {
        titledErrorPathByPred,
        incorrectPathByPred,
        requiredPathByPred,
        required,
        requiredListItem,
    };
};
exports.usePredefinedFormValidators = usePredefinedFormValidators;
