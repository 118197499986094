"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingWizardJsPaymentModal = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const commons_front_1 = require("@gerber/commons-front");
const booking_wizard_js_payment_modal_styled_1 = require("./booking-wizard-js-payment-modal.styled");
const ui_1 = require("client/ui");
const i18n_1 = require("client/i18n");
const BookingWizardJsPaymentModal = ({ response, ...props }) => {
    const { lang } = (0, i18n_1.useI18n)();
    const contentRef = (0, react_1.useRef)(null);
    const { paymentUrl, parameters: { brands, callbackUrl }, } = response;
    (0, commons_front_1.useAfterMount)(() => {
        const node = contentRef.current;
        if (!node) {
            return;
        }
        const script = document.createElement('script');
        script.src = response.paymentUrl;
        script.setAttribute('data-brands', brands);
        node.appendChild(script);
        const wpwlScript = document.createElement('script');
        wpwlScript.textContent = `var wpwlOptions = { locale: "${lang}" };`;
        node.appendChild(wpwlScript);
        const formH = document.createElement('form');
        formH.action = callbackUrl;
        formH.className = 'paymentWidgets';
        formH.setAttribute('data-brands', brands);
        const paymentScript = document.createElement('script');
        paymentScript.src = paymentUrl;
        formH.appendChild(paymentScript);
        node.append(formH);
    });
    return ((0, jsx_runtime_1.jsx)(booking_wizard_js_payment_modal_styled_1.BookingWizardJsPaymentModalHolder, { expandedMobile: false, ...props, children: (0, jsx_runtime_1.jsx)(ui_1.BaseModalContent, { ref: contentRef }) }));
};
exports.BookingWizardJsPaymentModal = BookingWizardJsPaymentModal;
