"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfoCardCancelButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const commons_front_1 = require("@gerber/commons-front");
const icons_1 = require("@gerber/icons");
const i18n_1 = require("client/i18n");
const components_1 = require("client/components");
exports.InfoCardCancelButton = (0, react_1.memo)((props) => {
    const { pack } = (0, i18n_1.useI18n)();
    return ((0, jsx_runtime_1.jsxs)(commons_front_1.CleanButton, { type: "button", ...props, children: [(0, jsx_runtime_1.jsx)(components_1.Text, { size: "sm", weight: 500, children: pack.common.remove }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$x": 2 }), (0, jsx_runtime_1.jsx)(icons_1.TimesIcon, {})] }));
});
exports.InfoCardCancelButton.displayName = 'InfoCardCancelButton';
