"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopGalleryPrefixHolder = void 0;
const styled_components_1 = require("styled-components");
exports.BookingDesktopGalleryPrefixHolder = styled_components_1.styled.div `
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    padding: ${spacing[5]} ${spacing[7]};
  `}
`;
