"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBookingWizardGlobalFormSideEffects = void 0;
const effects_1 = require("./effects");
const tracking_1 = require("client/tracking");
const useBookingWizardGlobalFormSideEffects = (attrs) => {
    (0, effects_1.useHideStartJumbotronOnModification)(attrs);
    (0, effects_1.useTranslateValueEntries)(attrs);
    (0, tracking_1.useGTMTrackBookingBasket)({
        value: attrs.value,
    });
};
exports.useBookingWizardGlobalFormSideEffects = useBookingWizardGlobalFormSideEffects;
