"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rawPreprocess = void 0;
const zod_1 = require("zod");
const rawPreprocess = (schema) => zod_1.z.preprocess(obj => ({
    ...obj,
    raw: obj,
}), schema.extend({
    raw: zod_1.z.any(),
}));
exports.rawPreprocess = rawPreprocess;
