"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MediumPicture = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
/* eslint-disable jsx-a11y/alt-text */
const react_1 = require("react");
const sdk_gerber_1 = require("@gerber/sdk-gerber");
const medium_picture_styled_1 = require("./medium-picture.styled");
exports.MediumPicture = (0, react_1.memo)(({ medium, type, ...props }) => {
    const [rootVariant, ...variants] = (0, sdk_gerber_1.filterMediumVariantsByType)(type)(medium.variants);
    return ((0, jsx_runtime_1.jsxs)(medium_picture_styled_1.MediumPictureHolder, { children: [variants.map(variant => ((0, jsx_runtime_1.jsx)("source", { type: `image/${variant.format.toLowerCase()}`, srcSet: variant.url }, variant.url))), (0, jsx_runtime_1.jsx)("img", { alt: medium.description || medium.title, ...props, src: rootVariant?.url })] }));
});
exports.MediumPicture.displayName = 'MediumPicture';
