"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.I18nProvider = exports.useI18n = exports.I18nContext = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const wouter_1 = require("wouter");
const react_1 = require("react");
const i18n_packs_1 = require("./i18n-packs");
exports.I18nContext = (0, react_1.createContext)({
    lang: 'en',
    pack: i18n_packs_1.APP_LANG_PACKS.en,
    getSupportedLangs: () => [],
    setLang: () => { },
});
const useI18n = () => (0, react_1.useContext)(exports.I18nContext);
exports.useI18n = useI18n;
const I18nProvider = ({ lang, children, }) => {
    const [location, navigate] = (0, wouter_1.useLocation)();
    const setLang = (newLang) => {
        const newUrl = location.replace(`/${lang}/`, `/${newLang}/`);
        navigate(newUrl, {
            replace: true,
        });
    };
    const value = (0, react_1.useMemo)(() => ({
        lang,
        pack: i18n_packs_1.APP_LANG_PACKS[lang],
        setLang,
        getSupportedLangs: () => Object.keys(i18n_packs_1.APP_LANG_PACKS),
    }), [lang]);
    return (0, jsx_runtime_1.jsx)(exports.I18nContext.Provider, { value: value, children: children });
};
exports.I18nProvider = I18nProvider;
