"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbHotelOffersHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.SbHotelOffersHolder = styled_components_1.styled.div `
  display: grid;
  grid-template-columns: 1fr;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    gap: ${spacing[4]};
  `}

  ${commons_front_1.styles.mediaMinScreenWidth('sm')} {
    grid-template-columns: 1fr 1fr;
    max-width: 75%;
    margin: 0 auto;

    ${({ theme: { spacing } }) => (0, styled_components_1.css) `
      gap: ${spacing[7]};
    `}
  }
`;
