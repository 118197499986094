"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopGalleryNavigationProvider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const wobbly_gallery_1 = require("@gerber/wobbly-gallery");
const commons_front_1 = require("@gerber/commons-front");
const icons_1 = require("@gerber/icons");
const decorators_1 = require("client/modules/booking/booking-wizard/decorators");
const context_1 = require("client/modules/booking/booking-wizard/context");
const screens_1 = require("client/modules/booking/booking-wizard/screens");
const form_1 = require("client/modules/booking/booking-wizard/form");
exports.BookingDesktopGalleryNavigationProvider = (0, decorators_1.controlledBookingValue)(({ control: { bind, value }, children, onPay }) => {
    const editorId = value.meta.editorId;
    const isDateFilled = (0, form_1.isDateBookingSectionFilled)(value);
    const isOfferFilled = (0, form_1.isOfferBookingSectionFilled)(value);
    const isReadonly = (0, form_1.isReadonlyBookingRoom)(value);
    const areAllFilled = isDateFilled && isOfferFilled;
    const slides = [
        {
            key: 'who',
            icon: (0, jsx_runtime_1.jsx)(icons_1.PeopleIcon, {}),
            isDisabled: () => isReadonly,
            content: ((0, jsx_runtime_1.jsx)(wobbly_gallery_1.WobblyGalleryItem, { children: ({ active }) => ((0, jsx_runtime_1.jsx)(screens_1.BookingStartScreen, { active: active, ...bind.entire() }, editorId)) }, "who")),
        },
        {
            key: 'date',
            icon: (0, jsx_runtime_1.jsx)(icons_1.CalendarIcon, {}),
            isDisabled: () => !isDateFilled || isReadonly,
            content: ((0, jsx_runtime_1.jsx)(wobbly_gallery_1.WobblyGalleryItem, { children: ({ active }) => ((0, jsx_runtime_1.jsx)(commons_front_1.ClientOnly, { children: (0, jsx_runtime_1.jsx)(screens_1.BookingDateScreen, { active: active, ...bind.entire() }, editorId) })) }, "date")),
        },
        {
            key: 'offer',
            icon: (0, jsx_runtime_1.jsx)(icons_1.BedIcon, {}),
            isDisabled: () => !isOfferFilled || isReadonly,
            content: ((0, jsx_runtime_1.jsx)(wobbly_gallery_1.WobblyGalleryItem, { children: ({ active }) => ((0, jsx_runtime_1.jsx)(commons_front_1.ClientOnly, { children: (0, jsx_runtime_1.jsx)(screens_1.BookingRoomScreen, { active: active, ...bind.entire() }, editorId) })) }, "offer")),
        },
        {
            key: 'ending',
            icon: (0, jsx_runtime_1.jsx)(icons_1.AirlineIcon, {}),
            isDisabled: () => !areAllFilled,
            content: ((0, jsx_runtime_1.jsx)(wobbly_gallery_1.WobblyGalleryItem, { children: ({ active }) => ((0, jsx_runtime_1.jsx)(commons_front_1.ClientOnly, { children: (0, jsx_runtime_1.jsx)(screens_1.BookingEndingScreen, { active: active, ...bind.entire() }, editorId) })) }, "ending")),
        },
        {
            key: 'contact',
            icon: (0, jsx_runtime_1.jsx)(icons_1.EndingFlagIcon, {}),
            isDisabled: () => !areAllFilled,
            content: ((0, jsx_runtime_1.jsx)(wobbly_gallery_1.WobblyGalleryItem, { children: ({ active }) => ((0, jsx_runtime_1.jsx)(commons_front_1.ClientOnly, { children: (0, jsx_runtime_1.jsx)(screens_1.BookingContactScreen, { active: active, onPay: onPay }) })) }, "contact")),
        },
    ];
    return ((0, jsx_runtime_1.jsx)(context_1.BookingWizardNavigationProvider, { slides: slides, children: attrs => ((0, jsx_runtime_1.jsx)(commons_front_1.ModalsContextProvider, { children: ({ modalVisible }) => ((0, jsx_runtime_1.jsx)(commons_front_1.ModalBlurWrapper, { "$active": modalVisible, children: children(attrs) })) })) }));
});
