"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.asyncDebounce = void 0;
const asyncDebounce = (config, fn) => {
    const { delay, initialInstant = false } = config;
    const lastCallUUID = { current: null };
    let timer = null;
    let firstCall = false;
    return async (...args) => {
        const uuid = Date.now();
        lastCallUUID.current = uuid;
        return new Promise((resolve, reject) => {
            const safeCall = async () => {
                try {
                    const result = await fn(...args);
                    if (lastCallUUID.current === uuid) {
                        lastCallUUID.current = null;
                        resolve(result);
                    }
                }
                catch (e) {
                    reject(e);
                }
            };
            if (!firstCall) {
                firstCall = true;
                if (initialInstant) {
                    void safeCall();
                    return;
                }
            }
            if (timer !== null) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                void safeCall();
            }, delay);
        });
    };
};
exports.asyncDebounce = asyncDebounce;
