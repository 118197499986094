"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopGalleryTrackItemHolder = void 0;
const commons_front_1 = require("@gerber/commons-front");
const styled_components_1 = require("styled-components");
exports.BookingDesktopGalleryTrackItemHolder = (0, styled_components_1.styled)(commons_front_1.CleanButton) `
  display: flex;
  position: absolute;
  left: 0;
  flex-direction: row;
  align-items: center;
  transform: translate3d(-12px, -50%, 0);
  transition: 150ms ease-in-out;
  transition-property: background, color;

  ${({ theme: { spacing, color, radius }, $active, disabled }) => (0, styled_components_1.css) `
    gap: ${spacing[2]};
    border-radius: ${radius[4]};
    padding: ${spacing[1]} ${spacing[2]} ${spacing[1]} ${spacing[3]};

    ${$active &&
    (0, styled_components_1.css) `
      color: ${color.base.primary.contrastText};
      background: ${color.base.primary.main};
    `}

    ${!$active &&
    (0, styled_components_1.css) `
      color: ${color.alias[disabled ? 'solidGray' : 'black'].main};
    `}

    &::before {
      content: '';
      border-radius: 100%;
      width: 8px;
      height: 8px;
      transform: translateX(calc(-50% + 0.5px));
      color: inherit;
      background: currentColor;
    }
  `}
`;
