"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LandingPageStory = exports.RESOLVE_LP_RELATIONS = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const function_1 = require("fp-ts/function");
const E = __importStar(require("fp-ts/Either"));
const react_1 = require("react");
const react_2 = require("@storyblok/react");
const sdk_storyblok_1 = require("@gerber/sdk-storyblok");
const commons_1 = require("@gerber/commons");
const landing_page_storyblok_component_1 = require("./landing-page-storyblok-component");
const providers_1 = require("client/providers");
const i18n_1 = require("client/i18n");
exports.RESOLVE_LP_RELATIONS = ['Hotel_Cards_List.cards'];
const LandingPageStory = ({ story }) => {
    const { lang } = (0, i18n_1.useI18n)();
    const mappedStory = (0, react_2.useStoryblokState)(story, {
        resolveRelations: exports.RESOLVE_LP_RELATIONS,
        language: lang,
    });
    const maybeParsedStory = (0, react_1.useMemo)(() => (0, function_1.pipe)(mappedStory, (0, commons_1.tryParseUsingZodSchema)(sdk_storyblok_1.SdkStoryblokPageStoryV)), [mappedStory]);
    if (E.isLeft(maybeParsedStory)) {
        return null;
    }
    const { content } = maybeParsedStory.right;
    return ((0, jsx_runtime_1.jsx)(providers_1.AppRouteProvidersWithI18n, { theme: content.theme, children: (0, jsx_runtime_1.jsx)(landing_page_storyblok_component_1.LandingPageStoryblokComponent, { item: content }) }));
};
exports.LandingPageStory = LandingPageStory;
