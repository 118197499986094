"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.wrapAround = void 0;
/**
 * @example
 *  wrapAround(10, -1) => 9
 *  wrapAround(10, 1) => 1
 */
const wrapAround = (max, value) => ((value % max) + max) % max;
exports.wrapAround = wrapAround;
