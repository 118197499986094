"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingExitButtonHolder = exports.HIDE_EXIT_BUTTON_BREAKPOINT = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const ui_1 = require("client/ui");
exports.HIDE_EXIT_BUTTON_BREAKPOINT = commons_front_1.styles.mediaMaxWidth(1800);
exports.BookingExitButtonHolder = (0, styled_components_1.styled)(ui_1.ExitButton) `
  ${exports.HIDE_EXIT_BUTTON_BREAKPOINT} {
    display: none;
  }
`;
