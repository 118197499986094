"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingOfferToolbarItemCta = exports.BookingOfferToolbarItemPrice = exports.BookingOfferToolbarItemInnerTitle = exports.BookingOfferToolbarItemTitle = exports.BookingOfferToolbarItemHolder = exports.BookingOfferToolbarItemWrapper = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BookingOfferToolbarItemWrapper = styled_components_1.styled.li `
  ${({ theme: { color, spacing } }) => (0, styled_components_1.css) `
    padding: ${spacing[3]} ${spacing[0]} ${spacing[3]} ${spacing[0]};

    &:not(:first-child) {
      border-top: 1px solid ${color.parts.divider.light.main};
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  `}
`;
exports.BookingOfferToolbarItemHolder = styled_components_1.styled.div `
  ${({ theme: { spacing, color } }) => (0, styled_components_1.css) `
    display: grid;
    grid-template: 'title price cta' / 1fr max-content max-content;
    gap: ${spacing[6]};
    place-items: center flex-start;

    &:not(:first-child) {
      border-top: 1px solid ${color.parts.divider.light.main};
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    ${commons_front_1.styles.mediaSizeDown.sm} {
      grid-template-columns: 1fr min-content;
      grid-template-areas:
        'title cta'
        'price cta';

      gap: ${spacing[1]} ${spacing[4]};
    }
  `}
`;
exports.BookingOfferToolbarItemTitle = styled_components_1.styled.span `
  grid-area: title;
  font-weight: 600;
  font-size: 0.92rem;
`;
exports.BookingOfferToolbarItemInnerTitle = styled_components_1.styled.span `
  display: block;
`;
exports.BookingOfferToolbarItemPrice = styled_components_1.styled.span `
  grid-area: price;
`;
exports.BookingOfferToolbarItemCta = styled_components_1.styled.span `
  grid-area: cta;
`;
