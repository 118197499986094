"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingOfferTitlePrice = exports.BookingOfferTitle = exports.BookingOfferTitleWrapper = exports.BookingOfferDescription = exports.BookingOfferContent = exports.BookingOfferCardPicture = exports.BookingOfferCardHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const styles_1 = require("@gerber/commons-front/dist/styles");
const shared_1 = require("client/modules/booking/shared");
const components_1 = require("client/components");
const OFFER_CARD_SHADOW = { shadowOpacity: 0.15, shadow: '0px 10px 24px' };
exports.BookingOfferCardHolder = styled_components_1.styled.article `
  display: grid;
  grid-template: 'header' 'content';

  ${(0, styles_1.hoverCardTransformAnim)(OFFER_CARD_SHADOW)}

  ${({ $active }) => $active && (0, styles_1.hoveredTransform)(OFFER_CARD_SHADOW)}
  ${({ theme: { color } }) => (0, styled_components_1.css) `
    border: 1px solid ${color.alias.black.main};
  `}

  ${commons_front_1.styles.mediaSizeDown.xs} {
    content-visibility: auto;
    contain-intrinsic-size: 0 500px;
  }
`;
exports.BookingOfferCardPicture = (0, styled_components_1.styled)(shared_1.MediumPicture) `
  grid-area: header;
  width: 100%;
  aspect-ratio: 1.85;
  object-fit: cover;
  object-position: left;
`;
exports.BookingOfferContent = styled_components_1.styled.div `
  grid-area: content;

  ${({ theme: { spacing, color } }) => (0, styled_components_1.css) `
    padding: ${spacing[6]} ${spacing[5]} ${spacing[4]};
    border-top: 1px solid ${color.alias.black.main};

    ${commons_front_1.styles.mediaSizeDown.xs} {
      padding: ${spacing[5]} ${spacing[4]};
    }
  `}
`;
exports.BookingOfferDescription = styled_components_1.styled.div `
  ${({ theme: { font, color } }) => (0, styled_components_1.css) `
    font-size: ${font.size.sm};
    color: ${color.base.darkMuted.main};
  `}
`;
exports.BookingOfferTitleWrapper = styled_components_1.styled.div `
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    gap: ${spacing[3]};

    ${commons_front_1.styles.mediaSizeDown.xs} {
      grid-template-columns: 1fr;
      gap: ${spacing[2]};
    }
  `}
`;
exports.BookingOfferTitle = (0, styled_components_1.styled)(components_1.H3) `
  font-size: 1.3rem;
`;
exports.BookingOfferTitlePrice = styled_components_1.styled.span ``;
