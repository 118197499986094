"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnimationManager = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const hooks_1 = require("../hooks");
const AnimationManager = ({ children, enabled = false, }) => {
    const value = (0, react_1.useMemo)(() => ({
        ...(0, hooks_1.createBlankManagerValue)(),
        enabled,
    }), [enabled]);
    return ((0, jsx_runtime_1.jsx)(hooks_1.AnimationManagerContext.Provider, { value: value, children: typeof children === 'function' ? children(value) : children }));
};
exports.AnimationManager = AnimationManager;
