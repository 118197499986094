"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMatchedOffersList = void 0;
const function_1 = require("fp-ts/function");
const TE = __importStar(require("fp-ts/TaskEither"));
const A = __importStar(require("fp-ts/Array"));
const client_1 = require("@gerber/ssr/dist/client");
const react_1 = require("@gerber/sdk-gerber/dist/third-party/react");
const commons_1 = require("@gerber/commons");
const helpers_1 = require("../helpers");
const context_1 = require("client/modules/booking/booking-wizard/context");
const form_1 = require("client/modules/booking/booking-wizard/form");
const useMatchedOffersList = ({ room, sort, }) => {
    const { sdk } = (0, react_1.useGerberSdk)();
    const config = (0, context_1.useBookingWizardConfigContext)();
    const filters = (0, helpers_1.getOffersFiltersFromValue)(room);
    return (0, client_1.useHydratedPromise)(async () => (0, function_1.pipe)(sdk.controllers.oneOffers.getOffers(filters), TE.map((0, function_1.flow)((0, helpers_1.innerJoinOffersWithConfig)(config), (0, form_1.isPackageGroupBookingRoomValue)(room)
        ? A.filter(item => item.type === 'PACKAGE' &&
            room.packageGroup?.id === item.packageGroupId)
        : array => array, helpers_1.groupOffersByCategory, (0, helpers_1.sortGroupedOffersBy)(sort ?? 'recommended'))), commons_1.tryOrThrowTE, commons_1.runTask), {
        skip: !filters,
        deps: [JSON.stringify(filters), JSON.stringify(config), sort],
        uuid: 'room-offers-list',
    });
};
exports.useMatchedOffersList = useMatchedOffersList;
