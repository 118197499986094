"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopAlignTableColumn = exports.TableRow = void 0;
const styled_components_1 = require("styled-components");
const TABLE_ROW_COLORS = {
    gray: 'rgba(0, 0, 0, 0.02)',
};
exports.TableRow = styled_components_1.styled.tr `
  ${({ $align = 'middle', $weight, $bordered, $noHorizontalPadding, theme: { color, font }, }) => (0, styled_components_1.css) `
    th {
      font-weight: ${$weight ?? 700};
      box-shadow: none;
    }

    > td,
    > th {
      text-align: left;
      font-size: ${font.size.sm};
      line-height: 1;
      vertical-align: ${$align};
      padding: 0.75rem;

      ${$bordered &&
    (0, styled_components_1.css) `
        border-top: 1px solid ${color.parts.divider.light.main};
      `}

      ${$noHorizontalPadding &&
    (0, styled_components_1.css) `
        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      `}
    }
  `}

  ${({ $color }) => $color &&
    (0, styled_components_1.css) `
      background: ${TABLE_ROW_COLORS[$color]};
    `}

    ${({ $striped }) => $striped &&
    (0, styled_components_1.css) `
      &:nth-child(even) {
        background: ${TABLE_ROW_COLORS.gray};
      }
    `}
`;
exports.TopAlignTableColumn = styled_components_1.styled.td `
  && {
    vertical-align: top !important;

    > * {
      position: relative;
      top: 1.1rem;
    }
  }
`;
