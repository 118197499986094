"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createBlankGlobalFormValue = exports.createBlankBookingRoom = exports.createBlankBookingContactValue = void 0;
const commons_1 = require("@gerber/commons");
const createBlankBookingContactValue = () => ({
    gender: 'male',
    title: null,
    address: null,
    email: null,
    firstName: null,
    lastName: null,
    phone: null,
});
exports.createBlankBookingContactValue = createBlankBookingContactValue;
const createBlankBookingRoom = () => ({
    meta: (0, commons_1.createBlankEditorIdMeta)(),
    comment: '',
    who: {
        adults: [],
        children: [],
    },
    date: null,
    packageGroup: null,
    offer: {
        selected: null,
    },
});
exports.createBlankBookingRoom = createBlankBookingRoom;
const createBlankGlobalFormValue = () => ({
    rooms: [(0, exports.createBlankBookingRoom)()],
    contact: (0, exports.createBlankBookingContactValue)(),
    terms: {
        general: false,
        security: false,
    },
    meta: {
        focusedRoomIndex: 0,
        hideMobileStartJumbotron: false,
    },
});
exports.createBlankGlobalFormValue = createBlankGlobalFormValue;
