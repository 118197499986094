"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingJumbotron = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BookingJumbotron = (0, styled_components_1.styled)(commons_front_1.Picture) `
  width: 100%;
  height: calc(100vh - 600px);
  max-height: 500px;
  object-fit: cover;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    margin-bottom: ${spacing[13]};
  `}

  @media screen and (max-height: 824px) {
    display: none;
  }
`;
