"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbNavigationC = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const desktop_1 = require("./desktop");
const mobile_1 = require("./mobile");
const SbNavigationC = props => {
    const isMobile = (0, commons_front_1.useIsMobile)();
    if (isMobile) {
        return (0, jsx_runtime_1.jsx)(mobile_1.SbMobileNavigationC, { ...props });
    }
    return (0, jsx_runtime_1.jsx)(desktop_1.SbDesktopNavigationC, { ...props });
};
exports.SbNavigationC = SbNavigationC;
