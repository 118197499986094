"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingOfferDescriptionSubtitle = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const date_fns_1 = require("date-fns");
const commons_1 = require("@gerber/commons");
const i18n_1 = require("client/i18n");
const ui_1 = require("client/ui");
const BookingOfferDescriptionSubtitle = ({ begin, nights, }) => {
    const { pack } = (0, i18n_1.useI18n)();
    return ((0, jsx_runtime_1.jsxs)(ui_1.BaseModalSubtitle, { children: [(0, commons_1.extractShortDate)(begin, '.'), ' - ', (0, commons_1.extractShortDate)((0, date_fns_1.addDays)(begin, nights), '.'), ` (${(0, commons_1.format)(pack.common.nights, { nights })})`] }));
};
exports.BookingOfferDescriptionSubtitle = BookingOfferDescriptionSubtitle;
