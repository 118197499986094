"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCalendarFiltersFromValue = void 0;
const commons_1 = require("@gerber/commons");
const getCalendarFiltersFromValue = ({ who, packageGroup, }) => ({
    children: (0, commons_1.pluck)('age')(who.children),
    adults: who.adults.length ?? 0,
    packageGroupId: packageGroup?.id,
});
exports.getCalendarFiltersFromValue = getCalendarFiltersFromValue;
