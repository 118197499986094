"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SidebarSummaryPriceLabel = exports.SidebarSummaryPriceTitleHolder = exports.SidebarSummaryPriceRow = void 0;
const styled_components_1 = require("styled-components");
exports.SidebarSummaryPriceRow = styled_components_1.styled.div `
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;
exports.SidebarSummaryPriceTitleHolder = styled_components_1.styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
`;
exports.SidebarSummaryPriceLabel = styled_components_1.styled.div `
  flex-shrink: 0;

  ${({ theme: { font } }) => (0, styled_components_1.css) `
    font-size: ${font.size.base};
  `}
`;
