"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StartScreenSelectAnotherRoomNotification = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_1 = require("@gerber/commons");
const shared_1 = require("../../../../parts/shared");
const context_1 = require("client/modules/booking/booking-wizard/context");
const i18n_1 = require("client/i18n");
const StartScreenSelectAnotherRoomNotification = () => {
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.start;
    const roomIndex = (0, context_1.useBookingGlobalForm)().value.meta.focusedRoomIndex;
    return ((0, jsx_runtime_1.jsx)(shared_1.BookingWizardNotification, { expandCacheId: "start-screen-another-room", html: (0, commons_1.format)(t.notification.selectAnotherRoom, {
            roomIndex: roomIndex + 1,
        }) }));
};
exports.StartScreenSelectAnotherRoomNotification = StartScreenSelectAnotherRoomNotification;
