"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useVanillaHistory = void 0;
/**
 * Hook that does not trigger any handler in `wouter`
 */
const useVanillaHistory = () => ({
    get of() {
        return Object.getPrototypeOf(window.history);
    },
    replaceState(url, data) {
        this.of.replaceState.call(window.history, data, '', url);
    },
});
exports.useVanillaHistory = useVanillaHistory;
