"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortGroupedOffersBy = void 0;
const function_1 = require("fp-ts/function");
const Ord_1 = require("fp-ts/Ord");
const fp_ts_1 = require("fp-ts");
const commons_1 = require("@gerber/commons");
const OFFER_SORT_METHODS = {
    recommended: (0, function_1.pipe)(fp_ts_1.number.Ord, (0, Ord_1.contramap)((item) => (0, function_1.pipe)(item.room.category.descriptions, (0, commons_1.maybeFindItemByProp)('type', 'Zimmersortierung'), fp_ts_1.option.chain(({ text }) => (0, commons_1.tryParseNumber)(text)), fp_ts_1.option.getOrElse(() => 0)))),
    'name-az': (0, function_1.pipe)(fp_ts_1.string.Ord, (0, Ord_1.contramap)((item) => item.room.category.name)),
    'rate:asc': (0, function_1.pipe)(fp_ts_1.number.Ord, (0, Ord_1.contramap)((item) => item.offers.reduce((acc, offer) => Math.min(acc, offer.price.amount), Infinity))),
    'rate:desc': (0, function_1.pipe)(fp_ts_1.number.Ord, (0, Ord_1.contramap)((item) => -item.offers.reduce((acc, offer) => Math.max(acc, offer.price.amount), -Infinity))),
};
const sortGroupedOffersBy = (sort) => (offers) => (0, function_1.pipe)(offers, fp_ts_1.array.sortBy([OFFER_SORT_METHODS[sort]]));
exports.sortGroupedOffersBy = sortGroupedOffersBy;
