"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMatchScreen = exports.useMatchScreenName = void 0;
const use_match_screen_by_user_agent_1 = require("./use-match-screen-by-user-agent");
const use_match_screen_by_window_width_1 = require("./use-match-screen-by-window-width");
const useMatchScreenName = () => {
    const uaScreenName = (0, use_match_screen_by_user_agent_1.useMatchScreenNameByUserAgent)();
    return (0, use_match_screen_by_window_width_1.useMatchScreenByWindowWidth)(uaScreenName);
};
exports.useMatchScreenName = useMatchScreenName;
const useMatchScreen = (...screens) => {
    const screenName = (0, exports.useMatchScreenName)();
    return screens.includes(screenName);
};
exports.useMatchScreen = useMatchScreen;
