"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbMobileNavigationHolder = void 0;
const styled_components_1 = require("styled-components");
exports.SbMobileNavigationHolder = styled_components_1.styled.nav `
  display: flex;
  width: 100%;
  z-index: 999;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);

  ${({ theme: { spacing, color } }) => (0, styled_components_1.css) `
    padding: ${spacing[3]};
    background: ${color.alias.lightGray.main};
  `}
`;
