"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingOfferCard = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
/* eslint-disable react-hooks/rules-of-hooks */
const react_1 = require("react");
const function_1 = require("fp-ts/function");
const Ord_1 = require("fp-ts/Ord");
const A = __importStar(require("fp-ts/Array"));
const N = __importStar(require("fp-ts/number"));
const O = __importStar(require("fp-ts/Option"));
const forms_1 = require("@under-control/forms");
const commons_1 = require("@gerber/commons");
const sdk_gerber_1 = require("@gerber/sdk-gerber");
const toolbar_1 = require("./toolbar");
const features_1 = require("./features");
const booking_offer_card_styled_1 = require("./booking-offer-card.styled");
const components_1 = require("client/components");
const i18n_1 = require("client/i18n");
const modals_1 = require("client/modules/booking/booking-wizard/modals");
const context_1 = require("client/modules/booking/booking-wizard/context");
exports.BookingOfferCard = (0, react_1.memo)((0, forms_1.controlled)(({ offer, control: { bind, value, setValue } }) => {
    const detailsModal = (0, modals_1.useBookingOfferDetailsModal)();
    const navigation = (0, context_1.useBookingWizardNavigation)();
    const { pack } = (0, i18n_1.useI18n)();
    const { mediums, name, description, teaser, features } = offer.room.category;
    const maybeLowestPriceOffer = (0, react_1.useMemo)(() => {
        const byOfferPrice = (0, function_1.pipe)(N.Ord, (0, Ord_1.contramap)((item) => item.price.amount));
        return (0, function_1.pipe)(offer.offers, A.sortBy([byOfferPrice]), A.head);
    }, [offer.offers]);
    const hasSelectedValue = (0, toolbar_1.useHasToolbarSelectedValue)({
        offers: offer.offers,
        value,
    });
    const onShowDetails = (0, function_1.pipe)(detailsModal.showAsOptional({
        defaultValue: value,
        offer,
    }), (0, commons_1.tapTaskOption)(result => {
        setValue({
            value: result.value,
        });
        navigation.context.next();
    }));
    return ((0, jsx_runtime_1.jsxs)(booking_offer_card_styled_1.BookingOfferCardHolder, { "$active": hasSelectedValue, children: [(0, jsx_runtime_1.jsx)(booking_offer_card_styled_1.BookingOfferCardPicture, { type: "MEDIUM", loading: "lazy", medium: mediums[0], onClick: () => void onShowDetails() }), (0, jsx_runtime_1.jsxs)(booking_offer_card_styled_1.BookingOfferContent, { children: [(0, jsx_runtime_1.jsxs)(booking_offer_card_styled_1.BookingOfferTitleWrapper, { children: [(0, jsx_runtime_1.jsx)(booking_offer_card_styled_1.BookingOfferTitle, { children: name }), O.isSome(maybeLowestPriceOffer) && ((0, jsx_runtime_1.jsx)(booking_offer_card_styled_1.BookingOfferTitlePrice, { children: (0, commons_1.format)(pack.common.fromPrice, {
                                    price: (0, sdk_gerber_1.formatPrice)(maybeLowestPriceOffer.value.price),
                                }) }))] }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$max-xs:y": 3, "$y": 1 }), (0, jsx_runtime_1.jsx)(components_1.Text, { color: "darkMuted", size: "sm", transform: "uppercase", children: pack.common.roomCategory }), features.length > 0 && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 5 }), (0, jsx_runtime_1.jsx)(features_1.BookingOfferFeatures, { features: features })] })), (teaser ?? description)?.trim()?.length > 0 && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 6 }), (0, jsx_runtime_1.jsx)(booking_offer_card_styled_1.BookingOfferDescription, { children: (0, commons_1.truncateText)(250)(teaser ?? description) })] })), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 6 }), (0, jsx_runtime_1.jsx)(toolbar_1.BookingOfferToolbar, { offer: offer, onShowDetails: () => void onShowDetails(), ...bind.entire() })] })] }));
}));
exports.BookingOfferCard.displayName = 'BookingOfferCard';
