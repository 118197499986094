"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingMonthSelectorTitle = exports.BookingMonthSelectorArrowButton = exports.BookingMonthSelectorStyled = void 0;
const styled_components_1 = require("styled-components");
const ui_1 = require("client/ui");
const components_1 = require("client/components");
exports.BookingMonthSelectorStyled = styled_components_1.styled.div `
  display: grid;
  grid-template-columns: min-content auto min-content;
  place-items: center;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    gap: ${spacing[6]};
  `}
`;
exports.BookingMonthSelectorArrowButton = (0, styled_components_1.styled)(ui_1.Button).attrs({
    $kind: 'outline',
    type: 'button',
}) `
  width: 64px;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    padding: ${spacing[1]} ${spacing[2]};
  `}
`;
exports.BookingMonthSelectorTitle = (0, styled_components_1.styled)(components_1.H2) `
  white-space: nowrap;
  font-weight: 500;
`;
