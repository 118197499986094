"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingRoomScreenDesktop = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const forms_1 = require("@under-control/forms");
const shared_1 = require("../shared");
const i18n_1 = require("client/i18n");
const decorators_1 = require("client/modules/booking/booking-wizard/decorators");
const form_1 = require("client/modules/booking/booking-wizard/form");
const ui_1 = require("client/ui");
const parts_1 = require("client/modules/booking/booking-wizard/parts");
exports.BookingRoomScreenDesktop = (0, decorators_1.controlledBookingValue)(({ control, active }) => {
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.room;
    const sortControl = (0, forms_1.useControlStrict)({
        defaultValue: 'recommended',
    });
    const { handleSubmitEvent, value, bind } = (0, form_1.useBookingWizardRoomScreenForm)({
        updateParentStateOnChange: true,
        control,
    });
    return ((0, jsx_runtime_1.jsx)(ui_1.Form, { onSubmit: handleSubmitEvent, ...(0, commons_front_1.testid)('wizard:room'), children: (0, jsx_runtime_1.jsx)(parts_1.BookingDesktopScreenLongContent, { header: t.header, headerProps: {
                toolbar: (0, jsx_runtime_1.jsx)(shared_1.RoomSortInput, { ...sortControl.bind.entire() }),
            }, footer: (0, jsx_runtime_1.jsx)(parts_1.BookingDesktopFooter, { disabled: {
                    next: !(0, form_1.isOfferBookingSectionFilled)(value),
                } }), children: (0, jsx_runtime_1.jsx)(shared_1.BookingOffersContainer, { ...bind.entire(), sort: sortControl.value, offscreen: !active }) }) }));
});
