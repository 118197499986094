"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingOfferToolbarItemTooltipHolder = void 0;
const styled_components_1 = require("styled-components");
exports.BookingOfferToolbarItemTooltipHolder = styled_components_1.styled.span `
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -3px;
  left: 0;
  cursor: pointer;

  width: 18px;
  height: 18px;

  ${({ theme: { color } }) => (0, styled_components_1.css) `
    color: ${color.base.muted.main};
  `}
`;
