"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingMobileStartFooterHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BookingMobileStartFooterHolder = styled_components_1.styled.div `
  ${commons_front_1.styles.flexCenter}

  width: 100%;
  grid-area: footer;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    padding: ${spacing[4]} ${spacing[4]} ${spacing[6]};
  `}
`;
