"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbHotelCardToolbarHolder = void 0;
const commons_front_1 = require("@gerber/commons-front");
const styled_components_1 = require("styled-components");
exports.SbHotelCardToolbarHolder = styled_components_1.styled.div `
  display: flex;
  flex-direction: row;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    grid-gap: ${spacing[4]};
  `}

  ${commons_front_1.styles.mediaSizeDown.sm} {
    display: grid;
    grid-template-columns: min-content;
  }
`;
