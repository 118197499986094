"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingCalendarCellConnectors = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const hooks_1 = require("../../hooks");
const booking_calendar_cell_connectors_bottom_title_styled_1 = require("./booking-calendar-cell-connectors-bottom-title.styled");
const booking_calendar_cell_connectors_inner_title_styled_1 = require("./booking-calendar-cell-connectors-inner-title.styled");
const booking_calendar_cell_connectors_styled_1 = require("./booking-calendar-cell-connectors.styled");
const areConnectorsPropsEqual = (prevProps, nextProps) => (0, hooks_1.compareCellConnectors)(prevProps.connectors)(nextProps.connectors) &&
    prevProps.date.toISOString() === nextProps.date.toISOString() &&
    prevProps.hasTopSpace === nextProps.hasTopSpace;
exports.BookingCalendarCellConnectors = (0, react_1.memo)(({ connectors, date, hasTopSpace }) => {
    const { left, right } = connectors;
    const isLeftAligned = date.getDay() === 1; // prevent overflow on Monday
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [left && ((0, jsx_runtime_1.jsx)(booking_calendar_cell_connectors_styled_1.BookingCellConnectorHolder, { "$hasTopSpace": hasTopSpace, "$color": left.color, "$direction": "left", children: left.content && ((0, jsx_runtime_1.jsx)(booking_calendar_cell_connectors_inner_title_styled_1.BookingCellConnectorsInnerPrefixTitle, { "$align": isLeftAligned ? 'left' : 'right', children: left.content })) })), right && ((0, jsx_runtime_1.jsx)(booking_calendar_cell_connectors_styled_1.BookingCellConnectorHolder, { "$hasTopSpace": hasTopSpace, "$color": right.color, "$direction": "right" })), left?.content && ((0, jsx_runtime_1.jsx)(booking_calendar_cell_connectors_bottom_title_styled_1.BookingCellConnectorsBottomTitle, { children: left.content }))] }));
}, areConnectorsPropsEqual);
exports.BookingCalendarCellConnectors.displayName = 'BookingCalendarCellConnectors';
