"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupByProp = exports.groupBy = void 0;
const groupBy = (fn) => (array) => array.reduce((acc, item) => {
    (acc[fn(item)] ||= []).push(item);
    return acc;
}, {});
exports.groupBy = groupBy;
const groupByProp = (key) => (0, exports.groupBy)(item => item[key]);
exports.groupByProp = groupByProp;
