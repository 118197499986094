"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokPageMetatagsRenderer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_helmet_1 = require("react-helmet");
const SdkStoryblokPageMetatagsRenderer = ({ metatags, }) => {
    const { title, description } = metatags;
    const og = {
        title: metatags.ogTitle || title,
        description: metatags.ogDescription || description,
        image: metatags.ogImage,
    };
    const twitter = {
        title: metatags.twitterTitle || og.title,
        description: metatags.twitterDescription || og.description,
        image: metatags.twitterImage || og.image,
    };
    return ((0, jsx_runtime_1.jsxs)(react_helmet_1.Helmet, { children: [title && (0, jsx_runtime_1.jsx)("title", { children: title }), description && (0, jsx_runtime_1.jsx)("meta", { name: "description", content: description }), og.title && (0, jsx_runtime_1.jsx)("meta", { property: "og:title", content: og.title }), og.description && ((0, jsx_runtime_1.jsx)("meta", { property: "og:description", content: og.description })), og.image && (0, jsx_runtime_1.jsx)("meta", { property: "og:image", content: og.image }), twitter.title && ((0, jsx_runtime_1.jsx)("meta", { property: "twitter:title", content: twitter.title })), twitter.description && ((0, jsx_runtime_1.jsx)("meta", { property: "twitter:description", content: twitter.description })), twitter.image && ((0, jsx_runtime_1.jsx)("meta", { property: "twitter:image", content: twitter.image }))] }));
};
exports.SdkStoryblokPageMetatagsRenderer = SdkStoryblokPageMetatagsRenderer;
