"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHotelCardInfo = void 0;
const TE = __importStar(require("fp-ts/TaskEither"));
const T = __importStar(require("fp-ts/Task"));
const function_1 = require("fp-ts/function");
const commons_1 = require("@gerber/commons");
const sdk_gerber_1 = require("@gerber/sdk-gerber");
const client_1 = require("@gerber/ssr/dist/client");
const react_1 = require("@gerber/sdk-gerber/dist/third-party/react");
const config_1 = require("client/config");
const i18n_1 = require("client/i18n");
const useHotelCardInfo = (oneId) => {
    const config = (0, config_1.useAppConfig)();
    const urlLang = (0, i18n_1.useI18nUrlLang)();
    const sdk = (0, react_1.useGerberLocalSdk)({
        client: {
            lang: (0, sdk_gerber_1.getApiLangFromCode)(urlLang),
            apiUrl: config.gerber.apiUrl,
            oneId,
        },
    });
    return (0, client_1.useHydratedPromise)((0, function_1.pipe)(sdk.controllers.oneConfig.getConfiguration(), TE.map(({ packageCategories, pageCustomization }) => ({
        homepageUrl: (0, commons_1.extractOrigin)(pageCustomization.contactUrl),
        packageGroups: packageCategories.flatMap(item => item.packageGroups),
    })), TE.getOrElseW(() => T.of({
        homepageUrl: null,
        packageGroups: [],
    }))), {
        uuid: `lp/booking-offers-${oneId}`,
        deps: [oneId, urlLang],
        cache: {
            expireInSeconds: 600,
            policy: 'client-only',
        },
    });
};
exports.useHotelCardInfo = useHotelCardInfo;
