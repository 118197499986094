"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const forms_1 = require("@under-control/forms");
const button_1 = require("../../button");
const i18n_1 = require("client/i18n");
exports.SelectButton = (0, forms_1.controlled)(({ control: { value, setValue }, filled, ...props }) => {
    const { pack } = (0, i18n_1.useI18n)();
    const onToggle = () => {
        setValue({
            value: !value,
        });
    };
    const $kind = (() => {
        if (filled) {
            return value ? 'inactive' : 'primary';
        }
        return value ? 'primary' : 'outline-transparent';
    })();
    return ((0, jsx_runtime_1.jsx)(button_1.Button, { title: pack.common.select, type: "button", "$kind": $kind, onClick: onToggle, ...props, children: pack.common.select }));
});
