"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableFooter = exports.TableBody = exports.TableHead = exports.Table = void 0;
const styled_components_1 = require("styled-components");
exports.Table = styled_components_1.styled.table `
  width: 100%;
  border-collapse: collapse;

  ${({ subTable, theme: { spacing } }) => subTable &&
    (0, styled_components_1.css) `
      margin: ${spacing[2]} 0 0 42px;

      > thead {
        text-align: left;
      }

      th {
        font-weight: 500;
        border-top: 0;
      }
    `}
`;
exports.TableHead = styled_components_1.styled.thead `
  vertical-align: top;
  text-align: left;
`;
exports.TableBody = 'tbody';
exports.TableFooter = styled_components_1.styled.tfoot `
  vertical-align: top;
`;
