"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsInViewport = void 0;
/* eslint-disable react-hooks/exhaustive-deps */
const react_1 = require("react");
const useIsInViewport = (init = {}) => {
    const elementRef = (0, react_1.useRef)(null);
    const [isIntersecting, setIsIntersecting] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        if (!elementRef.current) {
            return;
        }
        const observer = new IntersectionObserver(e => {
            setIsIntersecting(e[0].isIntersecting);
        }, {
            root: null,
            rootMargin: '0px',
            ...init,
        });
        observer.observe(elementRef.current);
    }, []);
    return {
        elementRef,
        isIntersecting,
    };
};
exports.useIsInViewport = useIsInViewport;
