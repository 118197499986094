"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppProviders = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
const commons_front_1 = require("@gerber/commons-front");
const config_1 = require("../config");
const theme_1 = require("../theme");
const AppProviders = ({ config, children, userAgent, ssrCookies, }) => ((0, jsx_runtime_1.jsx)(commons_front_1.SSRCookiesContext.Provider, { value: ssrCookies, children: (0, jsx_runtime_1.jsx)(config_1.AppConfigContext.Provider, { value: config, children: (0, jsx_runtime_1.jsx)(theme_1.ThemeProvider, { children: (0, jsx_runtime_1.jsx)(commons_front_1.UserAgentContext.Provider, { value: userAgent, children: (0, jsx_runtime_1.jsx)(react_1.SdkStoryblokProvider, { config: config.storyblok, children: children }) }) }) }) }));
exports.AppProviders = AppProviders;
