"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dropBlankObjValues = void 0;
const is_nil_1 = require("./is-nil");
const is_unsafe_obj_key_1 = require("./guards/is-unsafe-obj-key");
const dropBlankObjValues = (obj) => {
    const reduced = {};
    for (const [key, value] of Object.entries(obj)) {
        if ((0, is_unsafe_obj_key_1.isUnsafeObjKey)(key) || (0, is_nil_1.isNil)(value)) {
            continue;
        }
        reduced[key] = value;
    }
    return reduced;
};
exports.dropBlankObjValues = dropBlankObjValues;
