"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingCalendarSliderNavButton = exports.BookingCalendarSliderNavHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BookingCalendarSliderNavHolder = styled_components_1.styled.div `
  ${commons_front_1.styles.flexCenter}
`;
exports.BookingCalendarSliderNavButton = (0, styled_components_1.styled)(commons_front_1.CleanButton).attrs({
    $focusBorder: false,
}) `
  transition: opacity 150ms ease-in-out;

  ${commons_front_1.styles.flexCenter}

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    padding: ${spacing[4]};
  `}

  &[disabled] {
    opacity: 0.25;
  }

  ${({ $hoverMargin }) => commons_front_1.styles.hoverCardTransformAnim({
    margin: $hoverMargin,
    shadow: 'initial',
})}}
`;
