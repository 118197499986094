"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomSelectInputPlaceholder = exports.CustomSelectPhraseInput = void 0;
const styled_components_1 = require("styled-components");
exports.CustomSelectPhraseInput = styled_components_1.styled.input `
  all: unset;
  padding: 0;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.color.parts.input.placeholder};
  }

  ${({ $allowType }) => !$allowType &&
    (0, styled_components_1.css) `
      user-select: none;
      pointer-events: none;
      cursor: pointer;
      caret-color: transparent;
    `}
`;
exports.CustomSelectInputPlaceholder = styled_components_1.styled.span `
  color: ${({ theme }) => theme.color.parts.input.placeholder};
`;
