"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SkeletonBox = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const shineAnim = (0, styled_components_1.keyframes) `
  0%    { background-position-x: 0%; }
  100%  { background-position-x: -200%; }
`;
exports.SkeletonBox = styled_components_1.styled.div `
  animation: ${shineAnim} 1.5s linear infinite;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;

  ${({ $width, ...attrs }) => (0, styled_components_1.css) `
    width: ${(0, commons_front_1.genPxValueIfNumber)($width)};

    ${'$height' in attrs &&
    (0, styled_components_1.css) `
      height: ${(0, commons_front_1.genPxValueIfNumber)(attrs.$height)};
    `}

    ${'$ratio' in attrs &&
    (0, styled_components_1.css) `
      aspect-ratio: ${attrs.$ratio};
    `}
  `}

  ${commons_front_1.styles.mediaMaxScreenWidth('xs')} {
    animation: none;
    background: #ececec;
  }
`;
