"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SidebarPlaceholderIcon = exports.SidebarPlaceholderHolder = void 0;
const styled_components_1 = require("styled-components");
const icons_1 = require("@gerber/icons");
exports.SidebarPlaceholderHolder = styled_components_1.styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  text-align: center;
  line-height: 1.4;

  ${({ theme: { color, spacing } }) => (0, styled_components_1.css) `
    padding: ${spacing[14]} 0;
    color: ${color.base.muted.main};
  `}
`;
exports.SidebarPlaceholderIcon = (0, styled_components_1.styled)(icons_1.SidebarIcon) `
  width: 42px;
  color: #7f7f7f;
`;
