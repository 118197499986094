"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AgeSelectInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const forms_1 = require("@under-control/forms");
const NonEmptyArray_1 = require("fp-ts/NonEmptyArray");
const i18n_1 = require("client/i18n");
const ui_1 = require("client/ui");
exports.AgeSelectInput = (0, forms_1.controlled)(({ control: { value, setValue }, ageRange = { begin: 0, end: 17 }, ...props }) => {
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.start.form;
    const items = (0, react_1.useMemo)(() => (0, NonEmptyArray_1.range)(ageRange.begin, ageRange.end), [ageRange.begin, ageRange.end]);
    return ((0, jsx_runtime_1.jsx)(ui_1.SelectInputOrNative, { ...props, showSelectedAsFirst: false, items: items, formatSelectedValue: selected => {
            if (selected) {
                return `${t.age.label}: ${selected.name}`.toUpperCase();
            }
            return t.age.label.toUpperCase();
        }, value: (0, ui_1.toSelectItemOrNil)(true)(value), onChange: item => {
            setValue({
                value: item?.id,
            });
        } }));
});
