"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoryblokTitledCtaHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const config = {
    shouldForwardProp: (0, commons_front_1.shouldNotForwardProps)([
        ...(0, commons_front_1.maxBreakpointFields)('maxWidth'),
    ]),
};
exports.StoryblokTitledCtaHolder = styled_components_1.styled.div.withConfig(config) `
  ${(0, commons_front_1.maxBreakpointProp)('maxWidth')(maxWidth => maxWidth &&
    (0, styled_components_1.css) `
        max-width: ${maxWidth};
      `)}
`;
