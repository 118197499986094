"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokFooterBlockV = void 0;
const zod_1 = require("zod");
const sdk_storyblok_block_dto_1 = require("../../sdk-storyblok-block.dto");
const parts_1 = require("../../parts");
const sdk_storyblok_footer_social_block_1 = require("./sdk-storyblok-footer-social.block");
exports.SdkStoryblokFooterBlockV = (0, sdk_storyblok_block_dto_1.SdkStoryblokBlockV)('Footer', {
    hotel_name: zod_1.z.string(),
    address: parts_1.SdkStoryblokRichTextV,
    phone: zod_1.z.string(),
    email: zod_1.z.string(),
    socials: zod_1.z.array(sdk_storyblok_footer_social_block_1.SdkStoryblokFooterSocialBlockV),
    logo: parts_1.SdkStoryblokPictureV.optional().nullable().catch(null),
});
