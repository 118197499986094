"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingWizardContainerProviders = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const function_1 = require("fp-ts/function");
const TE = __importStar(require("fp-ts/TaskEither"));
const commons_1 = require("@gerber/commons");
const client_1 = require("@gerber/ssr/dist/client");
const react_1 = require("@gerber/sdk-gerber/dist/third-party/react");
const context_1 = require("./context");
const ui_1 = require("client/ui");
const BookingWizardContainerProviders = ({ children, }) => {
    const { sdk } = (0, react_1.useGerberSdk)();
    const sessionCtxValue = (0, context_1.useBookingWizardSessionContextValue)();
    const mapSdkResultToWizardConfig = (0, context_1.useMapSdkResultToWizardConfig)();
    const restoreWizardValue = (0, context_1.useRestoreBookingWizardValueTask)(sessionCtxValue);
    const { value: result, loading } = (0, client_1.useHydratedPromise)((0, function_1.pipe)(TE.Do, TE.bind('config', () => (0, function_1.pipe)(sdk.controllers.oneConfig.getConfiguration(), TE.map(mapSdkResultToWizardConfig))), TE.bindW('defaultValue', ({ config }) => restoreWizardValue(config)), commons_1.tryOrThrowTE), {
        uuid: 'booking-config',
        deps: [sdk.client.config.lang],
        ssr: false,
        preserveValueBetweenFetch: true,
        cache: {
            policy: 'never',
            expireInSeconds: 0,
        },
    });
    return ((0, jsx_runtime_1.jsx)(ui_1.SpinnerContainer, { "$height": "100dvh", loading: loading && !result, children: () => ((0, jsx_runtime_1.jsx)(context_1.BookingWizardConfigContext.Provider, { value: result.config, children: (0, jsx_runtime_1.jsx)(context_1.SdkBookingWizardSessionContext.Provider, { value: sessionCtxValue, children: (0, jsx_runtime_1.jsx)(context_1.ControlledBookingGlobalFormProvider, { defaultValue: result.defaultValue, children: (0, jsx_runtime_1.jsx)(context_1.BookingWizardSidebarProvider, { children: children }) }) }) })) }));
};
exports.BookingWizardContainerProviders = BookingWizardContainerProviders;
