"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SummaryEditableSectionButton = exports.SummaryEditableSectionContent = exports.SummaryEditableSectionHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.SummaryEditableSectionHolder = styled_components_1.styled.section `
  display: grid;
  grid-template: 'content cta' / 1fr min-content;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    gap: ${spacing[4]};
  `}
`;
exports.SummaryEditableSectionContent = styled_components_1.styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-area: content;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    gap: ${spacing[2]};
  `}
`;
exports.SummaryEditableSectionButton = (0, styled_components_1.styled)(commons_front_1.CleanButton) `
  grid-area: cta;

  &[disabled] {
    opacity: 0.25;
    pointer-events: none;
  }
`;
