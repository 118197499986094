"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopSidebarTruncatedToolbar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_1 = require("@gerber/icons");
const commons_front_1 = require("@gerber/commons-front");
const booking_desktop_sidebar_truncated_toolbar_styled_1 = require("./booking-desktop-sidebar-truncated-toolbar.styled");
const i18n_1 = require("client/i18n");
const context_1 = require("client/modules/booking/booking-wizard/context");
const components_1 = require("client/components");
const ui_1 = require("client/ui");
const BookingDesktopSidebarTruncatedToolbar = () => {
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.sidebar;
    const config = (0, context_1.useBookingWizardConfigContext)();
    return ((0, jsx_runtime_1.jsxs)(booking_desktop_sidebar_truncated_toolbar_styled_1.BookingSidebarTruncatedHeaderToolbarHolder, { "$row": true, "$gap": 3, children: [(0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsxs)(ui_1.Button, { as: components_1.UniversalLink, "$kind": "outline", target: "_blank", title: t.cta.website, href: config.hotel.contactUrl, ...(0, commons_front_1.testid)('sidebar:contact-button'), children: [t.cta.contact, (0, jsx_runtime_1.jsx)(ui_1.ButtonSuffixIcon, { children: (0, jsx_runtime_1.jsx)(icons_1.LongArrowRightThinIcon, { direction: "right" }) })] }) }), (0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)(ui_1.AppChangeLangSelect, { fillIfValueIsPresent: false }) })] }));
};
exports.BookingDesktopSidebarTruncatedToolbar = BookingDesktopSidebarTruncatedToolbar;
