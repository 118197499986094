"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MediumsPreviewToolbar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_1 = require("@gerber/icons");
const mediums_preview_toolbar_styled_1 = require("./mediums-preview-toolbar.styled");
const i18n_1 = require("client/i18n");
const ui_1 = require("client/ui");
const components_1 = require("client/components");
const MediumsPreviewToolbar = ({ navigation, preview360Url, }) => {
    const { pack } = (0, i18n_1.useI18n)();
    const { activeIndex, totalSlides, next, prev } = navigation.context;
    return ((0, jsx_runtime_1.jsxs)(mediums_preview_toolbar_styled_1.MediumsPreviewToolbarHolder, { children: [(0, jsx_runtime_1.jsx)(mediums_preview_toolbar_styled_1.MediumsPreviewNavButton, { disabled: !activeIndex, onClick: () => {
                    prev();
                }, children: (0, jsx_runtime_1.jsx)(icons_1.LongArrowRightThinIcon, { direction: "left" }) }), preview360Url && ((0, jsx_runtime_1.jsx)(components_1.UniversalLink, { href: preview360Url, target: "_blank", children: (0, jsx_runtime_1.jsxs)(ui_1.Button, { "$kind": "outline", as: "div", children: [pack.common.roomIn360, (0, jsx_runtime_1.jsx)(ui_1.ButtonSuffixIcon, { children: (0, jsx_runtime_1.jsx)(icons_1.Arrow360Icon, {}) })] }) })), (0, jsx_runtime_1.jsx)(mediums_preview_toolbar_styled_1.MediumsPreviewNavButton, { disabled: activeIndex + 1 === totalSlides, onClick: () => {
                    next();
                }, children: (0, jsx_runtime_1.jsx)(icons_1.LongArrowRightThinIcon, { direction: "right" }) })] }));
};
exports.MediumsPreviewToolbar = MediumsPreviewToolbar;
