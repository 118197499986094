"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingPersonalAddressForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
/* eslint-disable react-hooks/rules-of-hooks */
const react_1 = require("react");
const forms_1 = require("@under-control/forms");
const country_select_input_1 = require("../../shared/country-select-input");
const booking_personal_address_form_styled_1 = require("./booking-personal-address-form.styled");
const i18n_1 = require("client/i18n");
const ui_1 = require("client/ui");
exports.BookingPersonalAddressForm = (0, react_1.memo)((0, forms_1.controlled)(({ control: { bind }, errors }) => {
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.contact.personalData.address;
    const validation = (0, forms_1.useFormValidatorMessages)({ errors });
    return ((0, jsx_runtime_1.jsxs)(booking_personal_address_form_styled_1.BookingPersonalAddressGrid, { children: [(0, jsx_runtime_1.jsx)(ui_1.FormGroup, { required: true, space: 0, area: "street", label: t.street.label, error: (0, jsx_runtime_1.jsx)(ui_1.ValidationErrorsHint, { ...validation.extract('street') }), children: (0, jsx_runtime_1.jsx)(ui_1.Input, { ...bind.path('street', { input: val => val ?? '' }), required: true, name: "street", placeholder: t.street.placeholder }) }), (0, jsx_runtime_1.jsx)(ui_1.FormGroup, { required: true, space: 0, area: "zip-code", label: t.zipCode.label, error: (0, jsx_runtime_1.jsx)(ui_1.ValidationErrorsHint, { ...validation.extract('zipCode') }), children: (0, jsx_runtime_1.jsx)(ui_1.Input, { ...bind.path('zipCode', { input: val => val ?? '' }), required: true, name: "zipCode", placeholder: t.zipCode.placeholder }) }), (0, jsx_runtime_1.jsx)(ui_1.FormGroup, { required: true, space: 0, area: "city", label: t.city.label, error: (0, jsx_runtime_1.jsx)(ui_1.ValidationErrorsHint, { ...validation.extract('city') }), children: (0, jsx_runtime_1.jsx)(ui_1.Input, { ...bind.path('city', { input: val => val ?? '' }), required: true, name: "town", placeholder: t.city.placeholder }) }), (0, jsx_runtime_1.jsx)(ui_1.FormGroup, { required: true, space: 0, area: "country", label: t.country.label, error: (0, jsx_runtime_1.jsx)(ui_1.ValidationErrorsHint, { ...validation.extract('country') }), children: (0, jsx_runtime_1.jsx)(country_select_input_1.CountrySelectInput, { ...bind.path('country'), required: true, inputProps: {
                        placeholder: t.country.placeholder,
                    } }) })] }));
}));
exports.BookingPersonalAddressForm.displayName = 'BookingPersonalAddressForm';
