"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HotelThemeProvider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const E = __importStar(require("fp-ts/Either"));
const sdk_gerber_1 = require("@gerber/sdk-gerber");
const commons_1 = require("@gerber/commons");
const theme_provider_1 = require("./theme.provider");
const HOTEL_THEMES_MAPPING = {
    mooshaus: 'orange',
    lisl: 'pink',
    alpenrosesportaktiv: 'light-pink',
    sporthotel: 'cyan',
};
const HotelThemeProvider = ({ children, oneId, }) => {
    const maybeHotelId = (0, react_1.useMemo)(() => (0, commons_1.tryParseUsingZodSchema)(sdk_gerber_1.SdkKnownHotelIdV, true)(oneId), [oneId]);
    return ((0, jsx_runtime_1.jsx)(theme_provider_1.ThemeProvider, { ...(E.isRight(maybeHotelId) && {
            theme: HOTEL_THEMES_MAPPING[maybeHotelId.right],
        }), children: children }));
};
exports.HotelThemeProvider = HotelThemeProvider;
