"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientTasksResolver = void 0;
const O = __importStar(require("fp-ts/Option"));
const commons_1 = require("@gerber/commons");
const get_ssr_json_1 = require("../../client/get-ssr-json");
class ClientTasksResolver {
    constructor(hydrationData = {}) {
        this.memoryCache = commons_1.SyncMemoryCache.ofObject({});
        this.hydrationData = commons_1.SyncMemoryCache.ofObject(hydrationData || {});
    }
    static ofHydrationData() {
        return new ClientTasksResolver((0, get_ssr_json_1.getSSRJson)('preloaded-promises'));
    }
    getHydrationTaskResultAndInvalidate(uuid) {
        const { hydrationData } = this;
        const data = hydrationData.get(uuid);
        hydrationData.del(uuid);
        return data;
    }
    getCache(key) {
        return this.memoryCache.get(key);
    }
    tryResolveTask(dto) {
        if (!ClientTasksResolver.isDTOClientCached(dto)) {
            return dto.task();
        }
        const { expireInSeconds, key } = dto.cache;
        const cacheResult = this.getCache(key);
        if (O.isSome(cacheResult)) {
            return cacheResult.value;
        }
        return dto.task().then(result => {
            this.memoryCache.set(key, result, expireInSeconds);
            return result;
        });
    }
    static isDTOClientCached(dto) {
        return (!!dto.cache && this.ALLOWED_CACHE_POLICIES.includes(dto.cache.policy));
    }
}
exports.ClientTasksResolver = ClientTasksResolver;
ClientTasksResolver.ALLOWED_CACHE_POLICIES = [
    'client-only',
    'client-and-server',
];
