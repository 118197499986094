"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ORANGE_APP_THEME = void 0;
const default_theme_1 = require("./default.theme");
const ORANGE_ALIAS_COLORS = {
    black: {
        main: '#4C4B37',
        contrastText: '#ffffff',
    },
    white: {
        main: '#ffffff',
        contrastText: '#4C4B37',
    },
};
const ORANGE_BASE_COLORS = {
    primary: default_theme_1.DEFAULT_ALIAS_COLORS.orange,
};
const ORANGE_PARTS_COLORS = {
    summaryBox: ORANGE_ALIAS_COLORS.black,
    checkbox: ORANGE_ALIAS_COLORS.black,
    button: {
        primary: ORANGE_BASE_COLORS.primary,
        outline: ORANGE_ALIAS_COLORS.white,
    },
    input: {
        border: ORANGE_ALIAS_COLORS.black.main,
        filled: ORANGE_ALIAS_COLORS.black,
        focus: {
            border: ORANGE_ALIAS_COLORS.black.main,
        },
    },
    infoCard: {
        outline: {
            contrastText: ORANGE_ALIAS_COLORS.black.main,
        },
        light: {
            contrastText: ORANGE_ALIAS_COLORS.black.main,
        },
    },
};
exports.ORANGE_APP_THEME = {
    color: {
        alias: ORANGE_ALIAS_COLORS,
        base: ORANGE_BASE_COLORS,
        parts: ORANGE_PARTS_COLORS,
    },
};
