"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopSidebarToggleButtonHolder = void 0;
const styled_components_1 = require("styled-components");
const booking_desktop_sidebar_wrapper_styled_1 = require("./booking-desktop-sidebar-wrapper.styled");
const ui_1 = require("client/ui");
exports.BookingDesktopSidebarToggleButtonHolder = (0, styled_components_1.styled)(ui_1.Button) `
  display: none;

  ${booking_desktop_sidebar_wrapper_styled_1.SIDEBAR_TOGGLE_BREAKPOINT} {
    display: block;
    position: absolute;
    right: 100%;

    ${({ theme: { spacing } }) => (0, styled_components_1.css) `
      top: ${spacing[4]};
      margin-right: ${spacing[4]};
    `}
  }
`;
