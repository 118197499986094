"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingContactForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const forms_1 = require("@under-control/forms");
const _00_personal_data_1 = require("./00-personal-data");
const _01_people_info_1 = require("./01-people-info");
const _02_terms_1 = require("./02-terms");
const components_1 = require("client/components");
exports.BookingContactForm = (0, forms_1.controlled)(({ control: { bind }, errors }) => {
    const validation = (0, forms_1.useFormValidatorMessages)({ errors });
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(_00_personal_data_1.BookingPersonalDataSection, { topSpace: 0, ...bind.path('contact'), ...validation.extract('contact', { nested: true }) }), (0, jsx_runtime_1.jsx)(components_1.Divider, { "$space": 0 }), (0, jsx_runtime_1.jsx)(_01_people_info_1.BookingPeopleInfoSection, { errors: errors, ...bind.entire() }), (0, jsx_runtime_1.jsx)(components_1.Divider, { "$space": 0 }), (0, jsx_runtime_1.jsx)(_02_terms_1.BookingTermsSection, { bottomSpace: 0, ...bind.path('terms'), ...validation.extract('terms', { nested: true }) })] }));
});
exports.BookingContactForm.displayName = 'BookingContactForm';
