"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesInfoCard = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_1 = require("@gerber/icons");
const commons_1 = require("@gerber/commons");
const context_1 = require("../../../../context");
const sales_info_card_styled_1 = require("./sales-info-card.styled");
const i18n_1 = require("client/i18n");
const components_1 = require("client/components");
const shared_1 = require("client/modules/booking/shared");
const SalesInfoCard = () => {
    const { pack } = (0, i18n_1.useI18n)();
    const { date: { salesInfo }, } = (0, context_1.useBookingWizardConfigContext)();
    if (!salesInfo.length) {
        return null;
    }
    const content = salesInfo.map((item, index) => ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)("strong", { children: item.title }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 1 }), (0, jsx_runtime_1.jsx)(components_1.Text, { display: "block", children: `(${(0, commons_1.extractShortDate)(item.begin, '.')} - ${(0, commons_1.extractShortDate)(item.end, '.')})` }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 2 }), item.text, index + 1 !== salesInfo.length && ((0, jsx_runtime_1.jsx)(components_1.Divider, { "$style": "dashed", "$type": "light", "$space": 5 }))] }, item.title ?? item.begin.toString())));
    return ((0, jsx_runtime_1.jsx)(shared_1.AccordionInfoCard, { defaultExpanded: false, header: (0, jsx_runtime_1.jsx)(components_1.LabeledIcon, { icon: (0, jsx_runtime_1.jsx)(icons_1.InfoIcon, {}), size: "sm", children: pack.booking.wizard.sidebar.bookingInfo.title }), children: () => (0, jsx_runtime_1.jsx)(sales_info_card_styled_1.InfoCardContentHolder, { children: content }) }));
};
exports.SalesInfoCard = SalesInfoCard;
