"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppRouter = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const wouter_1 = require("wouter");
require("./theme");
const R = __importStar(require("./routes"));
const hooks_1 = require("./hooks");
const AppRouter = () => {
    const sitemap = (0, hooks_1.useSitemap)();
    const schemaAttrs = {
        schemaOnly: true,
    };
    return ((0, jsx_runtime_1.jsxs)(wouter_1.Switch, { children: [(0, jsx_runtime_1.jsx)(wouter_1.Route, { path: sitemap.wizard.root(schemaAttrs), component: R.BookingRoute }), (0, jsx_runtime_1.jsx)(wouter_1.Route, { path: sitemap.wizard.payment.success(schemaAttrs), component: R.BookingPaymentSuccessRoute }), (0, jsx_runtime_1.jsx)(wouter_1.Route, { path: sitemap.wizard.payment.error(schemaAttrs), component: R.BookingPaymentErrorRoute }), (0, jsx_runtime_1.jsx)(wouter_1.Route, { path: sitemap.wizard.payment.cancel(schemaAttrs), component: R.BookingPaymentCancelRoute }), (0, jsx_runtime_1.jsx)(wouter_1.Route, { path: sitemap.landingPage.show(schemaAttrs), component: R.LandingPageRoute })] }));
};
exports.AppRouter = AppRouter;
