"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokGridBlockV = exports.SdkStoryblokGridLayoutV = void 0;
const zod_1 = require("zod");
const sdk_storyblok_block_dto_1 = require("../../sdk-storyblok-block.dto");
const sdk_storyblok_rwd_attr_dto_1 = require("../../sdk-storyblok-rwd-attr.dto");
const data_source_1 = require("../../data-source");
const components_1 = require("../components");
const SdkStoryblokGridContentV = components_1.SdkStoryblokBaseBlockV.array();
exports.SdkStoryblokGridLayoutV = zod_1.z.enum(['1', '1:1', '1:1:1']);
exports.SdkStoryblokGridBlockV = zod_1.z.intersection((0, sdk_storyblok_block_dto_1.SdkStoryblokBlockV)('Layout_Grid', {
    content: SdkStoryblokGridContentV,
}), zod_1.z.intersection((0, sdk_storyblok_rwd_attr_dto_1.SdkStoryblokRwdAttrSM)('layout')(exports.SdkStoryblokGridLayoutV), (0, sdk_storyblok_rwd_attr_dto_1.SdkStoryblokRwdAttrSM)('space')(data_source_1.SdkStoryblokSpacingV)));
