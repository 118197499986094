"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWatchScreenSize = void 0;
const react_1 = require("react");
const use_element_listener_1 = require("./use-element-listener");
const use_window_listener_1 = require("./use-window-listener");
const useWatchScreenSize = (callback, { fireOnMount = true } = {}) => {
    (0, react_1.useEffect)(() => {
        if (fireOnMount) {
            callback();
        }
    }, []);
    (0, use_window_listener_1.useWindowListener)({
        resize: callback,
        orientationchange: callback,
    });
    (0, use_element_listener_1.useElementListener)({
        change: callback,
    }, { selectorFn: () => screen.orientation });
};
exports.useWatchScreenSize = useWatchScreenSize;
