"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopSidebar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const booking_desktop_sidebar_styled_1 = require("./booking-desktop-sidebar.styled");
const content_1 = require("./content");
const footer_1 = require("./footer");
const header_1 = require("./header");
const hooks_1 = require("./hooks");
const components_1 = require("client/components");
exports.BookingDesktopSidebar = (0, react_1.memo)(() => {
    const showSummary = (0, hooks_1.useShowBookingSummary)();
    return ((0, jsx_runtime_1.jsxs)(booking_desktop_sidebar_styled_1.BookingDesktopSidebarHolder, { children: [(0, jsx_runtime_1.jsxs)(booking_desktop_sidebar_styled_1.BookingDesktopSidebarScrollableContent, { "$scrollable": showSummary, children: [(0, jsx_runtime_1.jsx)(header_1.BookingDesktopSidebarHeader, { truncated: showSummary }), (0, jsx_runtime_1.jsx)(components_1.Divider, { "$type": "light" }), (0, jsx_runtime_1.jsx)(content_1.BookingDesktopSidebarContent, { showSummary: showSummary })] }), (0, jsx_runtime_1.jsx)(footer_1.BookingDesktopSidebarFooter, { showSummaryPrice: showSummary })] }));
});
exports.BookingDesktopSidebar.displayName = 'BookingDesktopSidebar';
