"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBookingRoomPrice = void 0;
const getBookingRoomPrice = (value) => {
    const { date, offer } = value;
    if (offer?.selected) {
        return {
            ...offer.selected.price,
            approx: false,
        };
    }
    if (!date?.offer) {
        return null;
    }
    const { minimumPrice } = date.offer;
    if (!minimumPrice) {
        return null;
    }
    return {
        ...minimumPrice,
        approx: true,
    };
};
exports.getBookingRoomPrice = getBookingRoomPrice;
