"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.layer = exports.makeLayer = void 0;
const styled_components_1 = require("styled-components");
const makeLayer = (layer) => (0, styled_components_1.css) `
  position: ${layer};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;
exports.makeLayer = makeLayer;
exports.layer = {
    absolute: (0, exports.makeLayer)('absolute'),
    fixed: (0, exports.makeLayer)('fixed'),
};
