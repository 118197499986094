"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useErrorNotification = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_1 = require("@gerber/icons");
const context_1 = require("../context");
const i18n_1 = require("client/i18n");
const useErrorNotification = () => {
    const notifications = (0, context_1.useNotifications)();
    const { pack } = (0, i18n_1.useI18n)();
    return {
        show: () => {
            notifications.push({
                icon: (0, jsx_runtime_1.jsx)(icons_1.ErrorIcon, {}),
                message: pack.notifications.error,
            });
        },
    };
};
exports.useErrorNotification = useErrorNotification;
