"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectAmount = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const function_1 = require("fp-ts/function");
const NonEmptyArray_1 = require("fp-ts/NonEmptyArray");
const Array_1 = require("fp-ts/Array");
const forms_1 = require("@under-control/forms");
const commons_1 = require("@gerber/commons");
const commons_front_1 = require("@gerber/commons-front");
const select_amount_styled_1 = require("./select-amount.styled");
const item_1 = require("./item");
const components_1 = require("client/components");
exports.SelectAmount = (0, forms_1.controlled)(({ control: { value, setValue }, min = 1, max, renderMetaFieldFn, toolbarInNewLine, required, errors, toolbar, }) => {
    const validation = (0, forms_1.useFormValidatorMessages)({ errors: errors ?? [] });
    const isMobile = (0, commons_front_1.useIsMobile)();
    const onToggle = (amount) => (toggled) => {
        const filteredValues = (() => {
            if (toggled) {
                return (0, function_1.pipe)((0, NonEmptyArray_1.range)(min, amount), (0, Array_1.map)(number => (0, function_1.pipe)(value, (0, commons_1.findItemByProp)('number', number)) ?? {
                    number,
                }));
            }
            return (0, function_1.pipe)(value, (0, Array_1.filter)(item => item.number < amount));
        })();
        setValue({
            value: filteredValues,
        });
    };
    const onChange = (amount) => (item) => {
        if (item) {
            const index = value.findIndex(({ number }) => number === amount);
            setValue({
                value: (0, Array_1.unsafeUpdateAt)(index, item, value),
            });
        }
        else {
            onToggle(amount)(false);
        }
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(components_1.CleanList, { "$wrap": true, "$row": true, "$flexChildren": true, "$gap": 2, children: [(0, NonEmptyArray_1.range)(min, max).map(amount => ((0, jsx_runtime_1.jsx)(item_1.SelectAmountItem, { amount: amount, value: (0, function_1.pipe)(value, (0, commons_1.findItemByProp)('number', amount)), renderMetaFieldFn: renderMetaFieldFn, invalid: validation.extract(`[${amount - min}]`).errors.length > 0, onToggle: onToggle(amount), onChange: onChange(amount) }, amount))), !isMobile && !toolbarInNewLine && toolbar && ((0, jsx_runtime_1.jsx)(select_amount_styled_1.SelectAmountListItemSuffix, { children: toolbar }))] }), (isMobile || toolbarInNewLine) && toolbar && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 4 }), toolbar] })), (0, jsx_runtime_1.jsx)(commons_front_1.HiddenRequiredInput, { required: required && !value.length })] }));
});
