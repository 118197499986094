"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.recreateBookingSessionRoomGuestsInfo = void 0;
const A = __importStar(require("fp-ts/Array"));
const TE = __importStar(require("fp-ts/TaskEither"));
const function_1 = require("fp-ts/function");
const commons_1 = require("@gerber/commons");
/**
 * It makes a trick. It saves in the API "guests" field who is the kid and who is not
 * by setting generated approx dates (at least for kids). It sucks but there is no
 * other way to do that sadly.
 */
const recreateBookingSessionRoomGuestsInfo = (sdk) => (sessionId) => (room) => ({ guests, ...sessionItem }) => {
    const { who: { adults, children }, } = room;
    const mapGuestsByType = (type, persons) => (0, function_1.pipe)(guests, A.filter(guest => guest.type === type), A.mapWithIndex((index, item) => {
        const personValue = persons[index];
        return {
            ...item,
            ...(0, commons_1.dropBlankObjValues)({
                birthdate: (0, commons_1.extractUnsafeDate)(personValue?.info?.birthDate),
                firstname: personValue?.info?.firstName,
                lastname: personValue?.info?.lastName,
            }),
        };
    }));
    return (0, function_1.pipe)(TE.of({
        ...sessionItem,
        guests: [
            ...mapGuestsByType('ADULT', adults),
            ...mapGuestsByType('CHILD', children),
        ],
    }), (0, commons_1.tapTaskEitherTE)(updatedSessionItem => sdk.controllers.session.updateItem({
        ...updatedSessionItem,
        extraServices: [],
        lastMinutePackage: null,
        variantId: null,
        sessionId,
    })));
};
exports.recreateBookingSessionRoomGuestsInfo = recreateBookingSessionRoomGuestsInfo;
