"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Input = void 0;
const commons_front_1 = require("@gerber/commons-front");
const styled_components_1 = require("styled-components");
exports.Input = styled_components_1.styled.input `
  all: unset;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  transition: 100ms ease-in-out;
  transition-property: background, outline;

  ${({ theme: { color, font } }) => (0, styled_components_1.css) `
    font-size: ${font.size.sm};
    border: 1px solid ${color.parts.input.border};
    color: ${color.parts.input.text};
    background: ${color.parts.input.background};
  `}

  ${({ $size = 'md' }) => {
    switch ($size) {
        case 'sm':
            return (0, styled_components_1.css) `
          min-height: 28px;
          padding: 4px 10px;
        `;
        case 'md':
            return (0, styled_components_1.css) `
          min-height: 48px;
          padding: 10px 16px;
          line-height: 24px;
        `;
        default: {
            const size = $size;
            console.warn('Unknown input size!', size);
        }
    }
}}

  ${({ $fillIfValuePresent = true, value, theme: { color } }) => !!value &&
    $fillIfValuePresent &&
    (0, styled_components_1.css) `
      &:not(:focus),
      &:-webkit-autofill {
        ${(0, commons_front_1.genPaletteColorCSS)(color.parts.input.filled)}

        font-weight: 600;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-background-clip: text;
          -webkit-text-fill-color: ${color.parts.input.filled.contrastText};
          box-shadow: inset 0 0 220px 220px ${color.parts.input.filled.main};
        }
      }

      &::-webkit-calendar-picker-indicator {
        filter: invert(1);
      }
    `}

  ${({ $wide }) => $wide &&
    (0, styled_components_1.css) `
      width: 400px;
      max-width: 100%;
    `}

  ${({ $expanded }) => {
    if ($expanded) {
        return (0, styled_components_1.css) `
        width: 100%;
      `;
    }
    return (0, styled_components_1.css) `
      min-width: 150px;
    `;
}}

  ${({ $active, theme: { color } }) => {
    const style = (0, styled_components_1.css) `
      outline: 1px solid ${color.parts.input.focus.border};
      background: ${color.parts.input.focus.background};
    `;
    return (0, styled_components_1.css) `
      ${$active && style}
      &:focus:not([readonly]) {
        ${style}
      }
    `;
}}

  &::placeholder {
    color: ${({ theme }) => theme.color.parts.input.placeholder};
  }

  &[type='color'] {
    display: flex;
    height: 32px;
    width: 64px;
    min-width: auto;
  }

  ${({ theme: { color: { parts }, }, }) => (0, styled_components_1.css) `
    &[disabled],
    &[readonly] {
      background: ${parts.input.readOnly.background};
      color: ${parts.input.readOnly.color};
      border-color: ${parts.input.readOnly.border};
    }

    &[disabled] {
      pointer-events: none;
    }
  `}
`;
