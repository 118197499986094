"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingCalendarCellClear = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_1 = require("@gerber/icons");
const booking_calendar_cell_clear_styled_1 = require("./booking-calendar-cell-clear.styled");
const i18n_1 = require("client/i18n");
const BookingCalendarCellClear = (props) => {
    const { pack } = (0, i18n_1.useI18n)();
    return ((0, jsx_runtime_1.jsx)(booking_calendar_cell_clear_styled_1.BookingCalendarCellClearHolder, { title: pack.common.clear, type: "button", ...props, children: (0, jsx_runtime_1.jsx)(icons_1.TimesIcon, { width: 12, height: 12, strokeWidth: 1.5 }) }));
};
exports.BookingCalendarCellClear = BookingCalendarCellClear;
