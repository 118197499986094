"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRestoreBookingWizardValueTask = void 0;
const function_1 = require("fp-ts/function");
const TE = __importStar(require("fp-ts/TaskEither"));
const O = __importStar(require("fp-ts/Option"));
const commons_1 = require("@gerber/commons");
const use_merge_booking_session_with_url_1 = require("./use-merge-booking-session-with-url");
/**
 * Restores value from (in order):
 *  1. SESSION
 *  2. URL
 */
const useRestoreBookingWizardValueTask = (sessionCtxValue) => {
    const { tryAssignValuesFromUrlParams } = (0, use_merge_booking_session_with_url_1.useMergeBookingSessionWithUrlParams)();
    const restoreWizardValue = (config) => (0, function_1.pipe)(sessionCtxValue.tryRestoreSessionFromUrlOrNone(), TE.chain(O.matchW(() => sessionCtxValue.tryRestoreSessionFromCookieOrNone(), restored => TE.of(O.some(restored)))), TE.chainW(maybeSession => (0, function_1.pipe)(tryAssignValuesFromUrlParams({ config, maybeSession }), (0, commons_1.tapTaskEither)(({ maybeUpsertedSession }) => {
        if (O.isSome(maybeUpsertedSession)) {
            sessionCtxValue.assignSession(maybeUpsertedSession.value);
        }
    }), TE.map(({ formValue }) => formValue))));
    return restoreWizardValue;
};
exports.useRestoreBookingWizardValueTask = useRestoreBookingWizardValueTask;
