"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnorderedList = void 0;
const styled_components_1 = require("styled-components");
exports.UnorderedList = styled_components_1.styled.ol `
  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    margin: 0;
    padding: 0 0 0 ${spacing[8]};
  `}

  & li::marker {
    color: inherit;
    line-height: inherit;
    font-size: inherit;
  }
`;
