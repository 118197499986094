"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseFullModalHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BaseFullModalHolder = styled_components_1.styled.div.withConfig({
    shouldForwardProp: (0, commons_front_1.shouldNotForwardProps)(['out']),
}) `
  ${commons_front_1.styles.layer.fixed}

  ${({ out }) => commons_front_1.styles.animation.modal.layer[out ? 'out' : 'in']};
  ${({ theme: { color } }) => (0, styled_components_1.css) `
    background: ${color.alias.lightGray.main};
  `}
`;
