"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingContactScreen = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const commons_front_1 = require("@gerber/commons-front");
const use_set_sidebar_content_if_active_1 = require("../use-set-sidebar-content-if-active");
const mobile_1 = require("./mobile");
const desktop_1 = require("./desktop");
exports.BookingContactScreen = (0, react_1.memo)(({ active, onPay }) => {
    const isMobile = (0, commons_front_1.useIsMobile)();
    (0, use_set_sidebar_content_if_active_1.useResetSidebarContentIfActive)({ active });
    if (isMobile) {
        return (0, jsx_runtime_1.jsx)(mobile_1.BookingContactScreenMobile, { onPay: onPay });
    }
    return (0, jsx_runtime_1.jsx)(desktop_1.BookingContactScreenDesktop, { onPay: onPay });
});
exports.BookingContactScreen.displayName = 'BookingContactScreen';
