"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOffersFiltersFromValue = void 0;
const commons_1 = require("@gerber/commons");
const getOffersFiltersFromValue = (value) => {
    const { date, who } = value;
    if (!date?.offer || !date.offer?.nights) {
        return null;
    }
    return {
        children: (0, commons_1.pluck)('age')(who.children),
        adults: who.adults.length ?? 0,
        arrivalDay: date.range.begin,
        nights: date.offer.nights,
    };
};
exports.getOffersFiltersFromValue = getOffersFiltersFromValue;
