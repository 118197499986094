"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPriceEqual = exports.mapPriceValue = exports.formatPrice = exports.SdkPriceV = void 0;
const zod_1 = require("zod");
exports.SdkPriceV = zod_1.z.object({
    amount: zod_1.z.number(),
    currency: zod_1.z.string(),
    symbol: zod_1.z.string(),
});
const formatPrice = ({ amount, symbol, hideDecimalsIfPossible, }) => {
    const formattedPrice = (hideDecimalsIfPossible && amount % 1 === 0 ? amount : amount.toFixed(2))
        .toString()
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return `${symbol} ${formattedPrice}`;
};
exports.formatPrice = formatPrice;
const mapPriceValue = (mapAmount) => (price) => ({
    ...price,
    amount: mapAmount(price.amount),
});
exports.mapPriceValue = mapPriceValue;
const isPriceEqual = (a) => (b) => (!a && !b) ||
    !!(a &&
        b &&
        a.amount === b.amount &&
        a.currency === b.currency &&
        a.symbol === b.symbol);
exports.isPriceEqual = isPriceEqual;
