"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopSidebarBaseFooter = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const booking_desktop_sidebar_base_footer_styled_1 = require("./booking-desktop-sidebar-base-footer.styled");
const shared_1 = require("client/modules/booking/booking-wizard/parts/shared");
const components_1 = require("client/components");
const i18n_1 = require("client/i18n");
exports.BookingDesktopSidebarBaseFooter = (0, react_1.memo)(() => {
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.sidebar;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(booking_desktop_sidebar_base_footer_styled_1.BookingDesktopSidebarBaseFooterHolder, { children: [(0, jsx_runtime_1.jsx)(components_1.H3, { children: t.payment.title }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 4 }), (0, jsx_runtime_1.jsx)(shared_1.PaymentMethodsRow, {})] }), (0, jsx_runtime_1.jsx)(components_1.Divider, { "$type": "light" }), (0, jsx_runtime_1.jsx)(shared_1.HotelContactRow, {})] }));
});
exports.BookingDesktopSidebarBaseFooter.displayName = 'BookingDesktopSidebarBaseFooter';
