"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbFooterC = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
const layout_1 = require("../layout");
const parts_1 = require("../../parts");
const storyblok_footer_styled_1 = require("./storyblok-footer.styled");
const parts_2 = require("./parts");
const i18n_1 = require("client/i18n");
const components_1 = require("client/components");
const SbFooterC = ({ item }) => {
    const t = (0, i18n_1.useI18n)().pack.landingPage.footer;
    return ((0, jsx_runtime_1.jsx)(layout_1.SbLayoutSection, { ...(0, react_1.sdkStoryblokEditableProps)(item), as: "footer", color: "muted", space: "md", children: (0, jsx_runtime_1.jsxs)(storyblok_footer_styled_1.SbFooterGrid, { children: [(0, jsx_runtime_1.jsx)(parts_2.SbFooterColumn, { area: "media", title: t.media, children: (0, jsx_runtime_1.jsx)(react_1.SdkSbComponentsListRenderer, { items: item.socials }) }), (0, jsx_runtime_1.jsx)(parts_2.SbFooterColumn, { area: "address", title: t.contact, children: (0, jsx_runtime_1.jsxs)(components_1.Grid, { gap: 19, template: "auto 1fr", children: [(0, jsx_runtime_1.jsx)(parts_1.SbRichText, { text: item.address }), (0, jsx_runtime_1.jsx)(parts_2.SbFooterContact, { email: item.email, phone: item.phone })] }) }), (0, jsx_runtime_1.jsx)(parts_2.SbFooterColumn, { area: "logo", children: item.logo && (0, jsx_runtime_1.jsx)(parts_2.SbFooterLogo, { picture: item.logo }) })] }) }));
};
exports.SbFooterC = SbFooterC;
