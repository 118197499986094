"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createBlankBookingPersonInfoValue = void 0;
const commons_1 = require("@gerber/commons");
const createBlankBookingPersonInfoValue = () => ({
    birthDate: null,
    firstName: null,
    lastName: null,
    meta: (0, commons_1.createBlankEditorIdMeta)(),
});
exports.createBlankBookingPersonInfoValue = createBlankBookingPersonInfoValue;
