"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingCalendarTable = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const date_fns_1 = require("date-fns");
const react_1 = require("react");
const wobbly_gallery_1 = require("@gerber/wobbly-gallery");
const commons_front_1 = require("@gerber/commons-front");
const commons_1 = require("@gerber/commons");
const forms_1 = require("@under-control/forms");
const hooks_1 = require("./hooks");
const booking_calendar_table_content_1 = require("./booking-calendar-table-content");
const booking_calendar_table_week_days_row_1 = require("./week-days/booking-calendar-table-week-days-row");
const slider_1 = require("./slider");
exports.BookingCalendarTable = (0, forms_1.controlled)(({ filters, navigation, header, cachedMonths, roomOffer, preselectedRanges = [], control: { value, setValue }, }) => {
    const isMobile = (0, commons_front_1.useIsMobile)();
    const [fetchSelectionOffers, selectionOffers] = (0, hooks_1.useBookingCalendarSelectionOffers)({
        filters,
    });
    const cells = (0, hooks_1.useBookingCalendarCellsList)({
        navigationDate: navigation.value.date,
        cachedMonths,
        filters,
    });
    const isScrollUpDisabled = (0, react_1.useMemo)(() => (0, date_fns_1.startOfMonth)(cells.visible[0].date) <
        (0, date_fns_1.startOfMonth)((0, commons_1.getZonedDate)(new Date())), [cells.visible]);
    const { draggableRef, updateScroll } = (0, wobbly_gallery_1.useWobblyDraggableAnim)({
        vertical: true,
        fallbackToTransition: false,
        slideDuration: isMobile ? 200 : 350,
    });
    const [onClickNav, navPromise] = (0, forms_1.usePromiseCallback)(async (delta) => {
        await updateScroll(true)(delta);
        const newDate = (0, date_fns_1.addWeeks)(cells.visible[0].date, delta * cells.preloadWeeks);
        navigation.setValue({
            merge: true,
            value: {
                date: newDate,
            },
        });
    });
    const onChange = (newValue) => {
        setValue({
            value: newValue,
        });
        // scroll next row if selected something from two last rows
        if (newValue &&
            !newValue.offer &&
            newValue.range.begin &&
            (0, commons_1.getTotalDatesRangeDates)({
                begin: (0, commons_1.getZonedDate)(new Date(newValue.range.begin)),
                end: cells.visible.slice(-1)[0].date,
            }) < 14) {
            void onClickNav(1);
        }
    };
    (0, commons_front_1.useInstantUpdateEffect)(() => {
        if (!draggableRef.current) {
            return;
        }
        draggableRef.current.style.transform = '';
    }, [navigation.value.date]);
    (0, commons_front_1.useInstantAfterMount)(() => {
        if (!selectionOffers.result && value?.range.begin) {
            void fetchSelectionOffers(value.range.begin);
        }
    });
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [header?.({ loading: cells.loading }), (0, jsx_runtime_1.jsxs)(slider_1.BookingCalendarSliderWrapper, { children: [!isMobile && ((0, jsx_runtime_1.jsx)(slider_1.BookingCalendarSliderNav, { disabled: navPromise.loading || isScrollUpDisabled, direction: "top", onClick: () => {
                            void onClickNav(-1);
                        } })), isMobile && (0, jsx_runtime_1.jsx)(booking_calendar_table_week_days_row_1.BookingCalendarTableWeekDaysRow, {}), (0, jsx_runtime_1.jsx)(slider_1.BookingCalendarSliderTrackWrapper, { children: (0, jsx_runtime_1.jsx)(slider_1.BookingCalendarSliderTrack, { ref: draggableRef, children: (0, jsx_runtime_1.jsx)(booking_calendar_table_content_1.BookingCalendarTableContent, { value: value, cells: cells, roomOffer: roomOffer, preselectedRanges: preselectedRanges, selectionOffers: {
                                    list: selectionOffers.result,
                                    fetchList: fetchSelectionOffers,
                                }, ...(selectionOffers.loading && {
                                    disabledRanges: [
                                        commons_1.DateRanges.fromInfinity(selectionOffers.optimisticArrivalDay ??
                                            (0, commons_1.getZonedDate)(new Date())),
                                    ],
                                    skeletonRange: commons_1.DateRanges.toInfinity(selectionOffers.optimisticArrivalDay ??
                                        (0, commons_1.getZonedDate)(new Date())),
                                }), onChange: onChange }) }) }), !isMobile && ((0, jsx_runtime_1.jsx)(slider_1.BookingCalendarSliderNav, { disabled: navPromise.loading, direction: "bottom", onClick: () => {
                            void onClickNav(1);
                        } }))] })] }));
});
