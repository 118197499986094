"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsyncSelectInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const client_1 = require("@gerber/ssr/dist/client");
const forms_1 = require("@under-control/forms");
const guards_1 = require("./guards");
const autocomplete_1 = require("./autocomplete");
const select_input_or_native_1 = require("./select-input-or-native");
exports.AsyncSelectInput = (0, forms_1.controlled)(({ preloadItems = true, highlight = true, phrasedResultWhenCompleteValue = false, fetchItemsKeys, onFetchItems, control: { bind, value }, disabled, ...props }) => {
    const searchPhrase = !phrasedResultWhenCompleteValue && (0, guards_1.isCompleteListItemValue)(value)
        ? undefined
        : value?.name;
    const uuid = (0, react_1.useId)();
    const [loadLock, setLoadLock] = (0, react_1.useState)(!preloadItems);
    const { value: responseItems, loading } = (0, client_1.useHydratedPromise)(async () => {
        let items = await onFetchItems(searchPhrase);
        if (highlight) {
            items = (0, autocomplete_1.highlightPhrase)(searchPhrase, items);
        }
        return items;
    }, {
        uuid,
        skip: loadLock,
        deps: [highlight, searchPhrase, ...(fetchItemsKeys ?? [])],
    });
    const onShowDropdown = () => {
        setLoadLock(false);
        props.onShowDropdown?.();
    };
    return ((0, jsx_runtime_1.jsx)(select_input_or_native_1.SelectInputOrNative, { ...props, ...bind.entire(), allowType: true, disabled: disabled, items: responseItems ?? [], loading: loading, resetIncompleteValueOnBlur: true, onShowDropdown: onShowDropdown }));
});
