"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingOffersGridHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BookingOffersGridHolder = styled_components_1.styled.div `
  display: grid;
  grid-template-columns: 1fr;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    gap: ${spacing[5]};
  `}

  ${commons_front_1.styles.mediaMinWidth(800)} {
    grid-template-columns: 1fr 1fr;

    ${({ theme: { spacing } }) => (0, styled_components_1.css) `
      gap: ${spacing[8]};
    `}
  }
`;
