"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mediaSizeDown = exports.mediaSizeUp = exports.mediaMaxScreenWidth = exports.mediaMinScreenWidth = exports.mediaMaxWidth = exports.mediaMinWidth = exports.mediaMaxHeight = exports.mediaMinHeight = exports.getScreenSizeIndex = exports.SCREEN_BREAKPOINTS_NAMES = exports.SCREEN_BREAKPOINTS = void 0;
const Record_1 = require("fp-ts/Record");
const function_1 = require("fp-ts/function");
exports.SCREEN_BREAKPOINTS = {
    xxxl: 1600,
    xxl: 1400,
    xl: 1200,
    lg: 1024,
    md: 768,
    sm: 600,
    xs: 440,
};
exports.SCREEN_BREAKPOINTS_NAMES = Object.keys(exports.SCREEN_BREAKPOINTS);
const getScreenSizeIndex = (name) => Object.keys(exports.SCREEN_BREAKPOINTS).indexOf(name);
exports.getScreenSizeIndex = getScreenSizeIndex;
const mediaMinHeight = (height) => `@media screen and (min-height: ${height + 1}px)`;
exports.mediaMinHeight = mediaMinHeight;
const mediaMaxHeight = (height) => `@media screen and (max-height: ${height}px)`;
exports.mediaMaxHeight = mediaMaxHeight;
const mediaMinWidth = (width) => `@media screen and (min-width: ${width + 1}px)`;
exports.mediaMinWidth = mediaMinWidth;
const mediaMaxWidth = (height) => `@media screen and (max-width: ${height}px)`;
exports.mediaMaxWidth = mediaMaxWidth;
const mediaMinScreenWidth = (screen) => (0, exports.mediaMinWidth)(exports.SCREEN_BREAKPOINTS[screen]);
exports.mediaMinScreenWidth = mediaMinScreenWidth;
const mediaMaxScreenWidth = (screen) => (0, exports.mediaMaxWidth)(exports.SCREEN_BREAKPOINTS[screen]);
exports.mediaMaxScreenWidth = mediaMaxScreenWidth;
exports.mediaSizeUp = (0, function_1.pipe)(exports.SCREEN_BREAKPOINTS, (0, Record_1.mapWithIndex)(exports.mediaMinScreenWidth));
exports.mediaSizeDown = (0, function_1.pipe)(exports.SCREEN_BREAKPOINTS, (0, Record_1.mapWithIndex)(exports.mediaMaxScreenWidth));
