"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingCalendarTableSkeleton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const commons_front_1 = require("@gerber/commons-front");
const hooks_1 = require("./hooks");
const week_days_1 = require("./week-days");
const booking_calendar_table_content_1 = require("./booking-calendar-table-content");
const slider_1 = require("./slider");
exports.BookingCalendarTableSkeleton = (0, react_1.memo)(() => {
    const isMobile = (0, commons_front_1.useIsMobile)();
    const cells = (0, hooks_1.useBookingCalendarCellsList)({
        skipLoading: true,
    });
    return ((0, jsx_runtime_1.jsxs)(slider_1.BookingCalendarSliderWrapper, { children: [!isMobile && (0, jsx_runtime_1.jsx)(slider_1.BookingCalendarSliderNav, { disabled: true, direction: "top" }), isMobile && (0, jsx_runtime_1.jsx)(week_days_1.BookingCalendarTableWeekDaysRow, {}), (0, jsx_runtime_1.jsx)(slider_1.BookingCalendarSliderTrackWrapper, { children: (0, jsx_runtime_1.jsx)(slider_1.BookingCalendarSliderTrack, { children: (0, jsx_runtime_1.jsx)(booking_calendar_table_content_1.BookingCalendarTableContent, { defaultValue: null, cells: cells }) }) }), !isMobile && (0, jsx_runtime_1.jsx)(slider_1.BookingCalendarSliderNav, { disabled: true, direction: "bottom" })] }));
});
exports.BookingCalendarTableSkeleton.displayName = 'BookingCalendarTableSkeleton';
