"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MdDescriptionContentHolder = void 0;
const styled_components_1 = require("styled-components");
exports.MdDescriptionContentHolder = styled_components_1.styled.span `
  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    & ol,
    & ul {
      margin: ${spacing[3]} 0;
      padding-left: ${spacing[8]};
    }

    & li {
      margin: 0;

      &:not(:last-child) {
        margin-bottom: ${spacing[1]};
      }
    }
  `}
`;
