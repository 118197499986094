"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toFlexStoryblokJustify = exports.SdkStoryblokJustifyV = void 0;
const zod_1 = require("zod");
exports.SdkStoryblokJustifyV = zod_1.z.enum(['left', 'right', 'center']);
const toFlexStoryblokJustify = (justify) => {
    switch (justify) {
        case 'center':
            return 'center';
        case 'left':
            return 'flex-start';
        case 'right':
            return 'flex-end';
        default: {
            const unknownJustify = justify;
            console.warn('Unknown justify:', unknownJustify);
            return 'flex-start';
        }
    }
};
exports.toFlexStoryblokJustify = toFlexStoryblokJustify;
