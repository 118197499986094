"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAppPrefixedRoute = void 0;
const wouter_1 = require("wouter");
const use_app_assign_base_prefix_1 = require("./use-app-assign-base-prefix");
const useAppPrefixedRoute = (path) => {
    const assignBasePrefix = (0, use_app_assign_base_prefix_1.useAssignAppBasePrefix)();
    return (0, wouter_1.useRoute)(assignBasePrefix(`/:lang${path}`));
};
exports.useAppPrefixedRoute = useAppPrefixedRoute;
