"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbHotelCardContent = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const forms_1 = require("@under-control/forms");
const commons_front_1 = require("@gerber/commons-front");
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
const storyblok_hotel_card_content_styled_1 = require("./storyblok-hotel-card-content.styled");
const toolbar_1 = require("./toolbar");
const components_1 = require("client/components");
const parts_1 = require("client/modules/landing-page/parts");
exports.SbHotelCardContent = (0, forms_1.controlled)(({ item, showOffersCTA, control: { bind } }) => {
    const { anim } = (0, commons_front_1.useInitialAnimatedElement)();
    return ((0, jsx_runtime_1.jsxs)(storyblok_hotel_card_content_styled_1.SbHotelCardContentHolder, { children: [(0, jsx_runtime_1.jsx)(components_1.H3, { ...anim('animate-fade-in-from-down'), children: item.title }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 5 }), (0, jsx_runtime_1.jsx)(parts_1.SbRichText, { text: item.description, ...anim('animate-fade-in-from-right') }), item.features.length > 0 && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 5 }), (0, jsx_runtime_1.jsx)(components_1.CleanList, { "$row": true, "$wrap": true, "$gap": 2, ...anim('animate-fade-in-from-left'), children: (0, jsx_runtime_1.jsx)(react_1.SdkSbComponentsListRenderer, { items: item.features, itemWrapperTag: "li" }) })] })), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 7 }), (0, jsx_runtime_1.jsx)(toolbar_1.SbHotelCardToolbar, { ...bind.entire(), item: item, showOffersCTA: showOffersCTA })] }));
});
