"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingOffersHeaderTitle = exports.BookingOffersHeaderHolder = void 0;
const styled_components_1 = require("styled-components");
const components_1 = require("client/components");
exports.BookingOffersHeaderHolder = styled_components_1.styled.div `
  display: flex;
  flex-direction: row;
  align-items: baseline;
  line-height: 1;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    gap: ${spacing[5]};
  `}
`;
exports.BookingOffersHeaderTitle = (0, styled_components_1.styled)(components_1.H2) `
  font-weight: 500;
  line-height: inherit;
`;
