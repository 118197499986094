"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAnimationManagerContext = exports.AnimationManagerContext = exports.createBlankManagerValue = void 0;
const react_1 = require("react");
const createBlankManagerValue = () => ({
    enabled: true,
    delay: 50,
    elements: new Set([]),
    getAnimStyleIfEnabled(style) {
        return this.enabled ? style : null;
    },
});
exports.createBlankManagerValue = createBlankManagerValue;
exports.AnimationManagerContext = (0, react_1.createContext)((0, exports.createBlankManagerValue)());
const useAnimationManagerContext = () => (0, react_1.useContext)(exports.AnimationManagerContext);
exports.useAnimationManagerContext = useAnimationManagerContext;
