"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbLayoutContainer = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const config = {
    shouldForwardProp: (0, commons_front_1.shouldNotForwardProps)(['wide']),
};
exports.SbLayoutContainer = styled_components_1.styled.div.withConfig(config) `
  margin: 0 auto;

  --container-x-space: 16px;

  ${({ wide }) => (0, styled_components_1.css) `
    width: ${wide ? 1340 : 1180}px;
    max-width: calc(100% - var(--container-x-space) * 2);
  `}
`;
