"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLockWindowScroll = void 0;
const react_1 = require("react");
const useLockWindowScroll = () => {
    (0, react_1.useLayoutEffect)(() => {
        const { style } = document.body;
        const scrollBarWidth = window.innerWidth - document.body.clientWidth;
        if (!scrollBarWidth) {
            return void 0;
        }
        style.overflow = 'hidden';
        style.paddingRight = `${scrollBarWidth}px`;
        return () => {
            style.paddingRight = '';
            style.overflow = '';
        };
    }, []);
};
exports.useLockWindowScroll = useLockWindowScroll;
