"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingStartScreenMobileWrapper = void 0;
const styled_components_1 = require("styled-components");
exports.BookingStartScreenMobileWrapper = styled_components_1.styled.div `
  display: flex;
  width: 100%;
  height: 100%;
  height: 100dvh;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  scroll-snap-type: y mandatory;
  flex-direction: column;

  > * {
    flex-shrink: 0;
    scroll-snap-align: start;
  }
`;
