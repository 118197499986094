"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidationErrorsHint = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const forms_1 = require("@under-control/forms");
const invalid_html_input_1 = require("./invalid-html-input");
const ValidationErrorsHint = ({ errors }) => {
    const { all } = (0, forms_1.useFormValidatorMessages)({ errors });
    if (!all?.length) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, forms_1.flattenMessagesList)(all).join(','), (0, jsx_runtime_1.jsx)(invalid_html_input_1.FormGroupInvalidHTMLInput, {})] }));
};
exports.ValidationErrorsHint = ValidationErrorsHint;
