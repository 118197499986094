"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHydratedPromise = void 0;
const react_1 = require("react");
const function_1 = require("fp-ts/function");
const O = __importStar(require("fp-ts/Option"));
const commons_1 = require("@gerber/commons");
const commons_front_1 = require("@gerber/commons-front");
const task_resolver_context_1 = require("./context/task-resolver-context");
/**
 * Hook that executes promise in SSR mode (and optionally caches it). Hook works only in client-only mode.
 *
 * @example
 *  const { http } = useAppContext();
 *  const { value, ...state } = useHydratedPromise(
 *    async () => {
 *      const response = await Faq.getContent(http);
 *      return remapFaq(response);
 *    },
 *    {
 *      deps: [],
 *      cache: {
 *        policy: 'client-and-server',
 *        expireInSeconds: Time.toSeconds.minutes(15),
 *      },
 *      onError: () => {
 *        history.push({
 *          pathname: route.PAGE_NOT_FOUND(config.LOCALE),
 *        });
 *      },
 *    }
 *  );
 */
function useHydratedPromise(fn, { uuid, skip, cache, deps, preserveValueBetweenFetch, ssr = true, onError, }) {
    const resolver = (0, task_resolver_context_1.useTasksResolverContext)();
    const safeFn = (0, commons_front_1.useRefSafeCallback)(fn);
    const safeErrorFn = (0, commons_front_1.useRefSafeCallback)(onError ?? (() => { }));
    // longer version of uuid passed to promise
    // it adds additional parameters like site / lang
    const safeLongUUID = (0, react_1.useMemo)(() => `${uuid}:${cache?.key ?? '-'}:${deps?.join(',') ?? '-'}`, [deps?.join(','), uuid, cache?.key]);
    // prevKey and safeKey behaves similar as useEffect deps
    // if prevKey differs from safeKey it triggers download
    const prevSkip = (0, commons_front_1.useInstantPrevious)(skip);
    const prevLongUUID = (0, commons_front_1.useInstantPrevious)(safeLongUUID);
    const initialRender = prevLongUUID === null;
    // sync lookup in cache by provided key
    const [state, setState] = (0, react_1.useState)(() => (0, function_1.pipe)(resolver.getHydrationTaskResultAndInvalidate(safeLongUUID), O.match(() => ({
        loading: true,
    }), (value) => ({
        loading: false,
        value,
    }))));
    const resetState = (0, commons_front_1.useRefSafeCallback)((silent) => {
        if (silent) {
            state.loading = true;
            delete state.error;
            if (!preserveValueBetweenFetch) {
                delete state.value;
            }
        }
        else {
            setState({
                loading: true,
                ...(preserveValueBetweenFetch && {
                    value: state.value,
                }),
            });
        }
    });
    /**
     * "prevQueryUUID" prevents situation when we call hook twice
     * and first response come to hook faster than second.
     * that response must be ignored
     *
     * @see
     *  Do not convert to async / await function! It will break SSR!
     *  execAndUpdateCache() must be calledin sync mode!
     */
    const prevQueryUUID = (0, react_1.useRef)();
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    const reload = (0, commons_front_1.useRefSafeCallback)((silent) => {
        const queryUUID = Date.now();
        prevQueryUUID.current = queryUUID;
        resetState(silent);
        if (!ssr && (0, commons_1.isSSR)()) {
            return Promise.resolve(null);
        }
        const promiseOrValue = resolver.tryResolveTask({
            uuid: safeLongUUID,
            task: safeFn,
            ...(cache && {
                cache: {
                    key: safeLongUUID,
                    ...cache,
                },
            }),
        });
        if (!promiseOrValue) {
            return Promise.resolve(null);
        }
        if (promiseOrValue instanceof Promise) {
            return promiseOrValue
                .then((result) => {
                if (prevQueryUUID.current === queryUUID) {
                    setState({
                        loading: false,
                        value: result,
                    });
                }
                return result;
            })
                .catch((e) => {
                if (prevQueryUUID.current === queryUUID) {
                    safeErrorFn(e);
                    setState({
                        loading: false,
                        error: e,
                    });
                }
                if (!silent) {
                    throw e;
                }
                else {
                    console.error(e);
                }
                return null;
            });
        }
        if (silent) {
            Object.assign(state, {
                loading: false,
                value: promiseOrValue,
            });
        }
        else {
            setState({
                loading: false,
                value: promiseOrValue,
            });
        }
        return Promise.resolve(promiseOrValue);
    });
    if (!skip &&
        (safeLongUUID !== prevLongUUID || prevSkip !== skip) &&
        (!initialRender || state.loading)) {
        state.loading = true;
        void reload(true);
    }
    return {
        ...state,
        reload,
    };
}
exports.useHydratedPromise = useHydratedPromise;
