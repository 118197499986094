"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbPackageGroupCard = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_1 = require("@gerber/commons");
const toolbar_1 = require("./toolbar");
const storyblok_package_group_card_styled_1 = require("./storyblok-package-group-card.styled");
const components_1 = require("client/components");
const SbPackageGroupCard = ({ oneId, packageGroup, }) => {
    const { mediums, name, description, teaser } = packageGroup;
    return ((0, jsx_runtime_1.jsxs)(storyblok_package_group_card_styled_1.SbPackageGroupCardHolder, { children: [(0, jsx_runtime_1.jsx)(storyblok_package_group_card_styled_1.SbPackageGroupCardPicture, { type: "MEDIUM", loading: "lazy", medium: mediums[0] }), (0, jsx_runtime_1.jsxs)(storyblok_package_group_card_styled_1.SbPackageGroupContent, { children: [(0, jsx_runtime_1.jsx)(storyblok_package_group_card_styled_1.SbPackageGroupTitle, { children: name }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 6 }), (0, jsx_runtime_1.jsx)(storyblok_package_group_card_styled_1.SbPackageGroupDescription, { children: teaser ?? (0, commons_1.truncateText)(250)(description) }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 6, "$grow": true }), (0, jsx_runtime_1.jsx)(toolbar_1.SbPackageGroupToolbar, { oneId: oneId, packageGroup: packageGroup })] })] }));
};
exports.SbPackageGroupCard = SbPackageGroupCard;
