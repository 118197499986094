"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokComponentRenderer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const context_1 = require("../../context");
const SdkStoryblokComponentRenderer = ({ item, }) => {
    const componentsMap = (0, context_1.useSdkStoryblokComponentsMap)();
    const Component = componentsMap[item.component];
    if (!Component) {
        console.warn('Missing component:', item);
        return null;
    }
    return (0, jsx_runtime_1.jsx)(Component, { item: item });
};
exports.SdkStoryblokComponentRenderer = SdkStoryblokComponentRenderer;
