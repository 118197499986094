"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.restorePrefilledRoom = void 0;
const NEA = __importStar(require("fp-ts/NonEmptyArray"));
const A = __importStar(require("fp-ts/Array"));
const TE = __importStar(require("fp-ts/TaskEither"));
const O = __importStar(require("fp-ts/Option"));
const function_1 = require("fp-ts/function");
const commons_1 = require("@gerber/commons");
const helpers_1 = require("client/modules/booking/booking-wizard/screens/02-date/helpers");
const form_1 = require("client/modules/booking/booking-wizard/form");
const restorePrefilledRoom = (sdk) => ({ adults, children, begin, end }) => (rooms) => {
    const truncatedRooms = (0, form_1.areCriticalBookingRoomValuesFilled)(NEA.last(rooms))
        ? rooms
        : NEA.init(rooms);
    const newRoom = {
        ...(0, form_1.createBlankBookingRoom)(),
        who: {
            children: (children ?? []).map((age, index) => ({
                number: index + 1,
                age,
            })),
            adults: A.makeBy(adults, index => ({
                number: index + 1,
            })),
        },
        ...(begin &&
            end && {
            date: {
                range: {
                    begin,
                    end,
                },
            },
        }),
    };
    const roomDateOfferMapperTask = (() => {
        if (!newRoom.date) {
            return TE.of(newRoom);
        }
        const offersFilters = (0, helpers_1.getCalendarFiltersFromValue)(newRoom);
        const nights = (0, commons_1.getTotalDatesRangeDates)({
            begin: begin,
            end: end,
        });
        return (0, function_1.pipe)(sdk.controllers.oneCalendar.getDay({
            ...offersFilters,
            arrivalDay: newRoom.date.range.begin,
        }), TE.map((0, function_1.flow)((0, commons_1.maybeFindItemByProp)('nights', nights), O.match(() => ({
            ...newRoom,
            date: null,
        }), (offer) => ({
            ...newRoom,
            date: {
                ...newRoom.date,
                offer,
            },
        })))));
    })();
    return (0, function_1.pipe)(roomDateOfferMapperTask, TE.map(mappedRoom => [
        ...truncatedRooms,
        mappedRoom,
    ]));
};
exports.restorePrefilledRoom = restorePrefilledRoom;
