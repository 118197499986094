"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectableList = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const styles_1 = require("../styles");
const hooks_1 = require("../hooks");
exports.SelectableList = (0, react_1.forwardRef)(({ style, items, loading, selected, prependEmptyOption, showSelectedAsFirst, onSelectItem, }, ref) => {
    const prependedItemsCount = +!!prependEmptyOption;
    const sortedItems = (0, react_1.useMemo)(() => {
        if (!selected || items.length < 10 || !showSelectedAsFirst) {
            return items;
        }
        return [...items].sort(item => (item.id === selected.id ? -1 : 0));
    }, [prependEmptyOption, items, showSelectedAsFirst]);
    // handle arrows up / down highlight
    const { highlightedIndex } = (0, hooks_1.useHighlightedItemIndex)({
        disabled: loading,
        resetKeys: [items, prependedItemsCount],
        totalItems: prependedItemsCount + sortedItems.length,
        firstIndexAfterReset: -1,
        onSelectItem: index => {
            if (index === null) {
                onSelectItem(null);
            }
            else {
                onSelectItem(sortedItems[index - prependedItemsCount] || null);
            }
        },
    });
    // show spinner on loading state instead options list
    let content = null;
    if (loading) {
        content = ((0, jsx_runtime_1.jsx)(styles_1.CustomSelectInputOption, { "$hoverable": false, children: (0, jsx_runtime_1.jsx)(styles_1.CustomSelectOptionSpinner, {}) }));
    }
    else {
        content = ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [prependEmptyOption && ((0, jsx_runtime_1.jsx)(styles_1.CustomSelectInputOption, { "$active": !selected, "$highlighted": highlightedIndex === 0, onClick: () => {
                        onSelectItem(null);
                    }, children: "\u00A0" })), sortedItems.map((item, index) => ((0, jsx_runtime_1.jsx)(styles_1.CustomSelectInputOption, { "$active": selected?.id === item.id, "$highlighted": highlightedIndex !== null &&
                        index === highlightedIndex - prependedItemsCount, title: item.text ?? item.name, onClick: () => {
                        onSelectItem(item);
                    }, children: item.nonValueText ?? item.name }, item.id)))] }));
    }
    return ((0, jsx_runtime_1.jsx)(styles_1.CustomSelectInputOptionsWrapper, { ref: ref, style: style, children: (0, jsx_runtime_1.jsx)(styles_1.CustomSelectInputOptions, { children: content }) }));
});
exports.SelectableList.displayName = 'SelectableList';
