"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VariantOfferSummary = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_1 = require("@gerber/icons");
const cover_1 = require("./cover");
const components_1 = require("client/components");
const ui_1 = require("client/ui");
const modals_1 = require("client/modules/booking/booking-wizard/modals");
const VariantOfferSummary = ({ variantOffer, showCover, showTeaser = true, }) => {
    const showDetailsModal = (0, modals_1.useBookingOfferDetailsModal)();
    const { room: { category }, } = variantOffer;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [showCover ? ((0, jsx_runtime_1.jsx)(cover_1.VariantOfferCover, { mediums: category.mediums })) : ((0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 5 })), (0, jsx_runtime_1.jsx)(components_1.LabeledIcon, { display: "flex", weight: 700, size: "base", icon: (0, jsx_runtime_1.jsx)(icons_1.CircleHeartIcon, {}), children: category.name }), showTeaser && category.teaser && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 3 }), (0, jsx_runtime_1.jsx)(components_1.Text, { size: "sm", children: category.teaser })] })), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 4 }), (0, jsx_runtime_1.jsx)(components_1.Text, { size: "sm", weight: showTeaser ? 600 : 500, children: (0, jsx_runtime_1.jsx)(ui_1.MoreInformationCleanButton, { onClick: () => void showDetailsModal.show({
                        readOnly: true,
                        defaultValue: variantOffer,
                        offer: {
                            offers: [variantOffer],
                            room: variantOffer.room,
                        },
                    }) }) })] }));
};
exports.VariantOfferSummary = VariantOfferSummary;
