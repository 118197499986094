"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.withSearchParams = void 0;
const Record_1 = require("fp-ts/Record");
const decode_search_params_1 = require("./decode-search-params");
const drop_search_params_1 = require("./drop-search-params");
const encode_search_params_1 = require("./encode-search-params");
const withSearchParams = (params) => (url) => {
    if ((0, Record_1.isEmpty)(params)) {
        return url;
    }
    const mergedParams = {
        ...(0, decode_search_params_1.decodeSearchParams)(url),
        ...params,
    };
    if ((0, Record_1.isEmpty)(mergedParams)) {
        return (0, drop_search_params_1.dropSearchParams)(url);
    }
    let searchStr = (0, encode_search_params_1.encodeSearchParams)(mergedParams);
    if (searchStr) {
        searchStr = `?${searchStr}`;
    }
    return `${(0, drop_search_params_1.dropSearchParams)(url)}${searchStr}`;
};
exports.withSearchParams = withSearchParams;
