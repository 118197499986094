"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseModalCloseButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const icons_1 = require("@gerber/icons");
const i18n_1 = require("client/i18n");
const BaseModalCloseButtonHolder = (0, styled_components_1.styled)(commons_front_1.CleanButton) `
  ${commons_front_1.styles.flexCenter}

  border-radius: 100%;

  ${({ theme: { color, spacing } }) => (0, styled_components_1.css) `
    ${(0, commons_front_1.genPaletteColorCSS)(color.base.primary)}

    padding: ${spacing[2]};
  `}

  ${({ $corner, theme: { spacing } }) => $corner &&
    (0, styled_components_1.css) `
      position: absolute;
      top: ${spacing[3]};
      right: ${spacing[3]};
      z-index: 9;
    `}
`;
const BaseModalCloseButton = ({ onClick, }) => {
    const { pack } = (0, i18n_1.useI18n)();
    return ((0, jsx_runtime_1.jsx)(BaseModalCloseButtonHolder, { "$corner": true, title: pack.common.close, onClick: onClick, children: (0, jsx_runtime_1.jsx)(icons_1.TimesIcon, {}) }));
};
exports.BaseModalCloseButton = BaseModalCloseButton;
