"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInstantEffect = void 0;
const react_1 = require("react");
const commons_1 = require("@gerber/commons");
const useInstantEffect = (fn, deps) => {
    const [prevDeps, setPrevDeps] = (0, react_1.useState)(null);
    if (!(0, commons_1.shallowCompareArrays)(prevDeps, deps)) {
        setPrevDeps(deps);
        fn();
    }
};
exports.useInstantEffect = useInstantEffect;
