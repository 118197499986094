"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const NOTIFICATION_HEIGHT = 48;
exports.NotificationHolder = styled_components_1.styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  transform: translateX(-50%);
  transition: top 250ms ease-in-out ${commons_front_1.styles.timingFunction.smooth.in};
  font-weight: 600;

  ${({ $width = 600, $index = 0 }) => (0, styled_components_1.css) `
    position: fixed;
    top: ${(NOTIFICATION_HEIGHT + 15) * $index}px;
    left: calc(50% - ${Math.floor($width / 2)}px);
    width: ${$width}px;
    height: ${NOTIFICATION_HEIGHT};
  `}

  ${({ $out }) => commons_front_1.styles.animation.modal[$out ? 'out' : 'in']};

  ${({ theme: { color, spacing, shadows }, $kind = 'primary' }) => (0, styled_components_1.css) `
    ${(0, commons_front_1.genPaletteColorCSS)(color.base[$kind])}

    margin-top: ${spacing[4]};
    padding: ${spacing[3]} ${spacing[4]};
    box-shadow: ${shadows[3]};

    & > svg {
      margin-right: ${spacing[5]};
      width: 24px;
      height: 24px;
    }
  `}
`;
