"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingMobileScreenContentToolbar = void 0;
const styled_components_1 = require("styled-components");
exports.BookingMobileScreenContentToolbar = styled_components_1.styled.div `
  grid-area: toolbar;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
      padding: ${spacing[2]} ${spacing[5]} ${spacing[2]};
    `}
`;
