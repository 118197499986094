"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBookingConfirmTracker = void 0;
const function_1 = require("fp-ts/function");
const O = __importStar(require("fp-ts/Option"));
const TE = __importStar(require("fp-ts/TaskEither"));
const commons_1 = require("@gerber/commons");
const commons_front_1 = require("@gerber/commons-front");
const react_1 = require("@gerber/sdk-gerber/dist/third-party/react");
const context_1 = require("../booking-wizard/context");
const tracking_1 = require("client/tracking");
const useBookingConfirmTracker = (action) => {
    const gtm = (0, tracking_1.useGTM)();
    const config = (0, context_1.useBookingWizardConfigContext)();
    const { session } = (0, context_1.useSubscribeBookingWizardSessionContent)();
    const { sdk } = (0, react_1.useGerberSdk)();
    const fireSuccessGTMEcomEvent = (0, function_1.pipe)(session, O.matchW(() => {
        console.warn('Missing session! Unable to track purchase!');
        return TE.left(void 0);
    }, ({ sessionId }) => (0, function_1.pipe)(sdk.controllers.session.getLastBooking({ sessionId }), (0, commons_1.tapTaskEither)(lastBooking => {
        const items = lastBooking.items.map(bookingItem => ({
            item_id: bookingItem.roomCategoryId,
            item_name: (0, commons_1.findItemById)(bookingItem.roomCategoryId)(config.rooms.categories)?.name ?? 'unknown',
            price: bookingItem.price.amount,
            quantity: 1,
        }));
        const maybePackageGroupId = lastBooking.items.find(item => !!item.packageGroupId)
            ?.packageGroupId ?? null;
        gtm.trackGenericEvent({
            name: 'purchase',
            package_group_id: maybePackageGroupId,
            ecommerce: {
                currency: lastBooking.total.currency,
                value: lastBooking.total.amount,
                item_brand: sdk.client.config.oneId,
                tax: 0,
                shipping: 0,
                transaction_id: lastBooking.bookingId,
                items,
            },
        });
    }))));
    (0, commons_front_1.useAfterMount)(() => {
        if (action === 'success') {
            void fireSuccessGTMEcomEvent();
        }
    });
};
exports.useBookingConfirmTracker = useBookingConfirmTracker;
