"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBookingContactScreenForm = void 0;
const function_1 = require("fp-ts/function");
const forms_1 = require("@under-control/forms");
const commons_1 = require("@gerber/commons");
const i18n_1 = require("client/i18n");
const hooks_1 = require("client/hooks");
const ui_1 = require("client/ui");
const context_1 = require("client/modules/booking/booking-wizard/context");
const form_1 = require("client/modules/booking/booking-wizard/form");
const useBookingContactScreenForm = (attrs) => {
    const t = (0, i18n_1.useI18n)().pack.validation;
    const { validation } = (0, context_1.useBookingWizardConfigContext)();
    const { required, incorrectPathByPred } = (0, hooks_1.usePredefinedFormValidators)();
    const comparePersonAge = (cmp) => (arrivalDate) => (info) => {
        const parsedDate = (0, commons_1.parseUnsafeDate)(info?.birthDate);
        if (!parsedDate) {
            return false;
        }
        return (0, function_1.pipe)(parsedDate, (0, commons_1.getPersonAge)(arrivalDate), cmp);
    };
    const isAdultPerson = comparePersonAge(age => age >= validation.adultAge);
    const validateRooms = (rooms) => {
        const validateRoom = ({ who: { adults, children }, date }, roomIndex) => {
            const arrivalDay = date.offer.arrivalDay;
            return [
                ...adults.flatMap((adult, adultIndex) => {
                    if (isAdultPerson(arrivalDay)(adult.info)) {
                        return [];
                    }
                    return [
                        (0, forms_1.error)(t.incorrect, null, `rooms[${roomIndex}].who.adults[${adultIndex}].info.birthDate`),
                    ];
                }),
                ...children.flatMap((child, childIndex) => {
                    if (comparePersonAge(age => age === child.age)(arrivalDay)(child.info)) {
                        return [];
                    }
                    return [
                        (0, forms_1.error)(t.incorrect, null, `rooms[${roomIndex}].who.children[${childIndex}].info.birthDate`),
                    ];
                }),
            ];
        };
        return rooms.flatMap(validateRoom);
    };
    const form = (0, form_1.useBookingWizardAbstractScreenForm)({
        ...attrs,
        validation: {
            mode: ['submit'],
            validators: ({ path }) => [
                required('contact.firstName'),
                required('contact.lastName'),
                required('contact.gender'),
                required('contact.phone'),
                required('contact.email'),
                incorrectPathByPred('contact.email', ({ value }) => !(0, hooks_1.isValidEmail)(value)),
                required('contact.address.city'),
                required('contact.address.country'),
                required('contact.address.street'),
                path('rooms', ({ value }) => validateRooms(value)),
            ],
        },
    });
    const handleSubmitEvent = (e) => {
        e.preventDefault();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-floating-promises
        form.submit().catch(() => {
            setTimeout(ui_1.focusOnFirstInvalidHTMLInput, 200);
        });
    };
    return {
        ...form,
        handleSubmitEvent,
    };
};
exports.useBookingContactScreenForm = useBookingContactScreenForm;
