"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsShareableBookingWizardValue = void 0;
const react_1 = require("react");
const form_1 = require("client/modules/booking/booking-wizard/form");
const context_1 = require("client/modules/booking/booking-wizard/context");
const useIsShareableBookingWizardValue = () => {
    const { value } = (0, context_1.useBookingGlobalForm)();
    return (0, react_1.useMemo)(() => value.rooms.some(form_1.isOfferBookingSectionFilled), [value.rooms]);
};
exports.useIsShareableBookingWizardValue = useIsShareableBookingWizardValue;
