"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCookie = void 0;
const use_cookies_1 = require("./use-cookies");
const useCookie = (name) => {
    const cookies = (0, use_cookies_1.useCookies)();
    return {
        get: () => cookies.get(name),
        set: (value, attrs) => cookies.set(name, value, attrs),
    };
};
exports.useCookie = useCookie;
