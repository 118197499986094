"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingMobileStartToolbar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const booking_mobile_start_toolbar_styled_1 = require("./booking-mobile-start-toolbar.styled");
const parts_1 = require("client/modules/booking/booking-wizard/parts");
const ui_1 = require("client/ui");
exports.BookingMobileStartToolbar = (0, react_1.memo)(() => ((0, jsx_runtime_1.jsxs)(booking_mobile_start_toolbar_styled_1.BookingMobileStartToolbarHolder, { children: [(0, jsx_runtime_1.jsx)(parts_1.BookingMobileExitButton, {}), (0, jsx_runtime_1.jsx)(ui_1.AppChangeLangSelect, {})] })));
exports.BookingMobileStartToolbar.displayName = 'BookingMobileStartToolbar';
