"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationCloseButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const icons_1 = require("@gerber/icons");
const i18n_1 = require("client/i18n");
const NotificationCloseButtonHolder = (0, styled_components_1.styled)(commons_front_1.CleanButton) `
  margin-left: auto;

  & > svg {
    width: 24px;
    height: 24px;
  }
`;
const NotificationCloseButton = ({ onClick, }) => {
    const { pack } = (0, i18n_1.useI18n)();
    return ((0, jsx_runtime_1.jsx)(NotificationCloseButtonHolder, { type: "button", title: pack.common.close, "$focusBorder": false, onClick: onClick, children: (0, jsx_runtime_1.jsx)(icons_1.TimesIcon, {}) }));
};
exports.NotificationCloseButton = NotificationCloseButton;
