"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingCalendarTableContent = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const forms_1 = require("@under-control/forms");
const commons_front_1 = require("@gerber/commons-front");
const commons_1 = require("@gerber/commons");
const hooks_1 = require("./hooks");
const cell_1 = require("./cell");
const helpers_1 = require("./helpers");
const booking_calendar_table_styled_1 = require("./booking-calendar-table.styled");
const slider_1 = require("./slider");
exports.BookingCalendarTableContent = (0, forms_1.controlled)(({ disabledRanges, skeletonRange, selectionOffers, roomOffer, cells, preselectedRanges = [], control: { setValue, value }, }) => {
    const isMobile = (0, commons_front_1.useIsMobile)();
    const getCellConnectors = (0, hooks_1.useBookingCalendarCellConnectors)({
        range: value?.range,
        selectionOffers: selectionOffers?.list,
        roomOffer,
    });
    const onAsyncClick = (0, commons_front_1.useRefSafeCallback)((cell) => async () => {
        const offers = await (async () => {
            // selection of end date
            if (value?.range.begin) {
                return (selectionOffers?.list ??
                    (await selectionOffers?.fetchList?.(value.range.begin)));
            }
            // clicking first date
            return selectionOffers?.fetchList?.(cell.date);
        })();
        if (!offers) {
            return;
        }
        const range = (0, helpers_1.selectCellRange)(cell.date)(value);
        const newValue = (() => {
            if (!range) {
                return null;
            }
            const offer = offers.find(item => item.date && (0, commons_1.compareDateOnly)(item.date)(cell.date));
            return {
                range,
                offer,
            };
        })();
        setValue({
            value: newValue,
            merge: !!newValue,
        });
    });
    const onClear = (0, commons_front_1.useRefSafeCallback)(() => {
        setValue({
            value: null,
        });
    });
    const renderCell = (cell) => ((0, jsx_runtime_1.jsx)(cell_1.BookingCalendarCellContainer, { cell: cell, selection: value, showDayShortcut: !isMobile, skeletonRange: skeletonRange, selectionOffers: selectionOffers?.list, disabledRanges: disabledRanges, preselectedRanges: preselectedRanges, onClick: () => {
            void onAsyncClick(cell)();
        }, onClear: onClear, ...(value?.range && {
            connectors: getCellConnectors(cell),
        }) }, cell.date.toString()));
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(slider_1.BookingCalendarSliderPrePreloaded, { children: cells.prepend.map(renderCell) }), (0, jsx_runtime_1.jsx)(booking_calendar_table_styled_1.BookingCalendarTableHolder, { children: cells.visible.map(renderCell) }), (0, jsx_runtime_1.jsx)(slider_1.BookingCalendarSliderPostPreloaded, { children: cells.append.map(renderCell) })] }));
});
