"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBookingWizardGlobalForm = void 0;
const TE = __importStar(require("fp-ts/TaskEither"));
const NEA = __importStar(require("fp-ts/NonEmptyArray"));
const O = __importStar(require("fp-ts/Option"));
const function_1 = require("fp-ts/function");
const forms_1 = require("@under-control/forms");
const react_1 = require("@gerber/sdk-gerber/dist/third-party/react");
const commons_1 = require("@gerber/commons");
const sdk_gerber_1 = require("@gerber/sdk-gerber");
const helpers_1 = require("../helpers");
const booking_form_exception_1 = require("../booking-form.exception");
const context_1 = require("../../context");
const payment_1 = require("../payment");
const side_effects_1 = require("../side-effects");
const use_booking_track_anonymized_session_1 = require("./use-booking-track-anonymized-session");
const ui_1 = require("client/ui");
const hooks_1 = require("client/hooks");
const useBookingWizardGlobalForm = ({ defaultValue, }) => {
    const { sdk } = (0, react_1.useGerberSdk)();
    const sitemap = (0, hooks_1.useSitemap)();
    const sessionCtx = (0, context_1.useBookingWizardSessionContextOrThrow)();
    const trackAnonymizedSession = (0, use_booking_track_anonymized_session_1.useBookingTrackAnonymizedSession)();
    const showErrorNotificationIfFail = (0, ui_1.useErrorTaskEitherNotification)();
    const handleBookPayment = (0, payment_1.useBookingWizardHandleBookPayment)();
    const redirectUrls = {
        successUrl: sitemap.wizard.payment.success({
            withOrigin: true,
        }),
        cancelUrl: sitemap.wizard.payment.cancel({
            withOrigin: true,
        }),
        errorUrl: sitemap.wizard.payment.error({
            withOrigin: true,
        }),
    };
    const incorrectTotalPrice = () => TE.left(NEA.of(new booking_form_exception_1.BookingFormException('checkout-validation-failed', 'Checkout validation failed! Incorrect total price!')));
    const validateSession = (totalPrice) => (sessionId) => (0, function_1.pipe)(sdk.controllers.checkout.validateBooking({
        sessionId,
        totalPrice,
    }), TE.chainW(({ errors }) => errors.length ? incorrectTotalPrice() : TE.of(void 0)));
    const book = (totalPrice) => (sessionId) => (0, function_1.pipe)(sdk.controllers.checkout.book({
        ...redirectUrls,
        sessionId,
        total: totalPrice,
    }), (0, commons_1.tapTaskEitherTE)(handleBookPayment));
    const onSubmit = async (value) => (0, function_1.pipe)(TE.Do, TE.bind('sessionId', () => (0, function_1.pipe)(sessionCtx.getSnapshot().session, O.map(item => item.sessionId), TE.fromOption(() => NEA.of(new sdk_gerber_1.SdkException('sdk-request-failed', 'Cannot create submit request due to empty session!'))))), TE.bindW('totalPrice', () => {
        const totalPrice = (0, helpers_1.getBookingTotalPrice)(value);
        if (!totalPrice) {
            return incorrectTotalPrice();
        }
        return TE.of(totalPrice);
    }), TE.bindW('errors', ({ sessionId, totalPrice }) => validateSession(totalPrice)(sessionId)), (0, commons_1.tapTaskEitherTE)(() => trackAnonymizedSession(value)), TE.chainW(({ sessionId, totalPrice }) => book(totalPrice)(sessionId)), showErrorNotificationIfFail, commons_1.toVoidTE, commons_1.tryOrThrowTE, commons_1.runTask);
    const form = (0, forms_1.useForm)({
        resetAfterSubmit: false,
        defaultValue: defaultValue ?? (0, helpers_1.createBlankGlobalFormValue)(),
        onSubmit,
    });
    (0, side_effects_1.useBookingWizardGlobalFormSideEffects)(form);
    return form;
};
exports.useBookingWizardGlobalForm = useBookingWizardGlobalForm;
