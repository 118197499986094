"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingEndingHeader = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const components_1 = require("client/components");
exports.BookingEndingHeader = (0, styled_components_1.styled)(components_1.H1).attrs({ color: 'primary' }) `
  ${commons_front_1.styles.mediaSizeUp.xs} {
    font-size: 2.75rem;
  }
`;
