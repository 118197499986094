"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWobblyGalleryItemContextOrNil = exports.useWobblyGalleryItemContext = exports.WobblyGalleryItemContext = void 0;
const react_1 = require("react");
const commons_front_1 = require("@gerber/commons-front");
exports.WobblyGalleryItemContext = (0, react_1.createContext)(null);
const useWobblyGalleryItemContext = () => (0, commons_front_1.useContextOrThrow)(exports.WobblyGalleryItemContext, 'Missing wobbly item context in tree!');
exports.useWobblyGalleryItemContext = useWobblyGalleryItemContext;
const useWobblyGalleryItemContextOrNil = () => (0, react_1.useContext)(exports.WobblyGalleryItemContext);
exports.useWobblyGalleryItemContextOrNil = useWobblyGalleryItemContextOrNil;
