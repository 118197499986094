"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StartScreenFormContent = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
/* eslint-disable react-hooks/rules-of-hooks */
const react_1 = require("react");
const forms_1 = require("@under-control/forms");
const icons_1 = require("@gerber/icons");
const commons_1 = require("@gerber/commons");
const controls_1 = require("../controls");
const notifications_1 = require("./notifications");
const i18n_1 = require("client/i18n");
const context_1 = require("client/modules/booking/booking-wizard/context");
const components_1 = require("client/components");
const ui_1 = require("client/ui");
exports.StartScreenFormContent = (0, react_1.memo)((0, forms_1.controlled)(({ control: { bind }, errors }) => {
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.start;
    const config = (0, context_1.useBookingWizardConfigContext)();
    const validation = (0, forms_1.useFormValidatorMessages)({ errors });
    const roomIndex = (0, context_1.useBookingGlobalForm)().value.meta.focusedRoomIndex;
    const kidAgeRange = (0, react_1.useMemo)(() => ({ begin: 0, end: config.validation.adultAge - 1 }), [config]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [roomIndex ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(notifications_1.StartScreenSelectAnotherRoomNotification, {}), (0, jsx_runtime_1.jsx)(components_1.Divider, { "$space": 7, style: { marginTop: 0 } })] })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [t.hint, (0, jsx_runtime_1.jsx)(components_1.Divider, { "$space": 7 })] })), (0, jsx_runtime_1.jsx)(ui_1.Table, { children: (0, jsx_runtime_1.jsxs)(ui_1.TableBody, { children: [(0, jsx_runtime_1.jsxs)(ui_1.TableRow, { "$noHorizontalPadding": true, children: [(0, jsx_runtime_1.jsx)(ui_1.TopAlignTableColumn, { width: 1, children: (0, jsx_runtime_1.jsx)(components_1.Text, { as: "div", transform: "uppercase", align: "right", children: t.form.adults.label }) }), (0, jsx_runtime_1.jsx)("td", { children: (0, jsx_runtime_1.jsx)(ui_1.SelectAmount, { ...bind.path('adults'), ...validation.extract('adults', { nested: true }), max: config.validation.maxAdults, required: true, toolbar: (0, jsx_runtime_1.jsx)(components_1.LabeledIcon, { color: "muted", icon: (0, jsx_runtime_1.jsx)(icons_1.InfoIcon, {}), children: (0, commons_1.format)(t.form.adults.tooltip, {
                                                age: config.validation.adultAge,
                                            }) }) }) })] }), (0, jsx_runtime_1.jsxs)(ui_1.TableRow, { "$noHorizontalPadding": true, children: [(0, jsx_runtime_1.jsx)(ui_1.TopAlignTableColumn, { width: 1, children: (0, jsx_runtime_1.jsx)(components_1.Text, { as: "div", transform: "uppercase", align: "right", children: t.form.children.label }) }), (0, jsx_runtime_1.jsx)("td", { children: (0, jsx_runtime_1.jsx)(controls_1.SelectPeopleAmountWithAge, { max: config.validation.maxKids, ageRange: kidAgeRange, ...bind.path('children'), ...validation.extract('children', {
                                            nested: true,
                                        }), toolbarInNewLine: true, toolbar: (0, jsx_runtime_1.jsx)(components_1.LabeledIcon, { color: "muted", icon: (0, jsx_runtime_1.jsx)(icons_1.InfoIcon, {}), children: t.form.children.tooltip }) }) })] }), validation.all.length > 0 && ((0, jsx_runtime_1.jsxs)(ui_1.TableRow, { children: [(0, jsx_runtime_1.jsx)("td", {}), (0, jsx_runtime_1.jsx)("td", { children: (0, jsx_runtime_1.jsx)(ui_1.FormErrors, { errors: (0, forms_1.flattenMessagesList)(validation.all) }) })] }))] }) }), (0, jsx_runtime_1.jsx)(components_1.Divider, { "$space": 7 })] }));
}));
exports.StartScreenFormContent.displayName = 'StartScreenFormContent';
