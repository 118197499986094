"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbFooterColumnTitle = exports.SbFooterColumnHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const config = {
    shouldForwardProp: (0, commons_front_1.shouldNotForwardProps)(['area']),
};
exports.SbFooterColumnHolder = styled_components_1.styled.div.withConfig(config) `
  display: flex;
  flex-direction: column;

  ${({ area }) => (0, styled_components_1.css) `
    grid-area: ${area};
  `}
`;
exports.SbFooterColumnTitle = styled_components_1.styled.div `
  ${({ theme: { font } }) => (0, styled_components_1.css) `
    font-size: ${font.size['lg-xl']};
    font-weight: 600;
  `}
`;
