"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LongArrowRightThinIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
/* eslint-disable max-len */
const commons_front_1 = require("@gerber/commons-front");
const LongArrowRightThinIcon = ({ style, direction = 'right', ...props }) => ((0, jsx_runtime_1.jsx)("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "17", fill: "none", viewBox: "0 0 16 17", style: {
        ...style,
        transform: (0, commons_front_1.getDirectionTransform)(0)(direction),
    }, ...props, children: (0, jsx_runtime_1.jsx)("path", { fill: "currentColor", d: "M7.646 16.354a.5.5 0 00.708 0l3.182-3.182a.5.5 0 10-.708-.708L8 15.293l-2.828-2.829a.5.5 0 10-.708.708l3.182 3.182zM8.5 16V0h-1v16h1z" }) }));
exports.LongArrowRightThinIcon = LongArrowRightThinIcon;
