"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appendHeadersToPayload = void 0;
const appendHeadersToPayload = (headers) => (payload) => ({
    ...payload,
    headers: {
        ...payload.headers,
        ...headers,
    },
});
exports.appendHeadersToPayload = appendHeadersToPayload;
