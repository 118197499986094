"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbDescriptionContent = void 0;
const styled_components_1 = require("styled-components");
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
exports.SbDescriptionContent = (0, styled_components_1.styled)(react_1.SdkSbComponentsListRenderer).attrs({
    wrapped: true,
}) `
  grid-area: content;
`;
