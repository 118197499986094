"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGerberSdkOrNil = exports.useGerberSdk = void 0;
const react_1 = require("react");
const commons_front_1 = require("@gerber/commons-front");
const context_1 = require("../context");
const useGerberSdk = () => (0, commons_front_1.useContextOrThrow)(context_1.SdkGerberContext, 'Missing gerber sdk context in tree!');
exports.useGerberSdk = useGerberSdk;
const useGerberSdkOrNil = () => (0, react_1.useContext)(context_1.SdkGerberContext);
exports.useGerberSdkOrNil = useGerberSdkOrNil;
