"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopScreenLongContent = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const parts_1 = require("client/modules/booking/booking-wizard/parts");
const BookingDesktopScreenLongContent = ({ children, footer, ...props }) => {
    const [loadingFooter, isWideFooter] = (0, commons_front_1.useWatchScreenSizeFlag)(width => width >= 1800);
    const shadowFooter = ((0, jsx_runtime_1.jsx)(parts_1.BookingDesktopFooterShadow, { wide: isWideFooter, children: footer }));
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(parts_1.BookingDesktopScreenContent, { ...props, ...(!loadingFooter && !isWideFooter && { footer: shadowFooter }), children: children }), !loadingFooter && isWideFooter && shadowFooter] }));
};
exports.BookingDesktopScreenLongContent = BookingDesktopScreenLongContent;
