"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormGroup = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const form_label_1 = require("./form-label");
const styles_1 = require("./styles");
const FormGroup = ({ className, required, label, labelProps, error, hint, children, footer, contentProps, space = 6, row = false, span, limitWidth, autoContentWidth, ...props }) => {
    let clonedChildren = (0, react_1.isValidElement)(children) && react_1.Children.count(children) === 1 && required
        ? (0, react_1.cloneElement)(children, { required: true })
        : children;
    if (autoContentWidth) {
        clonedChildren = (0, jsx_runtime_1.jsx)("div", { children: clonedChildren });
    }
    return ((0, jsx_runtime_1.jsxs)(styles_1.FormGroupHolder, { className: className, row: row, space: space, span: span, limitWidth: limitWidth, ...props, children: [typeof label === 'string' ? ((0, jsx_runtime_1.jsx)(form_label_1.FormLabel, { "$noSpace": row, required: required, ...labelProps, children: label })) : (label), (0, jsx_runtime_1.jsx)(styles_1.FormGroupContent, { ...contentProps, children: clonedChildren }), hint && (0, jsx_runtime_1.jsx)(styles_1.FormTooltip, { color: "muted", children: hint }), error && (0, jsx_runtime_1.jsx)(styles_1.FormTooltip, { color: "error", children: error }), footer] }));
};
exports.FormGroup = FormGroup;
