"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsInitialHTTPClientHistoryRoute = exports.useHTTPClientHistory = exports.StoreHttpClientHistory = void 0;
const commons_1 = require("@gerber/commons");
const wouter_1 = require("wouter");
const HTTP_PATH_CLIENT_HISTORY = new Set();
const StoreHttpClientHistory = () => {
    const [location] = (0, wouter_1.useLocation)();
    if (!(0, commons_1.isSSR)()) {
        HTTP_PATH_CLIENT_HISTORY.add(location);
    }
    return null;
};
exports.StoreHttpClientHistory = StoreHttpClientHistory;
const useHTTPClientHistory = () => HTTP_PATH_CLIENT_HISTORY;
exports.useHTTPClientHistory = useHTTPClientHistory;
const useIsInitialHTTPClientHistoryRoute = () => HTTP_PATH_CLIENT_HISTORY.size === 1;
exports.useIsInitialHTTPClientHistoryRoute = useIsInitialHTTPClientHistoryRoute;
