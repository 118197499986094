"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingMobileStartToolbarHolder = void 0;
const styled_components_1 = require("styled-components");
exports.BookingMobileStartToolbarHolder = styled_components_1.styled.div `
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  grid-area: toolbar;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    padding: ${spacing[4]};
  `}
`;
