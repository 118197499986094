"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackageGroupSummary = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_1 = require("@gerber/icons");
const cover_1 = require("./cover");
const package_group_summary_styled_1 = require("./package-group-summary.styled");
const components_1 = require("client/components");
const ui_1 = require("client/ui");
const i18n_1 = require("client/i18n");
const modals_1 = require("client/modules/booking/booking-wizard/modals");
const PackageGroupSummary = ({ packageGroup, showCover, showTeaser = true, }) => {
    const { pack } = (0, i18n_1.useI18n)();
    const { teaser, mediums } = packageGroup;
    const showDetailsModal = (0, modals_1.useBookingPackageGroupDetailsModal)();
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [showCover ? (0, jsx_runtime_1.jsx)(cover_1.PackageGroupCover, { mediums: mediums }) : (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 5 }), (0, jsx_runtime_1.jsxs)(components_1.LabeledIcon, { display: "flex", weight: 700, size: "base", icon: (0, jsx_runtime_1.jsx)(icons_1.CircleHeartIcon, {}), children: [packageGroup.name, !showCover && ((0, jsx_runtime_1.jsx)(package_group_summary_styled_1.PackageGroupSummaryTitleBadge, { children: pack.common.packageGroup }))] }), showTeaser && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 3 }), (0, jsx_runtime_1.jsx)(components_1.Text, { size: "sm", children: teaser })] })), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 4 }), (0, jsx_runtime_1.jsx)(components_1.Text, { size: "sm", weight: showTeaser ? 600 : 500, children: (0, jsx_runtime_1.jsx)(ui_1.MoreInformationCleanButton, { onClick: () => void showDetailsModal.show({ packageGroup }) }) })] }));
};
exports.PackageGroupSummary = PackageGroupSummary;
