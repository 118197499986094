"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useShowBookingSummary = void 0;
const context_1 = require("client/modules/booking/booking-wizard/context");
const form_1 = require("client/modules/booking/booking-wizard/form");
const useShowBookingSummary = () => {
    const { value } = (0, context_1.useBookingGlobalForm)();
    return ((0, form_1.isWhoBookingSectionFilled)(value.rooms[0]) ||
        value.rooms.some(form_1.isPackageGroupBookingSectionFilled));
};
exports.useShowBookingSummary = useShowBookingSummary;
