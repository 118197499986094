"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingCalendarSkeleton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const commons_front_1 = require("@gerber/commons-front");
const desktop_1 = require("./desktop");
const mobile_1 = require("./mobile");
exports.BookingCalendarSkeleton = (0, react_1.memo)(props => {
    const isMobile = (0, commons_front_1.useIsMobile)();
    if (isMobile) {
        return (0, jsx_runtime_1.jsx)(mobile_1.BookingMobileCalendarSkeleton, { ...props });
    }
    return (0, jsx_runtime_1.jsx)(desktop_1.BookingDesktopCalendarSkeleton, { ...props });
});
exports.BookingCalendarSkeleton.displayName = 'BookingCalendarSkeleton';
