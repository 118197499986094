"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSubscribeBookingWizardSidebarContent = void 0;
const react_1 = require("react");
const use_booking_wizard_sidebar_context_or_throw_1 = require("./use-booking-wizard-sidebar-context-or-throw");
const useSubscribeBookingWizardSidebarContent = () => {
    const store = (0, use_booking_wizard_sidebar_context_or_throw_1.useBookingWizardSidebarContextOrThrow)();
    return (0, react_1.useSyncExternalStore)(store.subscribe, store.getSnapshot, store.getSnapshot);
};
exports.useSubscribeBookingWizardSidebarContent = useSubscribeBookingWizardSidebarContent;
