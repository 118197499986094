"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createGerberSdkAutocomplete = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const function_1 = require("fp-ts/function");
const inputs_1 = require("@under-control/inputs");
const commons_1 = require("@gerber/commons");
const react_1 = require("@gerber/sdk-gerber/dist/third-party/react");
const async_select_input_1 = require("../../../select-input/async-select-input");
const createGerberSdkAutocomplete = ({ fetchFn, }) => (0, inputs_1.controlled)(({ control, filters, ...props }) => {
    const { sdk } = (0, react_1.useGerberSdk)();
    const onFetchItems = async (phrase) => (0, function_1.pipe)(fetchFn({ sdk, phrase, filters }), commons_1.tryOrThrowTE, commons_1.runTask);
    return ((0, jsx_runtime_1.jsx)(async_select_input_1.AsyncSelectInput, { ...props, ...control.bind.entire(), fetchItemsKeys: [JSON.stringify(filters)], onFetchItems: onFetchItems }));
});
exports.createGerberSdkAutocomplete = createGerberSdkAutocomplete;
