"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokSectionBlockV = exports.SdkStoryblokSectionColorV = void 0;
const zod_1 = require("zod");
const components_1 = require("../components");
const sections_1 = require("../sections");
const sdk_storyblok_rwd_attr_dto_1 = require("../../sdk-storyblok-rwd-attr.dto");
const data_source_1 = require("../../data-source");
const sdk_storyblok_block_dto_1 = require("../../sdk-storyblok-block.dto");
const sdk_storyblok_grid_block_1 = require("./sdk-storyblok-grid.block");
const SdkStoryblokSectionContentV = zod_1.z
    .union([
    sdk_storyblok_grid_block_1.SdkStoryblokGridBlockV,
    sections_1.SdkStoryblokBaseSectionBlockV,
    components_1.SdkStoryblokBaseBlockV,
])
    .array();
exports.SdkStoryblokSectionColorV = zod_1.z.enum(['none', 'muted']);
exports.SdkStoryblokSectionBlockV = zod_1.z.intersection((0, sdk_storyblok_block_dto_1.SdkStoryblokBlockV)('Layout_Section', {
    id: zod_1.z.string().optional(),
    color: exports.SdkStoryblokSectionColorV,
    content: SdkStoryblokSectionContentV,
    animated: zod_1.z.boolean().optional().default(true),
    no_top_space: zod_1.z.boolean().optional(),
    no_bottom_space: zod_1.z.boolean().optional(),
}), (0, sdk_storyblok_rwd_attr_dto_1.SdkStoryblokRwdAttrSM)('space')(data_source_1.SdkStoryblokSpacingV));
