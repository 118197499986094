"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LandingPageRoute = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const client_1 = require("@gerber/ssr/dist/client");
const error_1 = require("../error");
const hooks_1 = require("client/hooks");
const modules_1 = require("client/modules");
const i18n_1 = require("client/i18n");
const LandingPageRoute = () => {
    const [, params] = (0, hooks_1.useAppPrefixedRoute)('/:slug*');
    const http = (0, client_1.useHttpAction)();
    const urlLang = (0, i18n_1.useI18nUrlLang)();
    if (!params?.slug) {
        http.statusCode(404);
        return (0, jsx_runtime_1.jsx)(error_1.NotFoundRoute, {});
    }
    return ((0, jsx_runtime_1.jsxs)(i18n_1.I18nProvider, { lang: urlLang, children: [(0, jsx_runtime_1.jsx)(commons_front_1.NoViewportScaleMeta, {}), (0, jsx_runtime_1.jsx)(modules_1.LandingPageContainer, { slug: params.slug })] }));
};
exports.LandingPageRoute = LandingPageRoute;
