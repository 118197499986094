"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingWizardConfigV = exports.BookingPaymentMethodV = void 0;
const zod_1 = require("zod");
const commons_1 = require("@gerber/commons");
const sdk_gerber_1 = require("@gerber/sdk-gerber");
exports.BookingPaymentMethodV = zod_1.z.object({
    name: zod_1.z.string(),
    icon: commons_1.PictureV,
});
exports.BookingWizardConfigV = zod_1.z.object({
    hotel: zod_1.z.object({
        oneId: zod_1.z.string(),
        name: zod_1.z.string(),
        email: zod_1.z.string().nullable().optional(),
        address: zod_1.z.string().nullable().optional(),
        phone: zod_1.z.string(),
        contactUrl: zod_1.z.string(),
        websiteUrl: zod_1.z.string(),
        termsUrl: zod_1.z.string(),
        gdprUrl: zod_1.z.string(),
        cancellationUrl: zod_1.z.string(),
    }),
    start: zod_1.z.object({
        logo: commons_1.PictureV,
        background: commons_1.PictureV,
    }),
    date: zod_1.z.object({
        salesInfo: zod_1.z.array(sdk_gerber_1.SdkPageSalesInfoV),
    }),
    tracking: zod_1.z.object({
        gtmId: zod_1.z.string().optional().nullable(),
    }),
    validation: zod_1.z.object({
        adultAge: zod_1.z.number(),
        maxAdults: zod_1.z.number(),
        maxKids: zod_1.z.number(),
    }),
    payment: zod_1.z.object({
        methods: zod_1.z.array(exports.BookingPaymentMethodV),
    }),
    packages: zod_1.z.object({
        categories: zod_1.z.array(sdk_gerber_1.SdkConfigPackageCategoryV),
    }),
    rooms: zod_1.z.object({
        categories: zod_1.z.array(sdk_gerber_1.SdkConfigRoomCategoryV),
        features: zod_1.z.array(sdk_gerber_1.SdkConfigRoomCategoryFeatureV),
    }),
    rates: zod_1.z.array(sdk_gerber_1.SdkConfigRateV),
    countries: zod_1.z.array(sdk_gerber_1.SdkBookingCountryV),
});
