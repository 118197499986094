"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CleanButton = exports.cleanButtonStyle = void 0;
const styled_components_1 = require("styled-components");
const focus_highlight_style_1 = require("../styles/focus-highlight.style");
exports.cleanButtonStyle = (0, styled_components_1.css) `
  all: unset;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  &[disabled] {
    cursor: initial;
  }
`;
exports.CleanButton = styled_components_1.styled.button `
  ${exports.cleanButtonStyle}

  ${({ $focusBorder = true }) => $focusBorder &&
    (0, styled_components_1.css) `
      &:focus {
        ${(0, focus_highlight_style_1.focusHighlight)()}
      }
    `}

  ${({ $underline }) => $underline &&
    (0, styled_components_1.css) `
      text-decoration: underline;
    `}

  ${({ $center }) => $center &&
    (0, styled_components_1.css) `
      margin: 0 auto;
    `}
`;
