"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackageGroupCoverBadge = exports.PackageGroupCoverPicture = exports.PackageGroupCoverWrapper = void 0;
const styled_components_1 = require("styled-components");
const package_group_badge_styled_1 = require("../package-group-badge.styled");
const medium_picture_1 = require("client/modules/booking/shared/medium-picture");
exports.PackageGroupCoverWrapper = styled_components_1.styled.div `
  position: relative;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    margin: ${spacing[3]} -${spacing[4]} ${spacing[4]};
    width: calc(100% + 2 * ${spacing[4]});
  `}
`;
exports.PackageGroupCoverPicture = (0, styled_components_1.styled)(medium_picture_1.MediumPicture) `
  width: 100%;
  aspect-ratio: 2;
  object-fit: cover;
`;
exports.PackageGroupCoverBadge = (0, styled_components_1.styled)(package_group_badge_styled_1.PackageGroupBadge) `
  position: absolute;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    top: ${spacing[3]};
    left: ${spacing[3]};
  `}
`;
