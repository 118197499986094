"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopSidebarToggleButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_1 = require("@gerber/icons");
const booking_desktop_sidebar_toggle_button_styled_1 = require("./booking-desktop-sidebar-toggle-button.styled");
const BookingDesktopSidebarToggleButton = ({ toggled, ...props }) => ((0, jsx_runtime_1.jsx)(booking_desktop_sidebar_toggle_button_styled_1.BookingDesktopSidebarToggleButtonHolder, { ...props, type: "button", "$hoverable": false, "$kind": toggled ? 'outline' : 'primary', children: (0, jsx_runtime_1.jsx)(icons_1.CollapseSidebarIcon, { ...(!toggled && {
            style: {
                transform: 'rotate(90deg)',
            },
        }) }) }));
exports.BookingDesktopSidebarToggleButton = BookingDesktopSidebarToggleButton;
