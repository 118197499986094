"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseModalContent = void 0;
const styled_components_1 = require("styled-components");
exports.BaseModalContent = styled_components_1.styled.div `
  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    padding: ${spacing[4]} 0;

    &:last-child {
      padding-bottom: 0;
    }
  `}
`;
