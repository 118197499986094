"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkSessionLastBookingV = exports.SdkSessionLastBookingItemV = void 0;
const zod_1 = require("zod");
const dto_1 = require("../../../dto");
exports.SdkSessionLastBookingItemV = zod_1.z.object({
    price: dto_1.SdkPriceV,
    ratePlanId: zod_1.z.number(),
    roomCategoryId: zod_1.z.number(),
    packageGroupId: zod_1.z.number().optional().nullable(),
});
exports.SdkSessionLastBookingV = zod_1.z.object({
    bookingId: zod_1.z.string(),
    items: zod_1.z.array(exports.SdkSessionLastBookingItemV),
    total: dto_1.SdkPriceV,
});
