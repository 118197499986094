"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkGerberClientConfigV = void 0;
const zod_1 = require("zod");
const sdk_gerber_language_dto_1 = require("./sdk-gerber-language.dto");
exports.SdkGerberClientConfigV = zod_1.z.object({
    apiUrl: zod_1.z.string(),
    oneId: zod_1.z.string(),
    lang: sdk_gerber_language_dto_1.SdkGerberLanguageV.optional(),
});
