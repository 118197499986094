"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.concatUrls = void 0;
const drop_first_slash_1 = require("./drop-first-slash");
const drop_last_slash_1 = require("./drop-last-slash");
const is_absolute_url_1 = require("./is-absolute-url");
const concatUrls = (a, b) => {
    if (!a && b) {
        return b;
    }
    if (!b || b === '/') {
        return a;
    }
    if (b.startsWith('//')) {
        b = `https:${b}`;
    }
    if (!a || (0, is_absolute_url_1.isAbsoluteUrl)(b)) {
        return b;
    }
    const truncatedLeft = (0, drop_last_slash_1.dropLastSlash)(a);
    const truncatedRight = (0, drop_first_slash_1.dropFirstSlash)(b);
    if (truncatedRight[0] === '?') {
        return `${truncatedLeft}${truncatedRight}`;
    }
    return [truncatedLeft, truncatedRight].join('/');
};
exports.concatUrls = concatUrls;
