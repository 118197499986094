"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HotelContactRow = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const context_1 = require("../../../context");
const hotel_contact_row_styled_1 = require("./hotel-contact-row.styled");
const components_1 = require("client/components");
exports.HotelContactRow = (0, react_1.memo)(() => {
    const { phone, email, address } = (0, context_1.useBookingWizardConfigContext)().hotel;
    return ((0, jsx_runtime_1.jsxs)(hotel_contact_row_styled_1.HotelContactRowHolder, { children: [(0, jsx_runtime_1.jsx)(components_1.UniversalLink, { href: `tel:${phone}`, decoration: "underline", color: "inherit", weight: 700, children: phone }), (0, jsx_runtime_1.jsx)(components_1.UniversalLink, { href: `mailto:${email}`, color: "inherit", decoration: "underline", children: email }), (0, jsx_runtime_1.jsx)(components_1.Text, { children: address })] }));
});
exports.HotelContactRow.displayName = 'HotelContactRow';
