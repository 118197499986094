"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingMobileCalendarToolbarHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BookingMobileCalendarToolbarHolder = (0, styled_components_1.styled)(commons_front_1.Centered) `
  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    padding: ${spacing[8]} ${spacing[6]} ${spacing[0]};
  `}
`;
