"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopScreenContent = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const booking_desktop_screen_content_styled_1 = require("./booking-desktop-screen-content.styled");
const header_1 = require("./header");
const components_1 = require("client/components");
const BookingDesktopScreenContent = ({ prepend, header, footer, headerProps, children, ...props }) => ((0, jsx_runtime_1.jsx)(booking_desktop_screen_content_styled_1.BookingDesktopScreenContentHolder, { ...props, children: (0, jsx_runtime_1.jsxs)(commons_front_1.Container, { children: [prepend, header && ((0, jsx_runtime_1.jsx)(header_1.BookingDesktopScreenHeader, { ...headerProps, children: header })), children, footer && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 8 }), footer] }))] }) }));
exports.BookingDesktopScreenContent = BookingDesktopScreenContent;
