"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingCalendarCellSubtitle = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BookingCalendarCellSubtitle = styled_components_1.styled.span `
  position: absolute;
  top: 9px;
  width: 100%;

  ${({ theme: { font } }) => (0, styled_components_1.css) `
    font-size: 0.6rem;

    ${commons_front_1.styles.mediaSizeUp.md} {
      font-size: ${font.size.xs};
    }
  `}
`;
