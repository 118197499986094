"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfoCardHeader = void 0;
const styled_components_1 = require("styled-components");
exports.InfoCardHeader = styled_components_1.styled.div.withConfig({
    shouldForwardProp: prop => !['bold'].includes(prop),
}) `
  ${({ theme: { font } }) => (0, styled_components_1.css) `
    font-size: ${font.size.lg};
  `}

  ${({ bold = true }) => bold &&
    (0, styled_components_1.css) `
      font-weight: 600;
    `}
`;
