"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDirectionTransform = void 0;
const DIRECTION_DEGREE = {
    left: 90,
    top: 180,
    right: 270,
    bottom: 0,
};
const getDirectionTransform = (startAngle) => (direction) => `rotate(${startAngle + DIRECTION_DEGREE[direction]}deg)`;
exports.getDirectionTransform = getDirectionTransform;
