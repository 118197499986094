"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopSidebarExpandedHeader = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const booking_desktop_sidebar_expanded_header_styled_1 = require("./booking-desktop-sidebar-expanded-header.styled");
const booking_desktop_sidebar_expanded_logo_styled_1 = require("./booking-desktop-sidebar-expanded-logo.styled");
const toolbar_1 = require("./toolbar");
const context_1 = require("client/modules/booking/booking-wizard/context");
const ui_1 = require("client/ui");
const components_1 = require("client/components");
const BookingDesktopSidebarExpandedHeader = () => {
    const config = (0, context_1.useBookingWizardConfigContext)();
    return ((0, jsx_runtime_1.jsxs)(booking_desktop_sidebar_expanded_header_styled_1.BookingDesktopSidebarExpandedHeaderHolder, { children: [(0, jsx_runtime_1.jsx)(commons_front_1.GridArea, { "$name": "logo", children: (0, jsx_runtime_1.jsx)(components_1.UniversalLink, { href: config.hotel.websiteUrl, target: "_blank", ...(0, commons_front_1.testid)('sidebar:website-logo'), children: (0, jsx_runtime_1.jsx)(booking_desktop_sidebar_expanded_logo_styled_1.BookingDesktopSidebarExpandedLogo, { src: config.start.logo }) }) }), (0, jsx_runtime_1.jsx)(commons_front_1.GridArea, { "$name": "toolbar", "$placeSelf": "flex-start end", children: (0, jsx_runtime_1.jsx)(ui_1.AppChangeLangSelect, { fillIfValueIsPresent: false }) }), (0, jsx_runtime_1.jsx)(commons_front_1.GridArea, { "$name": "title", children: (0, jsx_runtime_1.jsx)(components_1.H2, { children: config.hotel.name }) }), (0, jsx_runtime_1.jsx)(commons_front_1.GridArea, { "$name": "footer", children: (0, jsx_runtime_1.jsx)(toolbar_1.BookingSidebarHeaderToolbar, {}) })] }));
};
exports.BookingDesktopSidebarExpandedHeader = BookingDesktopSidebarExpandedHeader;
