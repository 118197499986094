"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckSquareButton = exports.SQUARE_SQUARE_SIZE = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const icons_1 = require("@gerber/icons");
const checkbox_base_button_1 = require("./checkbox-base-button");
exports.SQUARE_SQUARE_SIZE = '24px';
const CheckSquareButtonHolder = (0, styled_components_1.styled)(checkbox_base_button_1.CheckboxBaseButton) `
  ${commons_front_1.styles.flexCenter}

  width: ${exports.SQUARE_SQUARE_SIZE};
  height: ${exports.SQUARE_SQUARE_SIZE};
  transition: 150ms ease-in;
  transition-property: background, color;

  ${({ theme: { color } }) => (0, styled_components_1.css) `
    border: 1px solid ${color.parts.checkbox.main};
  `}

  ${({ value, theme: { color } }) => value &&
    (0, styled_components_1.css) `
      background: ${color.parts.checkbox.main};
      color: ${color.alias.white.main};
    `}

  ${({ $invalid, theme }) => $invalid &&
    (0, styled_components_1.css) `
      border: 1px solid ${theme.color.base.error.main};
    `}

  ${({ $disabled }) => $disabled && commons_front_1.styles.disabledInputInner}

  svg {
    width: 60%;
  }
`;
const CheckSquareButton = ({ value, ...props }) => ((0, jsx_runtime_1.jsx)(CheckSquareButtonHolder, { value: value, ...props, children: value && (0, jsx_runtime_1.jsx)(icons_1.AcceptCheckIcon, {}) }));
exports.CheckSquareButton = CheckSquareButton;
