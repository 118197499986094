"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckboxBaseButton = void 0;
const styled_components_1 = require("styled-components");
exports.CheckboxBaseButton = styled_components_1.styled.span `
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  grid-area: checkbox;
  align-self: center;
  flex-shrink: 0;

  ${({ $alignTop }) => $alignTop &&
    (0, styled_components_1.css) `
      align-self: flex-start;
      margin-top: 5px;
    `}
`;
