"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBookingDateScreenForm = void 0;
const react_1 = require("react");
const helpers_1 = require("../helpers");
const context_1 = require("client/modules/booking/booking-wizard/context");
const booking_calendar_1 = require("client/modules/booking/booking-calendar");
const form_1 = require("client/modules/booking/booking-wizard/form");
const useBookingDateScreenForm = ({ control, }) => {
    const config = (0, context_1.useBookingWizardConfigContext)();
    const { value, ...attrs } = (0, form_1.useBookingWizardRoomScreenForm)({
        control,
        updateParentStateOnChange: true,
        affectedParentFields: () => ({
            offer: {
                selected: null,
            },
        }),
        isValueChanged: ({ globalValue, newValue }) => !(0, booking_calendar_1.isBookingCalendarSelectionValueEqual)(globalValue.date)(newValue.date),
    });
    const isFilled = (0, form_1.isDateBookingSectionFilled)(value);
    const isWhoFilled = (0, form_1.isWhoBookingSectionFilled)(control.value);
    const filters = (0, react_1.useMemo)(() => (0, helpers_1.getCalendarFiltersFromValue)(control.value), [JSON.stringify(control.value)]);
    const firstMonth = (0, booking_calendar_1.useBookingCalendarFirstAvailableMonth)({
        ...filters,
        skip: !isWhoFilled,
    });
    return {
        ...attrs,
        filters,
        preselectedRanges: config.date.salesInfo,
        loading: !isWhoFilled || firstMonth.loading,
        isFilled,
        firstMonth,
        value,
    };
};
exports.useBookingDateScreenForm = useBookingDateScreenForm;
