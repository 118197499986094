"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOfferFiltersFromSessionItem = void 0;
const O = __importStar(require("fp-ts/Option"));
const commons_1 = require("@gerber/commons");
const getOfferFiltersFromSessionItem = (sessionItem) => {
    const baseFilters = {
        roomCategoryId: sessionItem.roomCategoryId,
        rateId: sessionItem.rateId,
    };
    if ((0, commons_1.isNil)(baseFilters.roomCategoryId) || (0, commons_1.isNil)(baseFilters.rateId)) {
        return O.none;
    }
    switch (sessionItem.type) {
        case 'PACKAGE':
            return O.some({
                type: 'PACKAGE',
                packageGroupId: sessionItem.packageGroupId,
                ...baseFilters,
            });
        case 'ROOM':
            return O.some({
                ...baseFilters,
                type: 'ROOM',
            });
        default: {
            const unknownSessionItem = sessionItem;
            console.warn('Unknown session item!', unknownSessionItem);
            return O.none;
        }
    }
    return O.none;
};
exports.getOfferFiltersFromSessionItem = getOfferFiltersFromSessionItem;
