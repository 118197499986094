"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExitButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_1 = require("@gerber/icons");
const button_1 = require("../../button");
const i18n_1 = require("client/i18n");
const ExitButton = ({ direction, ...props }) => {
    const { pack } = (0, i18n_1.useI18n)();
    return ((0, jsx_runtime_1.jsxs)(button_1.Button, { title: pack.common.exit, type: "button", "$kind": "outline", "$hoverable": true, ...props, children: [(0, jsx_runtime_1.jsx)(button_1.ButtonPrefixIcon, { children: (0, jsx_runtime_1.jsx)(icons_1.LongArrowRightThinIcon, { direction: direction ?? 'left' }) }), pack.common.exit] }));
};
exports.ExitButton = ExitButton;
