"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SummaryEditableSection = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_1 = require("@gerber/icons");
const summary_editable_section_styled_1 = require("./summary-editable-section.styled");
const i18n_1 = require("client/i18n");
const SummaryEditableSection = ({ children, isEditable, onEditClick, }) => {
    const { pack } = (0, i18n_1.useI18n)();
    return ((0, jsx_runtime_1.jsxs)(summary_editable_section_styled_1.SummaryEditableSectionHolder, { children: [(0, jsx_runtime_1.jsx)(summary_editable_section_styled_1.SummaryEditableSectionContent, { children: children }), (0, jsx_runtime_1.jsx)(summary_editable_section_styled_1.SummaryEditableSectionButton, { type: "button", disabled: isEditable === false, title: pack.common.edit, onClick: onEditClick, children: (0, jsx_runtime_1.jsx)(icons_1.EditIcon, {}) })] }));
};
exports.SummaryEditableSection = SummaryEditableSection;
