"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingMobileStartJumbotronHolder = void 0;
const styled_components_1 = require("styled-components");
exports.BookingMobileStartJumbotronHolder = styled_components_1.styled.section `
  display: grid;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  height: 100dvh;
  place-items: center;
  grid-template:
    'toolbar' min-content
    'logo' 1fr
    'footer' min-content;
`;
