"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkSessionItemGuestV = exports.SdkSessionItemGuestTypeV = void 0;
const zod_1 = require("zod");
exports.SdkSessionItemGuestTypeV = zod_1.z.enum(['ADULT', 'CHILD']);
exports.SdkSessionItemGuestV = zod_1.z.intersection(zod_1.z.object({
    id: zod_1.z.string(),
    type: exports.SdkSessionItemGuestTypeV,
}), zod_1.z
    .object({
    firstname: zod_1.z.string(),
    lastname: zod_1.z.string(),
    birthdate: zod_1.z.string(),
    comment: zod_1.z.string().default(''),
    childAge: zod_1.z.number(),
})
    .partial());
