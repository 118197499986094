"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useContextOrThrow = void 0;
const react_1 = require("react");
const useContextOrThrow = (context, message) => {
    const ctx = (0, react_1.useContext)(context);
    if (!ctx) {
        throw new Error(message);
    }
    return ctx;
};
exports.useContextOrThrow = useContextOrThrow;
