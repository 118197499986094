"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokHotelBaseBlockV = void 0;
const zod_1 = require("zod");
const sdk_storyblok_hotel_card_block_1 = require("./sdk-storyblok-hotel-card.block");
const sdk_storyblok_hotel_cards_list_block_1 = require("./sdk-storyblok-hotel-cards-list.block");
const sdk_storyblok_hotel_feature_block_1 = require("./sdk-storyblok-hotel-feature.block");
exports.SdkStoryblokHotelBaseBlockV = zod_1.z.union([
    sdk_storyblok_hotel_card_block_1.SdkStoryblokHotelCardBlockV,
    sdk_storyblok_hotel_cards_list_block_1.SdkStoryblokHotelCardsListBlockV,
    sdk_storyblok_hotel_feature_block_1.SdkStoryblokHotelFeatureV,
]);
