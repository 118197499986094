"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingRoomPersonsRow = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_1 = require("@gerber/commons");
const forms_1 = require("@under-control/forms");
const person_info_row_1 = require("../person-info-row");
const comment_row_1 = require("../comment-row");
const form_1 = require("client/modules/booking/booking-wizard/form");
const i18n_1 = require("client/i18n");
const components_1 = require("client/components");
exports.BookingRoomPersonsRow = (0, forms_1.controlled)(({ control: { value, bind }, index, errors }) => {
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.contact;
    const validation = (0, forms_1.useFormValidatorMessages)({ errors });
    const { who, packageGroup } = value;
    const { adults, children } = who;
    if (!adults.length) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)("li", { children: [(0, jsx_runtime_1.jsx)(components_1.Text, { weight: 600, size: "lg", children: packageGroup?.name ?? (0, commons_1.format)(t.roomNo, { index: index + 1 }) }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 4 }), (0, jsx_runtime_1.jsx)(components_1.CleanList, { "$gap": 5, children: adults.map((adult, adultIndex) => ((0, jsx_runtime_1.jsx)(person_info_row_1.BookingPersonInfoRow, { title: (0, commons_1.format)(t.adultNo, { index: adultIndex + 1 }), ...validation.extract(`who.adults[${adultIndex}].info`, {
                        nested: true,
                    }), ...bind.path(`who.adults[${adultIndex}].info`, {
                        input: val => val ?? (0, form_1.createBlankBookingPersonInfoValue)(),
                    }) }, adult.number))) }), children.length > 0 && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 4 }), (0, jsx_runtime_1.jsx)(components_1.CleanList, { "$gap": 5, children: children.map((child, childIndex) => ((0, jsx_runtime_1.jsx)(person_info_row_1.BookingPersonInfoRow, { title: (0, commons_1.format)(t.childNo, {
                                index: childIndex + 1,
                                age: child.age,
                            }), ...validation.extract(`who.children[${childIndex}].info`, {
                                nested: true,
                            }), ...bind.path(`who.children[${childIndex}].info`, {
                                input: val => val ?? (0, form_1.createBlankBookingPersonInfoValue)(),
                            }) }, child.number))) })] })), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 7 }), (0, jsx_runtime_1.jsx)(comment_row_1.BookingRoomCommentRow, { ...bind.path('comment') })] }));
});
