"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingOfferFeatures = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const components_1 = require("client/components");
const ui_1 = require("client/ui");
const BookingOfferFeatures = ({ features, truncateAfterNthItem = 4, }) => {
    const [expanded, setExpanded] = (0, react_1.useState)(false);
    return ((0, jsx_runtime_1.jsxs)(components_1.CleanList, { "$gap": 1, "$row": true, "$wrap": true, "$flexChildren": true, children: [features
                .slice(0, expanded ? Infinity : truncateAfterNthItem)
                .map(feature => ((0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)(ui_1.Badge, { children: feature.name }) }, feature.id))), features.length > truncateAfterNthItem && ((0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)(components_1.Text, { size: "xs-sm", children: expanded ? ((0, jsx_runtime_1.jsx)(ui_1.ShowLessCleanButton, { onClick: () => {
                            setExpanded(false);
                        } })) : ((0, jsx_runtime_1.jsx)(ui_1.ShowMoreCleanButton, { onClick: () => {
                            setExpanded(true);
                        } })) }) }))] }));
};
exports.BookingOfferFeatures = BookingOfferFeatures;
