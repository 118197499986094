"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.absoluteCenter = void 0;
const styled_components_1 = require("styled-components");
exports.absoluteCenter = (0, styled_components_1.css) `
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
