"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoreThanOneRoomAlertHolder = void 0;
const styled_components_1 = require("styled-components");
exports.MoreThanOneRoomAlertHolder = styled_components_1.styled.div `
  width: 100%;

  ${({ theme: { color, spacing, font } }) => (0, styled_components_1.css) `
    padding: ${spacing[2]} ${spacing[3]};
    font-size: ${font.size['xs-sm']};
    font-weight: 600;
    background: ${color.alias.sunYellow.main};
    color: ${color.parts.summaryBox.main};
  `}
`;
