"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbInterpolationJumbotronPicture = exports.SbInterpolationJumbotronImage = void 0;
const styled_components_1 = require("styled-components");
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
exports.SbInterpolationJumbotronImage = styled_components_1.styled.div `
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  z-index: 998;
`;
exports.SbInterpolationJumbotronPicture = (0, styled_components_1.styled)(react_1.SbPicture) `
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
