"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingPaymentSuccessRouteMeta = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_helmet_1 = require("react-helmet");
const commons_front_1 = require("@gerber/commons-front");
const i18n_1 = require("client/i18n");
const BookingPaymentSuccessRouteMeta = () => {
    const { pack } = (0, i18n_1.useI18n)();
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(react_helmet_1.Helmet, { children: (0, jsx_runtime_1.jsx)("title", { children: pack.booking.confirm.success.title }) }), (0, jsx_runtime_1.jsx)(commons_front_1.NoViewportScaleMeta, {})] }));
};
exports.BookingPaymentSuccessRouteMeta = BookingPaymentSuccessRouteMeta;
