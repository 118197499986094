"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormErrorsHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.FormErrorsHolder = styled_components_1.styled.ol `
  ${commons_front_1.cleanList}

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    gap: ${spacing[2]};
  `}
`;
