"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingMobileFooter = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const icons_1 = require("@gerber/icons");
const commons_front_1 = require("@gerber/commons-front");
const wobbly_gallery_1 = require("@gerber/wobbly-gallery");
const hooks_1 = require("../../../hooks");
const details_1 = require("../details");
const booking_mobile_footer_styled_1 = require("./booking-mobile-footer.styled");
const ui_1 = require("client/ui");
const i18n_1 = require("client/i18n");
const context_1 = require("client/modules/booking/booking-wizard/context");
const areFooterPropsEqual = (prevProps, nextProps) => !nextProps.nextButton &&
    prevProps.submitOnNext === nextProps.submitOnNext &&
    !prevProps.disabled?.next === !nextProps.disabled?.next &&
    !prevProps.disabled?.prev === !nextProps?.disabled?.prev;
exports.BookingMobileFooter = (0, react_1.memo)(({ disabled, submitOnNext, nextButton }) => {
    const { pack } = (0, i18n_1.useI18n)();
    const onExit = (0, hooks_1.useExitBookingWizard)();
    const navigation = (0, context_1.useBookingWizardNavigation)();
    const slide = (0, wobbly_gallery_1.useWobblyGalleryItemContext)();
    const detailsModal = (0, details_1.useBookingMobileDetailsModal)();
    return ((0, jsx_runtime_1.jsxs)(booking_mobile_footer_styled_1.BookingMobileFooterHolder, { children: [(0, jsx_runtime_1.jsx)(commons_front_1.GridArea, { "$name": "left", children: (0, jsx_runtime_1.jsx)(booking_mobile_footer_styled_1.BookingMobileFooterArrowButton, { ...(0, commons_front_1.testid)('wizard:back'), type: "button", title: pack.common.back, disabled: !!disabled?.prev, onClick: () => {
                        if (slide.first) {
                            onExit();
                        }
                        else {
                            navigation.context.prev();
                        }
                    }, children: (0, jsx_runtime_1.jsx)(icons_1.LongArrowRight, { direction: "left" }) }) }), (0, jsx_runtime_1.jsx)(booking_mobile_footer_styled_1.BookingMobileFooterContent, { children: (0, jsx_runtime_1.jsxs)(ui_1.Button, { type: "button", "$kind": "outline", onClick: () => {
                        void detailsModal.show();
                    }, children: [pack.common.details, (0, jsx_runtime_1.jsx)(ui_1.ButtonSuffixIcon, { children: (0, jsx_runtime_1.jsx)(icons_1.OpenInBrowserIcon, {}) })] }) }), !nextButton && !slide.last && ((0, jsx_runtime_1.jsx)(commons_front_1.GridArea, { "$name": "right", children: (0, jsx_runtime_1.jsx)(ui_1.ContinueButton, { ...(!disabled?.next && { $weight: 700 }), ...(0, commons_front_1.testid)('wizard:continue'), type: submitOnNext ? 'submit' : 'button', disabled: !!disabled?.next, title: pack.common.next, direction: "right", onClick: () => {
                        if (!submitOnNext) {
                            navigation.context.next();
                        }
                    } }) })), nextButton && (0, jsx_runtime_1.jsx)(commons_front_1.GridArea, { "$name": "right", children: nextButton })] }));
}, areFooterPropsEqual);
exports.BookingMobileFooter.displayName = 'BookingMobileFooter';
