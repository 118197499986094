"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppChangeLangSelect = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const app_lang_select_input_1 = require("./app-lang-select-input");
const i18n_1 = require("client/i18n");
exports.AppChangeLangSelect = (0, react_1.memo)((props) => {
    const { lang, setLang } = (0, i18n_1.useI18n)();
    return (0, jsx_runtime_1.jsx)(app_lang_select_input_1.AppLangSelectInput, { value: lang, onChange: setLang, ...props });
});
exports.AppChangeLangSelect.displayName = 'AppChangeLangSelect';
