"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectInputOrNative = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const select_input_1 = require("./select-input");
const native_1 = require("./native");
const SelectInputOrNative = (props) => {
    const isMobile = (0, commons_front_1.useIsMobile)();
    if (isMobile) {
        return (0, jsx_runtime_1.jsx)(native_1.NativeSelectInput, { ...props });
    }
    return (0, jsx_runtime_1.jsx)(select_input_1.SelectInput, { ...props });
};
exports.SelectInputOrNative = SelectInputOrNative;
