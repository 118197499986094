"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokJumbotronBlockV = void 0;
const zod_1 = require("zod");
const components_1 = require("../components");
const sdk_storyblok_grid_block_1 = require("../layout/sdk-storyblok-grid.block");
const sdk_storyblok_block_dto_1 = require("../../sdk-storyblok-block.dto");
const sdk_storyblok_picture_dto_1 = require("../../parts/sdk-storyblok-picture.dto");
const sdk_storyblok_titled_cta_section_block_1 = require("./sdk-storyblok-titled-cta-section.block");
const SdkStoryblokJumbotronContentV = zod_1.z
    .union([
    components_1.SdkStoryblokBaseBlockV,
    sdk_storyblok_titled_cta_section_block_1.SdkStoryblokTitledCtaBlockV,
    sdk_storyblok_grid_block_1.SdkStoryblokGridBlockV,
])
    .array();
exports.SdkStoryblokJumbotronBlockV = (0, sdk_storyblok_block_dto_1.SdkStoryblokBlockV)('Section_Jumbotron', {
    image: sdk_storyblok_picture_dto_1.SdkStoryblokPictureV,
    sm_logo: sdk_storyblok_picture_dto_1.SdkStoryblokPictureV.optional().nullable().catch(null),
    content: SdkStoryblokJumbotronContentV,
});
