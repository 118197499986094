"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopSidebarSummaryPrice = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const O = __importStar(require("fp-ts/Option"));
const function_1 = require("fp-ts/function");
const sdk_gerber_1 = require("@gerber/sdk-gerber");
const booking_desktop_sidebar_summary_price_styled_1 = require("./booking-desktop-sidebar-summary-price.styled");
const i18n_1 = require("client/i18n");
const components_1 = require("client/components");
const context_1 = require("client/modules/booking/booking-wizard/context");
const BookingDesktopSidebarSummaryPrice = ({ totalPrice, }) => {
    const { pack } = (0, i18n_1.useI18n)();
    const { session } = (0, context_1.useSubscribeBookingWizardSessionContent)();
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [O.isSome(session) && !!session.value.deposit && ((0, jsx_runtime_1.jsxs)(booking_desktop_sidebar_summary_price_styled_1.SidebarSummaryPriceRow, { children: [(0, jsx_runtime_1.jsx)(booking_desktop_sidebar_summary_price_styled_1.SidebarSummaryPriceLabel, { children: pack.booking.wizard.price.deposit }), (0, jsx_runtime_1.jsx)(components_1.Text, { size: "sm", children: (0, function_1.pipe)(totalPrice, (0, sdk_gerber_1.mapPriceValue)(amount => amount * (session.value.deposit.depositPercentage / 100)), sdk_gerber_1.formatPrice) })] })), (0, jsx_runtime_1.jsxs)(booking_desktop_sidebar_summary_price_styled_1.SidebarSummaryPriceRow, { children: [(0, jsx_runtime_1.jsx)(booking_desktop_sidebar_summary_price_styled_1.SidebarSummaryPriceLabel, { children: pack.booking.wizard.price[totalPrice.approx ? 'total' : 'totalExact'] }), (0, jsx_runtime_1.jsxs)(booking_desktop_sidebar_summary_price_styled_1.SidebarSummaryPriceTitleHolder, { children: [(0, jsx_runtime_1.jsx)(components_1.Text, { size: "xl", weight: 600, children: (0, sdk_gerber_1.formatPrice)(totalPrice) }), (0, jsx_runtime_1.jsx)(components_1.Text, { size: "xs", align: "right", children: pack.common.includeTax })] })] })] }));
};
exports.BookingDesktopSidebarSummaryPrice = BookingDesktopSidebarSummaryPrice;
