"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingOfferDetailsModal = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const forms_1 = require("@under-control/forms");
const commons_front_1 = require("@gerber/commons-front");
const toolbar_1 = require("../../screens/03-room/shared/offer/toolbar");
const booking_offer_details_styled_1 = require("./booking-offer-details.styled");
const description_1 = require("./description");
const preview_1 = require("./preview");
const ui_1 = require("client/ui");
const BookingOfferDetailsModal = ({ readOnly, offer, defaultValue, onAfterChoose, ...props }) => {
    const form = (0, forms_1.useControlStrict)({
        defaultValue,
    });
    const hasSelectedValue = (0, toolbar_1.useHasToolbarSelectedValue)({
        offers: offer.offers,
        value: form.value,
    });
    return ((0, jsx_runtime_1.jsx)(ui_1.BaseModal, { ...props, size: 1200, children: (0, jsx_runtime_1.jsxs)(booking_offer_details_styled_1.BookingOfferDetailsModalContent, { children: [(0, jsx_runtime_1.jsx)(commons_front_1.GridArea, { "$name": "description", children: (0, jsx_runtime_1.jsx)(description_1.BookingOfferDescription, { offer: offer }) }), (0, jsx_runtime_1.jsx)(commons_front_1.GridArea, { "$name": "preview", style: { overflow: 'hidden' }, children: (0, jsx_runtime_1.jsx)(preview_1.BookingOfferPreview, { offer: offer }) }), (0, jsx_runtime_1.jsx)(commons_front_1.GridArea, { "$name": "offers", children: (0, jsx_runtime_1.jsx)(toolbar_1.BookingOfferToolbarList, { ...form.bind.entire(), readOnly: readOnly, offer: offer, withDescription: true }) }), (0, jsx_runtime_1.jsx)(commons_front_1.GridArea, { "$name": "toolbar", "$placeSelf": "end", children: readOnly ? ((0, jsx_runtime_1.jsx)(ui_1.ExitButton, { direction: "bottom", onClick: () => {
                            if (form.value) {
                                onAfterChoose(form.value);
                            }
                        } })) : ((0, jsx_runtime_1.jsx)(ui_1.ContinueButton, { direction: "bottom", disabled: !hasSelectedValue, onClick: () => {
                            if (form.value) {
                                onAfterChoose(form.value);
                            }
                        } })) })] }) }));
};
exports.BookingOfferDetailsModal = BookingOfferDetailsModal;
