"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDateRangeDays = void 0;
const date_fns_1 = require("date-fns");
const Array_1 = require("fp-ts/Array");
const get_days_difference_between_dates_1 = require("./get-days-difference-between-dates");
const getDateRangeDays = (range) => {
    const days = (0, get_days_difference_between_dates_1.getTotalDatesRangeDates)(range);
    return (0, Array_1.makeBy)(days + 1, day => (0, date_fns_1.addDays)(range.begin, day));
};
exports.getDateRangeDays = getDateRangeDays;
