"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tryGetReactComponentKey = void 0;
const tryGetReactComponentKey = (component) => {
    if (typeof component === 'object' &&
        'props' in component &&
        'animKey' in component.props) {
        return component.props.key;
    }
    if (typeof component === 'number' || typeof component === 'string') {
        return component.toString();
    }
    return null;
};
exports.tryGetReactComponentKey = tryGetReactComponentKey;
