"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Button = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.Button = styled_components_1.styled.button `
  all: unset;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  font-family: inherit;
  font-weight: ${({ $weight = 500 }) => $weight};
  transition: 150ms ease-in-out;
  transition-property: opacity, filter, background, color;
  border: 1px solid transparent;
  white-space: nowrap;

  ${({ theme: { shadows }, $shadow, disabled }) => $shadow &&
    !disabled &&
    (0, styled_components_1.css) `
      box-shadow: ${shadows[$shadow]};
    `}

  ${({ $showOutline = true }) => $showOutline &&
    (0, styled_components_1.css) `
      &:focus {
        ${commons_front_1.styles.focusHighlight()}
      }
    `}

  &:disabled {
    pointer-events: none;

    ${({ $kind, theme: { color } }) => {
    if ($kind === 'outline') {
        return (0, styled_components_1.css) `
          opacity: 0.35;
        `;
    }
    return (0, styled_components_1.css) `
        border-color: ${color.parts.button.inactive.main};

        ${(0, commons_front_1.genPaletteColorCSS)(color.parts.button.inactive)}
      `;
}}
  }

  ${({ $size, $square, theme: { font, lineHeight } }) => {
    switch ($size) {
        case undefined:
        case 'md':
            return (0, styled_components_1.css) `
          padding: 13px ${$square ? '13px' : '24px'};
          font-size: ${font.size.base};
          line-height: ${lineHeight[5]};
        `;
        case 'sm':
            return (0, styled_components_1.css) `
          padding: 11px ${$square ? '11px' : '15px'};
          font-size: ${font.size.sm};
          line-height: ${lineHeight[3]};
        `;
        case 'xs':
            return (0, styled_components_1.css) `
          padding: 7px ${$square ? '7px' : '10px'};
          font-size: ${font.size.sm};
          line-height: ${lineHeight[3]};
        `;
        case 'badge':
            return (0, styled_components_1.css) `
          padding: 7px ${$square ? '7px' : '10px'};
          font-size: ${font.size.xs};
          line-height: ${lineHeight[3]};
        `;
        default: {
            const unknownSize = $size;
            console.warn('Unknown button size:', unknownSize);
        }
    }
}}

  ${({ $hoverable = true, $hoverMargin = -2 }) => $hoverable &&
    commons_front_1.styles.hoverCardTransformAnim({
        shadowOpacity: 0.18,
        margin: $hoverMargin,
        shadow: '0px 4px 8px',
    })}

  ${({ $kind = 'primary', theme }) => {
    const buttonColor = theme.color.parts.button;
    if ($kind === 'outline-transparent') {
        return (0, styled_components_1.css) `
        color: ${buttonColor.outline.contrastText};
        border-color: ${buttonColor.outline.contrastText};
      `;
    }
    const color = buttonColor[$kind];
    switch ($kind) {
        case 'outline':
            return (0, styled_components_1.css) `
          border-color: ${color.contrastText};

          ${(0, commons_front_1.genPaletteColorCSS)(color)}
        `;
        default:
            return (0, styled_components_1.css) `
          border-color: ${color.main};

          ${(0, commons_front_1.genPaletteColorCSS)(color)}
        `;
    }
}}
`;
