"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopSidebarWrapper = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const sidebar_1 = require("../sidebar");
const booking_desktop_sidebar_toggle_button_1 = require("./booking-desktop-sidebar-toggle-button");
const booking_desktop_sidebar_wrapper_styled_1 = require("./booking-desktop-sidebar-wrapper.styled");
const BookingDesktopSidebarWrapper = ({ children, ...props }) => {
    const [toggledOnTablet, setToggledOnTablet] = (0, react_1.useState)(false);
    return ((0, jsx_runtime_1.jsxs)(booking_desktop_sidebar_wrapper_styled_1.BookingDesktopSidebarWrapperHolder, { children: [children, (0, jsx_runtime_1.jsxs)(booking_desktop_sidebar_wrapper_styled_1.BookingDesktopToggleSidebarWrapper, { "$toggledOnTablet": toggledOnTablet, children: [(0, jsx_runtime_1.jsx)(sidebar_1.BookingDesktopSidebar, { ...props }), (0, jsx_runtime_1.jsx)(booking_desktop_sidebar_toggle_button_1.BookingDesktopSidebarToggleButton, { toggled: toggledOnTablet, onClick: () => {
                            setToggledOnTablet(prevToggled => !prevToggled);
                        } })] })] }));
};
exports.BookingDesktopSidebarWrapper = BookingDesktopSidebarWrapper;
