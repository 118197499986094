"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.withProps = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const withProps = (defaultProps) => (Component) => {
    const Wrapped = (props) => ((0, jsx_runtime_1.jsx)(Component, { ...defaultProps, ...props }));
    Wrapped.displayName = 'withProps';
    return Wrapped;
};
exports.withProps = withProps;
