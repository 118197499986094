"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LIGHT_PINK_APP_THEME = void 0;
const LIGHT_PINK_ALIAS_COLORS = {
    black: {
        main: '#000000',
        contrastText: '#ffffff',
    },
    white: {
        main: '#ffffff',
        contrastText: '#000000',
    },
};
const LIGHT_PINK_BASE_COLORS = {
    primary: {
        main: '#E57EAC',
        contrastText: '#FFFFFF',
    },
};
const LIGHT_PINK_PARTS_COLORS = {
    summaryBox: LIGHT_PINK_ALIAS_COLORS.black,
    checkbox: LIGHT_PINK_ALIAS_COLORS.black,
    button: {
        primary: LIGHT_PINK_BASE_COLORS.primary,
        outline: LIGHT_PINK_ALIAS_COLORS.white,
    },
    input: {
        border: LIGHT_PINK_ALIAS_COLORS.black.main,
        filled: LIGHT_PINK_ALIAS_COLORS.black,
        focus: {
            border: LIGHT_PINK_ALIAS_COLORS.black.main,
        },
    },
    infoCard: {
        light: {
            contrastText: LIGHT_PINK_ALIAS_COLORS.black.main,
        },
    },
};
exports.LIGHT_PINK_APP_THEME = {
    color: {
        alias: LIGHT_PINK_ALIAS_COLORS,
        base: LIGHT_PINK_BASE_COLORS,
        parts: LIGHT_PINK_PARTS_COLORS,
    },
};
