"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LandingPageStoryblokComponent = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
const blocks_1 = require("./blocks");
const STORYBLOK_LP_CONNECTED_COMPONENTS = {
    Page: blocks_1.Pages.SbPageC,
    Navigation: blocks_1.Navs.SbNavigationC,
    Layout_Grid: blocks_1.Layouts.SbLayoutGridC,
    Layout_Section: blocks_1.Layouts.SbLayoutSectionC,
    Layout_Container: blocks_1.Layouts.SbLayoutContainerC,
    Component_HTML_Block: blocks_1.Components.SbHTMLBlockC,
    Component_Header: blocks_1.Components.SbHeaderC,
    Component_Button: blocks_1.Components.SbButtonC,
    Component_Spacer: blocks_1.Components.SbSpacerC,
    Component_Text: blocks_1.Components.SbTextC,
    Component_Billboard: blocks_1.Components.SbBillboardC,
    Component_Rich_Text: blocks_1.Components.SbRichTextC,
    Section_Jumbotron: blocks_1.Sections.SbJumbotronC,
    Section_Titled_CTA: blocks_1.Sections.SbTitledCtaSectionC,
    Section_Description_Image: blocks_1.Sections.SbDescriptionImageSectionC,
    Section_Gallery: blocks_1.Sections.SbGalleryC,
    Section_Gallery_Item: blocks_1.Sections.SbGalleryItemC,
    Footer: blocks_1.Footer.SbFooterC,
    Footer_Social: blocks_1.Footer.SbFooterSocialC,
    Hotel_Cards_List: blocks_1.Hotel.SbHotelCardsListC,
    Hotel_Card: blocks_1.Hotel.SbHotelCardC,
    Hotel_Feature: blocks_1.Hotel.SbHotelFeatureC,
};
const LandingPageStoryblokComponent = (props) => ((0, jsx_runtime_1.jsx)(react_1.SdkStoryblokComponent, { ...props, map: STORYBLOK_LP_CONNECTED_COMPONENTS }));
exports.LandingPageStoryblokComponent = LandingPageStoryblokComponent;
