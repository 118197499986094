"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollapsedSummaryInfoCard = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const summary_rows_1 = require("./summary-rows");
const form_1 = require("client/modules/booking/booking-wizard/form");
const shared_1 = require("client/modules/booking/shared");
const components_1 = require("client/components");
exports.CollapsedSummaryInfoCard = (0, react_1.memo)(({ room }) => !!(0, form_1.getBookingRoomPrice)(room) && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Divider, { "$type": "light", "$space": 3, "$hideIfLast": true }), (0, jsx_runtime_1.jsx)(shared_1.InfoCardContent, { children: (0, jsx_runtime_1.jsx)(summary_rows_1.PriceSummary, { room: room }) })] })));
exports.CollapsedSummaryInfoCard.displayName = 'CollapsedSummaryInfoCard';
