"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomSelectOptionSpinner = void 0;
const styled_components_1 = require("styled-components");
const spinner_1 = require("../../spinner");
exports.CustomSelectOptionSpinner = (0, styled_components_1.styled)(spinner_1.SpinnerContainer) `
  width: 100%;
  height: 100%;
  min-height: initial;
  margin: 5px 0 5px;

  ${spinner_1.Spinner} {
    width: 1.1rem;
    height: 1.1rem;
    opacity: 0.3;
  }
`;
