"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EN_LANG_PACK = exports.EN_BOOKING_ROOMS_SORT = exports.EN_LANG_GENDERS = void 0;
exports.EN_LANG_GENDERS = {
    family: 'Family',
    male: 'Mister',
    female: 'Misses',
};
exports.EN_BOOKING_ROOMS_SORT = {
    recommended: 'Recommended',
    'name-az': 'Sort alphabetically',
    'rate:asc': 'Sort by ascending price',
    'rate:desc': 'Sort by decending price',
};
exports.EN_LANG_PACK = {
    common: {
        close: 'Close',
        continue: 'Continue',
        website: 'Website',
        exit: 'Exit',
        edit: 'Edit',
        clear: 'Clear',
        remove: 'Remove',
        scroll: 'Scroll',
        more: 'More',
        next: 'Next',
        back: 'Back',
        select: 'Select',
        selected: 'Selected',
        details: 'Details',
        results: '%{total} results',
        moreInformation: 'More information',
        roomCategory: 'Room category',
        packageGroup: 'Package',
        includeTax: 'incl. taxes plus local tax (€ 2,00 p.P. and night from 15 years)',
        payNow: 'Pay now',
        fromPrice: 'from %{price}',
        showMore: 'Show more',
        showLess: 'Show less',
        nights: '%{nights} Nights',
        roomIn360: 'Room in 360°',
        variantOffer: 'Offer',
        contact: 'Contact',
    },
    gender: exports.EN_LANG_GENDERS,
    validation: {
        incorrect: 'Incorrect field value',
        required: 'This field is required',
    },
    placeholders: {
        chooseValue: 'Choose value...',
        enterValue: 'Enter value...',
    },
    notifications: {
        error: 'Error occurred!',
        urlCopied: 'Link has been copied to clipboard!',
    },
    booking: {
        title: 'Hotel booking',
        wizard: {
            share: {
                shareYourProgress: 'Share your booking',
                shareAndContinue: 'Share and continue',
                shareCopy: 'Share form copy',
            },
            summary: {
                title: 'Summary',
                room: 'Room #%{index}',
                roomShort: '#%{index}',
                adults: '%{total}x Adults',
                kids: '%{total}x Child (age %{age})',
                cancellation: 'Cancellation conditions',
                nights: (nights) => `${nights} night${nights === 1 ? '' : 's'}`,
            },
            price: {
                from: 'Price from',
                exact: 'Price',
                total: 'Total price from',
                totalExact: 'Total price',
                deposit: 'Deposit',
            },
            contact: {
                header: 'Check-Out',
                roomNo: 'Room #%{index}',
                adultNo: 'Adult #%{index}',
                childNo: 'Child #%{index} (%{age} years)',
                personalData: {
                    header: 'Personal Contact Information',
                    description: 'Please provide your full contact information, including your name, email address, mobile number, and your address. This information is required to confirm your booking and contact you if we have any questions.',
                    gender: {
                        label: 'Gender',
                        placeholder: 'Choose gender',
                    },
                    title: {
                        label: 'Title',
                        placeholder: 'Enter title',
                    },
                    firstName: {
                        label: 'First name',
                        placeholder: 'Enter first name',
                    },
                    lastName: {
                        label: 'Last name',
                        placeholder: 'Enter last name',
                    },
                    birthDate: {
                        label: 'Birth date',
                        placeholder: 'Enter birth date',
                    },
                    email: {
                        label: 'Email',
                        placeholder: 'Enter e-mail',
                    },
                    phone: {
                        label: 'Mobile phone number',
                        placeholder: 'Enter Mobile phone number',
                    },
                    address: {
                        street: {
                            label: 'Street and door number',
                            placeholder: 'Enter street & door number',
                        },
                        additionalInfo: {
                            label: 'Further address information',
                            placeholder: 'Enter further address information',
                        },
                        zipCode: {
                            label: 'ZIP Code',
                            placeholder: 'Enter ZIP Code',
                        },
                        city: {
                            label: 'City',
                            placeholder: 'Enter city',
                        },
                        country: {
                            label: 'Select country',
                            placeholder: 'Select country',
                        },
                    },
                },
                peopleInfo: {
                    title: 'Guest Information for Room Assignment',
                    description: 'Please provide the full names, first names, and birthdates of all people who will be staying in each room. This includes your own information if you are staying in one of the rooms.',
                    firstName: {
                        label: 'First name',
                        placeholder: 'First name',
                    },
                    lastName: {
                        label: 'Last name',
                        placeholder: 'Last name',
                    },
                    birthDate: {
                        label: 'Birth date',
                        placeholder: 'Birth date',
                    },
                },
                additionalInfo: {
                    title: 'Your personal message to us',
                    subtitle: 'Do you have a wish that you would like to tell us before your arrival?',
                    placeholder: 'Leave a notice here...?',
                },
            },
            terms: {
                title: 'Our Terms & Conditions',
                agree: {
                    generalTerms: {
                        title: 'I accept the %{anchor}.',
                        anchor: 'General Terms and Conditions',
                    },
                    securityTerms: {
                        title: 'I accept the %{anchor}.',
                        anchor: 'Data Privacy Policy',
                    },
                },
            },
            start: {
                header: 'Number of guests',
                hint: 'You can add another room in a later step.',
                cta: {
                    bookNow: 'Book now',
                },
                form: {
                    adults: {
                        label: 'Adults:',
                        tooltip: 'Adults are age %{age} or older',
                    },
                    children: {
                        label: 'Kids:',
                        tooltip: 'Please indicate the age of the child at the time of the stay',
                    },
                    age: {
                        label: 'Age',
                    },
                },
                notification: {
                    selectAnotherRoom: 'Please select who will be staying in <strong>Room #%{roomIndex}</strong>',
                    package: '<strong>Please note.</strong> You have chosen a flat rate that is bound to certain periods and, possibly, minimum occupancy. If you have any questions, please feel free to contact us by phone.',
                },
            },
            validation: {
                missingChildrenAge: 'Please add the age of your kids.',
            },
            date: {
                header: 'Choose your day of arrival and departure',
                minStay: 'min. Stay > %{} Nights',
                notification: {
                    package: '<strong>Please note.</strong> You have chosen a flat rate that is bound to certain periods and, possibly, minimum occupancy. If you have any questions, please feel free to contact us by phone.',
                },
            },
            room: {
                header: 'Select your room',
                sort: exports.EN_BOOKING_ROOMS_SORT,
            },
            ending: {
                header: 'Great.',
                subtitle: '<strong>We have all the info we need for your booking.</strong> You can decide now if you would like to book another room or offer or if you would like to complete the booking.',
                cta: {
                    completeBooking: 'Complete booking',
                    bookAnotherRoom: 'Book another room',
                },
                addMoreRooms: 'Would you like to extend your booking?',
            },
            sidebar: {
                alerts: {
                    moreThanOneRoomSelected: '<strong>Please note!</strong> You have selected %{count} rooms.',
                },
                cta: {
                    website: 'Hotel Website',
                    phone: 'Phone number',
                    contact: 'Request',
                },
                payment: {
                    title: 'Payment options',
                },
                placeholder: {
                    title: 'All relevant booking information is summarized here for you.',
                    subtitle: 'Get additional information about the individual steps as well as the overview of your booking research step here.',
                },
                bookingInfo: {
                    title: 'Booking info',
                },
            },
            footer: {
                cta: {
                    showDetails: 'Details',
                    hideDetails: 'Hide Details',
                },
            },
        },
        confirm: {
            success: {
                title: 'Payment successfully completed / Booking confirmed',
                description: 
                // eslint-disable-next-line quotes
                "<p>We're pleased to inform you that your payment has been successfully processed! Thank you for your reservation. Your room is now booked, and we look forward to welcoming you. </p> <br /> <p>If you have any further questions or concerns, please feel free to contact us.</p>",
                nextSteps: 'Your next steps!',
                shareWithYourFamily: 'Share with your fellow travelers, family or friends.',
            },
            error: {
                title: 'Payment aborted or Error in payment processing',
                description: '<p>We regret to inform you that your booking could not be successfully completed. The payment was aborted or has failed. We apologize for any inconvenience and want to inform you that your room is currently not reserved. </p> <br /> <p>The error in payment processing can have various reasons. Please contact us if you have any questions. Please also check your payment information and try again or contact your bank to resolve any possible issues.</p>',
            },
            cancel: {
                title: 'Payment aborted or Error in payment processing',
                description: '<p>We regret to inform you that your booking could not be successfully completed. The payment was aborted or has failed. We apologize for any inconvenience and want to inform you that your room is currently not reserved. </p> <br /> <p>The error in payment processing can have various reasons. Please contact us if you have any questions. Please also check your payment information and try again or contact your bank to resolve any possible issues.</p>',
            },
        },
    },
    landingPage: {
        footer: {
            media: 'Social Media',
            contact: 'Contact',
        },
        hotel: {
            bookRoom: 'Book now',
            discoverOffers: 'Discover offers',
            requestNow: 'Request',
        },
        offer: {
            bookNow: 'Book now',
        },
    },
    routeError: {
        notFound: 'Not found',
    },
};
