"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isBookingCalendarSelectionValueEqual = void 0;
const commons_1 = require("@gerber/commons");
const isBookingCalendarSelectionValueEqual = (a) => (b) => {
    if (!a || !b) {
        return a === b;
    }
    if (a.offer !== b.offer) {
        return false;
    }
    if (!(0, commons_1.compareDateOnly)(a.range.begin)(b.range.begin)) {
        return false;
    }
    if (a.range.end &&
        b.range.end &&
        !(0, commons_1.compareDateOnly)(a.range.end)(b.range.end)) {
        return false;
    }
    else if (a.range.end !== b.range.end) {
        return false;
    }
    return true;
};
exports.isBookingCalendarSelectionValueEqual = isBookingCalendarSelectionValueEqual;
