"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkBookingRoomMonthV = exports.SdkBookingRoomMonthInputV = void 0;
const zod_1 = require("zod");
const sdk_booking_room_month_day_dto_1 = require("./sdk-booking-room-month-day.dto");
exports.SdkBookingRoomMonthInputV = zod_1.z.object({
    arrivalMonth: zod_1.z.date().nullable(),
    adults: zod_1.z.number(),
    children: zod_1.z.array(zod_1.z.number()),
    packageGroupId: zod_1.z.number().optional().nullable(),
});
exports.SdkBookingRoomMonthV = zod_1.z.object({
    days: zod_1.z.array(sdk_booking_room_month_day_dto_1.SdkBookingRoomMonthDayV),
});
