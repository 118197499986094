"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokHydrateConfigV = exports.SdkStoryblokVersionV = void 0;
const zod_1 = require("zod");
exports.SdkStoryblokVersionV = zod_1.z.enum(['draft', 'published']);
exports.SdkStoryblokHydrateConfigV = zod_1.z.object({
    accessToken: zod_1.z.string().nonempty('Storyblok token cannot be empty!'),
    region: zod_1.z.string().default('eu').optional(),
    version: exports.SdkStoryblokVersionV,
});
