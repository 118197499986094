"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentMethodItemHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.PaymentMethodItemHolder = styled_components_1.styled.li `
  ${commons_front_1.styles.flexCenter}

  ${({ theme: { spacing, color } }) => (0, styled_components_1.css) `
    height: 30px;
    padding: ${spacing[2]};
    background: ${color.alias.white.main};
  `}

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
