"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBookingPackageGroupDetailsModal = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const package_group_details_modal_1 = require("./package-group-details.modal");
const useBookingPackageGroupDetailsModal = () => (0, commons_front_1.useAnimatedModal)({
    renderModalContent: ({ showProps, hiding, onAnimatedClose }) => ((0, jsx_runtime_1.jsx)(package_group_details_modal_1.BookingPackageGroupDetailsModal, { ...showProps, out: hiding, onAfterChoose: () => {
            void onAnimatedClose({
                value: showProps.packageGroup,
            });
        }, onClose: () => {
            void onAnimatedClose();
        } })),
});
exports.useBookingPackageGroupDetailsModal = useBookingPackageGroupDetailsModal;
