"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseModalWrapper = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BaseModalWrapper = styled_components_1.styled.section.withConfig({
    shouldForwardProp: (0, commons_front_1.shouldNotForwardProps)(['out']),
}) `
  ${commons_front_1.styles.layer.fixed}

  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 50px;
  z-index: 999;
  overflow-y: ${({ out }) => (out ? 'hidden' : 'auto')};
`;
