"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpinnerContainer = exports.SpinnerWrapper = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const styled_components_1 = require("styled-components");
const circle_spinner_1 = require("./circle-spinner");
exports.SpinnerWrapper = styled_components_1.styled.div `
  display: grid;

  ${({ $height }) => (0, styled_components_1.css) `
    min-height: ${$height ?? '300px'};
  `}
`;
const SpinnerContainer = ({ loading = true, children, ...props }) => {
    if (!loading) {
        return children ? (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: children() }) : null;
    }
    return ((0, jsx_runtime_1.jsx)(exports.SpinnerWrapper, { ...props, children: (0, jsx_runtime_1.jsx)(circle_spinner_1.Spinner, {}) }));
};
exports.SpinnerContainer = SpinnerContainer;
