"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShowMoreCleanButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_1 = require("@gerber/icons");
const commons_front_1 = require("@gerber/commons-front");
const i18n_1 = require("client/i18n");
const components_1 = require("client/components");
const ShowMoreCleanButton = ({ children, labelProps, count, ...props }) => {
    const { pack } = (0, i18n_1.useI18n)();
    return ((0, jsx_runtime_1.jsx)(commons_front_1.CleanButton, { type: "button", "$focusBorder": false, title: pack.common.showMore, ...props, children: (0, jsx_runtime_1.jsxs)(components_1.LabeledIcon, { icon: (0, jsx_runtime_1.jsx)(icons_1.PlusIcon, {}), "$spacing": 1, ...labelProps, children: [children ?? pack.common.showMore, count > 0 && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["\u00A0", `(${count})`] }))] }) }));
};
exports.ShowMoreCleanButton = ShowMoreCleanButton;
