"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotFoundLayer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const parts_1 = require("./parts");
const i18n_1 = require("client/i18n");
const NotFoundLayer = () => {
    const { pack } = (0, i18n_1.useI18n)();
    return (0, jsx_runtime_1.jsx)(parts_1.RouteErrorLayer, { children: pack.routeError.notFound });
};
exports.NotFoundLayer = NotFoundLayer;
