"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.disabledInput = exports.disabledInputInner = void 0;
const styled_components_1 = require("styled-components");
exports.disabledInputInner = (0, styled_components_1.css) `
  opacity: 0.5;
  pointer-events: none;
`;
exports.disabledInput = (0, styled_components_1.css) `
  &[disabled] {
    ${exports.disabledInputInner}
  }
`;
