"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useElementListener = void 0;
const react_1 = require("react");
const use_ref_safe_callback_1 = require("./use-ref-safe-callback");
const use_ref_safe_callbacks_hash_1 = require("./use-ref-safe-callbacks-hash");
/**
 * Automatic event handler mounter that remounts event handlers
 * when passed hash object keys changes. Handlers are cache safe
 * so passing any kind of react keys is no needed when any used variable
 * inside closure changes (uses the same approach like in use-ref-safe-callback)
 *
 * @example
 *  useElementListeners(
 *    {
 *      click() { ... },
 *      mouseenter() { ... },
 *      mouseleave() { ... },
 *    },
 *    {
 *      selectorFn: () => document.getElementById('rabarbar'),
 *    },
 *  );
 */
const useElementListener = (hash, { selectorFn, remountKey, options = {} }) => {
    const listeners = (0, use_ref_safe_callbacks_hash_1.useRefSafeCallbacksHash)(hash);
    const safeElementSelector = (0, use_ref_safe_callback_1.useRefSafeCallback)(selectorFn);
    (0, react_1.useEffect)(() => {
        const el = safeElementSelector();
        if (!el) {
            return undefined;
        }
        const cachedListeners = Object.entries(listeners);
        cachedListeners.forEach(([event, listener]) => {
            el.addEventListener(event, listener, options);
        });
        return () => {
            cachedListeners.forEach(([event, listener]) => {
                el.removeEventListener(event, listener, options);
            });
        };
    }, [listeners, remountKey]);
};
exports.useElementListener = useElementListener;
