"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BadgeWithIcon = void 0;
const styled_components_1 = require("styled-components");
const badge_styled_1 = require("./badge.styled");
exports.BadgeWithIcon = (0, styled_components_1.styled)(badge_styled_1.Badge) `
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    svg {
      height: 1em;
      margin-left: ${spacing[1]};
    }
  `}
`;
