"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbGalleryPicture = exports.SbGalleryItemHolder = void 0;
const styled_components_1 = require("styled-components");
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
exports.SbGalleryItemHolder = styled_components_1.styled.article `
  display: flex;
  flex-direction: column;

  width: var(--gallery-slider-width);
  margin: 0 var(--gallery-slider-spacing);
`;
exports.SbGalleryPicture = (0, styled_components_1.styled)(react_1.SbPicture).attrs({
    optimize: {
        size: {
            w: 620,
            h: 0,
        },
    },
}) `
  aspect-ratio: 1.7;
  object-fit: cover;
`;
