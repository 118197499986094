"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbNavigationToolbarHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const components_1 = require("client/components");
exports.SbNavigationToolbarHolder = (0, styled_components_1.styled)(components_1.CleanList).attrs({
    $row: true,
}) `
  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    gap: ${spacing[4]};

    ${commons_front_1.styles.mediaSizeDown.sm} {
      gap: ${spacing[2]};
    }
  `}
`;
