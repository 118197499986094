"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokStoryV = void 0;
const zod_1 = require("zod");
const helpers_1 = require("../helpers");
const SdkStoryblokStoryV = (content) => zod_1.z
    .object({
    id: zod_1.z.number(),
    created_at: zod_1.z.string(),
    uuid: zod_1.z.string(),
    name: zod_1.z.string(),
    slug: zod_1.z.string(),
    full_slug: zod_1.z.string(),
    content,
})
    .transform(helpers_1.normalizeStoryblokDTO);
exports.SdkStoryblokStoryV = SdkStoryblokStoryV;
