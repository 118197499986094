"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBookingCalendarSelectionOffers = void 0;
const function_1 = require("fp-ts/function");
const react_1 = require("react");
const forms_1 = require("@under-control/forms");
const commons_1 = require("@gerber/commons");
const react_2 = require("@gerber/sdk-gerber/dist/third-party/react");
const useBookingCalendarSelectionOffers = ({ filters, }) => {
    const { sdk } = (0, react_2.useGerberSdk)();
    const [optimisticArrivalDay, setOptimisticArrivalDay] = (0, react_1.useState)();
    const [fetchSelectionOffers, selectionOffers] = (0, forms_1.usePromiseCallback)(async (arrivalDay) => {
        setOptimisticArrivalDay(arrivalDay);
        return (0, function_1.pipe)(sdk.controllers.oneCalendar.getDay({
            ...filters,
            arrivalDay,
        }), (0, commons_1.tapTaskEitherError)(() => {
            setOptimisticArrivalDay(null);
        }), commons_1.tryOrThrowTE, commons_1.runTask);
    });
    return [
        fetchSelectionOffers,
        {
            ...selectionOffers,
            optimisticArrivalDay,
        },
    ];
};
exports.useBookingCalendarSelectionOffers = useBookingCalendarSelectionOffers;
