"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreloadAssetsMeta = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_helmet_1 = require("react-helmet");
const PreloadAssetsMeta = ({ as, type, urls, }) => ((0, jsx_runtime_1.jsx)(react_helmet_1.Helmet, { link: urls.map(href => ({
        rel: 'preload',
        crossOrigin: '',
        as,
        type,
        href,
    })) }));
exports.PreloadAssetsMeta = PreloadAssetsMeta;
