"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTimeout = void 0;
const react_1 = require("react");
function useTimeout(callback, { time, pause, key }) {
    (0, react_1.useEffect)(() => {
        if (pause) {
            return;
        }
        const timer = setTimeout(callback, time);
        return () => {
            clearTimeout(timer);
        };
    }, [pause, time, key]);
}
exports.useTimeout = useTimeout;
