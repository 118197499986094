"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.restoreContactFromSessionItem = void 0;
const function_1 = require("fp-ts/function");
const commons_1 = require("@gerber/commons");
const form_1 = require("client/modules/booking/booking-wizard/form");
const restoreContactFromSessionItem = ({ countries }) => (user) => {
    if (!user) {
        return (0, form_1.createBlankBookingContactValue)();
    }
    const country = user.country
        ? (0, function_1.pipe)(countries, (0, commons_1.findItemByProp)('id', user.country))
        : null;
    return {
        gender: user.salutation ?? 'male',
        title: user.title ?? null,
        firstName: user.firstname ?? null,
        lastName: user.lastname ?? null,
        email: user.email ?? null,
        phone: user.phone ?? null,
        address: {
            city: user.city ?? null,
            country: country ?? null,
            street: user.street ?? null,
            zipCode: user.zipcode ?? null,
        },
    };
};
exports.restoreContactFromSessionItem = restoreContactFromSessionItem;
