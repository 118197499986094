"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OfferSummary = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const editable_1 = require("../../editable");
const summary_box_styled_1 = require("../../editable/summary-box.styled");
const context_1 = require("client/modules/booking/booking-wizard/context");
const components_1 = require("client/components");
exports.OfferSummary = (0, react_1.memo)(({ offer, isEditable }) => {
    const navigation = (0, context_1.useBookingWizardNavigation)();
    const selected = offer?.selected;
    const onEditClick = () => {
        navigation.goTo('offer');
    };
    if (!selected?.room?.category) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(editable_1.SummaryEditableSection, { isEditable: isEditable && navigation.step !== 'offer', onEditClick: onEditClick, children: (0, jsx_runtime_1.jsxs)(summary_box_styled_1.SummaryBox, { children: [selected.room.category.name, ' ', (0, jsx_runtime_1.jsx)(components_1.Text, { size: "xs", children: selected.rate?.name ? `(${selected.rate?.name})` : null })] }) }));
});
exports.OfferSummary.displayName = 'OfferSummary';
