"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseModalHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BaseModalHolder = styled_components_1.styled.div.withConfig({
    shouldForwardProp: (0, commons_front_1.shouldNotForwardProps)([
        'out',
        'size',
        'minHeight',
        'innerScroll',
        'expandedMobile',
    ]),
}) `
  ${({ out }) => commons_front_1.styles.animation.modal[out ? 'out' : 'in']};

  ${({ theme: { spacing, color }, expandedMobile = true, size = 600 }) => (0, styled_components_1.css) `
    width: calc(100% - (${spacing[4]} * 2));
    max-width: ${size}px;
    margin-bottom: ${spacing[10]};
    padding: ${spacing[12]} ${spacing[12]} ${spacing[9]} ${spacing[12]};
    border: 1px solid ${color.alias.black.main};
    background: ${color.alias.white.main};
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.45);

    ${commons_front_1.styles.mediaSizeDown.xs} {
      ${expandedMobile
    ? (0, styled_components_1.css) `
            padding: ${spacing[4]} ${spacing[6]} ${spacing[5]};
            width: 100%;
            margin-bottom: 0;
          `
    : (0, styled_components_1.css) `
            padding: ${spacing[10]} ${spacing[6]} ${spacing[5]};
            margin-bottom: 0;
          `}
    }
  `}

  ${({ innerScroll = false, theme: { spacing } }) => innerScroll &&
    (0, styled_components_1.css) `
      max-height: calc(100% - (${spacing[4]} * 2));
      margin-bottom: 0;
      overflow-y: auto;
    `}

  ${({ minHeight }) => Number(minHeight) > 0 &&
    (0, styled_components_1.css) `
      min-height: ${minHeight}px;
    `}
`;
