"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkCheckoutController = void 0;
const function_1 = require("fp-ts/function");
const commons_1 = require("@gerber/commons");
const abstract_1 = require("../../abstract");
const session_1 = require("../session");
const dto_1 = require("./dto");
class SdkCheckoutController extends abstract_1.SdkGerberApiController {
    constructor() {
        super(...arguments);
        this.defaultBasePath = 'samera-one/:oneId/checkout';
        this.validateBooking = ({ sessionId, totalPrice, }) => this.fetchApiAndValidateTE(dto_1.SdkValidationErrorsV)({
            path: '/validate',
            options: (0, function_1.pipe)((0, commons_1.postPayload)({ total: totalPrice }), (0, session_1.withSessionIdHeader)({ sessionId })),
        });
        this.book = ({ sessionId, ...attrs }) => this.fetchApiAndValidateTE(dto_1.SdkBookOutputV)({
            path: '/book',
            options: (0, function_1.pipe)((0, commons_1.postPayload)(attrs), (0, session_1.withSessionIdHeader)({ sessionId })),
        });
    }
}
exports.SdkCheckoutController = SdkCheckoutController;
