"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBookingCalendarCellConnectors = exports.compareCellConnectors = void 0;
const styled_components_1 = require("styled-components");
const sdk_gerber_1 = require("@gerber/sdk-gerber");
const commons_1 = require("@gerber/commons");
const night_price_1 = require("./night-price");
const getEmptyCellConnectors = () => ({
    first: false,
    last: false,
    left: null,
    right: null,
});
const compareCellConnectors = (a) => (b) => a.left?.color === b.left?.color &&
    a.left?.content === b.left?.content &&
    a.right?.color === b.right?.color;
exports.compareCellConnectors = compareCellConnectors;
const useBookingCalendarCellConnectors = ({ range, ...attrs }) => {
    const theme = (0, styled_components_1.useTheme)();
    const nightPrice = (0, night_price_1.useBookingCalendarRangeNightPrice)({
        ...attrs,
        range,
    });
    if (!(0, commons_1.isNonNullableDateRange)(range)) {
        return getEmptyCellConnectors;
    }
    const defaultConnector = {
        color: theme.color.alias.black,
    };
    return cell => {
        if (!nightPrice) {
            return getEmptyCellConnectors();
        }
        const inDateRange = (0, commons_1.isInDateRange)(range)(cell.date);
        if (!inDateRange) {
            return getEmptyCellConnectors();
        }
        const isFirst = (0, commons_1.compareDateOnly)(range.begin)(cell.date);
        const isLast = (0, commons_1.compareDateOnly)(range.end)(cell.date);
        const content = nightPrice
            ? (0, sdk_gerber_1.formatPrice)({
                ...nightPrice,
                hideDecimalsIfPossible: true,
            })
            : null;
        const right = (() => {
            if (!isLast) {
                return defaultConnector;
            }
            return null;
        })();
        const left = (() => {
            if (!isFirst) {
                return {
                    ...defaultConnector,
                    content,
                };
            }
            return null;
        })();
        return {
            first: isFirst,
            last: isLast,
            left,
            right,
        };
    };
};
exports.useBookingCalendarCellConnectors = useBookingCalendarCellConnectors;
