"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.anonymizeGlobalFormValue = void 0;
const function_1 = require("fp-ts/function");
const NEA = __importStar(require("fp-ts/NonEmptyArray"));
const anonymizeGuestFormRoomValue = (guest) => ({
    ...guest,
    info: {
        ...guest.info,
        firstName: '***',
        lastName: '***',
    },
});
const anonymizeGlobalFormRoomValue = (value) => ({
    ...value,
    who: {
        adults: value.who.adults.map(anonymizeGuestFormRoomValue),
        children: value.who.children.map(anonymizeGuestFormRoomValue),
    },
});
const anonymizeGlobalFormValue = (value) => ({
    ...value,
    contact: {
        ...value.contact,
        email: '***',
        firstName: '***',
        lastName: '***',
        phone: '***',
        address: {
            ...value.contact.address,
            city: '***',
            street: '***',
            zipCode: '***',
            country: null,
        },
    },
    rooms: (0, function_1.pipe)(value.rooms, NEA.map(anonymizeGlobalFormRoomValue)),
});
exports.anonymizeGlobalFormValue = anonymizeGlobalFormValue;
