"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMapSdkResultToWizardConfig = void 0;
const react_1 = require("@gerber/sdk-gerber/dist/third-party/react");
const use_booking_payment_methods_1 = require("./use-booking-payment-methods");
const useMapSdkResultToWizardConfig = () => {
    const paymentMethods = (0, use_booking_payment_methods_1.useBookingPaymentMethods)();
    const { sdk } = (0, react_1.useGerberSdk)();
    return ({ maxChildren, maxAdults, salesInfos, roomCategories, packageCategories, pageCustomization: page, roomCategoryFeatures, rates, countries, adultAge, }) => ({
        hotel: {
            oneId: sdk.client.config.oneId,
            name: page.hotelName,
            phone: page.phone,
            address: page.address,
            email: page.email,
            websiteUrl: new URL(page.contactUrl).origin,
            contactUrl: page.contactUrl,
            gdprUrl: page.gdprUrl,
            termsUrl: page.termsUrl,
            cancellationUrl: page.cancellationUrl,
        },
        tracking: {
            gtmId: page.googleTagManagerTag,
        },
        validation: {
            maxAdults,
            maxKids: maxChildren,
            adultAge,
        },
        start: {
            logo: {
                original: page.logoUrl,
            },
            background: {
                original: page.headerImageUrl,
            },
        },
        date: {
            salesInfo: salesInfos,
        },
        payment: {
            methods: paymentMethods,
        },
        packages: {
            categories: packageCategories,
        },
        rooms: {
            categories: roomCategories,
            features: roomCategoryFeatures,
        },
        rates,
        countries,
    });
};
exports.useMapSdkResultToWizardConfig = useMapSdkResultToWizardConfig;
