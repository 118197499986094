"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseAppHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BaseAppHolder = styled_components_1.styled.div.withConfig({
    shouldForwardProp: (0, commons_front_1.shouldNotForwardProps)([
        'centered',
        'spaced',
    ]),
}) `
  min-height: 100vh;

  ${({ centered }) => centered && commons_front_1.styles.flexCenter}
  ${({ spaced, theme: { spacing } }) => spaced &&
    (0, styled_components_1.css) `
      padding: ${spacing[6]};
    `}
`;
