"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.format = void 0;
const INNER_ITEM_MATCH_REGEX = /%{([?.\w]*)}/g; // match = 1
/**
 * Inserts into template string with %{} characters variables
 *
 * @example
 * "test ${}" => "test variableValue"
 * "test ${abc}" => "test abc"
 */
function format(str, params) {
    let counter = 0;
    return str.replace(INNER_ITEM_MATCH_REGEX, (_, match) => {
        if (typeof match === 'string' && match.length) {
            return params[match];
        }
        return params[counter++];
    });
}
exports.format = format;
