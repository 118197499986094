"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShareSessionCopyButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_1 = require("@gerber/icons");
const hooks_1 = require("./hooks");
const ui_1 = require("client/ui");
const i18n_1 = require("client/i18n");
const ShareSessionCopyButton = ({ disabled, icon, ...props }) => {
    const { pack } = (0, i18n_1.useI18n)();
    const [cloneSessionUrl, { loading }] = (0, hooks_1.useCopyClonedSessionUrl)();
    return ((0, jsx_runtime_1.jsx)(ui_1.Button, { title: pack.booking.wizard.share.shareCopy, type: "button", "$kind": "outline", disabled: !!disabled || loading, onClick: () => void cloneSessionUrl(), ...props, children: icon ?? (0, jsx_runtime_1.jsx)(icons_1.CopyIcon, {}) }));
};
exports.ShareSessionCopyButton = ShareSessionCopyButton;
