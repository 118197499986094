"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.innerJoinOfferCategoryWithConfig = void 0;
const function_1 = require("fp-ts/function");
const O = __importStar(require("fp-ts/Option"));
const A = __importStar(require("fp-ts/Array"));
const commons_1 = require("@gerber/commons");
const innerJoinOfferCategoryWithConfig = (config) => (roomCategoryId) => (0, function_1.pipe)(config.rooms.categories, (0, commons_1.maybeFindItemById)(roomCategoryId), O.map((category) => {
    const features = (0, function_1.pipe)(category.features, A.filterMap((0, commons_1.maybeFindItemByIdInv)(config.rooms.features)));
    return {
        ...category,
        features,
    };
}));
exports.innerJoinOfferCategoryWithConfig = innerJoinOfferCategoryWithConfig;
