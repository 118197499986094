"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PredefinedUA = exports.useUserAgent = exports.UserAgentContext = exports.UNKNOWN_DEFAULT_USER_AGENT = void 0;
const react_1 = require("react");
const use_context_or_throw_1 = require("../use-context-or-throw");
exports.UNKNOWN_DEFAULT_USER_AGENT = {
    browser: { name: 'Chrome' },
    device: { type: 'desktop' },
};
exports.UserAgentContext = (0, react_1.createContext)(null);
const useUserAgent = () => (0, use_context_or_throw_1.useContextOrThrow)(exports.UserAgentContext, 'Missing user agent context in tree!');
exports.useUserAgent = useUserAgent;
exports.PredefinedUA = {
    Mobile: {
        device: {
            type: 'mobile',
        },
        browser: {
            name: 'Safari',
        },
    },
};
