"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbHotelCardC = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const forms_1 = require("@under-control/forms");
const commons_front_1 = require("@gerber/commons-front");
const storyblok_hotel_cards_divider_1 = require("../cards/storyblok-hotel-cards-divider");
const storyblok_hotel_card_styled_1 = require("./storyblok-hotel-card.styled");
const content_1 = require("./content");
const cover_1 = require("./cover");
const offers_1 = require("./offers");
const hooks_1 = require("./hooks");
const SbHotelCardC = ({ item, defaultExpanded = false }) => {
    const { anim } = (0, commons_front_1.useInitialAnimatedElement)();
    const toggled = (0, forms_1.useControl)({
        defaultValue: defaultExpanded,
    });
    const packageGroups = (0, hooks_1.useHotelCardInfo)(item.oneId);
    const hasPackageGroups = packageGroups.value && packageGroups.value.packageGroups.length > 0;
    const homepageUrl = packageGroups.value?.homepageUrl;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(storyblok_hotel_card_styled_1.SbHotelCard, { children: [(0, jsx_runtime_1.jsx)(cover_1.SbHotelCardCover, { item: item, href: homepageUrl ?? '#', ...anim('animate-fade-in-from-left'), ...(homepageUrl && {
                            target: '_blank',
                        }) }), (0, jsx_runtime_1.jsx)(content_1.SbHotelCardContent, { item: item, showOffersCTA: hasPackageGroups, ...toggled.bind.entire() })] }), toggled.value && hasPackageGroups && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(storyblok_hotel_cards_divider_1.SbHotelCardsDivider, {}), (0, jsx_runtime_1.jsx)(offers_1.SbHotelOffers, { oneId: item.oneId, packageGroups: packageGroups.value.packageGroups })] }))] }));
};
exports.SbHotelCardC = SbHotelCardC;
