"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingCalendarCellTooltip = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_tooltip_1 = require("react-tooltip");
const icons_1 = require("@gerber/icons");
const booking_calendar_cell_tooltip_styled_1 = require("./booking-calendar-cell-tooltip.styled");
const BookingCalendarCellTooltip = ({ content, }) => {
    const id = (0, react_1.useId)();
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(booking_calendar_cell_tooltip_styled_1.BookingCalendarCellTooltipHolder, { "data-tooltip-id": id, children: (0, jsx_runtime_1.jsx)(icons_1.InfoIcon, {}) }), (0, jsx_runtime_1.jsx)(react_tooltip_1.Tooltip, { id: id, content: content })] }));
};
exports.BookingCalendarCellTooltip = BookingCalendarCellTooltip;
