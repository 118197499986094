"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeI18nReqHeader = exports.decodeI18nOrDefault = void 0;
const accept_language_parser_1 = require("accept-language-parser");
const function_1 = require("fp-ts/function");
const fp_ts_1 = require("fp-ts");
const commons_1 = require("@gerber/commons");
const i18n_validator_1 = require("./i18n-validator");
const decodeI18nOrDefault = (lang) => (0, function_1.pipe)(lang, (0, commons_1.tryParseUsingZodSchema)(i18n_validator_1.I18nLangV, true), fp_ts_1.either.getOrElseW(() => 'en'));
exports.decodeI18nOrDefault = decodeI18nOrDefault;
const decodeI18nReqHeader = (acceptLanguage) => (0, function_1.pipe)(acceptLanguage ?? '', accept_language_parser_1.parse, fp_ts_1.array.head, fp_ts_1.option.map(item => item.code), fp_ts_1.option.toNullable, exports.decodeI18nOrDefault);
exports.decodeI18nReqHeader = decodeI18nReqHeader;
