"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkSessionUpdateItemInputV = void 0;
const zod_1 = require("zod");
const dto_1 = require("../../../dto");
const sdk_session_create_item_input_dto_1 = require("./sdk-session-create-item-input.dto");
const sdk_session_item_guest_dto_1 = require("./sdk-session-item-guest.dto");
exports.SdkSessionUpdateItemInputV = zod_1.z.object({
    id: zod_1.z.string(),
    type: sdk_session_create_item_input_dto_1.SdkSessionItemTypeV,
    arrivalDay: zod_1.z.string(),
    nights: zod_1.z.number(),
    guests: zod_1.z.array(sdk_session_item_guest_dto_1.SdkSessionItemGuestV),
    roomCategoryId: zod_1.z.number(),
    rateId: zod_1.z.number(),
    variantId: zod_1.z.string().nullable(),
    lastMinutePackage: zod_1.z.number().nullable(),
    price: dto_1.SdkPriceV,
    comment: zod_1.z.string().default(''),
    extraServices: zod_1.z.array(zod_1.z.any()),
});
