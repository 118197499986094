"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppRouteProviders = exports.AppRouteProvidersWithI18n = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const sdk_gerber_1 = require("@gerber/sdk-gerber");
const react_1 = require("@gerber/sdk-gerber/dist/third-party/react");
const commons_front_1 = require("@gerber/commons-front");
const react_2 = require("react");
const ui_1 = require("../ui");
const config_1 = require("../config");
const i18n_1 = require("client/i18n");
const theme_1 = require("client/theme");
const AppRouteProvidersWithI18n = ({ children, oneId, theme, }) => {
    const config = (0, config_1.useAppConfig)();
    const { lang } = (0, i18n_1.useI18n)();
    const sdkConfig = (0, react_2.useMemo)(() => ({
        ...config.gerber,
        lang: (0, sdk_gerber_1.getApiLangFromCode)(lang),
        oneId: oneId ?? config.gerber.defaultOneId,
    }), [config, lang]);
    let content = ((0, jsx_runtime_1.jsx)(react_1.SdkGerberProvider, { config: sdkConfig, children: (0, jsx_runtime_1.jsx)(ui_1.NotificationsContextProvider, { children: (0, jsx_runtime_1.jsx)(commons_front_1.ModalsContextProvider, { children: ({ modalVisible }) => ((0, jsx_runtime_1.jsx)(commons_front_1.ModalBlurWrapper, { "$active": modalVisible, children: children })) }) }) }));
    if (theme) {
        content = (0, jsx_runtime_1.jsx)(theme_1.ThemeProvider, { theme: theme, children: content });
    }
    else if (oneId) {
        content = (0, jsx_runtime_1.jsx)(theme_1.HotelThemeProvider, { oneId: oneId, children: content });
    }
    return content;
};
exports.AppRouteProvidersWithI18n = AppRouteProvidersWithI18n;
const AppRouteProviders = (props) => {
    const urlLang = (0, i18n_1.useI18nUrlLang)();
    return ((0, jsx_runtime_1.jsx)(i18n_1.I18nProvider, { lang: urlLang, children: (0, jsx_runtime_1.jsx)(exports.AppRouteProvidersWithI18n, { ...props }) }));
};
exports.AppRouteProviders = AppRouteProviders;
