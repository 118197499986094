"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useModal = void 0;
const react_1 = require("react");
const function_1 = require("fp-ts/function");
const fp_ts_1 = require("fp-ts");
const use_ref_safe_callback_1 = require("../use-ref-safe-callback");
const modals_context_provider_1 = require("./modals-context-provider");
/**
 * Hook that allows to "imperative" show modal in any callback.
 * Modals mounted by this hook survive component unmount.
 *
 * @example
 *  const useShowMagicModal = () => useModal<number, { magic: number }>({
 *    renderModalContent: ({ showProps: { magic }, onClose ) => (
 *      <Modal magic={magic} onClose={() => onClose(1234)} />
 *    ),
 *  });
 *
 *  ... in component:
 *
 * const modal = useShowMagicModal();
 * const onClick = async () => {
 *    const result = await modal.show({ magic: 1 })
 *    ... and the result is 1234 (see onClose above)
 * };
 */
function useModal(config) {
    const modalsContext = (0, react_1.useContext)(modals_context_provider_1.ModalsContext);
    const modalsContextRef = (0, react_1.useRef)();
    modalsContextRef.current = modalsContext;
    const [uuid, setUUID] = (0, react_1.useState)(null);
    const modalHandle = uuid ? modalsContext?.modals[uuid] : null;
    const safeRenderModalContent = (0, use_ref_safe_callback_1.useRefSafeCallback)(config.renderModalContent);
    const showModal = async (showProps) => new Promise((resolve, reject) => {
        if (!modalsContextRef.current ||
            (typeof uuid === 'string' && modalsContextRef.current.modals[uuid])) {
            reject(new Error('Missing modals context or uuid!'));
            return;
        }
        const { uuid: newUUID } = modalsContextRef.current.showModal({
            ...config,
            showProps,
            renderModalContent: safeRenderModalContent,
            onClose: (result) => {
                setUUID(null);
                config.onClose?.(result); // eslint-disable-line no-unused-expressions
                resolve(result);
            },
        });
        setUUID(newUUID);
    });
    const showAsOptional = (showProps) => (0, function_1.pipe)(async () => showModal(showProps), fp_ts_1.task.map(fp_ts_1.option.fromNullable));
    const closeModal = (0, use_ref_safe_callback_1.useRefSafeCallback)(() => {
        if (modalHandle && uuid) {
            modalsContextRef.current?.hideModal(uuid);
        }
    });
    const toggleModal = (0, use_ref_safe_callback_1.useRefSafeCallback)(async () => {
        if (modalHandle) {
            closeModal();
        }
        else {
            await showModal();
        }
    });
    const rerenderModal = (0, use_ref_safe_callback_1.useRefSafeCallback)((props = {}) => {
        if (modalHandle && uuid) {
            modalsContextRef.current?.updateModalProps(uuid, props);
        }
    });
    return {
        toggled: !!modalHandle,
        show: showModal,
        showAsOptional,
        close: closeModal,
        toggle: toggleModal,
        rerender: rerenderModal,
    };
}
exports.useModal = useModal;
