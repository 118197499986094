"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseFullModal = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const base_full_modal_styled_1 = require("./base-full-modal.styled");
const BaseFullModal = ({ out, children, ...props }) => {
    (0, commons_front_1.useLockWindowScroll)();
    return ((0, jsx_runtime_1.jsx)(base_full_modal_styled_1.BaseFullModalHolder, { out: out, ...props, children: children }));
};
exports.BaseFullModal = BaseFullModal;
