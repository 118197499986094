"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkGerber = void 0;
const sdk_geber_client_1 = require("./sdk-geber-client");
const controllers_1 = require("./controllers");
class SdkGerber {
    constructor(config) {
        this.client = new sdk_geber_client_1.SdkGerberClient(config.client);
        this.controllers = {
            session: new controllers_1.SdkSessionController(this.client),
            oneConfig: new controllers_1.SdkOneConfigController(this.client),
            oneCalendar: new controllers_1.SdkOneCalendarController(this.client),
            oneOffers: new controllers_1.SdkOneOffersController(this.client),
            checkout: new controllers_1.SdkCheckoutController(this.client),
        };
    }
}
exports.SdkGerber = SdkGerber;
