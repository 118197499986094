"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingSuccessShareRowHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BookingSuccessShareRowHolder = styled_components_1.styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    gap: ${spacing[8]};
  `}

  ${commons_front_1.styles.mediaSizeDown.xs} {
    flex-direction: column;

    ${({ theme: { spacing } }) => (0, styled_components_1.css) `
      gap: ${spacing[4]};
    `}
  }
`;
