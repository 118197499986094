"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingWizardNotificationHolder = void 0;
const styled_components_1 = require("styled-components");
const function_1 = require("fp-ts/function");
const commons_front_1 = require("@gerber/commons-front");
const theme_1 = require("client/theme");
exports.BookingWizardNotificationHolder = styled_components_1.styled.div `
  display: grid;
  grid-template: 'content close' / 1fr min-content;

  ${({ theme, theme: { spacing, shadows }, $color }) => (0, styled_components_1.css) `
    padding: ${spacing[3]} ${spacing[4]};
    margin: 0 auto ${spacing[6]};
    max-width: calc(100vw - (${spacing[4]} * 2));
    box-shadow: ${shadows[3]};

    ${(0, function_1.pipe)($color, (0, theme_1.extractThemeColorInv)(theme.color), commons_front_1.genPaletteColorCSS)}
  `}
`;
