"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRefSafeCallbacksHash = void 0;
const react_1 = require("react");
const Record_1 = require("fp-ts/Record");
/**
 * Function that returns hash that has the same reference.
 *
 * @see
 *  - Reference changes only if object keys change.
 *  - Function passed to callback hash does not need any kind of dependency keys.
 *    Hash always calls the newest JS function stored in callbacksRef
 *
 * @example
 *  const handlers = useRefSafeCallbacksHash({
 *    click: () => { ... }
 *  });
 *
 *  useEffect(() => { ... }, [handlers]);
 */
const useRefSafeCallbacksHash = (hash) => {
    const cacheKey = Object.keys(hash).join(',');
    const callbacksRef = (0, react_1.useRef)(null);
    callbacksRef.current = hash;
    return (0, react_1.useMemo)(() => {
        const result = (0, Record_1.mapWithIndex)((key) => (...args) => callbacksRef.current?.[key]?.apply(window, args))(hash ?? {});
        return result;
    }, [cacheKey]);
};
exports.useRefSafeCallbacksHash = useRefSafeCallbacksHash;
