"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbBillboardC = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
const storyblok_billboard_styled_1 = require("./storyblok-billboard.styled");
const SbBillboardC = ({ item, }) => ((0, jsx_runtime_1.jsx)(storyblok_billboard_styled_1.SbBillboard, { ...(0, react_1.sdkStoryblokEditableProps)(item), picture: item.image, loading: "lazy", optimize: {
        size: {
            w: 1230,
            h: 0,
        },
    } }));
exports.SbBillboardC = SbBillboardC;
