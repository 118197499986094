"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shallowCompareArrays = void 0;
const shallowCompareArrays = (a, b) => {
    if (a === b) {
        return true;
    }
    if (!a || !b) {
        return false;
    }
    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) {
            return false;
        }
    }
    return true;
};
exports.shallowCompareArrays = shallowCompareArrays;
