"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateRanges = void 0;
const _1 = require(".");
exports.DateRanges = {
    fromInfinity: (end) => ({
        begin: (0, _1.getZonedDate)(new Date('1000-01-01')),
        end,
    }),
    toInfinity: (begin) => ({
        begin,
        end: (0, _1.getZonedDate)(new Date('3000-01-01')),
    }),
};
