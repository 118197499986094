"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ssrHydrate = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const client_1 = require("react-dom/client");
const wouter_1 = require("wouter");
const client_2 = require("../resolvers/client");
const task_resolver_provider_1 = require("../resolvers/context/task-resolver-provider");
const get_ssr_json_1 = require("./get-ssr-json");
const context_1 = require("./context");
const ssrHydrate = (Component, { routerProps } = {}) => {
    const root = (0, client_1.createRoot)(document.getElementById('root-app-container'));
    const clientTasksResolver = client_2.ClientTasksResolver.ofHydrationData();
    root.render((0, jsx_runtime_1.jsx)(wouter_1.Router, { ...routerProps, children: (0, jsx_runtime_1.jsx)(context_1.HttpContext.Provider, { value: (0, context_1.createBlankHttpContext)(), children: (0, jsx_runtime_1.jsxs)(task_resolver_provider_1.TasksResolverProvider, { resolver: clientTasksResolver, children: [(0, jsx_runtime_1.jsx)(context_1.StoreHttpClientHistory, {}), (0, jsx_runtime_1.jsx)(Component, { ...(0, get_ssr_json_1.getSSRJson)('root-app-json') })] }) }) }));
};
exports.ssrHydrate = ssrHydrate;
