"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SidebarPlaceholder = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const sidebar_placeholder_styled_1 = require("./sidebar-placeholder.styled");
const components_1 = require("client/components");
const i18n_1 = require("client/i18n");
exports.SidebarPlaceholder = (0, react_1.memo)(() => {
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.sidebar.placeholder;
    return ((0, jsx_runtime_1.jsxs)(sidebar_placeholder_styled_1.SidebarPlaceholderHolder, { children: [(0, jsx_runtime_1.jsx)(sidebar_placeholder_styled_1.SidebarPlaceholderIcon, {}), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 4 }), (0, jsx_runtime_1.jsx)(components_1.Text, { size: "lg", color: "darkMuted", weight: 600, children: t.title }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 4 }), (0, jsx_runtime_1.jsx)(components_1.Text, { size: "base", color: "darkMuted", children: t.subtitle })] }));
});
exports.SidebarPlaceholder.displayName = 'SidebarPlaceholder';
