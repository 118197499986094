"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTranslateValueEntries = void 0;
const O = __importStar(require("fp-ts/Option"));
const NEA = __importStar(require("fp-ts/NonEmptyArray"));
const function_1 = require("fp-ts/function");
const commons_front_1 = require("@gerber/commons-front");
const sdk_gerber_1 = require("@gerber/sdk-gerber");
const commons_1 = require("@gerber/commons");
const context_1 = require("../../../context");
const useTranslateValueEntries = ({ value, setValue, }) => {
    const config = (0, context_1.useBookingWizardConfigContext)();
    (0, commons_front_1.useInstantUpdateEffect)(() => {
        const reloadRoomPackageGroup = (room) => {
            const { packageGroup } = room;
            if (!packageGroup) {
                return room;
            }
            return (0, function_1.pipe)(config.packages.categories, (0, sdk_gerber_1.findPackageGroupInConfigCategories)(packageGroup.id), O.matchW(() => room, (newPackageGroup) => ({
                ...room,
                packageGroup: newPackageGroup,
            })));
        };
        const reloadRoomOffer = (room) => {
            const { offer } = room;
            if (!offer?.selected) {
                return room;
            }
            const maybeFoundCategory = (0, commons_1.findItemById)(offer.selected.roomCategoryId)(config.rooms.categories);
            if (!maybeFoundCategory) {
                return room;
            }
            return {
                ...room,
                offer: {
                    ...offer,
                    selected: {
                        ...offer.selected,
                        room: {
                            ...offer.selected.room,
                            category: {
                                ...maybeFoundCategory,
                                features: offer.selected.room.category.features,
                            },
                        },
                    },
                },
            };
        };
        setValue({
            value: {
                ...value,
                rooms: (0, function_1.pipe)(value.rooms, NEA.map((0, function_1.flow)(reloadRoomPackageGroup, reloadRoomOffer))),
            },
        });
    }, [config]);
};
exports.useTranslateValueEntries = useTranslateValueEntries;
