"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectCellRange = void 0;
const commons_1 = require("@gerber/commons");
const selectCellRange = (date) => (value) => {
    if (value?.range.begin && (0, commons_1.compareDateOnly)(date)(value.range.begin)) {
        return null;
    }
    if (value?.range.end && (0, commons_1.compareDateOnly)(date)(value.range.end)) {
        return {
            begin: value.range.begin,
            end: value.range.begin,
        };
    }
    if (!value?.range.begin) {
        return {
            begin: date,
            end: date,
        };
    }
    return {
        begin: value.range.begin,
        end: date,
    };
};
exports.selectCellRange = selectCellRange;
