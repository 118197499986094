"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSpacerComponent = exports.spacer = void 0;
const styled_components_1 = require("styled-components");
const helpers_1 = require("../helpers");
const breakpoint_prop_style_1 = require("../styles/breakpoint-prop.style");
const spacer = () => (0, styled_components_1.css) `
  flex-shrink: 0;
  flex-grow: ${({ $grow }) => ($grow ? 1 : 0)};
  font-size: 0;

  ${(0, breakpoint_prop_style_1.maxBreakpointProp$)('x')((space) => space > 0 &&
    (0, styled_components_1.css) `
        width: ${({ theme: { spacing } }) => spacing[space ?? 5]};
      `)}

  ${(0, breakpoint_prop_style_1.maxBreakpointProp$)('y')((space) => space > 0 &&
    (0, styled_components_1.css) `
        display: block;
        height: ${({ theme: { spacing } }) => spacing[space]};
      `)}

  ${({ $collapseIfLast }) => $collapseIfLast &&
    (0, styled_components_1.css) `
      &:last-child {
        display: none;
      }
    `}
`;
exports.spacer = spacer;
const createSpacerComponent = () => styled_components_1.styled.div.withConfig({
    shouldForwardProp: (0, helpers_1.shouldNotForwardProps)([
        ...(0, breakpoint_prop_style_1.maxBreakpointFields$)('x'),
        ...(0, breakpoint_prop_style_1.maxBreakpointFields$)('y'),
    ]),
})(exports.spacer);
exports.createSpacerComponent = createSpacerComponent;
