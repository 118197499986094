"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToolbarContent = void 0;
const styled_components_1 = require("styled-components");
const components_1 = require("client/components");
exports.ToolbarContent = (0, styled_components_1.styled)(components_1.CleanList).attrs({
    $row: true,
    $gap: 5,
}) `
  width: 100%;
  height: 100%;
  align-items: center;

  > li {
    display: contents;
  }
`;
