"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormGroupInvalidHTMLInput = exports.focusOnFirstInvalidHTMLInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const form_group_invalid_html_input_styled_1 = require("./form-group-invalid-html-input.styled");
const i18n_1 = require("client/i18n");
const INVALID_HTML_FIELD_ATTR = 'data-invalid-field';
const focusOnFirstInvalidHTMLInput = () => {
    const formInput = document.querySelector(`[${INVALID_HTML_FIELD_ATTR}]`);
    if (!formInput) {
        return;
    }
    formInput.reportValidity();
};
exports.focusOnFirstInvalidHTMLInput = focusOnFirstInvalidHTMLInput;
exports.FormGroupInvalidHTMLInput = (0, react_1.memo)(() => {
    const { pack, lang } = (0, i18n_1.useI18n)();
    const ref = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        ref.current?.setCustomValidity(pack.validation.incorrect);
    }, [lang]);
    return ((0, jsx_runtime_1.jsx)(form_group_invalid_html_input_styled_1.FormGroupInvalidHtmlInputHolder, { ref: ref, [INVALID_HTML_FIELD_ATTR]: true }));
});
exports.FormGroupInvalidHTMLInput.displayName = 'FormGroupInvalidHTMLInput';
