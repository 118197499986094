"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkOneConfigController = void 0;
const abstract_1 = require("../../abstract");
const dto_1 = require("./dto");
class SdkOneConfigController extends abstract_1.SdkGerberApiController {
    constructor() {
        super(...arguments);
        this.defaultBasePath = 'samera-one/:oneId';
        this.getConfiguration = () => this.fetchApiAndValidateTE(dto_1.SdkOneConfigV)({
            path: 'configuration',
        });
    }
}
exports.SdkOneConfigController = SdkOneConfigController;
