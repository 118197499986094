"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findInDateRanges = void 0;
const date_fns_1 = require("date-fns");
const findInDateRanges = (value) => (ranges) => {
    const startValue = (0, date_fns_1.startOfDay)(value);
    const endValue = (0, date_fns_1.endOfDay)(value);
    return ranges.find(({ begin, end }) => startValue >= (0, date_fns_1.startOfDay)(begin) && endValue <= (0, date_fns_1.endOfDay)(end));
};
exports.findInDateRanges = findInDateRanges;
