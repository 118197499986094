"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WobblyGalleryItem = exports.areGalleryItemProps = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const commons_front_1 = require("@gerber/commons-front");
const styled_1 = require("../styled");
const context_1 = require("../context");
function areGalleryItemProps(props) {
    return typeof props?.children === 'function';
}
exports.areGalleryItemProps = areGalleryItemProps;
const WobblyGalleryItem = ({ renderOffscreen = true, attrs, children, }) => {
    const contentRef = (0, react_1.useRef)(null);
    (0, commons_front_1.useUpdateEffect)(() => {
        if (attrs?.active && contentRef.current) {
            contentRef.current.scrollTop = 0;
        }
    }, [attrs?.active]);
    if (!attrs) {
        return null;
    }
    const content = (() => {
        if (!renderOffscreen && !attrs.preload && !attrs.active) {
            return null;
        }
        return ((0, jsx_runtime_1.jsx)(context_1.WobblyGalleryItemContext.Provider, { value: attrs, children: children(attrs) }));
    })();
    return ((0, jsx_runtime_1.jsx)(styled_1.WobblyGalleryItemWrapper, { ref: contentRef, ...(attrs.active && { tabIndex: -1 }), children: content }));
};
exports.WobblyGalleryItem = WobblyGalleryItem;
