"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingOffersContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const hooks_1 = require("../hooks");
const header_1 = require("./header");
const grid_1 = require("./grid");
const decorators_1 = require("client/modules/booking/booking-wizard/decorators");
const components_1 = require("client/components");
const ui_1 = require("client/ui");
const tracking_1 = require("client/tracking");
exports.BookingOffersContainer = (0, decorators_1.controlledBookingValue)(({ control: { value, bind }, forceLoading, sort, offscreen }) => {
    const offers = (0, hooks_1.useMatchedOffersList)({ sort, room: value });
    const trackRoomsList = (0, tracking_1.useGTMTrackRoomsList)();
    (0, commons_front_1.useUpdateEffect)(() => {
        if (!offscreen && !offers.loading) {
            trackRoomsList(offers.value ?? []);
        }
    }, [offers.loading, offscreen]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(header_1.BookingOffersHeader, { duration: value.date, total: offers.value?.length ?? 0 }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 8, "$max-xs:y": 7 }), (0, jsx_runtime_1.jsx)(ui_1.SpinnerContainer, { loading: !!forceLoading || (!offers.value && offers.loading), children: () => ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(grid_1.BookingOffersGrid, { items: offers.value ?? [], ...bind.path('offer.selected') }) })) })] }));
});
