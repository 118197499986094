"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkGerberClient = void 0;
const function_1 = require("fp-ts/function");
const commons_1 = require("@gerber/commons");
const helpers_1 = require("./helpers");
class SdkGerberClient {
    constructor(config) {
        this.config = config;
        this.fetchApiTE = ({ path, options, ...attrs }) => {
            const { apiUrl, oneId, lang } = this.config;
            const url = (0, function_1.pipe)(path, (0, commons_1.concatUrlParts2C)(apiUrl, 'rest', SdkGerberClient.version), (0, commons_1.parameterizePath)({
                oneId,
            }), (0, commons_1.withSearchParams)({
                lang,
            }));
            return (0, helpers_1.fetchApiTE)({
                ...attrs,
                url,
                options: {
                    ...options,
                    headers: options?.headers,
                },
            });
        };
    }
}
exports.SdkGerberClient = SdkGerberClient;
SdkGerberClient.version = 'v1';
