"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAnimatedModal = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const use_modal_1 = require("./use-modal");
const animated_modal_wrapper_1 = require("./components/animated-modal-wrapper");
/**
 * Modal that shows modal and after calling onAnimatedClose removes
 * it from tree after specified delay in `closedAnimDuration`.
 * It is useful in components with hide animations.
 *
 * @example
 *  const modal = useAnimatedModal<boolean>({
 *    closeAnimDuration: 500,
 *    renderModalContent: ({ hiding, onAnimatedClose }) => (
 *      <UpdateResidentialAddressModal
 *        visible={!hiding}
 *        onDismiss={() => onAnimatedClose(false)}
 *      />
 *    ),
 *  });
 *  ...
 *  modal.show().then(...)
 *
 *  After calling onDissmis component will set state hiding to true
 *  and after 500ms it will be switched to false and modal will be
 *  removed from DOM.
 */
function useAnimatedModal({ closeAnimDuration = 300, renderModalContent, ...config }) {
    return (0, use_modal_1.useModal)({
        ...config,
        renderModalContent: ({ onClose, ...attrs }) => ((0, jsx_runtime_1.jsx)(animated_modal_wrapper_1.AnimatedModalWrapper, { closeAnimDuration: closeAnimDuration, onClose: onClose, children: ({ hiding, performCloseAnim }) => renderModalContent({
                ...attrs,
                hiding,
                onInstantClose: onClose,
                onAnimatedClose: performCloseAnim,
            }) })),
    });
}
exports.useAnimatedModal = useAnimatedModal;
