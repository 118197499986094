"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBookingWizardSessionContextValue = void 0;
const O = __importStar(require("fp-ts/Option"));
const TE = __importStar(require("fp-ts/TaskEither"));
const E = __importStar(require("fp-ts/Either"));
const NEA = __importStar(require("fp-ts/NonEmptyArray"));
const function_1 = require("fp-ts/function");
const zod_1 = require("zod");
const js_base64_1 = require("js-base64");
const react_1 = require("react");
const date_fns_1 = require("date-fns");
const react_2 = require("@gerber/sdk-gerber/dist/third-party/react");
const commons_front_1 = require("@gerber/commons-front");
const commons_1 = require("@gerber/commons");
const booking_wizard_session_exception_1 = require("../booking-wizard-session.exception");
const use_booking_wizard_session_id_cookie_1 = require("./use-booking-wizard-session-id-cookie");
const SessionUrlUuidParamsV = zod_1.z.union([
    zod_1.z.object({
        sessionId: zod_1.z.string(),
    }),
    zod_1.z
        .object({
        session: zod_1.z.string(),
    })
        .transform(({ session }) => ({
        session: JSON.parse(js_base64_1.Base64.decode(session)),
    })),
]);
const useBookingWizardSessionContextValue = () => {
    const { sdk } = (0, react_2.useGerberSdk)();
    const sessionCookie = (0, use_booking_wizard_session_id_cookie_1.useBookingWizardSessionIdCookie)(sdk.client.config.oneId);
    const getSessionUrlParams = (0, commons_front_1.useGetSearchParamsAndDropIO)(SessionUrlUuidParamsV, {
        session: null,
        sessionId: null,
    });
    return (0, react_1.useMemo)(() => {
        const subscriber = (0, commons_1.createStoreSubscriber)({
            session: O.none,
        });
        const maybeCookieSessionId = O.fromNullable(sessionCookie.get());
        const { createSession, getSession } = sdk.controllers.session;
        const assignSession = (session) => {
            subscriber.notify({
                session: O.some(session),
            });
            sessionCookie.set(session.sessionId, {
                expires: (0, date_fns_1.addDays)((0, commons_1.getZonedDate)(new Date()), 2),
            });
        };
        const tryRestoreSessionById = (sessionId) => (0, function_1.pipe)(getSession({
            sessionId,
        }), (0, commons_1.tapTaskEither)(assignSession));
        const tryRestoreSessionFromUrlOrNone = () => {
            const restoreMatchedParamsTask = (params) => (0, function_1.pipe)('sessionId' in params
                ? tryRestoreSessionById(params.sessionId)
                : TE.of(params.session), TE.map(O.some));
            return (0, function_1.pipe)(getSessionUrlParams(), E.match(() => TE.of(O.none), restoreMatchedParamsTask));
        };
        const tryRestoreSessionFromCookieOrNone = () => {
            const snapshot = subscriber.getSnapshot();
            if (O.isSome(snapshot.session)) {
                return TE.of(snapshot.session);
            }
            return (0, function_1.pipe)(maybeCookieSessionId, O.matchW(() => TE.of(O.none), cookieSessionId => (0, function_1.pipe)(tryRestoreSessionById(cookieSessionId), TE.map(O.some))));
        };
        const createSessionIfNotExists = () => {
            const createSessionTE = () => (0, function_1.pipe)(createSession(), (0, commons_1.tapTaskEither)(assignSession));
            return (0, function_1.pipe)(tryRestoreSessionFromCookieOrNone(), TE.chain(O.match(createSessionTE, data => TE.of(data))));
        };
        const fetchCurrentSession = () => (0, function_1.pipe)(subscriber.getSnapshot().session, TE.fromOption(() => NEA.of(new booking_wizard_session_exception_1.BookingWizardSessionException('reload-failed', 'Cannot load blank session!'))), TE.chainW(session => getSession({ sessionId: session.sessionId })));
        const reloadSession = () => (0, function_1.pipe)(fetchCurrentSession(), (0, commons_1.tapTaskEither)(assignSession));
        return {
            ...subscriber,
            tryRestoreSessionById,
            tryRestoreSessionFromUrlOrNone,
            tryRestoreSessionFromCookieOrNone,
            createSessionIfNotExists,
            assignSession,
            reloadSession,
            fetchCurrentSession,
        };
    }, [sessionCookie.get()]);
};
exports.useBookingWizardSessionContextValue = useBookingWizardSessionContextValue;
