"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkBookingRoomMonthDayV = void 0;
const zod_1 = require("zod");
const dto_1 = require("../../../dto");
const helpers_1 = require("./../../../helpers");
exports.SdkBookingRoomMonthDayV = zod_1.z.object({
    date: zod_1.z.string().transform(helpers_1.formatStringToLocalDate),
    minimumDayPrice: dto_1.SdkPriceV.optional(),
});
