"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingPersonalInfoForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
/* eslint-disable react-hooks/rules-of-hooks */
const react_1 = require("react");
const forms_1 = require("@under-control/forms");
const booking_personal_info_form_styled_1 = require("./booking-personal-info-form.styled");
const i18n_1 = require("client/i18n");
const ui_1 = require("client/ui");
exports.BookingPersonalInfoForm = (0, react_1.memo)((0, forms_1.controlled)(({ control: { bind }, errors }) => {
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.contact.personalData;
    const validation = (0, forms_1.useFormValidatorMessages)({ errors });
    return ((0, jsx_runtime_1.jsxs)(booking_personal_info_form_styled_1.BookingPersonalInfoGrid, { children: [(0, jsx_runtime_1.jsx)(ui_1.FormGroup, { required: true, space: 0, area: "gender", label: t.gender.label, error: (0, jsx_runtime_1.jsx)(ui_1.ValidationErrorsHint, { ...validation.extract('gender') }), children: (0, jsx_runtime_1.jsx)(ui_1.GenderSelectInput, { ...bind.path('gender'), required: true, inputProps: {
                        placeholder: t.gender.placeholder,
                    } }) }), (0, jsx_runtime_1.jsx)(ui_1.FormGroup, { space: 0, area: "title", label: t.title.label, error: (0, jsx_runtime_1.jsx)(ui_1.ValidationErrorsHint, { ...validation.extract('title') }), children: (0, jsx_runtime_1.jsx)(ui_1.Input, { ...bind.path('title', { input: val => val ?? '' }), name: "title", placeholder: t.title.placeholder }) }), (0, jsx_runtime_1.jsx)(ui_1.FormGroup, { required: true, space: 0, area: "first-name", label: t.firstName.label, error: (0, jsx_runtime_1.jsx)(ui_1.ValidationErrorsHint, { ...validation.extract('firstName') }), children: (0, jsx_runtime_1.jsx)(ui_1.Input, { ...bind.path('firstName', { input: val => val ?? '' }), required: true, name: "first-name", placeholder: t.firstName.placeholder }) }), (0, jsx_runtime_1.jsx)(ui_1.FormGroup, { required: true, space: 0, area: "last-name", label: t.lastName.label, error: (0, jsx_runtime_1.jsx)(ui_1.ValidationErrorsHint, { ...validation.extract('lastName') }), children: (0, jsx_runtime_1.jsx)(ui_1.Input, { ...bind.path('lastName', { input: val => val ?? '' }), required: true, name: "last-name", placeholder: t.lastName.placeholder }) }), (0, jsx_runtime_1.jsx)(ui_1.FormGroup, { required: true, space: 0, area: "email", label: t.email.label, error: (0, jsx_runtime_1.jsx)(ui_1.ValidationErrorsHint, { ...validation.extract('email') }), children: (0, jsx_runtime_1.jsx)(ui_1.Input, { ...bind.path('email', { input: val => val ?? '' }), required: true, name: "email", placeholder: t.email.placeholder }) }), (0, jsx_runtime_1.jsx)(ui_1.FormGroup, { required: true, space: 0, area: "phone", label: t.phone.label, error: (0, jsx_runtime_1.jsx)(ui_1.ValidationErrorsHint, { ...validation.extract('phone') }), children: (0, jsx_runtime_1.jsx)(ui_1.Input, { ...bind.path('phone', { input: val => val ?? '' }), name: "phone", placeholder: t.phone.placeholder }) })] }));
}));
exports.BookingPersonalInfoForm.displayName = 'BookingPersonalInfoForm';
