"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokBaseFooterComponentV = void 0;
const zod_1 = require("zod");
const sdk_storyblok_footer_block_1 = require("./sdk-storyblok-footer.block");
const sdk_storyblok_footer_social_block_1 = require("./sdk-storyblok-footer-social.block");
exports.SdkStoryblokBaseFooterComponentV = zod_1.z.union([
    sdk_storyblok_footer_block_1.SdkStoryblokFooterBlockV,
    sdk_storyblok_footer_social_block_1.SdkStoryblokFooterSocialBlockV,
]);
