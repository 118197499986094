"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopSidebarFooter = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const summary_1 = require("./summary");
const payment_1 = require("./payment");
const booking_desktop_sidebar_footer_styled_1 = require("./booking-desktop-sidebar-footer.styled");
const context_1 = require("client/modules/booking/booking-wizard/context");
const form_1 = require("client/modules/booking/booking-wizard/form");
const components_1 = require("client/components");
exports.BookingDesktopSidebarFooter = (0, react_1.memo)(({ showSummaryPrice = true, spaced = true, }) => {
    const { value } = (0, context_1.useBookingGlobalForm)();
    const totalPrice = (0, react_1.useMemo)(() => (0, form_1.getBookingTotalPrice)(value), [value]);
    if (showSummaryPrice && totalPrice?.amount) {
        return ((0, jsx_runtime_1.jsx)(summary_1.BookingDesktopSidebarSummaryFooter, { totalPrice: totalPrice, totalRooms: value.rooms.length }));
    }
    const content = ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Divider, { "$type": "light" }), (0, jsx_runtime_1.jsx)(payment_1.BookingDesktopSidebarBaseFooter, {})] }));
    if (spaced) {
        return ((0, jsx_runtime_1.jsx)(booking_desktop_sidebar_footer_styled_1.BookingDesktopSidebarFooterHolder, { children: content }));
    }
    return content;
});
exports.BookingDesktopSidebarFooter.displayName = 'BookingDesktopSidebarFooter';
