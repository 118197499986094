"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSelectedCell = void 0;
const commons_1 = require("@gerber/commons");
const isSelectedCell = (cell) => (range) => {
    if (!range?.begin) {
        return false;
    }
    if (!range.end) {
        return (0, commons_1.compareDateOnly)(cell.date)(range.begin);
    }
    return (0, commons_1.isInDateRange)(range)(cell.date);
};
exports.isSelectedCell = isSelectedCell;
