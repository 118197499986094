"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGTMTrackRoomsList = void 0;
const function_1 = require("fp-ts/function");
const A = __importStar(require("fp-ts/Array"));
const O = __importStar(require("fp-ts/Option"));
const hooks_1 = require("../hooks");
const modules_1 = require("client/modules");
const useGTMTrackRoomsList = () => {
    const gtm = (0, hooks_1.useGTM)();
    const maybePackageGroupId = (0, modules_1.useBookingWizardPackageGroupId)();
    return (items) => {
        const ecomItems = (0, function_1.pipe)(items, A.flatMap(item => item.offers), A.filterMap((offer) => {
            switch (offer.type) {
                case 'ROOM':
                    return O.some({
                        item_id: offer.roomCategoryId,
                        item_name: offer.room.category.name,
                        price: offer.price.amount,
                    });
                case 'PACKAGE':
                    return O.some({
                        item_id: offer.roomCategoryId,
                        item_name: `Pauschale ${offer.package.group.name} ${offer.room.category.name}`,
                        price: offer.price.amount,
                    });
                default:
                    return O.none;
            }
        }), A.uniq({
            equals: (a, b) => a.item_id === b.item_id && a.item_name === b.item_name,
        }));
        gtm.trackGenericEvent({
            name: 'view_item_list',
            package_group_id: maybePackageGroupId,
            ecommerce: {
                items: ecomItems,
            },
        });
    };
};
exports.useGTMTrackRoomsList = useGTMTrackRoomsList;
