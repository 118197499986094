"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingCalendarCellClearHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BookingCalendarCellClearHolder = (0, styled_components_1.styled)(commons_front_1.CleanButton).attrs({
    $focusBorder: false,
}) `
  padding: 4px;
`;
