"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBookingWizardHandleBookPayment = void 0;
const TE = __importStar(require("fp-ts/TaskEither"));
const NEA = __importStar(require("fp-ts/NonEmptyArray"));
const commons_1 = require("@gerber/commons");
const booking_form_exception_1 = require("../booking-form.exception");
const use_booking_wizard_js_payment_modal_1 = require("./use-booking-wizard-js-payment-modal");
const useBookingWizardHandleBookPayment = () => {
    const jsPaymentModal = (0, use_booking_wizard_js_payment_modal_1.useBookingWizardJsPaymentModal)();
    return (response) => {
        switch (response.type) {
            case 'JS':
                return TE.fromTask(async () => jsPaymentModal.show({
                    response,
                }));
            case 'REDIRECT':
                return TE.fromTask(async () => {
                    document.location.href = response.paymentUrl;
                    await (0, commons_1.timeout)(3600000);
                });
            default: {
                const data = response;
                console.warn('Unknown response payment method type!', data);
                return TE.left(NEA.of(new booking_form_exception_1.BookingFormException('unknown-payment-method', 'Unknown payment method!')));
            }
        }
    };
};
exports.useBookingWizardHandleBookPayment = useBookingWizardHandleBookPayment;
