"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopFooter = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const wobbly_gallery_1 = require("@gerber/wobbly-gallery");
const commons_front_1 = require("@gerber/commons-front");
const react_1 = require("react");
const booking_desktop_footer_styled_1 = require("./booking-desktop-footer.styled");
const ui_1 = require("client/ui");
const context_1 = require("client/modules/booking/booking-wizard/context");
exports.BookingDesktopFooter = (0, react_1.memo)(({ nextButton, disabled, onBack: onForwardBack, onNext, ...props }) => {
    const slide = (0, wobbly_gallery_1.useWobblyGalleryItemContext)();
    const navigation = (0, context_1.useBookingWizardNavigation)();
    const onBack = event => {
        navigation.context.prev();
        onForwardBack?.(event);
    };
    return ((0, jsx_runtime_1.jsxs)(booking_desktop_footer_styled_1.BookingDesktopFooterHolder, { ...props, children: [!slide.first && ((0, jsx_runtime_1.jsx)(booking_desktop_footer_styled_1.BookingDesktopFooterLeft, { children: (0, jsx_runtime_1.jsx)(ui_1.BackButton, { ...(0, commons_front_1.testid)('wizard:back'), type: "button", disabled: disabled?.prev, onClick: onBack }) })), !nextButton && !slide.last && ((0, jsx_runtime_1.jsx)(booking_desktop_footer_styled_1.BookingDesktopFooterRight, { children: (0, jsx_runtime_1.jsx)(ui_1.ContinueButton, { disabled: disabled?.next, ...(0, commons_front_1.testid)('wizard:continue'), ...(!disabled?.next && { $weight: 700 }), ...(onNext
                        ? { onClick: onNext, type: 'button' }
                        : { type: 'submit' }) }) })), nextButton && ((0, jsx_runtime_1.jsx)(booking_desktop_footer_styled_1.BookingDesktopFooterRight, { children: nextButton }))] }));
}, (prevProps, nextProps) => !nextProps.nextButton &&
    !prevProps.disabled?.next === !nextProps.disabled?.next &&
    !prevProps.disabled?.prev === !nextProps?.disabled?.prev);
exports.BookingDesktopFooter.displayName = 'BookingDesktopFooter';
