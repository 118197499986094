"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokNavigationBlockV = void 0;
const zod_1 = require("zod");
const sdk_storyblok_block_dto_1 = require("../../sdk-storyblok-block.dto");
const parts_1 = require("../../parts");
exports.SdkStoryblokNavigationBlockV = (0, sdk_storyblok_block_dto_1.SdkStoryblokBlockV)('Navigation', {
    title: zod_1.z.string(),
    phone: zod_1.z.string(),
    logo: parts_1.SdkStoryblokPictureV,
    homepage: parts_1.SdkStoryblokLinkV,
});
