"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBookingStartScreenForm = void 0;
const commons_1 = require("@gerber/commons");
const forms_1 = require("@under-control/forms");
const hooks_1 = require("client/hooks");
const i18n_1 = require("client/i18n");
const form_1 = require("client/modules/booking/booking-wizard/form");
const useBookingStartScreenForm = (attrs) => {
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.validation;
    const { required } = (0, hooks_1.usePredefinedFormValidators)();
    return (0, form_1.useBookingWizardRoomScreenForm)({
        ...attrs,
        affectedParentFields: () => ({
            date: null,
            offer: {
                selected: null,
            },
        }),
        validation: {
            validators: ({ path }) => [
                required('who.adults', field => !field.value.length),
                path('who.children', ({ value }) => (value || []).flatMap((child, index) => {
                    if ((0, commons_1.isNil)(child.age)) {
                        return [
                            (0, forms_1.error)(t.missingChildrenAge, void 0, `who.children[${index}]`),
                        ];
                    }
                    return [];
                })),
            ],
        },
    });
};
exports.useBookingStartScreenForm = useBookingStartScreenForm;
