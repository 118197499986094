"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Container = exports.CONTAINER_WIDE_SPACING = void 0;
const styled_components_1 = require("styled-components");
const helpers_1 = require("../helpers");
exports.CONTAINER_WIDE_SPACING = 180;
exports.Container = styled_components_1.styled.div.withConfig({
    shouldForwardProp: (0, helpers_1.shouldNotForwardProps)(['wide']),
}) `
  margin: 0 auto;

  ${({ wide }) => {
    const width = 960 + (wide ? exports.CONTAINER_WIDE_SPACING * 2 : 0);
    return (0, styled_components_1.css) `
      width: min(${width}px, 100%);
      max-width: calc(100vw - 30px);
    `;
}}
`;
