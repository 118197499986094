"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingPersonInfoRowFormGrid = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const shared_1 = require("../../../shared");
exports.BookingPersonInfoRowFormGrid = (0, styled_components_1.styled)(shared_1.BookingContactFormGrid) `
  grid-template-columns: 1fr;
  grid-template-areas: 'first-name' 'last-name' 'birth-date';

  ${commons_front_1.styles.mediaSizeUp.xs} {
    grid-template-columns: repeat(3, auto);
    grid-template-areas: 'first-name last-name birth-date';
  }
`;
