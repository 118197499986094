"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbJumbotronC = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
const hooks_1 = require("./hooks");
const storyblok_jumbotron_button_1 = require("./storyblok-jumbotron-button");
const parts_1 = require("./parts");
const components_1 = require("client/components");
const SbJumbotronC = ({ item, }) => {
    const isMobile = (0, commons_front_1.useIsMobile)();
    const { refs } = (0, hooks_1.useBackgroundInterpolation)();
    return ((0, jsx_runtime_1.jsxs)("article", { ...(0, react_1.sdkStoryblokEditableProps)(item), children: [isMobile ? ((0, jsx_runtime_1.jsxs)(parts_1.SbJumbotronWrapper, { children: [(0, jsx_runtime_1.jsx)(parts_1.SbJumbotronImage, { ref: refs.source, picture: item.image }), item.smLogo && (0, jsx_runtime_1.jsx)(parts_1.SbJumbotronLogo, { picture: item.smLogo })] })) : ((0, jsx_runtime_1.jsxs)(parts_1.SbJumbotronWrapper, { "$expanded": true, children: [(0, jsx_runtime_1.jsx)(parts_1.SbJumbotronImage, { ref: refs.source, picture: item.image }), (0, jsx_runtime_1.jsxs)(parts_1.SbInterpolationJumbotronImage, { ref: refs.interpolation, children: [(0, jsx_runtime_1.jsx)(parts_1.SbInterpolationJumbotronPicture, { picture: item.image, optimize: {
                                    size: {
                                        w: isMobile ? 600 : 2500,
                                        h: 0,
                                    },
                                } }), item.smLogo && (0, jsx_runtime_1.jsx)(parts_1.SbJumbotronLogo, { picture: item.smLogo })] })] })), !isMobile && (0, jsx_runtime_1.jsx)(storyblok_jumbotron_button_1.StoryblokJumbotronButton, {}), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 14, "$max-sm:y": 10 }), (0, jsx_runtime_1.jsx)(react_1.SdkSbComponentsListRenderer, { items: item.content, wrapped: true, animProps: {
                    animClassName: isMobile
                        ? 'animate-fade-in-from-down'
                        : 'animate-fade-in-from-left',
                } })] }));
};
exports.SbJumbotronC = SbJumbotronC;
