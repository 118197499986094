"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckCircleButton = exports.CIRCLE_CHECKBOX_SIZE = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const checkbox_base_button_1 = require("./checkbox-base-button");
exports.CIRCLE_CHECKBOX_SIZE = '28px';
exports.CheckCircleButton = (0, styled_components_1.styled)(checkbox_base_button_1.CheckboxBaseButton) `
  width: ${exports.CIRCLE_CHECKBOX_SIZE};
  height: ${exports.CIRCLE_CHECKBOX_SIZE};
  border: 1px solid ${({ theme }) => theme.color.parts.checkbox.main};
  border-radius: 100%;

  ${({ value }) => value &&
    (0, styled_components_1.css) `
      :after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        transform: translate(-50%, -50%);
        background: ${({ theme }) => theme.color.parts.checkbox.main};
        border-radius: 100% 100%;
      }
    `}

  ${({ $invalid, theme }) => $invalid &&
    (0, styled_components_1.css) `
      border: 1px solid ${theme.color.base.error.main};
    `}

  ${({ $disabled }) => $disabled && commons_front_1.styles.disabledInputInner}
`;
