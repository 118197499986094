"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokHotelCardsListBlockV = void 0;
const commons_1 = require("@gerber/commons");
const sdk_storyblok_block_dto_1 = require("../../sdk-storyblok-block.dto");
const sdk_storyblok_hotel_card_story_1 = require("../../stories/sdk-storyblok-hotel-card.story");
const SdkStoryblokHotelCardsV = sdk_storyblok_hotel_card_story_1.SdkStoryblokHotelCardItemStoryV.array().transform((0, commons_1.pluck)('content'));
exports.SdkStoryblokHotelCardsListBlockV = (0, sdk_storyblok_block_dto_1.SdkStoryblokBlockV)('Hotel_Cards_List', {
    cards: SdkStoryblokHotelCardsV,
});
