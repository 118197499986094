"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingPackageGroupDetailsModal = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const package_group_details_styled_1 = require("./package-group-details.styled");
const mediums_preview_1 = require("client/modules/mediums-preview");
const md_description_1 = require("client/modules/md-description");
const components_1 = require("client/components");
const ui_1 = require("client/ui");
const BookingPackageGroupDetailsModal = ({ packageGroup, renderCta, onAfterChoose, ...props }) => {
    const { name, description, includedServices, mediums } = packageGroup;
    return ((0, jsx_runtime_1.jsx)(ui_1.BaseModal, { ...props, size: 1200, children: (0, jsx_runtime_1.jsxs)(package_group_details_styled_1.BookingPackageGroupDetailsModalContent, { children: [(0, jsx_runtime_1.jsxs)(commons_front_1.GridArea, { "$name": "description", children: [(0, jsx_runtime_1.jsx)(ui_1.BaseModalHeader, { children: name }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 6 }), (0, jsx_runtime_1.jsx)(md_description_1.MdDescriptionContent, { content: description }), includedServices && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 4 }), (0, jsx_runtime_1.jsx)(md_description_1.MdDescriptionContent, { content: includedServices })] }))] }), (0, jsx_runtime_1.jsx)(commons_front_1.GridArea, { "$name": "preview", children: (0, jsx_runtime_1.jsx)(mediums_preview_1.MediumsPreview, { mediums: mediums }) }), (0, jsx_runtime_1.jsx)(commons_front_1.GridArea, { "$name": "toolbar", "$placeSelf": "end", children: renderCta?.({ onAfterChoose }) ?? ((0, jsx_runtime_1.jsx)(ui_1.ExitButton, { direction: "bottom", onClick: () => {
                            onAfterChoose();
                        } })) })] }) }));
};
exports.BookingPackageGroupDetailsModal = BookingPackageGroupDetailsModal;
