"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetSearchParamsAndDropIO = void 0;
const function_1 = require("fp-ts/function");
const commons_1 = require("@gerber/commons");
const use_vanilla_history_1 = require("./use-vanilla-history");
const useGetSearchParamsAndDropIO = (schema, resetValue) => {
    const vanillaHistory = (0, use_vanilla_history_1.useVanillaHistory)();
    const dropSearchParams = () => {
        vanillaHistory.replaceState((0, function_1.pipe)(document.location.href, (0, commons_1.withSearchParams)(resetValue)));
    };
    return () => (0, function_1.pipe)((0, commons_1.parseWindowSearchParams)(schema), (0, commons_1.tapEither)(dropSearchParams));
};
exports.useGetSearchParamsAndDropIO = useGetSearchParamsAndDropIO;
