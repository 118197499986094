"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterMediumVariantsByType = exports.SdkMediumV = void 0;
const zod_1 = require("zod");
const SdkMediumTypeV = zod_1.z.enum(['SMALL', 'MEDIUM', 'LARGE']);
const SdkMediumFormatV = zod_1.z.enum(['WEBP', 'JPEG']);
const SdkMediumVariantV = zod_1.z.object({
    type: SdkMediumTypeV,
    format: SdkMediumFormatV,
    width: zod_1.z.number(),
    height: zod_1.z.number(),
    url: zod_1.z.string(),
});
exports.SdkMediumV = zod_1.z.object({
    description: zod_1.z.string(),
    title: zod_1.z.string(),
    variants: zod_1.z.array(SdkMediumVariantV),
});
const filterMediumVariantsByType = (type) => (variants) => variants.filter(variant => variant.type === type);
exports.filterMediumVariantsByType = filterMediumVariantsByType;
