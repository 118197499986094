"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbJumbotronLogo = void 0;
const styled_components_1 = require("styled-components");
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
const commons_front_1 = require("@gerber/commons-front");
exports.SbJumbotronLogo = (0, styled_components_1.styled)(react_1.SbPicture).attrs({
    optimize: {
        size: {
            w: 260,
            h: 0,
        },
    },
}) `
  ${commons_front_1.styles.absoluteCenter}

  width: min(230px, 50%);
`;
