"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStoryblokSdkOrNil = exports.useStoryblokSdk = void 0;
const react_1 = require("react");
const commons_front_1 = require("@gerber/commons-front");
const context_1 = require("../context");
const useStoryblokSdk = () => (0, commons_front_1.useContextOrThrow)(context_1.SdkStoryblokContext, 'Missing storyblok sdk context in tree!');
exports.useStoryblokSdk = useStoryblokSdk;
const useStoryblokSdkOrNil = () => (0, react_1.useContext)(context_1.SdkStoryblokContext);
exports.useStoryblokSdkOrNil = useStoryblokSdkOrNil;
