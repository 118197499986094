"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toSelectItemOrNil = exports.toSelectItem = void 0;
const commons_1 = require("@gerber/commons");
const is_select_list_item_1 = require("../guards/is-select-list-item");
const toSelectItem = (duplicateNameAsID = false) => (name) => {
    if ((0, is_select_list_item_1.isSelectListItem)(name)) {
        return name;
    }
    return {
        id: duplicateNameAsID ? name : null,
        name,
    };
};
exports.toSelectItem = toSelectItem;
const toSelectItemOrNil = (duplicateNameAsID = false) => (name) => {
    if ((0, commons_1.isNil)(name)) {
        return null;
    }
    return (0, exports.toSelectItem)(duplicateNameAsID)(name);
};
exports.toSelectItemOrNil = toSelectItemOrNil;
