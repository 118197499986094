"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UniversalLink = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const wouter_1 = require("wouter");
const forms_1 = require("@under-control/forms");
const commons_1 = require("@gerber/commons");
const raw_link_1 = require("./raw-link");
const UniversalLink = ({ href, stopPropagation = true, openInNewTab, ...props }) => {
    const isAbsoluteHref = (0, commons_1.isAbsoluteUrl)(href);
    const isPhoneHref = (0, commons_1.isPhoneUrl)(href);
    const isMailHref = (0, commons_1.isMailUrl)(href);
    const isHashHref = (0, commons_1.isHashUrl)(href);
    const router = (0, wouter_1.useRouter)();
    const onClick = event => {
        if (isHashHref) {
            const section = document.getElementById(href.slice(1));
            if (section) {
                (0, forms_1.suppressEvent)(event);
                section.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }
        if (stopPropagation) {
            event.stopPropagation();
        }
        props?.onClick?.(event);
    };
    const sharedProps = {
        ...(openInNewTab && {
            target: '_blank',
        }),
        onClick,
    };
    if (isPhoneHref ||
        isMailHref ||
        isHashHref ||
        isAbsoluteHref ||
        props.target === '_blank') {
        let url = href;
        if (!isHashHref && !isAbsoluteHref) {
            url = (0, commons_1.concatUrls)(router.base ?? '', href);
        }
        return (0, jsx_runtime_1.jsx)(raw_link_1.RawLink, { href: url, ...props, ...sharedProps });
    }
    return ((0, jsx_runtime_1.jsx)(wouter_1.Link, { href: href, children: (0, jsx_runtime_1.jsx)(raw_link_1.RawLink, { ...props, ...sharedProps }) }));
};
exports.UniversalLink = UniversalLink;
