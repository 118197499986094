"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkSessionV = exports.SdkSessionIdV = void 0;
const zod_1 = require("zod");
const commons_1 = require("@gerber/commons");
const sdk_session_user_dto_1 = require("./sdk-session-user.dto");
const sdk_session_item_dto_1 = require("./sdk-session-item.dto");
const sdk_session_deposit_dto_1 = require("./sdk-session-deposit.dto");
exports.SdkSessionIdV = zod_1.z.string();
exports.SdkSessionV = zod_1.z.object({
    sessionId: exports.SdkSessionIdV,
    user: zod_1.z.union([sdk_session_user_dto_1.SdkSessionUserV.optional(), commons_1.castBlankObjectToNullV]),
    items: zod_1.z.array(sdk_session_item_dto_1.SdkSessionItemV).optional().default([]),
    deposit: sdk_session_deposit_dto_1.SdkSessionDepositV.optional(),
});
