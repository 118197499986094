"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingCalendarCellPreselectedFooter = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const commons_1 = require("@gerber/commons");
const booking_calendar_cell_footer_1 = require("./booking-calendar-cell-footer");
const i18n_1 = require("client/i18n");
const constants_1 = require("client/modules/booking/booking-calendar/constants");
exports.BookingCalendarCellPreselectedFooter = (0, react_1.memo)(({ cell, range }) => {
    const { pack } = (0, i18n_1.useI18n)();
    const currentDateRangeSize = (0, commons_1.getTotalDatesRangeDates)({
        begin: cell.date,
        end: range.end,
    }) + 1;
    const totalRangeSize = (0, commons_1.getTotalDatesRangeDates)({
        begin: range.begin,
        end: range.end,
    });
    if (currentDateRangeSize < 0) {
        return null;
    }
    const distanceToEndOfWeek = constants_1.CALENDAR_WEEK_TOTAL_CELLS -
        (0, commons_1.transformDayToSundayLast)(cell.date.getDay()) +
        1;
    // start selection on first column (when there is a break) OR if first date of range
    if (distanceToEndOfWeek !== constants_1.CALENDAR_WEEK_TOTAL_CELLS &&
        !(0, commons_1.compareDateOnly)(cell.date)(range.begin)) {
        return null;
    }
    const cellSpan = Math.min(currentDateRangeSize, distanceToEndOfWeek);
    const formattedText = (0, commons_1.format)(range.title ?? range.text ?? pack.booking.wizard.date.minStay, [totalRangeSize]);
    return ((0, jsx_runtime_1.jsx)(booking_calendar_cell_footer_1.BookingCalendarCellFooter, { "$span": cellSpan, title: formattedText, children: formattedText }));
});
exports.BookingCalendarCellPreselectedFooter.displayName =
    'BookingCalendarCellPreselectedFooter';
