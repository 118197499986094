"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingSidebarHeaderToolbarHolder = void 0;
const styled_components_1 = require("styled-components");
exports.BookingSidebarHeaderToolbarHolder = styled_components_1.styled.div `
  display: flex;
  flex-direction: row;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    gap: ${spacing[3]};
  `}
`;
