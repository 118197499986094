"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingMobileFooterArrowButton = exports.BookingMobileFooterContent = exports.BookingMobileFooterHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BookingMobileFooterHolder = styled_components_1.styled.div `
  display: grid;
  grid-template: 'left content right' / min-content 1fr min-content;
  place-items: center;

  ${({ theme: { spacing, color } }) => (0, styled_components_1.css) `
    padding: ${spacing[2]} ${spacing[3]};
    background: ${color.alias.lightGray.main};
  `}
`;
exports.BookingMobileFooterContent = styled_components_1.styled.div `
  grid-area: content;
  max-width: 100%;
`;
exports.BookingMobileFooterArrowButton = (0, styled_components_1.styled)(commons_front_1.CleanButton) `
  height: 100%;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    padding: 0 ${spacing[3]};
  `}

  &[disabled] {
    opacity: 0.25;
  }
`;
