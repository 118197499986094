"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormErrors = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const commons_1 = require("@gerber/commons");
const icons_1 = require("@gerber/icons");
const form_errors_styled_1 = require("./form-errors.styled");
const components_1 = require("client/components");
const FormErrors = ({ errors, className }) => {
    const uniqErrors = (0, react_1.useMemo)(() => (0, commons_1.unique)(errors), [errors]);
    if (!errors.length) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(form_errors_styled_1.FormErrorsHolder, { className: className, children: uniqErrors.map(error => ((0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)(components_1.LabeledIcon, { as: "div", color: "error", align: "center", size: "sm", icon: (0, jsx_runtime_1.jsx)(icons_1.WarningIcon, {}), children: error }) }, error))) }));
};
exports.FormErrors = FormErrors;
