"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WhoSummary = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const function_1 = require("fp-ts/function");
const Array_1 = require("fp-ts/Array");
const commons_1 = require("@gerber/commons");
const editable_1 = require("../../editable");
const summary_box_styled_1 = require("../../editable/summary-box.styled");
const i18n_1 = require("client/i18n");
const context_1 = require("client/modules/booking/booking-wizard/context");
const WhoSummary = ({ who, isEditable }) => {
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.summary;
    const navigation = (0, context_1.useBookingWizardNavigation)();
    const groupedKids = (0, react_1.useMemo)(() => (0, function_1.pipe)(who.children, (0, commons_1.groupByProp)('age'), obj => Object.entries(obj), (0, Array_1.map)(([age, kids]) => ({
        age: +age,
        total: kids.length,
    }))), [who]);
    const onEditClick = () => {
        navigation.goTo('who');
    };
    return ((0, jsx_runtime_1.jsxs)(editable_1.SummaryEditableSection, { isEditable: isEditable && navigation.step !== 'who', onEditClick: onEditClick, children: [(0, jsx_runtime_1.jsx)(summary_box_styled_1.SummaryBox, { children: (0, commons_1.format)(t.adults, { total: who.adults.length }) }), groupedKids.map(kids => ((0, jsx_runtime_1.jsx)(summary_box_styled_1.SummaryBox, { children: (0, commons_1.format)(t.kids, kids) }, kids.age)))] }));
};
exports.WhoSummary = WhoSummary;
