"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSelectedItem = exports.safePickListItem = void 0;
const react_1 = require("react");
const commons_1 = require("@gerber/commons");
const is_select_list_item_1 = require("../guards/is-select-list-item");
const safePickListItem = (value, items) => {
    if ((0, commons_1.isNil)(value) || value === '' || value === null) {
        return null;
    }
    if ((0, is_select_list_item_1.isSelectListItem)(value)) {
        return value;
    }
    return ((0, commons_1.findItemById)(value)(items || []) ?? {
        id: value,
        name: value,
    });
};
exports.safePickListItem = safePickListItem;
const useSelectedItem = (value, items) => (0, react_1.useMemo)(() => (0, exports.safePickListItem)(value, items), [value, items]);
exports.useSelectedItem = useSelectedItem;
