"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingMobileStartBackground = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BookingMobileStartBackground = (0, styled_components_1.styled)(commons_front_1.Picture) `
  ${commons_front_1.styles.layer.absolute}

  z-index: -1;
  object-fit: cover;
`;
