"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Grid = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const config = {
    shouldForwardProp: (0, commons_front_1.shouldNotForwardProps)([
        ...(0, commons_front_1.maxBreakpointFields)('template'),
        ...(0, commons_front_1.maxBreakpointFields)('gap'),
    ]),
};
exports.Grid = styled_components_1.styled.div.withConfig(config) `
  display: grid;

  ${(0, commons_front_1.maxBreakpointProp)('gap')(gap => gap &&
    (0, styled_components_1.css) `
        gap: ${({ theme }) => theme.spacing[gap]};
      `)}

  ${(0, commons_front_1.maxBreakpointProp)('template')(template => template &&
    (0, styled_components_1.css) `
        grid-template-columns: ${template};
      `)}
`;
