"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoreInformationCleanButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_1 = require("@gerber/icons");
const commons_front_1 = require("@gerber/commons-front");
const button_1 = require("../../../button");
const i18n_1 = require("client/i18n");
const MoreInformationCleanButton = ({ withIcon = true, short = false, ...props }) => {
    const { pack } = (0, i18n_1.useI18n)();
    return ((0, jsx_runtime_1.jsxs)(commons_front_1.CleanButton, { type: "button", ...props, children: [pack.common[short ? 'more' : 'moreInformation'], withIcon && ((0, jsx_runtime_1.jsx)(button_1.ButtonSuffixIcon, { children: (0, jsx_runtime_1.jsx)(icons_1.LongArrowRightThinIcon, { direction: "right" }) }))] }));
};
exports.MoreInformationCleanButton = MoreInformationCleanButton;
