"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBookingTotalPrice = void 0;
const get_booking_room_price_1 = require("./get-booking-room-price");
const getBookingTotalPrice = (value) => value.rooms.reduce((acc, room) => {
    const roomPrice = (0, get_booking_room_price_1.getBookingRoomPrice)(room);
    if (!roomPrice) {
        return null;
    }
    return {
        ...roomPrice,
        approx: roomPrice.approx || !!acc?.approx,
        amount: +(acc?.amount ?? 0) + +roomPrice.amount,
    };
}, null);
exports.getBookingTotalPrice = getBookingTotalPrice;
