"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbDescriptionImageHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const config = {
    shouldForwardProp: (0, commons_front_1.shouldNotForwardProps)([
        'align',
    ]),
};
exports.SbDescriptionImageHolder = styled_components_1.styled.article.withConfig(config) `
  display: grid;
  align-items: center;
  grid-template: 'image' 'content';

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    gap: ${spacing[8]};
  `}

  ${commons_front_1.styles.mediaSizeUp.lg} {
    ${({ theme: { spacing } }) => (0, styled_components_1.css) `
      gap: ${spacing[12]};
    `}

    ${({ align }) => {
    switch (align) {
        case 'left':
            return (0, styled_components_1.css) `
            grid-template: 'image content' / 45% 1fr;
          `;
        case 'right':
            return (0, styled_components_1.css) `
            grid-template: 'content image' / 1fr 45%;
          `;
        default: {
            const unknownAlign = align;
            console.warn('Unknown align:', unknownAlign);
            return null;
        }
    }
}}
  }
`;
