"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbGalleryItemC = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
const storyblok_gallery_item_styled_1 = require("./storyblok-gallery-item.styled");
const components_1 = require("client/components");
const parts_1 = require("client/modules/landing-page/parts");
const SbGalleryItemC = ({ item }) => {
    const { anim } = (0, commons_front_1.useInitialAnimatedElement)();
    return ((0, jsx_runtime_1.jsxs)(storyblok_gallery_item_styled_1.SbGalleryItemHolder, { ...(0, react_1.sdkStoryblokEditableProps)(item), children: [(0, jsx_runtime_1.jsx)(storyblok_gallery_item_styled_1.SbGalleryPicture, { picture: item.image, loading: "lazy", ...anim('animate-fade-in-from-right') }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 6 }), (0, jsx_runtime_1.jsx)(components_1.H3, { ...anim('animate-fade-in-from-left'), children: item.title }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 4 }), (0, jsx_runtime_1.jsx)(parts_1.SbRichText, { text: item.description, ...anim('animate-fade-in-from-down') }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 0, "$max-md:y": 6 }), item.toolbar && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 4 }), (0, jsx_runtime_1.jsx)(react_1.SdkSbComponentsListRenderer, { items: item.toolbar, animProps: {
                            animClassName: 'animate-fade-in-from-down',
                        } })] }))] }));
};
exports.SbGalleryItemC = SbGalleryItemC;
