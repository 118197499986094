"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingConfirmSubtitle = void 0;
const styled_components_1 = require("styled-components");
const components_1 = require("client/components");
exports.BookingConfirmSubtitle = (0, styled_components_1.styled)(components_1.H2) `
  font-size: 1.65rem;
  line-height: 1.3em;
  font-weight: 500;
`;
