"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fontFaceLocal = exports.fontFaceRemote = exports.fontFace = void 0;
const function_1 = require("fp-ts/function");
const styled_components_1 = require("styled-components");
const commons_1 = require("@gerber/commons");
const urlSrc = (basePrefix) => (url) => `url('${(0, commons_1.concatUrlParts)(basePrefix, url)}')`;
const localSrc = (name) => `local('${name}')`;
const fontFace = (src) => (styles) => (0, styled_components_1.css) `
    @font-face {
      src: ${src};
      font-family: '${({ theme }) => theme.font.name}';
      font-display: swap;

      ${styles}
    }
  `;
exports.fontFace = fontFace;
const fontFaceRemote = (url) => (styles) => (attrs) => (0, function_1.pipe)(url, urlSrc(attrs.basePrefix ?? '/'), exports.fontFace)(styles);
exports.fontFaceRemote = fontFaceRemote;
exports.fontFaceLocal = (0, function_1.flow)(localSrc, exports.fontFace);
