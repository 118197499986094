"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dropLastSlash = void 0;
const dropLastSlash = (url) => {
    if (!url) {
        return '';
    }
    return url.endsWith('/') ? url.slice(0, -1) : url;
};
exports.dropLastSlash = dropLastSlash;
