"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkSessionUserV = exports.SdkSessionUserInputV = exports.SdkSessionSalutationV = void 0;
const zod_1 = require("zod");
exports.SdkSessionSalutationV = zod_1.z.enum(['female', 'male']);
exports.SdkSessionUserInputV = zod_1.z.object({
    salutation: exports.SdkSessionSalutationV.catch('male'),
    title: zod_1.z.string().optional(),
    firstname: zod_1.z.string(),
    lastname: zod_1.z.string(),
    email: zod_1.z.string(),
    street: zod_1.z.string(),
    zipcode: zod_1.z.string(),
    city: zod_1.z.string(),
    country: zod_1.z.string(),
    phone: zod_1.z.string().optional(),
});
exports.SdkSessionUserV = exports.SdkSessionUserInputV.partial();
