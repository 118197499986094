"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSitemap = void 0;
const function_1 = require("fp-ts/function");
const react_1 = require("@gerber/sdk-gerber/dist/third-party/react");
const commons_1 = require("@gerber/commons");
const i18n_1 = require("client/i18n");
const config_1 = require("client/config");
const useSitemap = () => {
    const sdkCtx = (0, react_1.useGerberSdkOrNil)();
    const { lang } = (0, i18n_1.useI18n)();
    const { basePrefix } = (0, config_1.useAppConfig)();
    const withParams = (path) => ({ params, hash, search, withOrigin, withBasePrefix = true, schemaOnly, oneId, } = {}) => (0, function_1.pipe)(path, (0, commons_1.concatUrlParts2C)(withBasePrefix ? basePrefix : '', '/:lang'), withOrigin ? commons_1.prependCurrentOrigin : commons_1.identity, schemaOnly
        ? commons_1.identity
        : (0, commons_1.parameterizePath)({
            lang,
            oneId: oneId ?? sdkCtx?.sdk.client.config.oneId ?? '-',
            ...(params ?? {}),
        }), (0, commons_1.withHash)(hash), search ? (0, commons_1.withSearchParams)(search) : commons_1.identity);
    return {
        wizard: {
            root: withParams('/book/:oneId'),
            payment: {
                success: withParams('/payment/:oneId/success'),
                error: withParams('/payment/:oneId/error'),
                cancel: withParams('/payment/:oneId/cancel'),
            },
        },
        landingPage: {
            show: (attrs) => withParams(attrs.schemaOnly ? '/:slug*' : '/landing-page/:slug')(attrs),
        },
    };
};
exports.useSitemap = useSitemap;
