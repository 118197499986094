"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbFooterSocialText = exports.SbFooterSocialIcon = exports.SbFooterSocialHolder = void 0;
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
const styled_components_1 = require("styled-components");
const parts_1 = require("client/modules/landing-page/parts");
exports.SbFooterSocialHolder = (0, styled_components_1.styled)(parts_1.SbLink) `
  display: grid;
  grid-template-columns: 32px 1fr;
  align-items: center;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    gap: ${spacing[4]};

    &:not(:last-child) {
      margin-bottom: ${spacing[3]};
    }
  `}
`;
exports.SbFooterSocialIcon = (0, styled_components_1.styled)(react_1.SbPicture).attrs({
    optimize: {
        size: {
            w: 48,
            h: 0,
        },
    },
}) `
  width: 100%;
`;
exports.SbFooterSocialText = styled_components_1.styled.span ``;
