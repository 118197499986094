"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingCalendarCellContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const commons_1 = require("@gerber/commons");
const helpers_1 = require("../helpers");
const booking_calendar_cell_skeleton_1 = require("./booking-calendar-cell.skeleton");
const footer_1 = require("./footer");
const booking_calendar_cell_1 = require("./booking-calendar-cell");
const BookingCalendarCellContainer = ({ showDayShortcut, selection, disabledRanges, skeletonRange, selectionOffers, preselectedRanges, connectors, cell, onClick, onClear, }) => {
    const preselectedRange = (0, react_1.useMemo)(() => (0, commons_1.findInDateRanges)(cell.date)(preselectedRanges ?? []), [cell.date, preselectedRanges]);
    const { selected, disabled } = (0, react_1.useMemo)(() => ({
        selected: (0, helpers_1.isSelectedCell)(cell)(selection?.range),
        disabled: (0, helpers_1.isDisabledCell)(cell)({
            disabledRanges,
            selection: selection?.range,
            offers: selectionOffers,
        }),
    }), [cell, selectionOffers, disabledRanges, selection?.range]);
    if (cell.loading ||
        (!!skeletonRange && (0, commons_1.isInDateRange)(skeletonRange)(cell.date))) {
        return (0, jsx_runtime_1.jsx)(booking_calendar_cell_skeleton_1.BookingCalendarCellSkeleton, {}, cell.date.toString());
    }
    return ((0, jsx_runtime_1.jsx)(booking_calendar_cell_1.BookingCalendarCell, { info: cell, showDayShortcut: showDayShortcut, selected: selected, disabled: disabled, onClick: onClick, onClear: onClear, ...(preselectedRange?.text && {
            tooltip: preselectedRange.text,
        }), ...(connectors &&
            selected && {
            connectors,
        }), children: preselectedRange && ((0, jsx_runtime_1.jsx)(footer_1.BookingCalendarCellPreselectedFooter, { range: preselectedRange, cell: cell })) }));
};
exports.BookingCalendarCellContainer = BookingCalendarCellContainer;
