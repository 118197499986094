"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingStartScreenDesktop = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const shared_1 = require("../shared");
const hooks_1 = require("../hooks");
const context_1 = require("client/modules/booking/booking-wizard/context");
const decorators_1 = require("client/modules/booking/booking-wizard/decorators");
const form_1 = require("client/modules/booking/booking-wizard/form");
const i18n_1 = require("client/i18n");
const ui_1 = require("client/ui");
const parts_1 = require("client/modules/booking/booking-wizard/parts");
exports.BookingStartScreenDesktop = (0, decorators_1.controlledBookingValue)(({ control }) => {
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.start;
    const config = (0, context_1.useBookingWizardConfigContext)();
    const { bind, value, handleSubmitEvent, validator: { errors }, } = (0, hooks_1.useBookingStartScreenForm)({
        control,
    });
    return ((0, jsx_runtime_1.jsx)(ui_1.Form, { onSubmit: handleSubmitEvent, ...(0, commons_front_1.testid)('wizard:start'), children: (0, jsx_runtime_1.jsxs)(parts_1.BookingDesktopScreenContent, { prepend: (0, jsx_runtime_1.jsx)(parts_1.BookingJumbotron, { src: config.start.background }), header: t.header, footer: (0, jsx_runtime_1.jsx)(parts_1.BookingDesktopFooterShadow, { children: (0, jsx_runtime_1.jsx)(parts_1.BookingDesktopFooter, { disabled: {
                        next: errors.all.length > 0 || !(0, form_1.isWhoBookingSectionFilled)(value),
                    } }) }), children: [value.packageGroup && (0, jsx_runtime_1.jsx)(shared_1.StartScreenPackageNotification, {}), (0, jsx_runtime_1.jsx)(shared_1.StartScreenFormContent, { ...bind.path('who'), ...errors.extract('who', { nested: true }) })] }) }));
});
