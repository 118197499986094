"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHighlightedItemIndex = void 0;
const react_1 = require("react");
const commons_1 = require("@gerber/commons");
const commons_front_1 = require("@gerber/commons-front");
const useHighlightedItemIndex = ({ firstIndexAfterReset = -1, totalItems, resetKeys, disabled, onSelectItem, }) => {
    const [highlightedIndex, setHighlightedIndex] = (0, react_1.useState)(firstIndexAfterReset);
    (0, react_1.useEffect)(() => {
        setHighlightedIndex(firstIndexAfterReset);
    }, [totalItems, ...(resetKeys || [])]);
    const incrementHighlight = (sign) => {
        setHighlightedIndex((0, commons_1.clamp)(0, totalItems - 1, (highlightedIndex ?? firstIndexAfterReset) + sign));
    };
    (0, commons_front_1.useHotKeys)({
        ArrowUp: () => {
            incrementHighlight(-1);
        },
        ArrowDown: () => {
            incrementHighlight(1);
        },
        Enter: () => {
            // useful in tag input, preserve open state when adding new items
            if (highlightedIndex === firstIndexAfterReset && totalItems > 0) {
                onSelectItem(0);
            }
            else {
                onSelectItem(highlightedIndex);
                setHighlightedIndex(null);
            }
        },
    }, { disabled });
    return {
        highlightedIndex,
    };
};
exports.useHighlightedItemIndex = useHighlightedItemIndex;
