"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAsyncQueue = void 0;
const async_await_queue_1 = require("async-await-queue");
const react_1 = require("react");
const useAsyncQueue = ({ maxConcurrent }) => {
    const queueRef = (0, react_1.useRef)();
    if (!queueRef.current) {
        queueRef.current = new async_await_queue_1.Queue(maxConcurrent);
    }
    return queueRef.current;
};
exports.useAsyncQueue = useAsyncQueue;
