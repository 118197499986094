"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingMobileScreenContentWrapper = void 0;
const styled_components_1 = require("styled-components");
const ui_1 = require("client/ui");
exports.BookingMobileScreenContentWrapper = (0, styled_components_1.styled)(ui_1.Form) `
  display: grid;
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  height: 100dvh;
  grid-template:
    ${({ $hasToolbar }) => $hasToolbar && (0, styled_components_1.css) `'toolbar' min-content`}
    'content' 1fr
    'footer' min-content;
`;
