"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingSuccessConfirm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_1 = require("@gerber/icons");
const components_1 = require("../components");
const use_booking_confirm_tracker_1 = require("../use-booking-confirm-tracker");
const booking_success_confirm_styled_1 = require("./booking-success-confirm.styled");
const i18n_1 = require("client/i18n");
const shared_1 = require("client/modules/booking/booking-wizard/parts/shared");
const components_2 = require("client/components");
const BookingSuccessConfirm = () => {
    const t = (0, i18n_1.useI18n)().pack.booking.confirm.success;
    (0, use_booking_confirm_tracker_1.useBookingConfirmTracker)('success');
    return ((0, jsx_runtime_1.jsxs)(components_1.BookingConfirmHolder, { children: [(0, jsx_runtime_1.jsx)(components_1.BookingConfirmHeader, { children: t.title }), (0, jsx_runtime_1.jsx)(components_2.Spacer, { "$y": 8 }), (0, jsx_runtime_1.jsx)(components_2.Text, { dangerouslySetInnerHTML: { __html: t.description }, size: "lg" }), (0, jsx_runtime_1.jsx)(components_2.Divider, { "$space": 10, "$truncated": true }), (0, jsx_runtime_1.jsx)(components_1.BookingConfirmSubtitle, { children: t.nextSteps }), (0, jsx_runtime_1.jsx)(components_2.Spacer, { "$y": 7 }), (0, jsx_runtime_1.jsxs)(booking_success_confirm_styled_1.BookingSuccessShareRowHolder, { children: [(0, jsx_runtime_1.jsx)(components_2.LabeledIcon, { icon: (0, jsx_runtime_1.jsx)(icons_1.CheckCircleIcon, {}), children: t.shareWithYourFamily }), (0, jsx_runtime_1.jsx)(components_2.CleanList, { "$row": true, "$gap": 2, children: (0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)(shared_1.ShareCleanSessionButton, { "$size": "sm", icon: (0, jsx_runtime_1.jsx)(icons_1.ShareIcon, {}) }) }) })] })] }));
};
exports.BookingSuccessConfirm = BookingSuccessConfirm;
