"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomSelectInputOption = exports.SELECT_OPTION_MAGIC_CLASSES = void 0;
const styled_components_1 = require("styled-components");
const clsx_1 = require("clsx");
exports.SELECT_OPTION_MAGIC_CLASSES = {
    active: 'is-active',
    highlighted: 'is-highlighted',
};
exports.CustomSelectInputOption = styled_components_1.styled.li.attrs(({ className, $active, $highlighted, $hoverable = true }) => ({
    $hoverable,
    className: (0, clsx_1.clsx)(className, $active && exports.SELECT_OPTION_MAGIC_CLASSES.active, $highlighted && exports.SELECT_OPTION_MAGIC_CLASSES.highlighted),
})) `
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  user-select: none;
  cursor: ${({ $hoverable }) => $hoverable && 'pointer'};
  font-weight: ${({ $active }) => ($active ? 700 : 500)};
  white-space: nowrap;

  &:not(:last-child) {
    border-bottom: 1px solid
      ${({ theme }) => theme.color.parts.divider.light.main};
  }

  ${({ $highlighted, $hoverable }) => {
    if ($highlighted) {
        return (0, styled_components_1.css) `
        background: rgba(0, 0, 0, 0.08);
      `;
    }
    return (0, styled_components_1.css) `
      &.is-active ${$hoverable ? ', &:hover' : ''} {
        background: rgba(0, 0, 0, 0.05);
      }
    `;
}}
`;
