"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ensureInSsrOrThrow = void 0;
const is_ssr_1 = require("./is-ssr");
const ensureInSsrOrThrow = () => {
    if (!(0, is_ssr_1.isSSR)()) {
        throw new Error('This file should not be included in client bundle!');
    }
};
exports.ensureInSsrOrThrow = ensureInSsrOrThrow;
