"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingWeekDaysHeaderColumn = exports.BookingWeekDaysHeaderRow = void 0;
const styled_components_1 = require("styled-components");
const booking_calendar_table_styled_1 = require("../booking-calendar-table.styled");
exports.BookingWeekDaysHeaderRow = (0, styled_components_1.styled)(booking_calendar_table_styled_1.BookingCalendarTableRow) `
  background: none;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    padding: ${spacing[2]} 0;
  `}
`;
exports.BookingWeekDaysHeaderColumn = styled_components_1.styled.div `
  text-align: center;

  ${({ theme: { color, font } }) => (0, styled_components_1.css) `
    background: ${color.alias.white.main};
    font-size: ${font.size.xs};
  `}
`;
