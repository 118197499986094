"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomSelectInputHolder = void 0;
/* eslint-disable max-len */
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const input_1 = require("../../input");
exports.CustomSelectInputHolder = (0, styled_components_1.styled)(input_1.Input).attrs({
    as: 'div',
}) `
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-right: 50px;
  min-width: 100%;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  ${({ $allowType }) => $allowType &&
    (0, styled_components_1.css) `
      cursor: text;
    `}

  ${({ $filled, $fillIfValuePresent, theme: { color } }) => $filled &&
    (0, styled_components_1.css) `
      overflow: hidden;
      text-overflow: ellipsis;

      ${$fillIfValuePresent &&
        (0, styled_components_1.css) `
        ${(0, commons_front_1.genPaletteColorCSS)(color.parts.input.filled)}

        font-weight: 600;
      `}

      input {
        max-width: calc(100% - 20px);
      }
    `}

  ${({ $borderless }) => $borderless &&
    (0, styled_components_1.css) `
      border: 0;
      background: none;
      box-shadow: none;

      &:focus {
        outline: 0;
      }
    `}

  ${({ $hasPrefix, theme: { spacing } }) => $hasPrefix &&
    (0, styled_components_1.css) `
      flex-direction: row;
      flex-wrap: wrap;
      gap: ${spacing[2]} ${spacing[1]};
      line-height: 1rem;
      height: auto;

      input {
        width: auto;
        height: 28px;
      }
    `}

  ${({ $showArrow, $active, $filled, $fillIfValuePresent }) => $showArrow &&
    (0, styled_components_1.css) `
      &:after {
        content: ' ';
        position: absolute;
        width: 18px;
        height: 10px;
        right: 16px;
        top: 50%;
        transition: transform 250ms ease-in-out;
        transform: translateY(-50%) rotate(0deg);
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 0.5L9 8.5L17 0.5' stroke='black'/%3E%3C/svg%3E%0A");

        ${$active &&
        (0, styled_components_1.css) `
          transform: translateY(-50%) rotate(180deg);
        `}

        ${$fillIfValuePresent &&
        $filled &&
        (0, styled_components_1.css) `
          filter: invert(1);
        `}
      }
    `}
`;
