"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShowLessCleanButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const i18n_1 = require("client/i18n");
const ShowLessCleanButton = ({ children, ...props }) => {
    const { pack } = (0, i18n_1.useI18n)();
    return ((0, jsx_runtime_1.jsx)(commons_front_1.CleanButton, { type: "button", "$focusBorder": false, title: pack.common.showLess, ...props, children: children ?? pack.common.showLess }));
};
exports.ShowLessCleanButton = ShowLessCleanButton;
