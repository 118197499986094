"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GridArea = void 0;
const styled_components_1 = require("styled-components");
exports.GridArea = styled_components_1.styled.div `
  grid-area: ${({ $name }) => $name};

  ${({ $placeSelf }) => $placeSelf &&
    (0, styled_components_1.css) `
      place-self: ${$placeSelf};
    `}
`;
