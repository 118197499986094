"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbPackageGroupToolbarHolder = void 0;
const styled_components_1 = require("styled-components");
exports.SbPackageGroupToolbarHolder = styled_components_1.styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
