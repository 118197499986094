"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbJumbotronWrapper = void 0;
const styled_components_1 = require("styled-components");
exports.SbJumbotronWrapper = styled_components_1.styled.div `
  display: flex;
  align-items: flex-end;
  position: relative;

  ${({ $expanded }) => $expanded &&
    (0, styled_components_1.css) `
      height: 100dvh;
    `}
`;
