"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbHeaderC = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@gerber/sdk-storyblok/dist/third-party/react");
const headers_1 = require("client/components/headers");
const HEADER_COMPONENTS = {
    1: headers_1.H1,
    2: headers_1.H2,
    3: headers_1.H3,
};
const SbHeaderC = ({ item, }) => {
    const Component = HEADER_COMPONENTS[item.level];
    if (!Component) {
        console.warn('Incorrect header level:', item);
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(Component, { ...(0, react_1.sdkStoryblokEditableProps)(item), align: item.align, color: item.color, children: item.title }));
};
exports.SbHeaderC = SbHeaderC;
