"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createCleanListComponent = exports.cleanList = void 0;
const styled_components_1 = require("styled-components");
exports.cleanList = (0, styled_components_1.css) `
  margin: 0;
  padding: 0;
  list-style-type: none;
`;
const cleanListComponent = () => (0, styled_components_1.css) `
  ${exports.cleanList}

  display: flex;
  flex-direction: column;

  ${({ $type }) => $type &&
    (0, styled_components_1.css) `
      list-style-type: ${$type};
    `}

  ${({ $collapseEmpty }) => $collapseEmpty &&
    (0, styled_components_1.css) `
      &:empty {
        display: none;
      }
    `}

  ${({ $row, $centered }) => $row &&
    (0, styled_components_1.css) `
      flex-direction: row;
      place-items: ${$centered && 'center'};
    `}

  grid-gap: ${({ theme, $gap }) => theme.spacing[$gap]};

  ${({ $justify }) => $justify &&
    (0, styled_components_1.css) `
      justify-content: ${$justify};
    `}

  ${({ $wrap }) => $wrap &&
    (0, styled_components_1.css) `
      flex-wrap: wrap;
    `}

  ${({ $flexChildren }) => $flexChildren &&
    (0, styled_components_1.css) `
      > li {
        display: flex;
        align-items: center;
      }
    `}
`;
const createCleanListComponent = () => styled_components_1.styled.ol(cleanListComponent);
exports.createCleanListComponent = createCleanListComponent;
