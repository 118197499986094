"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBookingCalendarCellsMonthLoader = void 0;
const TE = __importStar(require("fp-ts/TaskEither"));
const function_1 = require("fp-ts/function");
const date_fns_1 = require("date-fns");
const react_1 = require("react");
const react_2 = require("@gerber/sdk-gerber/dist/third-party/react");
const client_1 = require("@gerber/ssr/dist/client");
const commons_1 = require("@gerber/commons");
const useBookingCalendarCellsMonthLoader = ({ month, cachedMonths, filters, skip, }) => {
    const { sdk } = (0, react_2.useGerberSdk)();
    const explicitCacheOrFetchMonthTask = () => {
        const cachedMonth = cachedMonths?.find(cache => (0, commons_1.compareDateOnly)(cache.days[0].date)(month));
        if (cachedMonth) {
            return TE.of(cachedMonth);
        }
        return sdk.controllers.oneCalendar.getMonth({
            ...filters,
            arrivalMonth: month,
        });
    };
    const offersResult = (0, client_1.useHydratedPromise)((0, function_1.pipe)(explicitCacheOrFetchMonthTask(), commons_1.tryOrThrowTE), {
        skip,
        uuid: 'month-days',
        deps: [month.getFullYear(), month.getMonth(), JSON.stringify(filters)],
        cache: {
            policy: 'client-only',
            expireInSeconds: 15000,
        },
    });
    const extendWithOfferData = (dates) => dates.map(date => ({
        date,
        loading: offersResult.loading,
        ...offersResult.value?.days?.find(offer => (0, commons_1.compareDateOnly)(date)(offer.date)),
    }));
    const days = (0, react_1.useMemo)(() => (0, function_1.pipe)((0, commons_1.getDateRangeDays)({
        begin: (0, date_fns_1.startOfMonth)(month),
        end: (0, date_fns_1.endOfMonth)(month),
    }), extendWithOfferData), [month.getFullYear(), month.getMonth(), offersResult.value]);
    return {
        days,
    };
};
exports.useBookingCalendarCellsMonthLoader = useBookingCalendarCellsMonthLoader;
