"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkConfigPackageV = void 0;
const zod_1 = require("zod");
const commons_1 = require("@gerber/commons");
const sdk_date_range_dto_1 = require("../../../dto/sdk-date-range.dto");
exports.SdkConfigPackageV = commons_1.IdNameV.extend({
    duration: zod_1.z.number().optional(),
    teaser: zod_1.z.string().optional(),
    description: zod_1.z.string().optional(),
    arrivalRanges: zod_1.z.array(sdk_date_range_dto_1.SdkDateRangeV),
});
