"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CancellationSummaryHolder = void 0;
const styled_components_1 = require("styled-components");
const components_1 = require("client/components");
exports.CancellationSummaryHolder = (0, styled_components_1.styled)(components_1.LabeledIcon) `
  ${components_1.LabeledIconText} {
    align-items: baseline;
    justify-content: space-between;
    flex: 1;
  }
`;
