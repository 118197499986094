"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GTMHeadScript = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
/* eslint-disable max-len */
const react_helmet_1 = require("react-helmet");
const config_1 = require("client/config");
const GTMHeadScript = () => {
    const { gtm, storyblok } = (0, config_1.useAppConfig)();
    if (storyblok.version === 'draft' || !gtm) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(react_helmet_1.Helmet, { children: (0, jsx_runtime_1.jsx)("script", { id: "gtm-tag", async: true, children: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtm.accountId}');` }) }));
};
exports.GTMHeadScript = GTMHeadScript;
