"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingMobileRoomSwitch = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const commons_1 = require("@gerber/commons");
const ui_1 = require("client/ui");
const context_1 = require("client/modules/booking/booking-wizard/context");
const i18n_1 = require("client/i18n");
exports.BookingMobileRoomSwitch = (0, react_1.memo)(() => {
    const { pack } = (0, i18n_1.useI18n)();
    const { value, bind } = (0, context_1.useBookingGlobalForm)();
    const { rooms } = value;
    const shortForm = rooms.length >= 4;
    const tabs = (0, react_1.useMemo)(() => rooms.map((_, index) => ({
        id: index,
        name: (0, commons_1.format)(pack.booking.wizard.summary[shortForm ? 'roomShort' : 'room'], { index: index + 1 }),
    })), [rooms]);
    return ((0, jsx_runtime_1.jsx)(ui_1.Tabs, { tabs: tabs, ...bind.path('meta.focusedRoomIndex', {
            output: val => +val,
        }) }));
});
exports.BookingMobileRoomSwitch.displayName = 'BookingMobileRoomSwitch';
