"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useExitBookingWizard = void 0;
const client_1 = require("@gerber/ssr/dist/client");
const context_1 = require("../context");
const useExitBookingWizard = () => {
    const config = (0, context_1.useBookingWizardConfigContext)();
    const isInitialRoute = (0, client_1.useIsInitialHTTPClientHistoryRoute)();
    return () => {
        // close tab if user entered site from hotel site as new tab
        if (isInitialRoute &&
            document.referrer.startsWith(config.hotel.websiteUrl)) {
            window.close();
        }
        else if (isInitialRoute && document.referrer === '') {
            document.location.href = config.hotel.websiteUrl;
        }
        else {
            history.back();
        }
    };
};
exports.useExitBookingWizard = useExitBookingWizard;
