"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbNavigationToolbar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const items_1 = require("./items");
const storyblok_navigation_toolbar_styled_1 = require("./storyblok-navigation-toolbar.styled");
const ui_1 = require("client/ui");
const SbNavigationToolbar = ({ item, truncated, }) => {
    const { homepage, phone } = item;
    return ((0, jsx_runtime_1.jsxs)(storyblok_navigation_toolbar_styled_1.SbNavigationToolbarHolder, { children: [homepage && ((0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)(items_1.SbNavigationHomepageItem, { truncated: truncated, link: homepage }) })), phone && ((0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)(items_1.SbNavigationPhoneItem, { phone: phone }) })), (0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)(ui_1.AppChangeLangSelect, { fillIfValueIsPresent: false }) })] }));
};
exports.SbNavigationToolbar = SbNavigationToolbar;
