"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckboxTitleHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.CheckboxTitleHolder = styled_components_1.styled.span `
  ${({ theme }) => (0, styled_components_1.css) `
    margin-left: ${theme.spacing[3]};
    font-size: ${theme.font.size.base};
    line-height: ${theme.lineHeight[5]};
  `}

  ${({ $disabled }) => $disabled &&
    (0, styled_components_1.css) `
      pointer-events: none;
    `}

  ${commons_front_1.styles.mediaSizeUp.sm} &:hover {
    text-decoration: underline;
  }
`;
