"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingCalendarCellTitleToolbarHolder = exports.BookingCalendarCellTitleInnerHolder = exports.BookingCalendarCellTitleHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const connectors_1 = require("../connectors");
exports.BookingCalendarCellTitleHolder = styled_components_1.styled.span `
  ${commons_front_1.styles.flexCenter}

  width: 100%;
  text-transform: uppercase;
  z-index: 5;

  ${({ $hasTopSpace }) => (0, connectors_1.connectorSizeBoxStyle)($hasTopSpace)}

  ${({ theme: { font } }) => (0, styled_components_1.css) `
    ${commons_front_1.styles.mediaSizeUp.md} {
      font-size: ${font.size.xl};
    }
  `}

  ${({ $selected, $shrink, theme: { color } }) => $selected &&
    (0, styled_components_1.css) `
      color: ${color.alias.white.main};
      font-weight: 600;

      &::before {
        content: '';

        ${commons_front_1.styles.absoluteCenter}

        height: 100%;
        background: ${color.alias.black.main};
        z-index: 0;

        ${$shrink
        ? (0, styled_components_1.css) `
              width: 28px;
            `
        : (0, styled_components_1.css) `
              aspect-ratio: 1.25;
              border-radius: 30px;
            `}
      }
    `}

  ${({ $hasSelectedToolbar, $shrink }) => !$shrink &&
    (0, styled_components_1.css) `
      &::before {
        ${$hasSelectedToolbar
        ? (0, styled_components_1.css) `
              margin-left: -12px;
              width: 58px;
            `
        : (0, styled_components_1.css) `
              width: 40px;
            `}

        ${commons_front_1.styles.mediaSizeUp.md} {
          ${$hasSelectedToolbar
        ? (0, styled_components_1.css) `
                width: 80px;
                margin-left: -15px;
              `
        : (0, styled_components_1.css) `
                width: 50px;
              `}
        }
      }
    `}
`;
exports.BookingCalendarCellTitleInnerHolder = styled_components_1.styled.span `
  position: relative;
  z-index: 1;
`;
exports.BookingCalendarCellTitleToolbarHolder = styled_components_1.styled.span `
  ${commons_front_1.styles.flexCenter}

  position: absolute;
  left: calc(50% - 45px);
  top: 50%;
  transform: translateY(-50%);

  ${({ theme: { color, font } }) => (0, styled_components_1.css) `
    color: ${color.alias.black.contrastText};
    font-size: ${font.size.xs};
    font-weight: 500;
  `}

  ${commons_front_1.styles.mediaSizeDown.md} {
    left: calc(50% - 35px);
  }
`;
