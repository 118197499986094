"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NativeSelectInputHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
const styles_1 = require("../styles");
exports.NativeSelectInputHolder = (0, styled_components_1.styled)(styles_1.CustomSelectInputHolder) `
  select {
    ${commons_front_1.styles.layer.absolute}

    opacity: 0;
    margin: 0;
  }
`;
