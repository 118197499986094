"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingErrorConfirm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const components_1 = require("../components");
const use_booking_confirm_tracker_1 = require("../use-booking-confirm-tracker");
const components_2 = require("client/components");
const i18n_1 = require("client/i18n");
const BookingErrorConfirm = () => {
    const t = (0, i18n_1.useI18n)().pack.booking.confirm.error;
    (0, use_booking_confirm_tracker_1.useBookingConfirmTracker)('error');
    return ((0, jsx_runtime_1.jsxs)(components_1.BookingConfirmHolder, { children: [(0, jsx_runtime_1.jsx)(components_1.BookingConfirmHeader, { dangerouslySetInnerHTML: { __html: t.title } }), (0, jsx_runtime_1.jsx)(components_2.Spacer, { "$y": 7 }), (0, jsx_runtime_1.jsx)(components_2.Text, { dangerouslySetInnerHTML: { __html: t.description }, size: "lg" })] }));
};
exports.BookingErrorConfirm = BookingErrorConfirm;
