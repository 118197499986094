"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingOfferToolbarList = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
/* eslint-disable react/display-name */
const forms_1 = require("@under-control/forms");
const sdk_gerber_1 = require("@gerber/sdk-gerber");
const react_1 = require("react");
const item_1 = require("./item");
const components_1 = require("client/components");
exports.BookingOfferToolbarList = (0, forms_1.controlled)((0, react_1.forwardRef)(({ withDescription, offer, readOnly, control: { value, setValue } }, ref) => {
    const isSelectedItem = (item) => !!value && (0, sdk_gerber_1.isExtendedOfferEqual)(item)(value);
    const onToggle = (item) => (toggled) => {
        setValue({
            value: toggled ? item : null,
            merge: false,
        });
    };
    return ((0, jsx_runtime_1.jsx)(components_1.CleanList, { ref: ref, children: offer.offers.map(item => ((0, jsx_runtime_1.jsx)(item_1.BookingOfferToolbarItem, { offer: item, withDescription: withDescription, readOnly: readOnly, value: isSelectedItem(item), onChange: onToggle(item) }, `${item.type}-${item.rateId}`))) }));
}));
