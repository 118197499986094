"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormGroupInvalidHtmlInputHolder = void 0;
const styled_components_1 = require("styled-components");
exports.FormGroupInvalidHtmlInputHolder = styled_components_1.styled.input `
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: block;
  pointer-events: none;
`;
