"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingDesktopSidebarSummaryShare = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const booking_desktop_sidebar_summary_share_styled_1 = require("./booking-desktop-sidebar-summary-share.styled");
const i18n_1 = require("client/i18n");
const components_1 = require("client/components");
const shared_1 = require("client/modules/booking/booking-wizard/parts/shared");
exports.BookingDesktopSidebarSummaryShare = (0, react_1.memo)(() => {
    const { pack } = (0, i18n_1.useI18n)();
    return ((0, jsx_runtime_1.jsxs)(booking_desktop_sidebar_summary_share_styled_1.SidebarSummaryShareHolder, { children: [(0, jsx_runtime_1.jsx)(components_1.Text, { color: "white", weight: 600, children: pack.booking.wizard.share.shareYourProgress }), (0, jsx_runtime_1.jsxs)(components_1.CleanList, { "$row": true, "$gap": 2, children: [(0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)(shared_1.ShareAndContinueButton, { "$size": "xs" }) }), (0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)(shared_1.ShareSessionCopyButton, { "$size": "xs" }) })] })] }));
});
exports.BookingDesktopSidebarSummaryShare.displayName =
    'BookingDesktopSidebarSummaryShare';
