"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingSidebarTruncatedHeaderToolbarHolder = void 0;
const styled_components_1 = require("styled-components");
const components_1 = require("client/components");
exports.BookingSidebarTruncatedHeaderToolbarHolder = (0, styled_components_1.styled)(components_1.CleanList) `
  grid-area: toolbar;
  justify-content: flex-end;
  align-items: center;
`;
