"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbHotelBookCTA = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const commons_front_1 = require("@gerber/commons-front");
const icons_1 = require("@gerber/icons");
const components_1 = require("client/components");
const ui_1 = require("client/ui");
const i18n_1 = require("client/i18n");
const hooks_1 = require("client/hooks");
const SbHotelBookCTA = ({ oneId, ...props }) => {
    const { pack } = (0, i18n_1.useI18n)();
    const sitemap = (0, hooks_1.useSitemap)();
    const searchParams = (0, commons_front_1.useGetSearchParams)();
    return ((0, jsx_runtime_1.jsx)(components_1.UniversalLink, { href: sitemap.wizard.root({
            oneId,
            search: { skipWelcome: true, ...searchParams },
        }), ...props, children: (0, jsx_runtime_1.jsxs)(ui_1.Button, { "$kind": "outline", as: "span", children: [pack.landingPage.hotel.bookRoom, (0, jsx_runtime_1.jsx)(ui_1.ButtonSuffixIcon, { children: (0, jsx_runtime_1.jsx)(icons_1.LongArrowRightThinIcon, {}) })] }) }));
};
exports.SbHotelBookCTA = SbHotelBookCTA;
