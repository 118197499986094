"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkPageCustomizationV = void 0;
const zod_1 = require("zod");
exports.SdkPageCustomizationV = zod_1.z.object({
    hotelName: zod_1.z.string(),
    phone: zod_1.z.string().default('+43 5239 5207'),
    imprintUrl: zod_1.z.string(),
    termsUrl: zod_1.z.string(),
    gdprUrl: zod_1.z.string(),
    email: zod_1.z.string().optional(),
    googleTagManagerTag: zod_1.z.string().optional().nullable(),
    address: zod_1.z.string().optional().nullable(),
    logoUrl: zod_1.z
        .string()
        .default('https://images.samera.one/mooshaus/logo-1682499081543.png'),
    headerImageUrl: zod_1.z
        .string()
        .default('https://images.samera.one/mooshaus/header-1682498947451.jpg'),
    contactUrl: zod_1.z.string().default('https://mooshaus.at/anfrage.html'),
    cancellationUrl: zod_1.z.string(),
});
