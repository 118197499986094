"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Badge = void 0;
const styled_components_1 = require("styled-components");
const function_1 = require("fp-ts/function");
const commons_front_1 = require("@gerber/commons-front");
const theme_1 = require("client/theme");
exports.Badge = styled_components_1.styled.span `
  display: inline-flex;
  padding: 3px 8px;
  white-space: nowrap;

  ${({ theme: { font } }) => (0, styled_components_1.css) `
    font-size: ${font.size.xs};
    font-weight: 500;
  `}

  ${({ $kind = 'gray', theme: { color } }) => {
    switch ($kind) {
        case 'outline':
            return (0, styled_components_1.css) `
          border: 1px solid ${color.alias.gray.main};
          color: ${color.alias.black.main};
        `;
        default:
            return (0, function_1.pipe)($kind, (0, theme_1.extractThemeColorInv)(color), commons_front_1.genPaletteColorCSS);
    }
}}

  ${({ $uppercase }) => $uppercase &&
    (0, styled_components_1.css) `
      text-transform: uppercase;
    `}

  ${({ $hoverable }) => $hoverable &&
    (0, styled_components_1.css) `
      cursor: pointer;

      ${commons_front_1.styles.hoverCardTransformAnim({
        shadowOpacity: 0.18,
        margin: -1,
        shadow: '0px 4px 8px',
    })}
    `}
`;
