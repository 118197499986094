"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingContactFormGrid = void 0;
const styled_components_1 = require("styled-components");
exports.BookingContactFormGrid = styled_components_1.styled.div `
  display: grid;
  max-width: 600px;

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    gap: ${spacing[6]};
  `};
`;
