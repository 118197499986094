"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectPeopleAmountWithAge = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const function_1 = require("fp-ts/function");
const Array_1 = require("fp-ts/Array");
const forms_1 = require("@under-control/forms");
const age_select_input_1 = require("./age-select-input");
const select_people_amount_with_age_types_1 = require("./select-people-amount-with-age.types");
const ui_1 = require("client/ui");
exports.SelectPeopleAmountWithAge = (0, forms_1.controlled)(({ control: { value, setValue }, errors, ageRange, ...props }) => {
    const onChange = (items) => {
        const mappedItems = (0, function_1.pipe)(items, (0, Array_1.map)((0, select_people_amount_with_age_types_1.ofSelectAmountItemWithAge)(null)));
        setValue({
            value: mappedItems,
        });
    };
    const renderMetaFieldFn = ({ active, control: metaControl, }) => {
        if (!active) {
            return null;
        }
        return ((0, jsx_runtime_1.jsx)(age_select_input_1.AgeSelectInput, { ...metaControl.bind.path('age'), borderless: true, ageRange: ageRange, prependEmptyOption: false, showClearButton: false, fillIfValueIsPresent: false, inputProps: {
                style: {
                    paddingLeft: 12,
                    outline: 0,
                },
            } }));
    };
    return ((0, jsx_runtime_1.jsx)(ui_1.SelectAmount, { errors: errors, renderMetaFieldFn: renderMetaFieldFn, value: value, onChange: onChange, ...props }));
});
