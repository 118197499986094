"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.restoreWhoFromSessionItem = void 0;
const A = __importStar(require("fp-ts/Array"));
const function_1 = require("fp-ts/function");
const commons_1 = require("@gerber/commons");
const restoreWhoFromSessionItem = (sessionItem) => {
    const transformGuestToWizardValue = (index) => (guest) => ({
        number: index + 1,
        info: {
            birthDate: guest.birthdate ?? null,
            firstName: guest.firstname ?? null,
            lastName: guest.lastname ?? null,
            meta: (0, commons_1.createBlankEditorIdMeta)(),
        },
    });
    const adults = (0, function_1.pipe)(sessionItem.guests, A.reduce([], (acc, guest) => {
        if (guest.type === 'ADULT') {
            return [...acc, transformGuestToWizardValue(acc.length)(guest)];
        }
        return acc;
    }));
    const children = (0, function_1.pipe)(sessionItem.guests, A.reduce([], (acc, guest) => {
        if ((0, commons_1.isNil)(guest.childAge) || guest.type !== 'CHILD') {
            return acc;
        }
        return [
            ...acc,
            {
                ...transformGuestToWizardValue(acc.length)(guest),
                age: guest.childAge,
            },
        ];
    }));
    return {
        children,
        adults,
    };
};
exports.restoreWhoFromSessionItem = restoreWhoFromSessionItem;
