"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_1 = require("@gerber/icons");
const SbIcon = ({ icon, ...props }) => {
    const Component = icons_1.ICONS_MAP[icon];
    if (!Component) {
        console.warn('Missing icon:', icon);
    }
    return (0, jsx_runtime_1.jsx)(Component, { ...props });
};
exports.SbIcon = SbIcon;
