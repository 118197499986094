"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormGroupHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.FormGroupHolder = (0, styled_components_1.styled)(commons_front_1.GridSpan).withConfig({
    shouldForwardProp: (0, commons_front_1.shouldNotForwardProps)([
        'space',
        'gap',
        'limitWidth',
        'row',
        'area',
    ]),
}) `
  position: relative;

  &:empty {
    display: none;
  }

  ${({ area }) => area &&
    (0, styled_components_1.css) `
      grid-area: ${area};
    `}

  ${({ theme, row, gap = 4 }) => row &&
    (0, styled_components_1.css) `
      display: flex;
      flex-direction: row;
      gap: ${theme.spacing[gap]};
      place-items: center;
    `}

  ${({ theme, space = 1 }) => space &&
    (0, styled_components_1.css) `
      &:not(:last-child) {
        margin-bottom: ${theme.spacing[space]};
      }
    `}

  ${({ limitWidth }) => limitWidth &&
    (0, styled_components_1.css) `
      max-width: 250px;
    `}
`;
