"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokLinkV = void 0;
const zod_1 = require("zod");
const SdkStoryblokLinkTypeV = zod_1.z.enum(['story', 'url']);
exports.SdkStoryblokLinkV = zod_1.z.object({
    linktype: SdkStoryblokLinkTypeV,
    target: zod_1.z.string().optional(),
    cached_url: zod_1.z.string().optional(),
});
