"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getApproxFocusedMonthName = exports.getApproxFocusedMonth = void 0;
const function_1 = require("fp-ts/function");
const date_fns_1 = require("date-fns");
const commons_1 = require("@gerber/commons");
const constants_1 = require("client/modules/booking/booking-calendar/constants");
const getApproxFocusedMonth = (date) => {
    const distanceToStartOfNextMonth = (0, date_fns_1.getDaysInMonth)(date) - date.getDate();
    if (distanceToStartOfNextMonth < constants_1.CALENDAR_MONTH_TOTAL_CELLS / 2) {
        const shiftedDate = (0, date_fns_1.addMonths)(date, 1);
        return {
            month: shiftedDate.getMonth(),
            date: shiftedDate,
        };
    }
    return {
        month: date.getMonth(),
        date,
    };
};
exports.getApproxFocusedMonth = getApproxFocusedMonth;
/**
 * Calendar can display few days from previous month after clicking top arrow.
 * Arrow scrolls calendar week by week. We want to display previous month only if
 * there is more than one week visible.
 */
const getApproxFocusedMonthName = (lang) => (date) => (0, function_1.pipe)(date, exports.getApproxFocusedMonth, obj => ({
    ...obj,
    name: (0, commons_1.getMonthName)(lang)(obj.month),
}));
exports.getApproxFocusedMonthName = getApproxFocusedMonthName;
