"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingCalendarCellHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.BookingCalendarCellHolder = styled_components_1.styled.div `
  ${commons_front_1.styles.flexCenter}

  position: relative;
  height: 70px;
  flex-direction: column;
  place-content: start center;
  text-align: center;
  transition: 150ms ease-in-out;
  transition-property: color, background;

  ${({ theme: { color, spacing } }) => (0, styled_components_1.css) `
    gap: 6px;
    padding: ${spacing[3]} ${spacing[2]} ${spacing[2]};
    background: ${color.alias.white.main};
  `}

  ${({ $disabled, theme: { color } }) => {
    if ($disabled) {
        return (0, styled_components_1.css) `
        pointer-events: none;
        color: ${color.alias.solidGray.main};
      `;
    }
    return (0, styled_components_1.css) `
      color: ${color.alias.black.main};
      cursor: pointer;

      &:hover {
        background: ${color.alias.gray.main};
      }
    `;
}}

  ${commons_front_1.styles.mediaSizeUp.md} {
    height: 96px;
  }
`;
