"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToolbarItemHolder = void 0;
const styled_components_1 = require("styled-components");
const commons_front_1 = require("@gerber/commons-front");
exports.ToolbarItemHolder = styled_components_1.styled.button.withConfig({
    shouldForwardProp: (0, commons_front_1.shouldNotForwardProps)(['active']),
}) `
  ${commons_front_1.cleanButtonStyle}
  ${commons_front_1.styles.disabledInput}

  position: relative;
  flex-direction: row;
  z-index: 2;
  border: 1px solid;
  border-bottom: 0;

  ${({ theme: { font, radius } }) => (0, styled_components_1.css) `
    border-top-left-radius: ${radius[2]};
    border-top-right-radius: ${radius[2]};
    font-size: ${font.size.sm};
  `}

  ${({ active, theme: { color, spacing } }) => {
    if (active) {
        return (0, styled_components_1.css) `
        ${(0, commons_front_1.genPaletteColorCSS)(color.parts.tabs.active)}

        top: 1px;
        padding: ${spacing[3]} ${spacing[4]};
        border-color: ${color.parts.tabs.active.border};
        font-weight: 600;
      `;
    }
    return (0, styled_components_1.css) `
      ${(0, commons_front_1.genPaletteColorCSS)(color.parts.tabs.inactive)}

      top: 4px;
      padding: ${spacing[2]} ${spacing[4]};
      border-color: ${color.parts.tabs.inactive.border};
    `;
}}
`;
