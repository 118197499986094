"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NativeSelectInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const forms_1 = require("@under-control/forms");
const commons_1 = require("@gerber/commons");
const helpers_1 = require("../helpers");
const hooks_1 = require("../hooks");
const styles_1 = require("../styles");
const native_select_input_styled_1 = require("./native-select-input.styled");
exports.NativeSelectInput = (0, forms_1.controlled)(({ control, style, className, compact = true, items, inputProps, expanded, showArrow = true, borderless, prependEmptyOption = true, formatSelectedValue, }) => {
    const { value, setValue } = control;
    const mappedItems = (0, react_1.useMemo)(() => (0, helpers_1.toSelectItems)(true)(items), [items]);
    const serializeSelectedID = typeof mappedItems[0]?.id === 'number'
        ? (num) => Number.parseInt(num, 10)
        : commons_1.identity;
    const selected = (0, hooks_1.useSelectedItem)(value, mappedItems ?? []);
    const onChange = (event) => {
        const newId = (0, forms_1.pickEventValue)(event);
        setValue({
            value: (0, commons_1.findItemById)(serializeSelectedID(newId))(mappedItems) ?? null,
        });
    };
    return ((0, jsx_runtime_1.jsx)(styles_1.CustomSelectInputWrapper, { className: className, style: style, "$compact": compact, children: (0, jsx_runtime_1.jsxs)(native_select_input_styled_1.NativeSelectInputHolder, { ...inputProps, "$borderless": borderless, "$expanded": expanded, "$showArrow": showArrow, children: [(0, jsx_runtime_1.jsxs)("select", { value: selected?.id ?? ' ', onChange: onChange, children: [prependEmptyOption && ((0, jsx_runtime_1.jsx)("option", { value: ' ', label: typeof prependEmptyOption === 'string'
                                ? prependEmptyOption
                                : ' ' })), mappedItems.map(item => ((0, jsx_runtime_1.jsx)("option", { value: item.id, children: item.text ?? item.name }, item.id)))] }), formatSelectedValue?.(selected) ?? selected?.name] }) }));
});
