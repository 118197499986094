"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SdkStoryblokPageBlockV = void 0;
const zod_1 = require("zod");
const helpers_1 = require("../../../helpers");
const sdk_storyblok_block_dto_1 = require("../../sdk-storyblok-block.dto");
const sdk_storyblok_singleton_dto_1 = require("../../sdk-storyblok-singleton.dto");
const navigation_1 = require("../navigation");
const layout_1 = require("../layout");
const footer_1 = require("../footer");
const SdkStoryblokPageThemeV = zod_1.z.enum([
    'default',
    'orange',
    'pink',
    'light-pink',
    'cyan',
    'gold',
]);
const SdkStoryblokPageContentComponentV = zod_1.z.union([
    layout_1.SdkStoryblokGridBlockV,
    layout_1.SdkStoryblokSectionBlockV,
]);
const SdkStoryblokPageMetaTagsV = zod_1.z
    .object({
    title: zod_1.z.string(),
    description: zod_1.z.string(),
    og_image: zod_1.z.string(),
    og_title: zod_1.z.string(),
    og_description: zod_1.z.string(),
    twitter_description: zod_1.z.string(),
    twitter_title: zod_1.z.string(),
    twitter_image: zod_1.z.string(),
})
    .transform(helpers_1.normalizeStoryblokDTO);
exports.SdkStoryblokPageBlockV = (0, sdk_storyblok_block_dto_1.SdkStoryblokBlockV)('Page', {
    metatags: SdkStoryblokPageMetaTagsV,
    navigation: (0, sdk_storyblok_singleton_dto_1.SdkStorySingletonV)(navigation_1.SdkStoryblokNavigationBlockV),
    content: zod_1.z.array(SdkStoryblokPageContentComponentV),
    footer: (0, sdk_storyblok_singleton_dto_1.SdkStorySingletonV)(footer_1.SdkStoryblokFooterBlockV),
    theme: SdkStoryblokPageThemeV.optional(),
});
