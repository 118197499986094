"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingTermsSection = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
/* eslint-disable react-hooks/rules-of-hooks */
const react_1 = require("react");
const forms_1 = require("@under-control/forms");
const commons_front_1 = require("@gerber/commons-front");
const i18n_1 = require("client/i18n");
const context_1 = require("client/modules/booking/booking-wizard/context");
const components_1 = require("client/components");
const ui_1 = require("client/ui");
exports.BookingTermsSection = (0, react_1.memo)((0, forms_1.controlled)(({ errors, control: { bind }, ...props }) => {
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.terms;
    const validation = (0, forms_1.useFormValidatorMessages)({ errors });
    const config = (0, context_1.useBookingWizardConfigContext)();
    return ((0, jsx_runtime_1.jsxs)(ui_1.Section, { title: t.title, ...props, children: [(0, jsx_runtime_1.jsx)(ui_1.FormGroup, { required: true, space: 4, error: (0, jsx_runtime_1.jsx)(ui_1.ValidationErrorsHint, { ...validation.extract('general') }), children: (0, jsx_runtime_1.jsx)(ui_1.Checkbox, { ...bind.path('general'), shape: "square", children: (0, commons_front_1.reactFormat)(t.agree.generalTerms.title, {
                        anchor: ((0, jsx_runtime_1.jsx)(components_1.UniversalLink, { stopPropagation: true, color: "inherit", target: "_blank", decoration: "underline", href: config.hotel.termsUrl, children: t.agree.generalTerms.anchor })),
                    }) }) }), (0, jsx_runtime_1.jsx)(ui_1.FormGroup, { required: true, space: 0, error: (0, jsx_runtime_1.jsx)(ui_1.ValidationErrorsHint, { ...validation.extract('security') }), children: (0, jsx_runtime_1.jsx)(ui_1.Checkbox, { ...bind.path('security'), shape: "square", children: (0, commons_front_1.reactFormat)(t.agree.securityTerms.title, {
                        anchor: ((0, jsx_runtime_1.jsx)(components_1.UniversalLink, { stopPropagation: true, color: "inherit", target: "_blank", decoration: "underline", href: config.hotel.gdprUrl, children: t.agree.securityTerms.anchor })),
                    }) }) })] }));
}));
exports.BookingTermsSection.displayName = 'BookingTermsSection';
