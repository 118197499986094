"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingMobileStartJumbotron = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const booking_mobile_start_jumbotron_styled_1 = require("./booking-mobile-start-jumbotron.styled");
const parts_1 = require("./parts");
const context_1 = require("client/modules/booking/booking-wizard/context");
exports.BookingMobileStartJumbotron = (0, react_1.memo)(() => {
    const { start } = (0, context_1.useBookingWizardConfigContext)();
    return ((0, jsx_runtime_1.jsxs)(booking_mobile_start_jumbotron_styled_1.BookingMobileStartJumbotronHolder, { children: [(0, jsx_runtime_1.jsx)(parts_1.BookingMobileStartBackground, { src: start.background }), (0, jsx_runtime_1.jsx)(parts_1.BookingMobileStartToolbar, {}), (0, jsx_runtime_1.jsx)(parts_1.BookingMobileStartLogo, { src: start.logo }), (0, jsx_runtime_1.jsx)(parts_1.BookingMobileStartFooter, {})] }));
});
exports.BookingMobileStartJumbotron.displayName = 'BookingMobileStartJumbotron';
