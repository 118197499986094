"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingConfirmHeader = void 0;
const styled_components_1 = require("styled-components");
const components_1 = require("client/components");
exports.BookingConfirmHeader = (0, styled_components_1.styled)(components_1.H1).attrs({
    color: 'primary',
}) `
  font-size: 2.5rem;
  line-height: 1.3em;
  font-weight: 600;
`;
