"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoreThanOneRoomAlert = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const commons_1 = require("@gerber/commons");
const more_than_one_room_alert_styled_1 = require("./more-than-one-room-alert.styled");
const i18n_1 = require("client/i18n");
exports.MoreThanOneRoomAlert = (0, react_1.memo)(({ totalRooms }) => {
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.sidebar.alerts;
    return ((0, jsx_runtime_1.jsx)(more_than_one_room_alert_styled_1.MoreThanOneRoomAlertHolder, { dangerouslySetInnerHTML: {
            __html: (0, commons_1.format)(t.moreThanOneRoomSelected, { count: totalRooms }),
        } }));
});
exports.MoreThanOneRoomAlert.displayName = 'MoreThanOneRoomAlert';
