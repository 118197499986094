"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingEndingContent = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const NonEmptyArray_1 = require("fp-ts/NonEmptyArray");
const commons_front_1 = require("@gerber/commons-front");
const icons_1 = require("@gerber/icons");
const booking_ending_content_styled_1 = require("./booking-ending-content.styled");
const components_1 = require("client/components");
const ui_1 = require("client/ui");
const form_1 = require("client/modules/booking/booking-wizard/form");
const i18n_1 = require("client/i18n");
const context_1 = require("client/modules/booking/booking-wizard/context");
exports.BookingEndingContent = (0, react_1.memo)(({ hideBack }) => {
    const t = (0, i18n_1.useI18n)().pack.booking.wizard.ending;
    const isMobile = (0, commons_front_1.useIsMobile)();
    const navigation = (0, context_1.useBookingWizardNavigation)();
    const { value, setValue } = (0, context_1.useBookingGlobalForm)();
    const onAddNewRoom = () => {
        const newRooms = (0, NonEmptyArray_1.concat)([(0, form_1.createBlankBookingRoom)()])(value.rooms);
        setValue({
            merge: true,
            value: {
                meta: {
                    ...value.meta,
                    focusedRoomIndex: value.rooms.length,
                },
                rooms: newRooms,
            },
        });
        navigation.goTo('who');
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(booking_ending_content_styled_1.BookingEndingHeader, { children: t.header }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 9, "$max-xs:y": 7 }), (0, jsx_runtime_1.jsx)(components_1.Text, { size: isMobile ? 'xl' : '2xl', dangerouslySetInnerHTML: { __html: t.subtitle } }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 10, "$max-xs:y": 8 }), (0, jsx_runtime_1.jsxs)(components_1.CleanList, { "$row": true, "$gap": 4, children: [!isMobile && !hideBack && ((0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsx)(ui_1.BackButton, { type: "button", onClick: navigation.context.prev }) })), (0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsxs)(ui_1.Button, { type: "button", onClick: navigation.context.next, "$weight": 600, "$hoverable": true, children: [t.cta.completeBooking, (0, jsx_runtime_1.jsx)(ui_1.ButtonSuffixIcon, { children: (0, jsx_runtime_1.jsx)(icons_1.CheckIcon, { strokeWidth: 2 }) })] }) })] }), (0, jsx_runtime_1.jsx)(components_1.Divider, { "$space": 12, "$max-sm:space": 7, "$truncated": true }), (0, jsx_runtime_1.jsx)(components_1.Text, { size: isMobile ? 'lg' : 'xl', children: t.addMoreRooms }), (0, jsx_runtime_1.jsx)(components_1.Spacer, { "$y": 7 }), (0, jsx_runtime_1.jsxs)(ui_1.Button, { type: "button", "$kind": "outline", "$hoverable": true, onClick: onAddNewRoom, children: [t.cta.bookAnotherRoom, (0, jsx_runtime_1.jsx)(ui_1.ButtonSuffixIcon, { children: (0, jsx_runtime_1.jsx)(icons_1.PlusThinIcon, {}) })] })] }));
});
exports.BookingEndingContent.displayName = 'BookingEndingContent';
